import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

export default ({ member, affiliations }) => {

    const { adressBte,
        adressCity,
        adressNum,
        adressPostalCode,
        adressStreet,
        birthDate,
        email,
        firstName,
        gender,
        groupIdentification,
        groupName,
        gsm,
        identification,
        lastName,
        phone } = member;

    return (<Document>
        <Page style={styles.body}>

            <Text style={styles.header} fixed>FFBMP</Text>
            <Text style={styles.title}>Fiche Affilié</Text>

            <Text style={styles.subtitle}>Club de l'affilié</Text>
            <View style={styles.table}>
                <View style={styles.tableRow}>
                    <View style={styles.tableCol4}>
                        <Text style={styles.tableCell}>Matricule du club</Text>
                    </View>
                    <View style={styles.tableCol8}>
                        <Text style={styles.tableCell}>{groupIdentification ?? ""}</Text>
                    </View>
                </View>
                <View style={styles.tableRow}>
                    <View style={styles.tableCol4}>
                        <Text style={styles.tableCell}>Dénomination du club</Text>
                    </View>
                    <View style={styles.tableCol8}>
                        <Text style={styles.tableCell}>{groupName ?? ""}</Text>
                    </View>
                </View>
            </View>
            <Text style={styles.subtitle}>Signalétique de l'affilié</Text>
            <View style={styles.table}>
                <View style={styles.tableRow}>
                    <View style={styles.tableCol4}>
                        <Text style={styles.tableCell}>Matricule de l'affilié</Text>
                    </View>
                    <View style={styles.tableCol8}>
                        <Text style={styles.tableCell}>{identification ?? "/"}</Text>
                    </View>
                </View>
                <View style={styles.tableRow}>
                    <View style={styles.tableCol4}>
                        <Text style={styles.tableCell}>Nom</Text>
                    </View>
                    <View style={styles.tableCol8}>
                        <Text style={styles.tableCell}>{lastName ?? " "}</Text>
                    </View>
                </View>
                <View style={styles.tableRow}>
                    <View style={styles.tableCol4}>
                        <Text style={styles.tableCell}>Prénom</Text>
                    </View>
                    <View style={styles.tableCol8}>
                        <Text style={styles.tableCell}>{firstName ?? " "}</Text>
                    </View>
                </View>
                <View style={styles.tableRow}>
                    <View style={styles.tableCol4}>
                        <Text style={styles.tableCell}>Date de naissance</Text>
                    </View>
                    <View style={styles.tableCol8}>
                        <Text style={styles.tableCell}>{(birthDate && new Date(birthDate).toLocaleDateString("fr-FR")) ?? " "}</Text>
                    </View>
                </View>
                <View style={styles.tableRow}>
                    <View style={styles.tableCol4}>
                        <Text style={styles.tableCell}>Sexe</Text>
                    </View>
                    <View style={styles.tableCol8}>
                        <Text style={styles.tableCell}>{gender ?? " "}</Text>
                    </View>
                </View>
                <View style={styles.tableRow}>
                    <View style={styles.tableCol4}>
                        <Text style={styles.tableCell}>Chef de Famille</Text>
                    </View>
                    <View style={styles.tableCol8}>
                        <Text style={styles.tableCell}>{member.hasOwnProperty("familyChief") ? (member.familyChief ? "OUI" : "NON") : "OUI - NON"}</Text>
                    </View>
                </View>
                <View style={styles.tableRow}>
                    <View style={styles.tableCol4}>
                        <Text style={styles.tableCell}>Adresse</Text>
                    </View>
                    <View style={styles.tableCol8}>
                        <Text style={styles.tableCell}>{`${adressStreet ?? " "} ${adressNum ?? " "}${(adressBte ? 'Bte ' + adressBte : "")} - ${adressPostalCode ?? " "} ${adressCity ?? " "}`}</Text>
                    </View>
                </View>
                <View style={styles.tableRow}>
                    <View style={styles.tableCol4}>
                        <Text style={styles.tableCell}>Téléphone</Text>
                    </View>
                    <View style={styles.tableCol8}>
                        <Text style={styles.tableCell}>{phone ?? " "}</Text>
                    </View>
                </View>
                <View style={styles.tableRow}>
                    <View style={styles.tableCol4}>
                        <Text style={styles.tableCell}>Mobile</Text>
                    </View>
                    <View style={styles.tableCol8}>
                        <Text style={styles.tableCell}>{gsm ?? " "}</Text>
                    </View>
                </View>
                <View style={styles.tableRow}>
                    <View style={styles.tableCol4}>
                        <Text style={styles.tableCell}>Email</Text>
                    </View>
                    <View style={styles.tableCol8}>
                        <Text style={styles.tableCell}>{email ?? " "}</Text>
                    </View>
                </View>
            </View>
            <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (`Page: ${pageNumber} / ${totalPages} (Date: ${new Date().toLocaleDateString()})`)} fixed />
        </Page>
        <Page style={styles.body}>
            <Text style={styles.subtitle}>Historique de l'affilié</Text>
            <View style={{ borderStyle: "solid", borderColor: '#000', borderWidth: 1, padding: 10 }}>
                {affiliations?.map(c => <Text style={{ fontSize: 12, marginBottom:5 }} key={c.id}>{`${c?.start ? new Date(c.start).getFullYear() : '/'} à ${c?.end ? new Date(c.end).getFullYear() : 'Aujourd\'hui'} | ${c.groupIdentification} - ${c.groupName}`}</Text>)}
            </View>
        </Page>
    </Document>);
};

const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    title: {
        fontSize: 15,
        textAlign: 'center',
        fontFamily: 'Times-Roman'
    },
    author: {
        fontSize: 12,
        textAlign: 'center',
        marginBottom: 40,
    },
    subtitle: {
        fontSize: 18,
        margin: 12,
        fontFamily: 'Times-Roman'
    },
    text: {
        margin: 12,
        fontSize: 14,
        textAlign: 'justify',
        fontFamily: 'Times-Roman'
    },
    image: {
        marginVertical: 15,
        marginHorizontal: 100,
    },
    header: {
        fontSize: 12,
        marginBottom: 20,
        textAlign: 'center',
        color: 'grey',
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    tableColHeader: {
        width: "25%",
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderBottomColor: '#000',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCol12: {
        width: "100%",
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCol8: {
        width: "66%",
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCol6: {
        width: "50%",
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCol4: {
        width: "33%",
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCol3: {
        width: "25%",
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCellHeader: {
        margin: 5,
        fontSize: 12,
        fontWeight: 500
    },
    tableCell: {
        margin: 5,
        fontSize: 10
    }
});
