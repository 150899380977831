import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useUserData } from 'services/UserContext';

export default _ => {
    const history = useHistory();
    const [ca, setCa] = useState([]);
    const [cps, setCps] = useState([]);
    const [clubs, setClubs] = useState([]);
    const [user] = useUserData();

    const provinces = [
        "dfc8be92-8cb2-11de-a173-001372568c9a",
        "dfc8c0fe-8cb2-11de-a173-001372568c9a",
        "dfc8c284-8cb2-11de-a173-001372568c9a",
        "dfc8c3f6-8cb2-11de-a173-001372568c9a",
        "dfc8c554-8cb2-11de-a173-001372568c9a"
    ];

    const Init = () =>{
        if (user.isAdmin)
        history.push(`/ca`);

        if (user?.groupes?.length === 1) {
            if (user?.groupes?.filter(e => e.id === "dfc82482-8cb2-11de-a173-001372568c9a").length > 0) {
                history.push(`/ca`);
                return;
            }

            if (user?.groupes?.filter(e => provinces.includes(e.id)).length > 0) {
                history.push(`/provinces/${user?.groupes[0]?.id}`);
                return;
            }

            history.push(`/clubs/${user?.groupes[0]?.id}`);
            return;
        }

        setCa(user?.groupes?.filter(e => e.id === "dfc82482-8cb2-11de-a173-001372568c9a"));
        setCps(user?.groupes?.filter(e => provinces.includes(e.id)));
        setClubs(user?.groupes?.filter(e => e.id !== "dfc82482-8cb2-11de-a173-001372568c9a" && !provinces.includes(e.id)));
    }

    useEffect(Init, []);

    return (<div className="p-5">
        {user?.groupes?.length > 0 && <>
            <h3>Mes Groupes</h3>
            <div className="row">
                {ca.map((e, i) => <div key={i} className="col-lg-4">
                    <div  className="card">
                        <h5 className="card-header">{e.name}</h5>
                        <div className="card-body">
                            <Link to="/ca" className="btn btn-primary">
                                <i className="fa-solid fa-arrow-right-to-bracket"/>
                            </Link>
                        </div>
                    </div>
                </div>)}

                {cps.map((e, i) => <div key={i} className="col-lg-4">
                    <div className="card">
                        <h5 className="card-header">{e.name}</h5>
                        <div className="card-body">
                            <Link to={`/provinces/${e.id}`} className="btn btn-primary">
                                <i className="fa-solid fa-arrow-right-to-bracket" />
                            </Link>
                        </div>
                    </div>
                </div>)}

                {clubs.map((e, i) => <div key={i} className="col-lg-4">
                    <div className="card">
                        <h5 className="card-header">{e.name}</h5>
                        <div className="card-body">
                            <Link to={`/clubs/${e.id}`} className="btn btn-primary">
                                <i className="fa-solid fa-arrow-right-to-bracket" />
                            </Link>
                        </div>
                    </div>
                </div>)}
            </div>
        </>}
    </div>);
};

