import React from 'react';
import { View, Text } from '../../PdfWorker';

export default ({ individu, club }) => {
    return (<View wrap={false} style={{ width: "auto", marginTop: 10, marginBottom: 10 }} >
        <Text style={{ fontSize: 12, textAlign: 'center', marginBottom: 10 }}>{`${club?.matricule} - ${club?.name}`}</Text>
        {club?.coord?.website && <Text style={{ fontSize: 12, textAlign: 'center', marginBottom: 10 }}>{club?.coord?.website}</Text>}
        <View style={{ width: "auto", flexDirection: "row" }}>
            <View style={{ width: "25%" }}>
                <Text style={{ fontSize: 10 }}>Responsable qui sera repris dans le calendrier</Text>
            </View>
            <View style={{ width: "25%" }}>
                <Text style={{ fontSize: 10 }}>{`${individu?.lastName} ${individu?.firstName},\n ${individu?.coord?.adress?.streetName} ${individu?.coord?.adress?.streetNumber} ${individu?.coord?.adress?.streetNumberBte ? `Bte ${individu?.coord?.adress?.streetNumberBte}` : ''} \n ${individu?.coord?.adress?.postalCode} ${individu?.coord?.adress?.city}`}</Text>
            </View>
            <View style={{ width: "50%" }}>
                <Text style={{ fontSize: 10 }}>Tél.: {individu?.coord?.phone ?? "/"} </Text>
                <Text style={{ fontSize: 10 }}>Gsm.: {individu?.coord?.gsm ?? "/"} </Text>
                <Text style={{ fontSize: 10 }}>Email.: {individu?.coord?.email ?? "/"} </Text>
            </View>
        </View>
    </View>);
}

