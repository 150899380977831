import React from 'react';
import { useParams } from 'react-router-dom';
import useDataFetch from 'services/useDataFetch';
import useFetch from 'services/useFetch';

export default _ => {
    const { id } = useParams();
    const { fetch } = useDataFetch();
    const { data: cotisations, refetch } = useFetch(`/api/members/${id}/cotisations`);

    const handleSuppr = (cid, annee) => fetch(`/api/members/${id}/cotisations/${cid}?annee=${annee}`, 'DELETE').then(_ => refetch());

    return (<div>        
        <h1 className="display-4" >Cotisations: </h1>

        <table class="table">
            <thead>
                <tr>
                    <th scope="col">Année</th>
                    <th scope="col">Groupe</th>
                    <th scope="col">Actions</th>
                </tr>
            </thead>
            <tbody>
                {cotisations?.map((e, i) => <tr>
                    <th scope="row">{e.annee}</th>
                    <td>{`(${e.groupMatricule}) - ${e.groupName}`}</td>
                    <td>
                        <button className="btn btn-danger" onClick={_ => handleSuppr(e.id, e.annee)} data-toggle="tooltip" title="Supprimer">
                            <i className="fas fa-trash fa-sm" />
                        </button>
                    </td>
                </tr>)}
            </tbody>
        </table>

        <ul>
            
        </ul>
    </div>);
}