import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AssurancesApi } from "../../../api/AssurancesApi";
import { ClubApi } from "../../../api/ClubApi";
import ErrorBoundary from "../../../lib/Antoine/ErrorBoundary";
import Alert from "../../0components/Alert";
import Form, { Row, FormInputDate, SubTitle, TableSelect, ElemDate, ElemList } from '../../Form/Form';

export default ({ demande }) => {
    return (<div>
        {ElemDate("Début de Marche", demande.dateMarcheDebut)}
        {ElemDate("Fin de Marche", demande.dateMarcheFin)}
        {ElemList("Affiliés", demande.affilies.map(e =>`${e.firstName} ${e.lastName}`))}
    </div>);
}

export const Form147 = ({ gid: id }) => {
    const history = useHistory();

    const submit = fields => {
        AssurancesApi
            .create(fields, '147')
            .then(res => {
                if (!res.ok) {
                    throw res;
                }
            })
            .then(_ => history.goBack())
            .catch(err => console.error(err))
    }

    return (<FormA147
        titre="Création Demande ROI ASS 147 : Dénicheur de parcours"
        initData={{ "groupId": id }}
        onSubmit={submit} 
        />);
};

export const UpdateForm147 = ({ demande }) => {
    const history = useHistory();

    const submit = fields => {
        AssurancesApi
            .update(fields, '147')
            .then(res => {
                if (!res.ok) {
                    throw res;
                }
            })
            .then(_ => history.goBack())
            .catch(err=>console.error(err))
    }

    return (<ErrorBoundary fallback={<Alert message="Une erreur est survenue dans le formulaire"/>}>
        <FormA147
            titre="Modification Demande ROI ASS 147 : Dénicheur de parcours"
            initData={demande}
            onSubmit={submit}
        />
    </ErrorBoundary>);
};

const FormA147 = ({ titre, initData, onSubmit, error }) => {
    const [fields, setFields] = useState(initData);

    const [membresDuClub, setMembresDuClub] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const handleSubmit = e => {
        e.preventDefault();
        onSubmit(fields);
    }

    useEffect(_ => {
        setIsLoading(true);
        ClubApi
            .getMembres(fields.groupId)
            .then(data => setMembresDuClub(data))
            .finally(setIsLoading(false));
    }, [fields]);

    if (isLoading)
        return <p>Chargement des données de la demande d'assurance</p>

    if (error)
        return <Alert message={error} />

    return (<Form title={titre} submit={handleSubmit}>
        <Row>
            <div className="col-md-6">
                <SubTitle>Marche</SubTitle>
                <Row>
                    <div className="col-md-6"> 
                        <FormInputDate
                            label="Début"
                            value={fields["dateMarcheDebut"]}
                            handleChange={val => setFields({ ...fields, "dateMarcheDebut": val })}
                        />
                    </div>
                    <div className="col-md-6">
                        <FormInputDate
                            label="Fin"
                            value={fields["dateMarcheFin"]}
                            handleChange={val => setFields({ ...fields, "dateMarcheFin": val })}
                        />
                    </div>
                </Row>
            </div>
        </Row>
        <TableSelect
            data={fields.affilies}
            setData={e => setFields({ ...fields, "affilies": e })}
            format={[
                { name: "Matricule", id: "matricule" },
                { name: "Prénom", id: "firstName", },
                { name: "Nom", id: "lastName" },
            ]}
            options={membresDuClub?.map(e => Object.fromEntries(new Map([
                ['id', e.id],
                ['libelle', `${e.lastName} ${e.firstName} - ${e.matricule}`],
                ['data', e]
            ])))}
        />
    </Form>);
};