import React, { useState, useEffect } from 'react';
import { MarchesApi } from '../../api/MarchesApi';

export default _ => {
    const [updating, setUpdating] = useState(true);
    const [legendes, setLegendes] = useState([]);

    useEffect(_ => loadLegendes(), []);

    const loadLegendes = _ => {
        setUpdating(true)
        MarchesApi.legendes.getAll()
            .then(res => setLegendes(res))
            .finally(_ => setUpdating(false));
    }

    const validData = (libelle, code) => {
        // libelle doit être un string
        // non null
        // entre 1 et 60 caractères

        if (typeof (libelle) != 'string') { 
            window.alert('erreur de format pour le libelle'); 
            return false
        }

        if (code.length === 0) {
            window.alert('libelle vide');
            return false
        }

        if (code.length > 60) {
            window.alert('libelle trop long');
            return false
        }

        // Code doit exister
        // être non null
        // etre un string entre 1 et 4 caractères
        if (typeof (code) != 'string') {
            window.alert('erreur de format pour le libelle');
            return false
        }

        if (code.length === 0) {
            window.alert('code vide');
            return false
        }

        if (code.length > 4) {
            window.alert('code trop long');
            return false
        }

        return true;
    }

    const Update = g => {
        var libelle = window.prompt("Quel est le nouveau nom de cette légende pour remplacer'" + g.libelle+ "'?");
        var code = window.prompt("Quel est le nouveau code de cette légende pour remplacer'" + g.code+ "'?");

        if (validData(libelle, code)) { 
            setUpdating(true);
            MarchesApi.legendes.update(g.id, code, libelle)
                .then(_ => loadLegendes())
                .finally(_ => setUpdating(false));
        }
    }

    const Create = _ => {
        var libelle = window.prompt("Quel est le nom de cette nouvelle légende ?");
        var code = window.prompt("Quel est le code de cette nouvelle légende ?");

        if (validData(libelle, code)) {
            setUpdating(true);
            MarchesApi.legendes.create(code, libelle)
                .then(_ => loadLegendes())
                .finally(_ => setUpdating(false));
        }
    }

    const Delete = ({libelle, id}) => {
        if (window.confirm(`êtes vous sûr de vouloir supprimer ${libelle} ?`)) {
            if (window.confirm(`Attention: Opération irréversible : Cette opération va supprimer la légende dans toutes les marches de la base de données, continuer ?`)) {
                setUpdating(true);
                MarchesApi.legendes.delete(id)
                    .then(_ => loadLegendes())
                    .finally(_ => setUpdating(false));
            }
        }
    }

    return (<>
        <label>Légendes {updating ?
            <div className="spinner-border text-primary" role="status" style={{ height: "1rem", width: "1rem" }} >
                <span className="sr-only">Chargement...</span>
            </div>
            : <span>
                <i className="fas fa-check-circle fa-sm text-success" data-toggle="tooltip" title="à jour" style={{ height: "1rem", width: "1rem" }} />
                Sauvegardé
            </span>}
        </label>
        <div className="card mb-2">
            <div className="card-header d-flex justify-content-between align-items-center">
                Libelle de légende
                <button type="button" className="btn btn-primary m-2 justify-content-end d-flex" onClick={Create}>
                    <i className="fas fa-xs fa-plus m-2" data-toggle="tooltip" title="créer" />
                </button>
            </div>
            <ul className="list-group list-group-flush">
                {legendes.map(g => <li key={g.id} className="list-group-item d-flex justify-content-between align-items-center" > 
                    {g.libelle} - { g.code }
                    <div className="justify-content-end d-flex">
                        <button type="button" className="btn btn-primary m-1" onClick={_ => Update(g)}>
                            <i className="fas fa-xs fa-pen m-1" data-toggle="tooltip" title="Modifier" />
                        </button>
                        <button type="button" className="btn btn-danger m-1" onClick={_ => Delete(g)}>
                            <i className="fas fa-xs fa-trash m-1" data-toggle="tooltip" title="Supprimer" />
                        </button>
                    </div>
                </li>)}
            </ul>
        </div>
    </>);
}