import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";

import { MembreApi } from 'api/MembreApi';

import Spinner from 'components/0components/Spinner';
import FormCoordonnees from 'components/Form/FormCoordonnees';
import { FormInput2, Required, SubmitButtonLoading } from 'components/Form/Form';

export default _ => {
    const { id } = useParams();
    const history = useHistory();

    const [fields, setFields] = useState({});

    const [isLoaded, setIsLoaded] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);

    useEffect(_ => {
        setIsLoaded(false);
        MembreApi
            .get(id)
            .then(res => setFields(res))
            .finally(_ => setIsLoaded(true));
    }, [id]);// eslint-disable-line react-hooks/exhaustive-deps

    const contactSubmit = e => {
        e.preventDefault();
        setSubmitLoading(true);
        MembreApi
            .update(fields)
            .then(_ => history.goBack())
            .finally(_ => setSubmitLoading(false));
    }

    return (<>
        {!isLoaded && <Spinner />}
        {isLoaded && <form onSubmit={e => contactSubmit(e)}>
            <h1 className="m-3">Modifier Membre</h1>
            <div className="row m-2">
                <div className="col-md">
                    <FormInput2
                        required
                        label="Nom"
                        value={fields?.lastName}
                        onChange={val => setFields({ ...fields, "lastName": val })}
                        minLength="1"
                        maxLength="60"
                        placeholder="Nom de l'affilié"
                    />
                </div>
                <div className="col-md">
                    <FormInput2
                        required
                        label="Prénom"
                        value={fields?.firstName}
                        onChange={val => setFields({ ...fields, "firstName": val })}
                        maxLength="60"
                        placeholder="Nom de l'affilié"
                    />
                </div>
            </div>
            <div className="row m-2">
                <div className="col-lg-2">
                    <label>Date de Naissance</label>
                    <input
                        className="form-control mr-sm-2"
                        type="date"
                        onChange={(e) => setFields({ ...fields, "birthDate": e.target.value })}
                        value={fields?.birthDate?.split('T')[0] || ""}
                    />
                </div>
            </div>
            <div className="row m-2">
                <div className="col">
                    <label className="mr-1">Sexe<Required />  </label>
                    <br />
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="radioGender"
                            value="M"
                            onChange={e => setFields({ ...fields, "gender": "M" })}
                            checked={fields?.gender === "M"}
                            required
                        />
                        <label className="form-check-label">M</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="radioGender"
                            value="F"
                            onChange={e => setFields({ ...fields, "gender": "F" })}
                            checked={fields?.gender === "F"}
                            required
                        />
                        <label className="form-check-label">F</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="radioGender"
                            value="A"
                            onChange={e => setFields({ ...fields, "gender": "A" })}
                            checked={fields?.gender === "A"}
                            required
                        />
                        <label className="form-check-label">Autre</label>
                    </div>
                </div>
            </div>
            <div className="row m-2">
                <div className="col">
                    <label className="mr-1">Chef de Famille</label>
                    <br />
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="radioFamily"
                            value="1"
                            onChange={e => setFields({ ...fields, "familyChief": true })}
                            checked={fields?.familyChief === true}
                        />
                        <label className="form-check-label">Oui</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="radioFamily"
                            value="0"
                            onChange={e => setFields({ ...fields, "familyChief": false })}
                            checked={fields?.familyChief === false}
                        />
                        <label className="form-check-label">Non</label>
                    </div>
                </div>
            </div>
            <FormCoordonnees fields={fields?.coord ?? {}} setFields={val => setFields({ ...fields, "coord": val ?? {} })} />
            <SubmitButtonLoading isLoading={submitLoading} />
        </form>}
        
    </>);
}
