import React, { useState, useEffect } from 'react';

import { ClubApi } from 'api/ClubApi';

import Bubble from './Bubble';
import { ToEuro } from 'lib/FormatNum';

export const BNumMember = ({ membres, isLoading }) => {

    return (<Bubble
        color="primary"
        icon="fa-users"
        title="Membres Actifs"
        dataLoading={isLoading}
        data={membres ? `${membres?.filter(m => m.isCotisationEnOrdre).length} / ${membres?.length}` : ""}
    />);
}

export const BCandidats = ({ candidats, isLoading, }) => {
    const nbCandidatsRefus = candidats?.filter(e => e.isRefus).length;

    const Color = (data) => {
        if (data && (data.length !== undefined))
            return data.length > 0 ? "warning" : "info"
        else
            return "secondary";
    }

    return (<Bubble
        color={Color(candidats)}
        icon={`fa-user-plus ${nbCandidatsRefus > 0 ? "fa-bounce" : ""}`}
        title="Candidatures"
        dataLoading={isLoading}
        data={`${candidats?.length ?? ""} ${nbCandidatsRefus > 0 ? `(${nbCandidatsRefus} refusées)` : ""}`}
    />);
}

export const BCredit = ({ credit, cost}) => {
    return (<Bubble
        color="success"
        icon="fa-euro-sign"
        title="Crédit restant (Coût d'une cotisation)"
        dataLoading={!(credit !== undefined && cost)}
        data={`${(credit !== undefined ? ToEuro(credit) : "...")} (${cost ? ToEuro(cost) : "..."})`}
        dataColor={cost > credit ? "text-danger" : "text-gray-800"}
    />);
}

export const BMarches = ({ marches, isLoading }) => {

    return (<Bubble
        color={marches ? "info" : "secondary"}
        icon="fa-calendar"
        title="Marches à venir"
        dataLoading={isLoading}
        data={marches?.filter(e => new Date(e.date).getTime() > new Date().getTime()).length ?? "chargement"}
    />);
}

export const BAss = ({ idClub }) => {
    const [demandes, setDemandes] = useState();
    const [isLoading, setIsLoading] = useState(true);

    function FetchClub ()
    {
        ClubApi
            .getAssurances(idClub)// Récupère toutes les assurances de l'année en cours
            .then(res => setDemandes(res))
            .finally(_ => setIsLoading(false));
    }

    useEffect(FetchClub, [idClub]);

    return (<Bubble
        color="info"
        icon="fa-file-contract"
        title="Assurances"
        dataLoading={isLoading}
        data={`${demandes?.filter(e => e.status === 1)?.length} en attente`}
    />);
}

export const BDoc = _ => {

    return (<Bubble 
        className="cardhover"
        color="info"
        icon="fa-file"
        title="Documents"
        data="4"
    />);
}
