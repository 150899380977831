import React from 'react';
import ModifyCotisation from './ModifyCotisation';
import ModifyDateParam from './ModifyDateParam';
import ModifyLegendes from './ModifyLegendes';
import RemoveOldAffilies from './RemoveOldAffilies';

export default _ => {
    return (<>
        <h1 className="display-4">Paramètres</h1>
        <div className="row">
            <div className="col">
                <ModifyCotisation titre="Prix Cotisation" param="MONTANT_COTISATION"/>
                <RemoveOldAffilies />
                <ModifyCotisation titre="Prix ASS140" param="MONTANT_A140" />
                <ModifyCotisation titre="Prix ASS144" param="MONTANT_A144" />
                <ModifyCotisation titre="Prix ASS201" param="MONTANT_A201" />
                <ModifyDateParam titre="Date Limite Remise Projet Calendrier" param="CAL_PROJ_DATELIMIT"/>
                <ModifyDateParam titre="Date Limite Remise Calendrier" param="CAL_EXPORT_DATELIMIT"/>
            </div>
            <div className="col">
                <ModifyLegendes />
            </div>
        </div>
    </>);
}
