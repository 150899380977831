import { Auth } from "components/Form/Form";

const url = '/api/v1/orga';

export const OrganigrammeApi = {
    get: (id) => {
        return fetch(`${url}/${id}`, Auth.options('GET'))
            .then(res => res.json());
    },

    /// secrétaire du groupe
    getSecretary: (id) => {
        return fetch(`${url}/${id}/secretary`, Auth.options('GET'))
            .then(res => {
                if (res.status === 204)
                    return null;
                else if (res.ok)
                    return res.json();
                else throw res;
            })
    },

    typeFunctions: (id) => {
        return fetch(`${url}/${id}/typepostes`, Auth.options('GET'))
            .then(res => res.json());
    },

    create: (grpId, affId, postId, startDate, endDate) => {
        return fetch(`${url}`, Auth.options('POST', {
            affId: affId,
            grpid: grpId,
            posteid: postId,
            debut: startDate,
            fin: endDate
        })).catch(err => console.error(err, grpId,affId,postId, startDate, endDate));
    },

    update: (id, startDate, endDate) => {
        // le backend ne gère pas les strings vide car il ne peut les convertir en date
        if (endDate === "")
            endDate = null;

        return fetch(`${url}`, Auth.options('PATCH', {
            id: id,
            debut: startDate,
            fin: endDate
        })).catch(err => console.error(err, id, startDate, endDate));
    },

    delete: (id) => {
        return fetch(`${url}/${id}`, Auth.options('DELETE'));
    },
}