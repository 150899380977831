import { Actions } from "services/AccessControl";
import { isAllowed } from "lib/permToolkit";
import Groups from "../Groups";

export default (subject, action, ressource) => {

    switch (action) {
        case Actions.Read:
            return isAllowed(subject, null, ressource);
        case Actions.Update:
            return isAllowed(subject, "GestionMembres", ressource) || isAllowed(subject, "ModificationMembres", ressource);
        case Actions.Delete:
            return isAllowed(subject, null, Groups.CA);
        default: 
            return false;
    }
}