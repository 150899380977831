import React from 'react';
import { Route } from "react-router-dom";
import {ErrorPage403} from "pages/ErrorPages";
import Show from "./Show";

export default ({ object, action, children, ...rest}) => {
    return (<Route {...rest}>
        <Show object={object} action={action} fallback={<ErrorPage403 />}>
            {children || null}
        </Show>
    </Route>);
}