import React from 'react';
import { useHistory, useParams } from "react-router-dom";

import WalkForm from './WalkForm';
import Spinner from 'components/0components/Spinner';
import useWalk from 'hooks/useWalk';
import ErrorBoundary from 'lib/Antoine/ErrorBoundary';

const ModifyWalk = _ => {
    const history = useHistory();
    const { id } = useParams();

    const { walk, isLoading, UpdateWalk } = useWalk(id);

    const submit = (e, fields) => {
        e.preventDefault();

        UpdateWalk(fields)
            .then(_ => history.goBack());
    }

    if (isLoading)
        return < Spinner />;

    return (<ErrorBoundary fallback={<p>Une erreur s'est produite dans Le formulaire de la marche</p> }>
        <WalkForm
            title="Modifier la marche"
            data={walk}
            submit={submit} />
    </ErrorBoundary>);
}

export default ModifyWalk;