import React, { useState } from 'react';

import { MembreApi } from 'api/MembreApi';

import { SearchIcon } from 'components/Icons';

export default ({ value, onChange }) => {
    const [membres, setMembres] = useState([]);
    const [search, setSearch] = useState("");
    const [selectedId, setSelectedId] = useState(value);

    const fetchMembres = _ => {
        setSelectedId();

        MembreApi.search(search)
            .then(res => setMembres(res));
    }

    const handleClick = membre => {
        setSearch(membre.matricule);
        setSelectedId(membre.id);
        setMembres(membres.filter(z => z.id === membre.id));//filtre la liste
        onChange(membre.id);
    }

    return (<>
        <label>Recherche par matricule</label>
        <div className="input-group mb-2">
            <input
                className="form-control"
                type="text"
                onChange={e => setSearch(e.target.value)}
                value={search}
                placeholder="ex: 123456"
                required
            />
            <div className="input-group-append">
                <button className="btn btn-primary" type="button" onClick={fetchMembres}><SearchIcon/></button>
            </div>
        </div>
        <div className="list-group">
            {membres.map(e => <div key={e.id} className="list-group-item list-group-item-action list-group-item-light" onClick={_ => handleClick(e)} >
                <div className="row">
                    <div className="col">
                        {e.matricule} - {e.firstName} {e.lastName}
                    </div>
                    <div className="col-auto">
                        {e.id === selectedId && <i className="text-success fa-solid fa-check" />}
                    </div>
                </div>
            </div>)}
        </div>
    </>);
}