import React from 'react';
import PdfWorker, { View, Text, Image, Row } from '../../PdfWorker';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Button from 'components/0components/Button';
import { FormatAdressOneLine } from '../../../../lib/FormatAdress';

export default ({ orga, club }) => {

    if (orga == null)
        return (<div className="alert alert-danger" role="alert"> L'organigramme n'a pas pu être chargé correctement </div>);

    if (club == null)
        return (<div className="alert alert-danger" role="alert"> Le club n'a pas pu être chargé correctement </div>);
 
    return (<PDFDownloadLink document={<MonDocument orga={orga} club={club} />} fileName={`${club?.matricule}_Orga.pdf`}>
        {({ loading }) => (loading ? 'Génération du document...' : <Button icon="fa-file-pdf ml-1" >Organigramme </Button>)}
    </PDFDownloadLink>);
}

const MonDocument = ({ orga, club }) => {

    return (<PdfWorker>
        <View style={{ display: "table", width: "auto", flexDirection: "row" }}>
            <View style={{ margin: "auto", flexDirection: "row", width: "20%" }}>
                <Image style={{ width: "100%", height: "auto", padding: 10 }} src="img/FFBMPLogo.png" />
            </View>
            <View style={{ margin: "auto", width: "80%", marginRight: 10 }}>
                <Text style={{ fontSize: 12, marginBottom: 5, textAlign: 'center' }}>FEDERATION FRANCOPHONE BELGE DE MARCHES POPULAIRES</Text>
                <Text style={{ fontSize: 10, textAlign: 'center' }}>Organigramme</Text>
            </View>
        </View>

        <View style={{ marginBottom: 0, borderWidth: 1, padding: 3 }}>
            <View style={{ flexDirection: "row", margin: 2 }}>
                <Text style={{ fontSize: 10, textAlign: 'center' }}>{`${club?.matricule} - ${club?.name}`}</Text>
            </View>
        </View>

        <View>
            {orga?.map((e, i) => <Row key={i}>
                <View style={{ width: "20%", marginTop: 10 }}>
                    <Text style={{ fontSize: 8 }}>{e?.post}</Text>
                </View>
                <View style={{ width: "30%", marginTop: 10 }}>
                    <Text style={{ fontSize: 8 }}>{`${e?.member?.firstName} ${e?.member?.lastName}`}</Text>
                    <Text style={{ fontSize: 8 }}>{`${e?.member?.matricule}`}</Text>
                </View>
                <View style={{ width: "50%", marginTop: 10 }}>
                    <Text style={{ fontSize: 8 }}>{FormatAdressOneLine(e?.member?.coord?.adress)}</Text>
                    <Text style={{ fontSize: 8 }}>Tél: {e?.member?.coord?.phone}</Text>
                    <Text style={{ fontSize: 8 }}>GSM: {e?.member?.coord?.gsm}</Text>
                    <Text style={{ fontSize: 8 }}>Email: {e?.member?.coord?.email}</Text>
                </View>
            </Row>)}
        </View>

    </PdfWorker>);
}