import React, { useState } from "react";
import { useHistory} from "react-router-dom";
import Club from "bll/Club/Club";
import FormatDate from "lib/FormatDate";
import { searchCompare } from "lib/utils";

import AffiliationsClubPdf from "components/pdfs/AffiliationsClubPdf";
import { Actions, Objects, Show } from "services/AccessControl";
import CodeBar from "components/pdfs/Club/CodeBar";
import BarreRechercheMembres from "./BarreRechercheMembres";
import SelecteurAnneeCotisation from "./SelecteurAnneeCotisation";

const Membres = ({ id, membres, reaffiliationYear, setReaffiliationYear, clickMemberPayCotisation, isCreditSuffisant, club }) => {
    const history = useHistory();
    const [search, setSearch] = useState("");

    const IsReaffiliationActive = Club.isPeriodeReaffiliation;
    
    const handleInfoMember = member => history.push(`/membres/${member.id}`);

    const HandlePayCotisation = (e, membre) => {
        if (membre?.id) {
            e.stopPropagation();
            e.target.disabled = true;
            clickMemberPayCotisation(membre?.id);
        }
    }

    const filtreMembres = member => {
        return searchCompare(member?.firstName, search) ||
            searchCompare(member?.lastName, search) ||
            searchCompare(member?.matricule, search);
    }

    const StatusIconOk = _ => <span><i className="fas fa-fw fa-check-circle text-success" data-toggle="tooltip" title="En Ordre" /> En Ordre</span>;
    const StatusIconReaff = _ => <span><i className="fas fa-fw fa-exclamation-triangle text-secondary" data-toggle="tooltip" title="Réaffiliation Requise" /> À payer</span>;

    const MemberStatusIcon = ({ status }) => status ? <StatusIconOk /> : <StatusIconReaff />;

    return (<>
        <div className="row mb-3 mx-0">
            <div className="col-md-auto m-0 p-0">
                <h3 className="h3">Membres</h3>
            </div>
            
            <div className="col-md ml-4 m-0 p-0">
                <BarreRechercheMembres search={search} setSearch={setSearch} />
            </div>
            
            <div className="col-md-auto p-0">
                {IsReaffiliationActive || id === '34be1016-ff6a-11eb-8cf3-000d3a2065e4' ? <SelecteurAnneeCotisation reaffiliationYear={reaffiliationYear} setReaffiliationYear={setReaffiliationYear} /> : <></>}
            </div>

            <div className="col-md-auto p-0 ml-2 m-0">
                <AffiliationsClubPdf id={id} />
            </div>

            <div className="col-md-auto p-0 ml-2 m-0">
                <CodeBar MembresActifs={membres?.filter(m => m.isCotisationEnOrdre)} club={club}/>
            </div>
        </div>
        <div className="card">
            <div className="table-responsive-xl">
                <table className="table table-hover mb-0">
                    <thead className="thead-light">
                        <tr>
                            <th scope="col" className="text-dark">Nom</th>
                            <th scope="col" className="text-dark">Prénom</th>
                            <th scope="col" className="text-dark">Matricule</th>
                            <th scope="col" className="text-dark">Date de Naissance</th>
                            <th scope="col" className="text-dark">État Cotistation</th>
                            <Show object={Objects.Cotisation} action={Actions.Pay}> 
                                <th scope="col">Réaffiliation</th>
                            </Show>
                        </tr>
                    </thead>
                    <tbody>
                        {membres?.filter(filtreMembres).map((member,i) => (<tr key={i} onClick={_ => handleInfoMember(member)}>
                            <td>{member?.lastName}</td>
                            <td>{member?.firstName}</td>
                            <td>{member?.matricule}</td>
                            <td>{FormatDate(member?.birthDate)}</td> 
                            <td>
                                <MemberStatusIcon status={member?.isCotisationEnOrdre} />
                            </td>
                            <Show object={Objects.Cotisation} action={Actions.Pay}> 
                                <td>
                                    {!member?.isCotisationEnOrdre ? <button type="button" className="btn btn-success" onClick={e => HandlePayCotisation(e, member)}>
                                        <i className="fas fa-xs fa-check m-1" data-toggle="tooltip" title="Valider la cotisation" />
                                    </button> : ""}
                                </td>
                            </Show>
                        </tr>))}
                    </tbody>
                </table>
            </div>
        </div>
    </>);
}

export default Membres;