import React, { useState } from 'react';
import OrganigrammePdf from 'components/pdfs/OrganigrammePdf';

export default _ => {
    const [isVisible, setIsVisible] = useState(false);

    return (<div className="d-flex justify-content-between align-items-center" onClick={_ => setIsVisible(true)}>
        <p className="my-auto"><span className="badge badge-danger mr-3">PDF</span>Organigramme du CA</p>
        <button className="btn btn-primary ml-3" >
            <i className="fas fa-sm fa-arrow-down" data-toggle="tooltip" title="Télécharger" />
        </button>
        {isVisible && <OrganigrammePdf grpId="dfc82482-8cb2-11de-a173-001372568c9a" isVisible={isVisible} setIsVisible={setIsVisible} />}
    </div>);
}