import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { UserApi } from 'api/UserApi';
import LoginPage from '../views/Login/LoginPage';

export default _ => {
    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const [submitLoading, setSubmitLoading] = useState(false);
    const [error, setError] = useState();
    const history = useHistory();

    const submit = e => {
        e.preventDefault();
        setSubmitLoading(true);

        UserApi.login(login, password)
        .then(_ => history.push('/'))
        .catch(err => setError(err))
        .finally(_ => setSubmitLoading(false))
    }
    
    return LoginPage(login, setLogin, password, setPassword, submit, submitLoading, error, setError);
}