import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import GroupInfoForm from 'components/Club/GroupInfoForm';
import { onChangeInput } from 'components/Form/Utils/EventsHandler';
import { ClubApiV2 } from 'api/ClubApiV2';

export default _ => {
    const history = useHistory();
    const location = useLocation();

    const [errors, setErrors] = useState();

    const [formData, setFormData] = useState({
        parentGroupId: location.state.idgrp,
        typeGroup: location.state.grptype || '8baa825c-cf9a-11de-90a6-001372568c9a',
        matricule: '',
        name: '',
        cptFin: '',
        cptFinLibelle: '',
        numEnterprise: '',
        bankNumber: '',
        bankBicNumber: '',
        isffbmp: true,
        coord: {
            adress: {
                streetName: '',
                streetNumber: '',
                streetNumberBte: '',
                city: '',
                postalCode: '',
                countryCode: 'BE',
                countryLibelle: ''
            },
            phone: '',
            gsm: '',
            email: '',
            website: ''
        }
    })

    const SubmitCreateNewGroupForm = e => {
        e.preventDefault();
        ClubApiV2
            .creerClub(formData)
            .then(_ => history.goBack())
            .catch(err => err.json().then(errmessage => setErrors(errmessage.errors)));
    }

    useEffect(_ => console.log(errors), [errors]);

    return (<GroupInfoForm
        Submit={SubmitCreateNewGroupForm}
        formData={formData}
        onChangeInput={e => onChangeInput(e, formData, setFormData)}
        errors={errors}
    />);
}