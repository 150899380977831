import React from 'react';
import { Switch, Redirect } from "react-router-dom";

import { Route, Actions, Objects } from '../../services/AccessControl';

import FicheCandidat from './FicheCandidat';
import CreateCandidat from './CreateCandidat';
import ModifyCandidat from './ModifyCandidat';

export default _ => {
    const path = "/candidats";

    return (<Switch>
        <Route path={`${path}/create`} exact object={Objects.Candidat} action={Actions.Create}>
            <CreateCandidat/>
        </Route>
        <Route path={`${path}/:candidatId/update`} object={Objects.Candidat} action={Actions.Update}>
            <ModifyCandidat/>
        </Route>
        <Route path={`${path}/:candidatId`} object={Objects.Candidat} action={Actions.Valid}>
            <FicheCandidat/>
        </Route>
        <Route path={`${path}/*`} >
            <Redirect to="/404" />
        </Route>
    </Switch>);
}