import React from 'react';

export default ({ active, color, title, dataLoading, data, dataColor = "text-gray-800", icon, className, children, ...rest }) => {
    return (<div className={`card border-left-${color ?? "secondary"} ${(active ? `bg-${color}` : "")} shadow h-100 py-2 ${className ?? ""}`} {...rest}>
        <div className="card-body">
            <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                    <div className={`text-xs font-weight-bold text-${color ?? "secondary"} text-uppercase mb-1`}>{title}</div>
                    {dataLoading ?
                        <div className={`spinner-grow text-${color ?? "secondary"}`} role="status"><span className="sr-only">Loading...</span></div>
                        :
                        <div className={`h5 mb-0 font-weight-bold ${dataColor}`}>{data}</div>}
                </div>
                <div className="col-auto">
                    <i className={`fas ${icon} fa-2x text-gray-300`} style={{ "--fa-animation-iteration-count": "3", "--fa-animation-timing": "ease-in-out" }}></i>
                </div>
            </div>
        </div>
    </div>);
};