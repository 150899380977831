import React from 'react';
import { useHistory } from 'react-router-dom';
import Spinner from 'components/0components/Spinner';

export default ({ liste: members,isLoading}) => {
    const history = useHistory();

    const title = members => {
        if (!members || members?.length === 0)
            return " Pas d'Affiliations en attente";

        return members.length > 1 ? "Affiliations en Attente" : "Affiliation en Attente";
    }

    if (isLoading)
        return <Spinner />;

    return (<div className="Row">
        <h3 className="h3 mb-4">{title(members?.length)}</h3>
        <div className="card">
            <div className="table-responsive">
                <table className="table table-hover mb-0">
                    <thead className="thead-light">
                        <tr>
                            <th scope="col" className="text-dark">Nom</th>
                            <th scope="col" className="text-dark">Prénom</th>
                            <th scope="col" className="text-dark">Date de Naissance</th>
                            <th scope="col" className="text-dark">Groupe</th>
                            <th scope="col" className="text-dark">Encodeur</th>
                            <th scope="col" className="text-dark">Annee d'inscription</th>
                        </tr>
                    </thead>
                    <tbody>
                        {members?.map(m => (<tr key={m.id} onClick={_ => history.push(`/candidats/${m.id}`)} className={m?.doublons?.length > 0 ? "table-warning" : ""}>
                            <td className="align-middle">{m.lastName ?? "Pas Disponnible"}</td>
                            <td className="align-middle">{m.firstName}</td>
                            <td className="align-middle">{m?.birthDate ? new Date(m.birthDate).toLocaleDateString() : "Pas Disponible"}</td>
                            <td className="align-middle">{`(${m.groupMatricule}) ${m.groupName}` ?? "Pas Disponnible"}</td>
                            <td className="align-middle">{`${m.encodeurNom} (${m.encodeurMatricule})` ?? "Pas Disponnible"}</td>
                            <td className="align-middle">{m.anneeAffiliation ?? "Pas Disponnible"}</td>
                        </tr>))}
                    </tbody>
                </table>
            </div>
        </div>
    </div>);
}