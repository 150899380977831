import React from 'react';
import { Switch, Route, Link } from "react-router-dom";

import { PreferencesProvider } from 'services/PreferencesContext';
import { UserProvider } from 'services/UserContext';

import PrivateRoute from 'services/privateRoute';
import Login from 'pages/Login';
import LogOut from 'pages/LogOut';
import NavBar from 'components/Layout/NavBar';

import Application from './Application';
import { Current_version } from './pages/ReleaseNotes';

export default _ => {
    return (<Switch>
        <Route exact path='/login' component={Login} />
        <Route exact path='/logout' component={LogOut} />

        <PrivateRoute path="*">
            <UserProvider>
                <PreferencesProvider>
                    <NavBar />
                    <div id="content" className="container-fluid p-5" style={{ "minHeight": "100vh" }} >
                        <Application />
                    </div>
                    <footer className="footer flex-shrink-0 py-1 bg-dark text-white-50 sticky-bottom" >
                        <div className="container text-center">
                            <Link to="/releasenotes" className="text-white mr-2" style={{ fontSize: "0.7rem" }}><strong>{Current_version}</strong></Link>  
                            <a href="https://www.axentys.be" rel="noopener noreferrer" target="_blank" className="text-white" style={{ fontSize: "0.7rem"}}>
                                <span>Powered by <strong>Axentys</strong></span>
                            </a>
                        </div>
                    </footer>
                </PreferencesProvider>
            </UserProvider>
        </PrivateRoute>
    </Switch>);
}