import React from 'react';
import PdfWorker, { View, Text, Image, Row } from './PdfWorker';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Button from '../0components/Button';

export default ({ orga, secretaire, club }) => {
    return (<PDFDownloadLink document={<MonDocument club={club} orga={orga} secretaire={secretaire}/>} fileName={`${orga?.club?.matricule}_Orga.pdf`}>
        {({ loading }) => (loading ? 'Génération du document...' : <Button icon="fa-file-pdf ml-1" >Organigramme </Button>)}
    </PDFDownloadLink>);
}

const adresse = adr => {
    if (adr) {
        let { streetName, streetNumber, streetNumberBte, postalCode, city } = adr;
        return (`${streetName} ${streetNumber}${streetNumberBte ? " bte " + streetNumberBte: ""}, ${postalCode} ${city}`);
    }
    else
        return null;
}

const MonDocument = ({ orga, secretaire, club }) => {

    const Mydataline = ({ titre, val }) => (<View style={{ flexDirection: "row", margin: 2 }}>
            <View style={{ width: "30%" }}>
                <Text style={{ fontSize: 10 }}>{titre}</Text>
            </View>
            <View style={{ width: "70%" }}>
                <Text style={{ fontSize: 10 }}>{val}</Text>
            </View>
        </View>);

    return (<PdfWorker>
        <View style={{ display: "table", width: "auto", flexDirection: "row" }}>
            <View style={{ margin: "auto", flexDirection: "row", width: "20%" }}>
                <Image style={{ width: "100%", height: "auto", padding: 10 }} src="img/FFBMPLogo.png" />
            </View>
            <View style={{ margin: "auto", width: "80%", marginRight: 10 }}>
                <Text style={{ fontSize: 12, marginBottom: 5, textAlign: 'center' }}>FEDERATION FRANCOPHONE BELGE DE MARCHES POPULAIRES</Text>
                <Text style={{ fontSize: 10, textAlign: 'center' }}>Organigramme</Text>
            </View>
        </View>

        <View>
            <Text style={{ fontSize: 10, marginBottom: 5 }}>Organigramme à transmettre en 2 exemplaires originaux signés à votre secrétaire provincial:</Text>
            {secretaire && <Text style={{ fontSize: 10, marginBottom: 10 }}>{`${secretaire?.firstName} ${secretaire?.lastName} - ${adresse(secretaire?.coord?.adress)} - Email: ${secretaire?.coord?.email}`}</Text>}

            <Text style={{ fontSize: 10, marginBottom: 5 }}>1) chaque fois qu'une fonction change de titulaire</Text>
            <Text style={{ fontSize: 10, marginBottom: 5 }}>2) chaque fois qu'une des données du cadre est modifiée</Text>
            <Text style={{ fontSize: 10, marginBottom: 10 }}>3) chaque année en décembre</Text>
            <Text style={{ fontSize: 10, marginBottom: 10 }}>Toutes les autres modifications sont à effectuer directement par le club via le système en ligne.</Text>
            <Text style={{ fontSize: 10, marginBottom: 10 }}>Pour les clubs non connectés l'envoi d'organigrammes papier s'impose dans tous les cas.</Text>
        </View>

        <View style={{ marginBottom: 0, borderWidth: 1, padding: 3 }}>
            <Mydataline titre="Matricule et nom du club" val={`${club?.matricule} - ${club?.name}`}/>
            <Mydataline titre="N° entreprise (si ASBL)" val={`${club?.numEnterprise}`}/>
            <Mydataline titre="Siège du club" val={`${club?.coord?.adress?.postalCode} ${club?.coord?.adress?.city}`}/>
            <Mydataline titre="N° compte en banque" val={`IBAN: ${club?.bankNumber}\n BIC: ${club?.bankBicNumber}`}/>
            <Mydataline titre="Intitulé cu compte" val={`${club?.cptFinLibelle}`}/>
            <Mydataline titre="Site Web" val={`${club?.coord?.website}`}/>
            <Mydataline titre="Email standard" val={`${club?.coord?.email}`}/>
            <Mydataline titre="Présence d'un DEA" val={club?.isDea ? "Oui" : "Non"}/>
        </View>

        <View>
            {orga?.map((e, i) => <Row key={i}>
                <View style={{ width: "20%", marginTop: 10 }}>
                    <Text style={{ fontSize: 10 }}>{e?.post}</Text>
                </View>
                <View style={{ width: "30%", marginTop: 10 }}>
                    <Text style={{ fontSize: 10 }}>{`${e?.member?.firstName} ${e?.member?.lastName}`}</Text>
                    <Text style={{ fontSize: 10 }}>{`${e?.member?.matricule}`}</Text>
                </View>
                <View style={{ width: "50%", marginTop: 10 }}>
                    <Text style={{ fontSize: 10 }}>{adresse(e?.member?.coord?.adress)}</Text>
                    <Text style={{ fontSize: 10 }}>Tél: {e?.member?.coord?.phone}</Text>
                    <Text style={{ fontSize: 10 }}>GSM: {e?.member?.coord?.gsm}</Text>
                    <Text style={{ fontSize: 10 }}>Email: {e?.member?.coord?.email}</Text>
                </View>
            </Row>)}
        </View>

        <Row>
            <View style={{flexDirection: "row", marginTop: 20, marginBottom: 60 }}>
                <View style={{ width: "33%" }}>
                    <Text style={{ fontSize: 10 }}>Signature Président</Text>
                </View>
                <View style={{ width: "34%" }}>
                    <Text style={{ fontSize: 10 }}>Signature Secrétaire</Text>
                </View>
                <View style={{ width: "33%" }}>
                    <Text style={{ fontSize: 10 }}>Signature Trésorier</Text>
                </View>
            </View>
        </Row>
        <Row>
            <View style={{flexDirection: "row", marginTop: 10 }}>
                <View style={{ width: "33%" }}>
                    <Text style={{ fontSize: 10 }}>Date mise à jour Club: __/__/____ </Text>
                </View>
                <View style={{ width: "34%" }}>
                    <Text style={{ fontSize: 10 }}>Date mise à jour CP: __/__/____ </Text>
                </View>
                <View style={{ width: "33%" }}>
                    <Text style={{ fontSize: 10 }}>Date mise à jour CA: __/__/____</Text>
                </View>
            </View>
        </Row>

    </PdfWorker>);
}