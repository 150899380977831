import React from 'react';
import { useHistory } from "react-router-dom";
import FormUser from './FormUser';
import { UtilisateurApi } from '../../api/UtilisateursApi';

export default _ => {
    const history = useHistory();

    const handleSubmit = (e,fields) => {
        e.preventDefault();

        UtilisateurApi
            .create(fields)
            .then(_ => history.goBack());
    }

    return (<>
        <FormUser
            title="Créer Utilisateur"
            handleSubmit={handleSubmit} />
    </>);
}