import React, { useState, useEffect } from 'react';
import { Auth, FormSelect } from '../Form/Form';
import { PDFDownloadLink, Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import Spinner from '../0components/Spinner';
import Button from '../0components/Button';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

export default _ => {
    const [isVisible, setIsVisible] = useState(false);

    return (<div className = "d-flex justify-content-between align-items-center" onClick = { _ => setIsVisible(true) } >
        <p className="my-auto"><span className="badge badge-danger mr-3">PDF</span>Statistiques Générales</p>
        <button className="btn btn-primary ml-3" >
            <i className="fas fa-sm fa-arrow-down" data-toggle="tooltip" title="Télécharger" />
        </button>
        {isVisible && <MyModal isVisible={isVisible} setIsVisible={setIsVisible} />}
    </div>);
}

const MyModal = ({ isVisible, setIsVisible }) => {
    const [step, setStep] = useState(0); // 0: init, 1: Loading, 2:Ready

    const currentYear = new Date().getFullYear();
    const beginYear = 1979; //pour le modal
    const endYear = currentYear + 1; //pour le modal
    const [annee, setAnnee] = useState(new Date().getFullYear()); //année de recherche
    const [annees, setAnnees] = useState([]); //liste des années

    const [data, setData] = useState(); //données des membres

    useEffect(_ => setAnnees(yearsListBetween(beginYear, endYear)), [beginYear, endYear]);
    useEffect(_ => fetchData(annee), [annee, isVisible]);

    const yearsListBetween = (debut, fin) => {
        let tmp = []
        for (let i = fin; i >= debut; i--)
            tmp.push(i);
        return tmp;
    }

    const fetchData = annee => {
        setStep(1);
        Auth.fetch(`/api/stats/global?annee=${annee}`, Auth.options('GET'))
            .then(res => setData(res))
            .then(_ => setStep(2))
            .catch(err => console.error(err))
    }

    const renderBotom = step => {
        switch (step) {
            case 1:
                return <Spinner />;
            case 2:
                return (<PDFDownloadLink document={<Layout data={data} annee={annee}></Layout>} fileName={`FFBMP Répartition des membres ${annee}.pdf`}>
                    {({ loading }) => (loading ? 'Génération du document...' : <Button icon="fa-download" onClick={_ => { setIsVisible(false); }}>Télécharger</Button>)}
                </PDFDownloadLink>);
            default:
                return (<></>);

        }
    }

    return (<Modal isOpen={isVisible} toggle={_ => setIsVisible(!isVisible)}>
        <ModalHeader >PDF - Statistiques Générales</ModalHeader>
        <ModalBody>
            <FormSelect label="Année" required value={annee} handleChange={val => setAnnee(val)} disabled={step === 1}>
                {annees.map(e => <option value={e} key={e}>{e}</option>)}
            </FormSelect>
            {renderBotom(step)}
        </ModalBody>
    </Modal>);
}

const Layout = ({ data, annee }) => {

    const reducer = (previousValue, currentValue) => previousValue + currentValue;

    const styles = StyleSheet.create({
        body: {
            paddingTop: 35,
            paddingBottom: 65,
            paddingHorizontal: 35
        },
        row: { flexDirection: "row" },
        pageFooter: {
            position: 'absolute',
            fontSize: 8,
            bottom: 8,
            left: 10,
            right: 10,
            color: 'grey'
        },
        pageFooterCenter: { textAlign: 'center' },
        pageFooterLeft: { textAlign: 'left' },
        pageFooterRight: { textAlign: 'right' },
        table: {
            display: "table",
            width: "auto",
            borderStyle: "solid",
            borderColor: '#000',
            borderWidth: 1,
            borderRightWidth: 0,
            borderBottomWidth: 0
        },
        tableRow: {
            margin: "auto",
            flexDirection: "row"
        },
        tableCol: {
            borderStyle: "solid",
            borderColor: '#000',
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
            padding: 2
        },
        tableCollHeader: {
            fontSize: 12,
            fontWeight: 500
        },
        tableCell: { fontSize: 8 }
    });

    return (<Document>
        <Page style={styles.body} orientation="landscape">
            <Text style={[styles.subtitle, { fontSize: 15, marginBottom: 10 }]}>FEDERATION FRANCOPHONE BELGE DE MARCHES POPULAIRES - Année {annee}</Text>
            <View style={styles.table}>
                <View style={styles.tableRow}>
                    <View style={[styles.tableCol, { width: "20%" }]}>
                        <Text style={styles.tableCell}></Text>
                    </View>
                    <View style={[styles.tableCol, { width: "80%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>Nombre de membres</Text>
                    </View>
                </View>
                <View style={styles.tableRow}>
                    <View style={[styles.tableCol, { width: "20%" }]}>
                        <Text style={styles.tableCell}>Province</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "10%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>- 6 ans</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "10%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>- 12 ans</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "10%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>- 18 ans</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "10%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>- 25 ans</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "10%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>- 35 ans</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "10%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>+ 35 ans</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "20%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>TOTAUX</Text>
                    </View>
                </View>
                <View style={styles.tableRow}>
                    <View style={[styles.tableCol, { width: "20%" }]}>
                        <Text style={styles.tableCell}></Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>F</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>H</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>A</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>F</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>H</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>A</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>F</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>H</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>A</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>F</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>H</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>A</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.3%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>F</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.3%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>H</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.3%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>A</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>F</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>H</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>A</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>F</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>H</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>A</Text>
                    </View>

                    <View style={[styles.tableCol, { width: "10%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>TOTAL</Text>
                    </View>
                </View>

                {data && data.length > 0 && data.map(e => <View key={e.nom} style={styles.tableRow}>
                    <View style={[styles.tableCol, { width: "20%" }]}>
                        <Text style={[styles.tableCell, { fontSize: 8 }]}>{e.nom}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>{e.f6}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>{e.m6}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>{e.a6}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>{e.f12}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>{e.m12}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>{e.a12}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>{e.f18}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>{e.m18}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>{e.a18}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>{e.f25}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>{e.m25}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>{e.a25}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>{e.f35}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>{e.m35}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>{e.a35}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>{e.fP35}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>{e.mP35}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>{e.aP35}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>{e.totF}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>{e.totM}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>{e.totA}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "10%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>{e.tot}</Text>
                    </View>
                </View>)}


                {data && data.length > 0 && <View style={styles.tableRow}>
                    <View style={[styles.tableCol, { width: "20%" }]}>
                        <Text style={[styles.tableCell, { fontSize: 8 }]}>- TOTAL -</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>{data.map(e => e.f6).reduce(reducer)}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>{data.map(e => e.m6).reduce(reducer)}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>{data.map(e => e.a6).reduce(reducer)}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>{data.map(e => e.f12).reduce(reducer)}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>{data.map(e => e.m12).reduce(reducer)}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>{data.map(e => e.a12).reduce(reducer)}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>{data.map(e => e.f18).reduce(reducer)}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>{data.map(e => e.m18).reduce(reducer)}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>{data.map(e => e.a18).reduce(reducer)}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>{data.map(e => e.f25).reduce(reducer)}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>{data.map(e => e.m25).reduce(reducer)}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>{data.map(e => e.a25).reduce(reducer)}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>{data.map(e => e.f35).reduce(reducer)}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>{data.map(e => e.m35).reduce(reducer)}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>{data.map(e => e.a35).reduce(reducer)}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>{data.map(e => e.fP35).reduce(reducer)}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>{data.map(e => e.mP35).reduce(reducer)}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>{data.map(e => e.aP35).reduce(reducer)}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>{data.map(e => e.totF).reduce(reducer)}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>{data.map(e => e.totM).reduce(reducer)}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "3.333%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>{data.map(e => e.totA).reduce(reducer)}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "10%", textAlign: 'center' }]}>
                        <Text style={styles.tableCell}>{data.map(e => e.tot).reduce(reducer)}</Text>
                    </View>
                </View>}

            </View>
            <Text style={[styles.pageFooter, styles.pageFooterLeft]} fixed >FFBMP</Text>
            <Text style={[styles.pageFooter, styles.pageFooterCenter]} fixed >Année {annee}</Text>
            <Text style={[styles.pageFooter, styles.pageFooterRight]} fixed >Date: {new Date().toLocaleDateString()}</Text>
        </Page>
    </Document>);
}