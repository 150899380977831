import React from 'react';
import FormatDate from '../../../lib/FormatDate';
import Button from '../../0components/Button';
import { PDFDownloadLink, Text, Row, Table2, CellHeader, Cell } from '../PdfWorker';
import AssuranceLayoutPDF from './AssuranceLayoutPDF';

export default ({ demande }) => {
    return (<PDFDownloadLink document={<MonDocument demande={demande} />} fileName={`DemandeAssurance${demande.numeroDemande}.pdf`}>
        {({ loading }) => (loading ? 'Génération du document...' : <Button icon="fa-file-pdf">Télécharger</Button>)}
    </PDFDownloadLink>);
}

const mydate = d => d ? FormatDate(d) : "...../...../.....";

const MonDocument = ({ demande }) => {
    return (<AssuranceLayoutPDF demande={demande} titre="Dénicheurs de parcours" subtitle="Demande de couverture d'assurance" roi="ROI ASS 147">
        <Text style={{ fontSize: 10, borderWidth: 1, margin:10, padding:3 }}>Cette demande prend cours le jour de sa réception (et au plus tôt 8 mois avant la date de la marche) et se termine une semaine avant la date de la marche. Pour être assurés en cas d'accident, les dénicheurs (minimum 2, maximum 6) devront faire partie de la F.F.B.M.P. et devront être au moins deux sur le parcours. Les dénicheurs doivent être repris sur une liste nominative F.F.B.M.P., soit du cercle organisateur, soit d'un autre cercle F.F.B.M.P.</Text>

        <Text style={{ fontSize: 10, margin: 10}}>Date de la marche du {mydate(demande?.dateMarcheDebut)} au {mydate(demande?.dateMarcheFin)} inclus</Text>

        <Table2>
            <Row>
                <CellHeader style={{ width: "15%" }}>Matricule</CellHeader>
                <CellHeader style={{ width: "25%" }}>Nom</CellHeader>
                <CellHeader style={{ width: "25%" }}>Prénom</CellHeader>
                <CellHeader style={{ width: "35%" }}>Club</CellHeader>
            </Row>
            {demande?.affilies?.map((e, i) => <Row key={i}>
                <Cell style={{ width: "15%" }}>{e?.matricule ?? " "}</Cell>
                <Cell style={{ width: "25%" }}>{e?.lastName ?? " "}</Cell>
                <Cell style={{ width: "25%" }}>{e?.firstName ?? " "}</Cell>
                <Cell style={{ width: "35%" }}>{e?.groupName ?? " "}</Cell>
            </Row>)}
        </Table2>

    </AssuranceLayoutPDF>);
}