import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import FormUser from './FormUser';
import Spinner from 'components/0components/Spinner';
import { UtilisateurApi } from 'api/UtilisateursApi';

export default _ => {
    const { id } = useParams();
    const history = useHistory();

    const [val, setVal] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const handleSubmit = (e, fields) => {
        e.preventDefault();
        UtilisateurApi
            .update(fields)
            .then(_ => history.goBack());
    }

    useEffect(_ => {
        setIsLoading(true);
        UtilisateurApi
            .get(id)
            .then(res => setVal(res))
            .finally(_ => setIsLoading(false));
    }, [id]);

    if (isLoading)
        return (<Spinner />);

    return (<FormUser
        title="Modifier Utilisateur"
        handleSubmit={handleSubmit}
        initData={val}
    />);
}
