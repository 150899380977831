import { useState } from 'react';
import { Auth } from "components/Form/Form";
//DEPRECATED
export default _ => {
    const [isLoading, setIsLoading] = useState(false);

    const fetch = async (myRequest, method, data) => {
        setIsLoading(true);
        return window.fetch(myRequest, Auth.options(method ?? 'GET', data))
            .then(async(res) => {
                const contentType = res.headers.get("content-type");

                if (res.status >= 200 && res.status <= 299) {
                    if (contentType && contentType.indexOf("application/json") !== -1)
                        return res.json();
                }
                else if (res.status >= 100 && res.status <= 199)
                    return;
                else {
                    if (contentType && contentType.indexOf("application/problem+json") !== -1)
                        throw await res.json();
                    else
                        throw await res;
                }
            })
            
            .finally(setIsLoading(false));
    }

    return { fetch, isLoading };
}