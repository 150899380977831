import React, { useState } from 'react';

import { OrganigrammeApi } from 'api/OrganigrammeApi';

import UpdateOrgaModal from 'components/Orga/UpdateOrgaModal';
import { UpdateIcon } from 'components/Icons';

export default ({ id, reload, initStartDate, initEndDate }) => {
    const [isVisible, setIsVisible] = useState(false);

    const handleClick = _ => setIsVisible(true);

    const submit = (e, startDate, endDate) => {
        e.preventDefault();

        OrganigrammeApi
            .update(id, startDate, endDate)
            .then(reload)
            .then(_ => setIsVisible(false))
            .catch(err => console.error(err));
    };

    return (<>
        <button className="btn btn-primary mr-1" onClick={handleClick}>
            <UpdateIcon />
        </button>
        {isVisible && <UpdateOrgaModal
            title="Modifier"
            initStartDate={initStartDate}
            initEndDate={initEndDate}
            submit={submit}
            isVisible={isVisible}
            setIsVisible={setIsVisible}
        />}
    </>);
}