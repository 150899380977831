import { Auth } from "components/Form/Form"

export const UserApi = {
    login: (userName, password) => {
        return fetch('/log', {
            method: 'POST',
            headers: new Headers({ "Content-Type": "application/json" }),
            body: JSON.stringify({ "login": userName, "password": password })
        })
            .then(async res => {
                if (res.status >= 200 && res.status <= 299)
                    return res.text();
                else {
                    let out = await res.json();
                    throw out;
                }
            })
            .then(res => Auth.login(res))
    },
}