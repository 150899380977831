import React, { useState } from 'react';
import { FormSelect, Label } from 'components/Form/Form';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import createTokenProvider from 'services/createTokenProvider';
import { yearsListBetween } from '../../../lib/utils';

export default _ => {
    const [isVisible, setIsVisible] = useState(false);

    return (<div className="d-flex justify-content-between align-items-center" onClick={_ => setIsVisible(true)}>
        <p className="my-auto"><span className="badge badge-success mr-3">XLSX</span>Calendrier</p>
        <button className="btn btn-primary ml-3" >
            <i className="fas fa-sm fa-arrow-down" data-toggle="tooltip" title="Télécharger" />
        </button>
        {isVisible && <MyModal isVisible={isVisible} setIsVisible={setIsVisible} />}
    </div>);
}

const MyModal = ({ isVisible, setIsVisible }) => {
    const annees = yearsListBetween(1979, new Date().getFullYear()+2);

    const [province, setProvince] = useState("");
    const [annee, setAnnee] = useState(new Date().getFullYear());

    return (<Modal isOpen={isVisible} toggle={_ => setIsVisible(!isVisible)}>
        <ModalHeader >XLSX - Calendrier</ModalHeader>
        <ModalBody>
            <FormSelect label="Province" required value={province} handleChange={val => setProvince(val)}>
                <option value="">Calendrier Général</option>
                <option value="dfc8be92-8cb2-11de-a173-001372568c9a">BRUXELLES - BRABANT WALLON</option>
                <option value="dfc8c0fe-8cb2-11de-a173-001372568c9a">HAINAUT</option>
                <option value="dfc8c284-8cb2-11de-a173-001372568c9a">LIEGE</option>
                <option value="dfc8c3f6-8cb2-11de-a173-001372568c9a">LUXEMBOURG</option>
                <option value="dfc8c554-8cb2-11de-a173-001372568c9a">NAMUR</option>
            </FormSelect>
            <div className="m-2">
                <Label label="Année" />
                <select className="custom-select mr-sm-2" value={annee} onChange={e => setAnnee(e.target.value)}>
                    {annees.map((e,i) => <option key={i}>{e}</option>)}
                </select>
            </div>
            <button className="btn btn-primary" onClick={_ => { window.open(`/api/marches/export/xlsx?jwt=${createTokenProvider().getToken()}&annee=${annee}&groupid=${province}`) } } >Télécharger</button>
        </ModalBody>
    </Modal>);
}
