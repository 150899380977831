import React, { useState, useEffect } from 'react';
import { Auth, FormSelect } from '../../Form/Form';
import Spinner from '../../0components/Spinner';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { yearsListBetween } from '../../../lib/utils';
import CalendrierGeneralLayout from './CalendrierGeneralLayout';

export default _ => {
    const [isVisible, setIsVisible] = useState(false);

    return (<div className="d-flex justify-content-between align-items-center" onClick={_ => setIsVisible(true)}>
        <p className="my-auto"><span className="badge badge-danger mr-3">PDF</span>Calendrier des Marches</p>
        <button className="btn btn-primary ml-3" >
            <i className="fas fa-sm fa-arrow-down" data-toggle="tooltip" title="Télécharger" />
        </button>
        {isVisible && <MyModal isVisible={isVisible} setIsVisible={setIsVisible} />}
    </div>);
}

const MyModal = ({ isVisible, setIsVisible }) => {
    const curYear = new Date().getFullYear()

    const beginYear = curYear;
    const endYear = curYear + 2;

    const [annee, setAnnee] = useState(curYear + 1); //année de recherche
    const [marches, setMarches] = useState();

    useEffect(_ => {
        setMarches();
        fetch(`api/marches/export/full?startDate=${annee}-01-01&endDate=${annee}-12-31`, Auth.options('GET'))
            .then(res => res.json())
            .then(res => setMarches(res));
    }, [annee])

    const canPdfBeGenerated = marches;

    return (<Modal isOpen={isVisible} toggle={_ => setIsVisible(false)}>
        <ModalHeader toggle={_ => setIsVisible(false)}>Génération Calendrier</ModalHeader>
        <ModalBody>
            <FormSelect label="Année" required value={annee} handleChange={val => setAnnee(val)}>
                {yearsListBetween(beginYear, endYear).map(e => <option value={e} key={e}>{e}</option>)}
            </FormSelect>

            {canPdfBeGenerated &&
                <CalendrierGeneralLayout
                    data={{
                        marches: marches,
                        annee: annee
                    }}
                    fileName={`Calendrier_fédéral_${annee}`} />}

            {!canPdfBeGenerated && <>
                <p>Chargement des données...</p>
                <Spinner />
            </>}

        </ModalBody>
    </Modal>);
}
