import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import adresse from 'lib/Membres/adresse';
import Loading from 'components/Loading/Loading';

export default props => {
    const isVisible = props.isVisible;
    const toggle = props.toggle;
    const club = props.club;

    if (!club)
        return <Loading/>

    const { name, matricule, coord } = club;

    return (<Modal isOpen={isVisible} toggle={toggle} size="lg" centered>
        <ModalHeader toggle={toggle}>{name}</ModalHeader>
        <ModalBody>
            <div className="p-3">
                <div className="row">
                    <div className="col-4">Matricule:</div>
                    <div className="col-8">{matricule ?? "Pas de Matricule trouvé" }</div>
                </div>
                <CoordView coord={coord} />
            </div>
            {props.children}
        </ModalBody>
    </Modal>);
}

const CoordView = ({ coord }) => {

    if (!coord)
        return <></>;

    const { adress, website, phone, gsm, email } = coord;

    return (<>
        <div className="row">
            <div className="col-4">Adresse:</div>
            <div className="col-8">{adress ? adresse(adress) : '/'}</div>
        </div>
        <div className="row"> 
            <div className="col-4">Site Web:</div> 
            <div className="col-8">{website ?? '/'}</div> 
        </div> 
        <div className="row"> 
            <div className="col-4">Téléphonne:</div> 
            <div className="col-8">{phone ?? '/'}</div> 
        </div> 
        <div className="row"> 
            <div className="col-4">GSM:</div> 
            <div className="col-8">{gsm ?? '/'}</div> 
        </div> 
        <div className="row"> 
            <div className="col-4">E-Mail:</div> 
            <div className="col-8">{email ?? '/'}</div> 
        </div>
    </>);

} 