import React from 'react';

import { Actions, Objects, Show } from 'services/AccessControl';

import WarningBubble from './WarningBubble';

export default ({candidats, marches, assurances})  => {
    const idCA = "dfc82482-8cb2-11de-a173-001372568c9a";

    return (<div className="row">
        <Show object={Objects.Candidat} action={Actions.Valid} ressource={idCA}>
            <div className="col-md mb-3">
                <WarningBubble
                    title="Candidats en attente"
                    array={candidats}
                    icon="fa-users"
                />
            </div>
        </Show>
        <Show object={Objects.Marche} action={Actions.Valid} ressource={idCA}>
            <div className="col-md mb-3">
                <WarningBubble
                    title="marches en attente"
                    array={marches}
                    icon="fa-calendar"
                />
            </div>
        </Show>
        <Show object={Objects.Assurance} action={Actions.Valid} ressource={idCA}>
            <div className="col-md mb-3">
                <WarningBubble
                    title="Demandes d'assurance en attente"
                    array={assurances}
                    icon="fa-file-contract"
                />
            </div>
        </Show>
    </div>);
}
