import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { removeFromArray } from "../../../lib/utils";
import Button from "../../0components/Button";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Spinner from "../../0components/Spinner";
import adresse from "../../../lib/Membres/adresse";
import { MembreApi } from "../../../api/MembreApi";
import { AssurancesApi } from "../../../api/AssurancesApi";
import FormatDate from "../../../lib/FormatDate";

export default ({ demande }) => {
    return (<>
        <b>Affiliés: </b>
        <AffiliesTable affilies={demande?.affilies}/>
    </>);
};

const AffiliesTable = ({ affilies, setAffilies, setIsModalVisible, handleSuppr, ...rest }) => {

    const handleClickAdd = _ => setIsModalVisible(true);

    if (!affilies)
        return <Spinner />;

    return (<table className="table table-striped mt-2" {...rest}>
        <thead className="thead-dark">
            <tr>
                <th scope="col">Matricule</th>
                <th scope="col">Nom</th>
                <th scope="col">Prenom</th>
                <th scope="col">Date de Naissance</th>
                <th scope="col">Adresse</th>
                {setAffilies && <th scope="col"><Button onClick={handleClickAdd}>+</Button></th>}
            </tr>
        </thead>
        <tbody>
            {affilies?.map((e, i) => (<tr key={i}>
                <td>{e.matricule}</td>
                <td>{e.lastName}</td>
                <td>{e.firstName}</td>
                <td>{FormatDate(e.birthDate)}</td>
                <td>{adresse(e?.coord?.adress)}</td>
                {setAffilies && <td><Button onClick={e => handleSuppr(e, i)}>-</Button></td>}
            </tr>))}
        </tbody>
    </table>);
}

const MyModal = ({ isVisible, setIsVisible, handleAdd }) => {
    const [membres, setMembres] = useState([]);
    const [search, setSearch] = useState(""); 

    useEffect(_ => {
        MembreApi
            .search(search)
            .then(res => setMembres(res));
    }, [search]);

    const addMarcheur = (e, m) => {
        e.preventDefault();
        handleAdd({ "id": m.id, "matricule": m.matricule, "lastName": m.lastName, "firstName": m.firstName, "birthDate": m.birthDate, gender:m.gender });
    }

    return (<Modal isOpen={isVisible} toggle={_ => setIsVisible(false)}>
        <ModalHeader toggle={_ => setIsVisible(false)}>Ajouter un Membre</ModalHeader>
        <ModalBody>
            <form>
                <label>Recherche par matricule</label>
                <input
                    className="form-control mr-sm-2 mb-2"
                    type="text"
                    onChange={e => setSearch(e.target.value)}
                    value={search}
                    required
                />
                <div className="list-group">
                    {membres?.map((m, i) => <div key={i} className="list-group-item list-group-item-action list-group-item-light" onClick={e => addMarcheur(e, m)}>{m.matricule} - {m.firstName} {m.lastName}</div>)}
                </div>
            </form>
        </ModalBody>
    </Modal>);
}

const MyForm = ({ titre, initData, onSubmit }) => {
    const [fields, setFields] = useState(initData);

    const handleSubmit = e => {
        e.preventDefault();
        onSubmit(fields);
    }

    if (!fields)
        return <p>Chargement des données</p>

    return (<div>
        <h1 className="display-4 mb-4">{titre}</h1>
        <ManagedTable data={fields.affilies ?? []} setData={elem => setFields({ ...fields, 'affilies': elem })} />
        <Button onClick={handleSubmit}>Confirmer</Button>
    </div>);
}

const ManagedTable = ({ data, setData, ...rest }) => {
    const [isAddModalVisible, setIsModalVisible] = useState(false);

    const add = v => {
        setIsModalVisible(false);
        let tmp = [...data];
        tmp.push(v);
        setData(tmp);
    }

    const handleSuppr = (e, i) => {
        e.preventDefault();
        setData(removeFromArray(i, data));
    };

    return (<div {...rest}>
        {data && <>
            <MyModal isVisible={isAddModalVisible} setIsVisible={setIsModalVisible} handleAdd={v => add(v)} />
            <AffiliesTable affilies={data} setAffilies={setData} setIsModalVisible={setIsModalVisible} handleSuppr={handleSuppr} />
        </>}
    </div>);
}

export const Form201 = ({ gid: id }) => {
    const history = useHistory();

    const submit = fields => {
        AssurancesApi
            .create(fields, '201')
            .then(_ => history.goBack())
    }

    return <MyForm
        titre="Création Demande ROI ASS 201 : Incapacité temporaire"
        initData={{ "groupId": id }}
        onSubmit={submit}
    />;
};

export const UpdateForm201 = ({ demande }) => {
    const history = useHistory();

    const submit = fields => {
        AssurancesApi
            .update(fields, '201')
            .then(_ => history.goBack())
    }

    return <MyForm
        titre="Modification Demande ROI ASS 201 : Incapacité temporaire"
        initData={demande}
        onSubmit={submit}
    />;
};
