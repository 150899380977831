import React, { useState, useEffect } from 'react';
import { ParametresApi } from 'api/ParametresApi';
import { LoadingButton } from 'components/0components/Spinner';

export default ({titre, param}) => {
    const [costAffiliation, setCostAffiliation] = useState('');

    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(true);

    function FetchingParam()
    {
        setIsLoading(true);
        ParametresApi.get(param)
            .then(res => setCostAffiliation(res.value))
            .catch(err => setError('Erreur récupération de la donnée impossible'))
            .finally(_ => setIsLoading(false))
    }
    
    useEffect(FetchingParam,[]);

    const submitHandler = e => {
        e.preventDefault();
        setIsLoading(true);
        ParametresApi
            .update(param, costAffiliation)
            .then(res => {if (!res.ok) throw res; })
            .catch(err => setError('Erreur lors de la modification'))
            .finally(_ => setIsLoading(false))
    }

    return (<div className='m-3'>
        <form onSubmit={submitHandler}>
            <label htmlFor="costcot">{titre}</label>
            <div className="form-row">
                <div className="col-md-6 mb-3">
                    <input
                        id="costcot"
                        className={!error ? "form-control mb-1" : "form-control mb-1 is-invalid"}
                        type="number"
                        min="0"
                        max="100"
                        step=".01"
                        placeholder="Prix"
                        required
                        onChange={e => setCostAffiliation(e.target.value)}
                        value={costAffiliation}
                    />
                    {error && <div className="invalid-feedback">{error}</div>}
                </div>
                <div className="col-md-6 mb-3">
                    {isLoading ?
                        <LoadingButton>Sauvegarder</LoadingButton> :
                        <button className="btn btn-primary" type="submit">Sauvegarder</button>}
                </div>
            </div>
        </form>
    </div>);
}