import createTokenProvider from './createTokenProvider';

const createAuthProvider = _ => {
    const tokenProvider = createTokenProvider();

    const login = newToken => tokenProvider.setToken(newToken);
    const logout = _ => tokenProvider.setToken(false);
    const fetchToken = _ => tokenProvider.getToken();
    const token = _ => tokenProvider.getToken();
    const isLoggedIn = tokenProvider.isLoggedIn;

    const options = (method, data) => requestOptions(method, JSON.stringify(data));

    const requestOptions = (method, data) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", 'Bearer ' + tokenProvider.getToken());

        return {
            method: method,
            headers: myHeaders,
            body: data
        };
    }


    const getRequestOptions = _ => requestOptions('GET');
    const postRequestOptions = data => requestOptions('POST', data);
    const patchRequestOptions = data => requestOptions('PATCH', data);
    const deleteRequestOptions = data => requestOptions('DELETE', data);

    const check = res => {
        if (res.status >= 200 && res.status <= 299)
            return res.json();
        else 
            throw res;
    }

    const checkError = async res => {
        if (res.status >= 200 && res.status <= 299)
            return res.json();
        else if (res.status >= 100 && res.status <= 199)
            return ;
        else 
            throw await res.json();
    }

    const checkErrorNoData = async res => {
        if (res.status >= 200 && res.status <= 299)
            return;
        else if (res.status >= 100 && res.status <= 199)
            return;
        else
            throw await res.json();
    }

    const fetchFile = async (uri) => await window.fetch(uri, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + tokenProvider.getToken()
        }
    });
    const fetch = async (uri, options) => await window.fetch(uri, options).then(checkError);
    const fetch2 = async (uri, method, data) => await fetch(uri, options(method, data));
    const fetchNoData = async (uri, method, data) => await window.fetch(uri, options(method, data)).then(checkErrorNoData);

    return {
        check,
        fetch,
        fetchFile,
        fetchNoData,
        fetch2,
        fetchToken,
        token,
        isLoggedIn,
        login,
        logout,
        options,
        requestOptions,
        getRequestOptions,
        postRequestOptions,
        patchRequestOptions,
        deleteRequestOptions
    }
};

export default createAuthProvider();
