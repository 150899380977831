import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './Marches.css';

import { FormatDateShortAndYear } from 'lib/FormatDate';

import { MarchesApi } from 'api/MarchesApi';

export default _ => {
    const history = useHistory();

    const initBeginSearch = new Date().toISOString().split('T')[0];
    const initEndSearch = new Date().getFullYear() + 1 + "-01-01";

    const [marches, setMarches] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchMarches = (beginSearch, endSearch) => {
        setIsLoading(true);

        MarchesApi
            .search(beginSearch, endSearch)
            .then(res => setMarches(res))
            .catch(err => console.error(err))
            .finally(_ => setIsLoading(false));
    }

    const handleClickMarche = m => { history.push(`/marches/${m.id}`); }
    const handleSearchMarche = (beginSearch, endSearch) => { fetchMarches(beginSearch, endSearch); }

    useEffect(_ => {
        fetchMarches(initBeginSearch, initEndSearch);
    }, [initBeginSearch, initEndSearch]);

    return (<>
        <div className="row mb-4">
            <div className="col-lg">
                <h3 className="h3">Marches</h3>
            </div>
            <div className="col-lg-auto">
                <RechercheMarche
                    handleSearchMarche={handleSearchMarche}
                    initBeginSearch={initBeginSearch}
                    initEndSearch={initEndSearch}
                />
            </div>
        </div>
        {isLoading ? <LoadingSpinner /> : <ListeMarchesTable marches={marches} handleClickMarche={handleClickMarche} />}
    </>);
};

const LoadingSpinner = _ => {
    return (<div className="d-flex text-primary justify-content-center">
        <div className="spinner-border" role="status" style={{ width: "3rem", height: "3rem"}}>
            <span className="sr-only">Loading...</span>
        </div>
    </div>);
}

const RechercheMarche = ({initBeginSearch, initEndSearch, handleSearchMarche }) => {
    const [beginSearch, setBeginSearch] = useState(initBeginSearch);
    const [endSearch, setEndSearch] = useState(initEndSearch);

    return (<div className="container">
        <form onSubmit={e => {e.preventDefault(); handleSearchMarche(beginSearch, endSearch);}}>
            <div className="row">
                <div className="col" />
                <div className="col-md-auto my-auto">
                    <span>Marches du: </span>
                </div>
                <div className="col-md-auto">
                    <input
                        className="form-control"
                        type="date"
                        onChange={e => setBeginSearch(e.target.value)}
                        value={beginSearch}
                    />
                </div>
                <div className="col-md-auto my-auto">
                    <span>au</span>
                </div>
                <div className="col-md-auto">
                    <input
                        className="form-control"
                        type="date"
                        min={beginSearch}
                        onChange={e => setEndSearch(e.target.value)}
                        value={endSearch}
                    />
                </div>
                <div className="col-md-auto">
                    <button type="submit" className="btn btn-primary" >
                        Rechercher
                        <i className="fa-solid fa-magnifying-glass ml-2"></i>
                    </button>
                </div>
                <div className="col" />
            </div>
        </form>
    </div>);
}

// const ListeMarches = ({ marches, handleClickMarche }) => {

//     const TexteDescriptionTronque = description => {
//         if (description.length > 60)
//             return description?.substring(0, 80 - 3) + "..."
//         else
//             return description;
//     }

//     return (<div className="row ml-lg-5 mr-lg-5">
//         {marches?.map(m =>
//             <div className="col-lg-4" key={m.id} onClick={_ => handleClickMarche(m)}>
//                 <div className={`card card-margin cardhover `}>
//                     <div className="card-body pt-0 mt-3">
//                         <div className="row">
//                             <div className="col-4 ">
//                                 <div className="p-2 border rounded text-primary" style={{ backgroundColor:"#edf1fc"} }> 
//                                     <p className="text-center h5">{new Date(m.date).toLocaleString('fr-FR', { weekday: 'short' })}</p>
//                                     <p className="text-center h3">{new Date(m.date).getDate()}</p>
//                                     <p className="text-center h5">{new Date(m.date).toLocaleString('fr-FR', { month: 'short' })}</p>
//                                 </div>
//                             </div>
//                             <div className="col-8">
//                                 <p className={`text-dark ${!m.validee ? "text-danger" : ""}`}>{m.titreMarche}</p>
//                                 <p className="lead small">{new Date(m.date).toLocaleString('fr-FR', { weekday: "long", year: "numeric", month: "long", day: "numeric" })}</p>
//                             </div>
//                         </div>
//                         <div>
//                             <p className="m-2"><i className="fas fa-fw fa-map-marker-alt text-secondary mr-1" />{m.lieuDepart}</p>
//                             <p className="m-2"><i className="fas fa-fw fa-users text-secondary mr-1" />{m?.groupMatricule} - {m?.groupLibelle}</p>
//                             <p>{TexteDescriptionTronque(m?.description)}</p>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         )}
//     </div>);
// }

const ListeMarchesTable = ({ marches, handleClickMarche }) => {

    const FormatStateWalk = walk => {
        if (new Date(walk?.date) >= new Date()) {
            if (walk?.validee)
                return "text-primary";
            else
                return "text-warning";
        }
        else
            return "text-secondary";
    }

    return (<div className="card">
        <div className="table-responsive-xl">
            <table className="table table-hover">
                <thead className="thead-light">
                    <tr>
                        <th scope="col" className="text-dark">Date</th>
                        <th scope="col" className="text-dark">Marche</th>
                        <th scope="col" className="text-dark">Club</th>
                    </tr>
                </thead>
                <tbody>
                    {marches?.map(m => <tr key={m.id} onClick={_ => handleClickMarche(m)}>
                        <th className={FormatStateWalk(m)}>
                            {m.date ? FormatDateShortAndYear(m.date) : "Indisponible"}
                        </th>
                        <td>{m?.titreMarche ?? ""}</td>
                        <td>{`(${m?.groupMatricule}) ${m.groupLibelle}`}</td>
                    </tr>)}
                </tbody>
            </table>
        </div>
    </div>);
}