import React, { useState } from 'react';
import { useHistory } from "react-router-dom";

import Button from 'components/0components/Button';

import ModalInfoClub from "./ModalInfoClub";
import { ClubApiV2 } from 'api/ClubApiV2';
import { Actions, Objects, Show } from '../../../services/AccessControl';

export default ({ club, reloadClub }) => {
    const history = useHistory();
    const [showModal, setShowModal] = useState(false);

    const toggleModal = _ => setShowModal(!showModal);
    const handleModify = _ => history.push({ pathname: `/clubs/${club?.id}/modifier/` });
    const handleActivate = _ => ClubApiV2.activerClub(club?.id).then(_ => reloadClub())
    const handleDisactivate = _ => ClubApiV2.desactiverClub(club?.id).then(_ => reloadClub());

    if (!club)
        return <></>;

    return (<>
        <Button onClick={toggleModal} ><i className="fas fa-fw fa-info" /></Button>
        <ModalInfoClub club={club} isVisible={showModal} toggle={toggleModal}>
            <div className="row">
                <div className="col-auto my-auto">
                    <button type="button" className="btn btn-primary" data-toggle="tooltip" title="Modifier" onClick={handleModify}>
                        Modifier  <i className="fas fa-fw fa-pen" />
                    </button>
                </div>
                <Show action={Actions.Manage} object={Objects.Club} ressource={club?.Id}>
                    {!club?.isActive && <div className="col-auto my-auto">
                        <Button onClick={handleActivate} color="success">
                            Réactiver le club<i className="fas fa-fw fa-play" />
                        </Button>
                    </div>}
                    {club?.isActive && <div className="col-auto my-auto">
                        <Button onClick={handleDisactivate} color="danger">
                            Désactiver le club<i className="fas fa-fw fa-stop" />
                        </Button>
                    </div>}
                </Show>
            </div>  
        </ModalInfoClub>
    </>);
}