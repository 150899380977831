import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AssurancesApi } from 'api/AssurancesApi';

import { UpdateForm140 } from './Ass140';
import { UpdateForm143 } from './Ass143';
import { UpdateForm143Bis } from './Ass143Bis';
import { UpdateForm144 } from './Ass144';
import { UpdateForm147 } from './Ass147';
import { UpdateForm201 } from './Ass201';

export default _ => {
    const { id } = useParams();
    const [demande, setDemande] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(_ => {
        AssurancesApi
            .get(id)
            .then(res => setDemande(res))
            .catch(err => console.error(err))
            .finally(_ => setIsLoading(false))
    },[id])

    if (!id) {
        console.error("Update Demande Ass", "id: " + id);
        return <ErrorBanner text="Erreur: id null"/>
    }
    
    const UpdateDemandeForm = _ => {
        console.log(demande)
        switch (demande?.code) {
            case 1400:
                return <UpdateForm140 demande={demande} />;
            case 1430:
                return <UpdateForm143 demande={demande} />;
            case 1431:
                return <UpdateForm143Bis demande={demande} />;
            case 1440:
                return <UpdateForm144 demande={demande} />;
            case 1470:
                return <UpdateForm147 demande={demande} />;
            case 2010:
                return <UpdateForm201 demande={demande} />;
            default:
                return <ErrorBanner text="Erreur: code null" />;
        }
    }

    if (isLoading)
        return <p>Chargement...</p>

    return (<UpdateDemandeForm demande={demande}/>)
}

const ErrorBanner = ({text}) => {
    return (<div className="alert alert-danger" role="alert">{text}</div>);
}