import React, { useState, useEffect } from 'react';
import { useParams, useHistory, Switch, NavLink, Redirect } from 'react-router-dom';

import { Actions, Objects, Show, Route } from 'services/AccessControl';

import Club from 'bll/Club/Club';

import { MembreApi } from 'api/MembreApi';
import { ClubApi } from 'api/ClubApi';
import { ParametresApi } from 'api/ParametresApi';
import { CandidatApi } from 'api/CandidatApi';
import { MarchesApi } from 'api/MarchesApi';
import { OrganigrammeApi } from 'api/OrganigrammeApi';

import Loading from 'components/Loading/Loading';
import ModalInfoClub from 'components/Club/ModalInfoClub';
import Candidats from 'components/Club/Candidats';
import Organigramme from 'components/Club/Organigramme';

import { BAss, BCandidats, BCredit, BMarches, BNumMember } from './ClubPage/Bubbles';
import ClubAssurances from './ClubAssurances';
import ClubCotisation from './ClubCotisation';
import Marches from './ClubPage/Marches';
import Membres from './ClubPage/Membres';

import useAcces from 'services/AccessControl/useAcces';
import useAcces2 from '../../services/AccessControl/useAcces2';

export default _ => {
    const { id } = useParams();
    const history = useHistory();

    const hasPerm = useAcces();
    const hasPerm2 = useAcces2();

    const path = `/clubs/${id}`;

    const initBeginSearchAssurances = new Date().getFullYear() + "-01-01";
    const initEndSearchAssurances = new Date().getFullYear() + "-12-31";

    const [reaffiliationYear, setReaffiliationYear] = useState(new Date().getFullYear());

    const [cost, setCost] = useState();

    const [club, setClub] = useState();
    const [isClubLoading, setIsClubLoading] = useState(true);

    const [membres, setMembres] = useState([]); 
    const [isMembresLoading, setIsMembresLoading] = useState(true);

    const [marches, setMarches] = useState([]); 
    const [isMarchesLoading, setIsMarchesLoading] = useState(true);

    const [candidats, setCandidats] = useState([]);
    const [isCandidatsLoading, setCandidatIsLoading] = useState(true);

    const [assurances, setAssurances] = useState([]);
    const [isAssurancesLoading, setIsAssurancesLoading] = useState(true);

    const [organigramme, setOrganigramme] = useState([]);
    const [isOrgaLoading, setIsOrgaLoading] = useState(true);

    const fetchCandidats = _ => {
        ClubApi
            .getCandidats(id)
            .then(res => setCandidats(res))
            .finally(_ => setCandidatIsLoading(false))
    }

    const fetchMarches = _ => {
        MarchesApi
            .byGroup(id)
            .then(res => setMarches(res))
            .finally(_ => setIsMarchesLoading(false));
    }

    const fetchCost = _ => {
        ParametresApi
            .get("MONTANT_COTISATION")
            .then(res => setCost(res.value));
    };

    const fetchMembres = _ => {
        ClubApi
            .getMembres(id, reaffiliationYear)
            .then(res => setMembres(res))
            .finally(_ => setIsMembresLoading(false));
    };

    const fetchAssurances = (beginSearch, endSearch) => {
        ClubApi
            .getAssurances(id, beginSearch, endSearch)
            .then(res => setAssurances(res))
            .finally(_ => setIsAssurancesLoading(false));
    }

    const fetchClub = _ => {
        ClubApi
            .get(id)
            .then(res => setClub(res))
            .finally(_ => setIsClubLoading(false));
    }; 

    const fetchOrganigramme = _ => {
        OrganigrammeApi
            .get(id)
            .then(res => setOrganigramme(res))
            .finally(_ => setIsOrgaLoading(false));
    } 

    const handleDeleteCandidat = candidat => {
        CandidatApi
            .delete(candidat.id)
            .then(fetchCandidats);
    }

    const handleUpdateCredit = (id, credit) => {
        ClubApi
            .updateCredit(id, credit)
            .then(fetchClub);
    }

    const handleUpdateCandidat = candidat => history.push(`/candidats/${candidat.id}/update`);
    const handleInfoWalk = id => history.push(`/marches/${id}`);
    const handleClickCreateMarche = _ => history.push('/marches/nouvelle', { groupId: id });

    const clickMemberPayCotisation = (memberId) => {
        //if (!Club.isCreditSuffisant(club, cost)) {
        //    window.alert("Credit Insuffisant");
        //    return;
        //}

        MembreApi.PayerCotisation(memberId, id, reaffiliationYear)
            .then(fetchMembres)
            .then(fetchClub);
    }

    useEffect(fetchMembres, [reaffiliationYear]);
    useEffect(fetchCost, [id]);
    useEffect(fetchClub, [id]);
    useEffect(fetchMembres, [id]);
    useEffect(fetchCandidats, [id]);
    useEffect(fetchMarches, [id]);
    useEffect(fetchOrganigramme, [id]);
    useEffect(_ => {
        fetchAssurances(initBeginSearchAssurances, initEndSearchAssurances);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initBeginSearchAssurances, initEndSearchAssurances, id]);

    const handleClickAssurance = AssuranceId => history.push(`/assurances/${AssuranceId}`);
    const handleSearch = (beginSearch, endSearch) => fetchAssurances(beginSearch, endSearch);

    const handleNouvAss = code => {
        history.push(`/assurances/new`, { code: code, grpid: id });
    };
   
    if (isClubLoading)
        return <Loading />;

    if (!isClubLoading && !club)
        return <h1>Erreur, club Introuvable</h1>;

    return (<>
        <div className="row mb-4">
            <div className="col-auto">
                <h1 className={club?.isActive ? "display-4" : " display-4 text-danger" }>{club?.matricule} - {club?.name}</h1>
            </div>
            <div className="col-auto ml-3 m-auto">
                <ModalInfoClub club={club} reloadClub={fetchClub} />
            </div>
            <div className="col"/>
        </div>

        <div className="row mb-2">
            <Show object={Objects.Club} action={Actions.Read} ressource={id}>
                <div className="col-lg mb-2">
                    <BNumMember
                        membres={membres}
                        isLoading={isMembresLoading}
                    />
                </div>
            </Show>

            <Show object={Objects.Candidat} action={Actions.Read} ressource={id}>
                <div className="col-lg mb-2">
                    <BCandidats candidats={candidats} />
                </div>
            </Show>

            <Show object={Objects.Cotisation} action={Actions.Read} ressource={id}>
                <div className="col-lg mb-2">
                    <BCredit
                        cost={cost}
                        credit={club?.credit ?? 0}
                    />
                </div>
            </Show>

            <Show object={Objects.Marche} action={Actions.Read} ressource={id}>
                <div className="col-lg mb-2">
                    <BMarches 
                        marches={marches}
                        isLoading={isMarchesLoading} />
                </div>
            </Show>

            <Show object={Objects.Assurance} action={Actions.Read} ressource={id}>
                <div className="col-lg mb-2">
                    <BAss idClub={id} />
                </div>
            </Show>

        </div>
        <div className="card p-4">
            <div className="row">
                <div className="col-xl-auto">
                    <ul className="nav nav-pills flex-column">
                        {hasPerm2(id) && <li className="nav-item">
                            <NavLink className="nav-link" to={path} exact>
                                <i className="fa-solid fa-users" /> Membres
                            </NavLink>
                        </li>}
                        {hasPerm(Objects.Candidat, Actions.Read, id) && hasPerm2(id) && <li className="nav-item">
                            <NavLink className="nav-link" to={path + '/candidats'} exact>
                                <i className="fa-solid fa-user-plus" /> Candidats {candidats?.length > 0 && <span className="badge badge-danger">{candidats?.length}</span>}
                            </NavLink>
                        </li>}

                        {hasPerm(Objects.Marche, Actions.Read, id) && hasPerm2(id) && <li className="nav-item">
                            <NavLink className="nav-link" to={path + '/marches'}>
                                <i className="fa-solid fa-calendar" /> Marches
                            </NavLink>
                        </li>}

                        {hasPerm(Objects.Assurance, Actions.Read, id) && hasPerm2(id) &&  <li className="nav-item">
                            <NavLink className="nav-link" to={path + '/assurances'}>
                                <i className="fa-solid fa-file" /> Assurances
                            </NavLink>
                        </li>}

                        {hasPerm(Objects.Organigramme, Actions.Read, id) && <li className="nav-item">
                            <NavLink className="nav-link" to={path + '/organigramme'}>
                                <i className="fa-solid fa-sitemap" /> Organigramme
                            </NavLink>
                        </li>}

                        {hasPerm(Objects.Cotisation, Actions.Update, id) && hasPerm2(id) && <li className="nav-item">
                            <NavLink className="nav-link" to={path + '/credit'}>
                                <i className="fa-solid fa-coins" /> Crédit
                            </NavLink>
                        </li>}
                    </ul>
                </div>
                <div className="col-auto">
                    <div className="vr" style={{ "height": "100%" }}></div>
                </div>
                <div className="col-xl">
                    <Switch>
                        <Route exact path="/clubs/:id" object={Objects.Club} action={Actions.Read} ressource={id}>
                            <Membres
                                id={id}
                                club={club}
                                cost={cost}
                                isCreditSuffisant={Club.isCreditSuffisant(club, cost)}
                                membres={membres}
                                reaffiliationYear={reaffiliationYear}
                                setReaffiliationYear={setReaffiliationYear}
                                clickMemberPayCotisation={clickMemberPayCotisation} />
                        </Route>

                        <Route path="/clubs/:id/candidats" object={Objects.Candidat} action={Actions.Read} ressource={id}>
                            <Candidats
                                club={club}
                                candidats={candidats}
                                isLoading={isCandidatsLoading}
                                handleDeleteCandidat={handleDeleteCandidat}
                                handleUpdateCandidat={handleUpdateCandidat}
                            />
                        </Route>

                        <Route path="/clubs/:id/credit" object={Objects.Cotisation} action={Actions.Update} ressource={id}>
                            <ClubCotisation
                                credit={club.credit}
                                isLoading={isClubLoading}
                                handleUpdateCredit={handleUpdateCredit}
                            />
                        </Route>

                        <Route path="/clubs/:id/assurances" object={Objects.Assurance} action={Actions.Read} ressource={id}>
                            <ClubAssurances
                                assurances={assurances}
                                isLoading={isAssurancesLoading}
                                handleSearch={handleSearch}
                                handleClickAssurance={handleClickAssurance}
                                initBeginSearch={initBeginSearchAssurances}
                                initEndSearch={initEndSearchAssurances}
                                handleNouvAss={handleNouvAss}
                            />
                        </Route>

                        <Route path="/clubs/:id/marches" object={Objects.Marche} action={Actions.Read} ressource={id}>
                            <Marches
                                marches={marches}
                                reload={fetchMarches}
                                isLoading={isMarchesLoading}
                                handleInfoWalk={handleInfoWalk}
                                handleClickCreateMarche={handleClickCreateMarche}
                            />
                        </Route>

                        <Route path="/clubs/:id/organigramme" object={Objects.Organigramme} action={Actions.Read} ressource={id}>
                            <Organigramme
                                id={id}
                                organigramme={organigramme}
                                isLoading={isOrgaLoading}
                                reload={fetchOrganigramme}
                            />
                        </Route>

                        <Route path="/clubs/*">
                            <Redirect to="/404"/>
                        </Route>
                    </Switch>
                </div>
            </div>
        </div>
    </>);
}