import React from 'react';

import Input from 'components/Form/Input';
import SubmitButtonLoading from 'components/Form/SubmitButtonLoading';
import ContactDetailsForm from './ContactDetailsForm';

const GroupInfoForm = (props) => {
    const { Submit, formData, onChangeInput, errors } = props;

    const { matricule, name, cptFin, cptFinLibelle, numEnterprise, bankNumber, bankBicNumber, isffbmp, coord } = formData;

    return (<div className="container-fluid">
        <form onSubmit={e => Submit(e)} className="m-2">
            <h1 className="display-4 mb-3">Création de club</h1>
            <div className="mb-4">
                <h4 className="h4 mb-3">Informations Générales</h4>
                <Input
                    label='Matricule:'
                    name='matricule'
                    minLength='1'
                    maxLength='8'
                    placeholder='ex: BBW  XXX'
                    onChange={onChangeInput}
                    value={matricule}
                    error={errors?.Matricule}
                    required
                />
                <Input
                    label="Nom du groupe:"
                    name='name'
                    minLength='1'
                    maxLength='60'
                    onChange={onChangeInput}
                    value={name}
                    error={errors?.Name}
                    required
                />
                <Input
                    label="Groupe FFBMP"
                    name="isffbmp"
                    type="checkbox"
                    onChange={onChangeInput}
                    value={isffbmp}
                    error={errors?.Isffbmp}
                />
            </div>
            <div className="mb-4">
                 <ContactDetailsForm
                    name="coord"
                    value={coord}
                    onChange={onChangeInput}
                    error={errors?.coord}
                />                
            </div>
            <div className="mb-4">
                <h4 className="h4 mb-3">Données financières</h4>
                <Input
                    label='Compte Financier:'
                    name='cptFin'
                    placeholder='Compte financier'
                    onChange={onChangeInput}
                    value={cptFin}
                    error={errors?.CptFin}
                />
                <Input
                    label='N° de compte (IBAN):'
                    name='bankNumber'
                    pattern='[a-zA-Z]{2}[0-9, ]+'
                    placeholder='BEXX XXXX XXXX XXXX'
                    onChange={onChangeInput}
                    value={bankNumber}
                    error={errors?.BankNumber}
                />
                <Input
                    label='Code BIC:'
                    name='bankBicNumber'
                    placeholder='code BIC de la banque'
                    onChange={onChangeInput}
                    value={bankBicNumber}
                    error={errors?.BankBicNumber}
                />
                <Input
                    label='Intitulé Compte Financier'
                    name='cptFinLibelle'
                    placeholder='Intitulé Compte Financier'
                    onChange={onChangeInput}
                    value={cptFinLibelle}
                    error={errors?.CptFinLibelle}
                />
                <Input
                    label="N° d'entreprise:"
                    name='numEnterprise'
                    placeholder="N° d'entreprise"
                    onChange={onChangeInput}
                    value={numEnterprise}
                    error={errors?.NumEnterprise}
                />
            </div>
            <SubmitButtonLoading isLoading={false} />
        </form>
    </div>);
}

export default GroupInfoForm;