import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from "react-router-dom";
import FormatDate from 'lib/FormatDate';
import FormatAdress from 'lib/FormatAdress';
import './iframe.css';
import Spinner from 'components/0components/Spinner';
import { MarchesApi } from 'api/MarchesApi';
import { Actions, Objects, Show } from 'services/AccessControl';
import { FormatDateShortAndYear } from 'lib/FormatDate';

export default _ => {
    const { id } = useParams();
    const history = useHistory();

    const [walk, setWalk] = useState();
    const [isLoading, setIsLoading] = useState(true); // principalement utilisé pour le chargement initial des données 

    function fetchMarche() 
    {
        MarchesApi
            .get(id)
            .then(res => setWalk(res))
            .finally(_ => setIsLoading(false));
    } 

    useEffect(fetchMarche, [id]);

    const handleInvalidate = _ => {
        MarchesApi
            .invalidate(id)
            .then(fetchMarche);
    }

    const handleValidate = _ => {
        MarchesApi
            .validate(id)
            .then(fetchMarche);
    }

    const handleModify = _ => history.push(`/marches/modifier/${id}`);

    const handleDelete = _ => {
        if (window.confirm(`Vous allez supprimer la marche ${walk.titreMarche}. Continuer ?`)) {
            MarchesApi
                .delete(id)
                .then(_ => history.goBack())
        }
    }

    function getDayLabel(date) {
        const days = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
        const dayIndex = date.getDay();
        return days[dayIndex];
    }

    if (isLoading)
        return <Spinner/>

    return (<>
        <div className="row mb-4">
            <div className="col">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                <h4 className="h4-responsive"><strong className="mr-2">{FormatDateShortAndYear(walk?.date)}</strong>{walk?.titreMarche}</h4>
                                <p className="lead">
                                    {walk?.typeMarcheLibelle}
                                    <span className={`h6 badge badge-${walk?.validee ? "success" : "danger"}  ml-2`}>
                                        {walk?.validee ? "Validée" : "Pas Validée"}
                                    </span>
                                </p>
                            </div>
                            <div className="col-auto my-auto">
                                {walk?.validee && <>
                                    <Show object={Objects.Marche} action={Actions.Valid}>
                                        <button type="button" className="btn btn-danger m-1" onClick={handleInvalidate}>
                                            Invalider <i className="fas fa-sm fa-times" data-toggle="tooltip" title="Invalider" />
                                        </button>
                                    </Show>
                                </>} 
                                {!walk?.validee &&
                                    <div>
                                        <Show object={Objects.Marche} action={Actions.Valid}>
                                            <button type="button" className="btn btn-success m-1" onClick={handleValidate}>
                                                Valider <i className="fas fa-sm fa-check" data-toggle="tooltip" title="Valider" />
                                            </button>
                                        </Show>
                                        <Show object={Objects.Marche} action={Actions.Update}>
                                            <button type="button" className="btn btn-primary m-1" onClick={handleModify}>
                                                Modifier <i className="fas fa-sm fa-pen" data-toggle="tooltip" title="Modifier" />
                                            </button>
                                        </Show>
                                        <Show object={Objects.Marche} action={Actions.Delete}>
                                            <button type="button" className="btn btn-danger m-1" onClick={handleDelete}>
                                                Supprimer <i className="fas fa-sm fa-trash" data-toggle="tooltip" title="Modifier" />
                                            </button>
                                        </Show>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div className="row">
            <div className="col-xl-4">
                <div className="card">
                    <div className="card-header"><i className="fas fa-info fa-sm mr-2" data-toggle="tooltip" title="info" /> Infos</div>
                    <div className="card-body">
                        <p><b>Groupe:</b> {`${walk?.groupMatricule} - ${walk?.groupLibelle}`}</p>
                        <p><b>IGN:</b> {walk?.carteIgn}</p>
                        <p><b>Legende:</b>{walk?.legende?.map(e => <span className="badge badge-info m-1" key={e.libelle}>{e.libelle}</span>)}</p>
                        <p><b>Fête Mobile :</b> {walk?.feteMobile === "1" ? "oui" : "non"}</p>
                        <p><b>Vélo:</b> {walk?.velo ? "oui" : "non"}</p>

                        <p><b>Debut Marche Permanente:</b> {FormatDate(walk?.debutMarchePermanente)}</p>
                        <p><b>Fin Marche Permanente:</b> {walk?.finMarchePermanente}</p>
                        <p><b>ivv Lieu Départ:</b> {walk?.ivvLieuDepart}</p>
                        <p><b>ivv Distance:</b> {walk?.ivvDistance}</p>
                        <p><b>ivv Heure Départ:</b> {walk?.ivvHeureDepart}</p>
                    </div>
                </div>
            </div>
            <div className="col-xl-4">
                <div className="card mb-2">
                    <div className="card-header"><i className="fas fa-sm fa-route mr-2" data-toggle="tooltip" title="info" /> Distances</div>
                    <div className="card-body">
                        <p><b>Distance(s) - heure de départ:</b></p>{walk?.distances?.map((e, i) => <p key={i}><i>{e.distance}</i> | <b>{e.heureDepart}</b></p>)}
                    </div>
                </div>
                {walk?.description && <div className="card">
                    <div className="card-header"><i className="fas fa-sm fa-comment mr-2" data-toggle="tooltip" title="message" /> Description</div>
                    <div className="card-body">
                        <p>{walk?.description}</p>
                    </div>
                </div>}
            </div>
            <div className="col-xl-4">
                <div className="card">
                    <div className="card-header"><i className="fas fa-sm fa-map mr-2" data-toggle="tooltip" title="carte" />Adresse</div>
                    <div className="card-body">
                        <p><b>{`${walk?.lieuDepart ?? "Lieu Inconnu ??? "}`}</b></p>
                        {walk?.adress && <>
                            {FormatAdress(walk?.adress)}
                            {walk?.adress?.streetName && walk?.adress?.streetNumber && walk?.adress?.postalCode && <div className="iframe-container">
                                <iframe title="Gmaps" src={`https://www.google.com/maps/embed/v1/search?key=AIzaSyBa6wifSBqpMtC0xipWWDWzS0EzWevGOi8&q=${walk?.lieuDepart ?? "%02%03"}+${walk?.adress?.streetName ?? "%02%03"}+${walk?.adress?.streetNumber ?? "%02%03"}+${walk?.adress?.streetNumberBte ?? "%02%03"}+${walk?.adress?.postalCode ?? "%02%03"}+${walk?.adress?.city ?? "%02%03"}+${walk?.adress?.countryLibelle ?? "%02%03"}`} width="auto" style={{ border: "0" }} allowFullScreen loading="lazy"></iframe>
                            </div>}
                        </>}
                    </div>
                </div>
            </div>
        </div>
    </>);
}