import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { CandidatApi } from 'api/CandidatApi';
import { MembreApi } from 'api/MembreApi';

import Spinner from 'components/0components/Spinner';
import Input from 'components/0components/Input';
import { SearchIcon } from '../Icons';


export default _ => {
    const { candidatId } = useParams();
    const history = useHistory();

    const [rNom, setRNom] = useState("");
    const [rPrenom, setRPrenom] = useState("");
    const [rMatricule, setRMatricule] = useState("");

    const [demande, setDemande] = useState();
    const [homonymes, setHomonymes] = useState();
    const [isHomonymesLoading, setIsHomonymesLoading] = useState(true);

    function fetchDemande () {
        CandidatApi
            .get(candidatId)
            .then(res => setDemande(res))
    }

    const fetchHomonymes = (rMatricule, rNom, rPrenom) => {
        setIsHomonymesLoading(true);
        MembreApi
            .search(rMatricule, rNom, rPrenom, false)
            .then(res => setHomonymes(res))
            .finally(_ => setIsHomonymesLoading(false));
    }

    useEffect(fetchDemande, []);

    const search = _ => fetchHomonymes(rMatricule, rNom, rPrenom);

    const handleAffilie = _ => {
        CandidatApi
            .validate(candidatId)
            .then(_ => history.goBack());
    }

    const handleUpdateCandidat = _ => history.push(`/candidats/${candidatId}/update`);

    const handleRefuse = _ => {
        var raison = prompt("Pourquoi refuser ce Membre", "/");
        if (raison != null) {
            CandidatApi
               .refus(candidatId, raison)
               .then(_ => history.goBack());
        }
    }

    const handleClickTransfertMembre = (event, membre) => {
        event.preventDefault();
        event.stopPropagation();

        CandidatApi
            .transfert(candidatId, membre.id)
            .then(_ => history.push('/'));
    }

    useEffect(_ => {
        setRNom(demande?.lastName);
        setRPrenom(demande?.firstName);
        setRMatricule(demande?.matricule);
        fetchHomonymes(demande?.matricule, demande?.lastName, demande?.firstName);
    }, [demande])

    return (<>
        <div className="row mb-2">
            <div className="col-md">
                <h1 className="display-4">Demande d'affiliation <span className="lead">Gestion des candidatures</span></h1>
            </div>
            <div className="col-md-auto my-auto">
                <button type="button" className="btn btn-primary m-2" data-toggle="tooltip" title="Modifier le candidat" onClick={handleUpdateCandidat}>Modifier<i className="fas fa-fw fa-pen ml-1" /></button>
                <button type="button" className="btn btn-primary m-2" data-toggle="tooltip" title="Créer un nouveau membre" onClick={handleAffilie}>Créer un Nouveau Membre <i className="fas fa-fw fa-plus ml-1" /></button>
                <button type="button" className="btn btn-danger" data-toggle="tooltip" title="Refuser ce membre" onClick={handleRefuse}>Refuser le membre<i className="fas fa-fw fa-times ml-1" /></button>
            </div>
        </div>
        {!demande && <Spinner/>}
        {demande && <div className="card mb-3">
            <div className="card-body">
                <div className="row py-3">
                    <div className="col-lg-6 px-5 h-100 mx-auto my-auto" >
                        <div className="row">
                            <div className="col-6">
                                <img src="img/add_user.svg" alt="illustration ajout utilisateur" className="img-fluid" />
                            </div>
                            <div className="col-6 ">
                                <h4 align="center" className="text-dark">{`${demande?.lastName ?? ""} ${demande?.firstName ?? ""}`}</h4>
                                <p align="center">{`(${demande?.groupMatricule ?? ""}) ${demande?.groupName ?? ""}`}</p>
                                <p align="center" style={{ fontSize: "14px" }} className="lead">Date de la demande: <span className="font-weight-bold">{(demande?.dateAffiliation ? new Date(demande.dateAffiliation).toLocaleDateString() : "/")}</span></p>
                                <p align="center" style={{ fontSize: "14px" }} className="lead">Année d'affilition: <span className="font-weight-bold">{demande?.anneeAffiliation ?? "/"}</span></p>
                                <p align="center" style={{ fontSize: "14px" }} className="lead">Encodeur: <span className="font-weight-bold">{`${demande.encodeurNom} (${demande.encodeurMatricule})`}</span></p>
                                {demande?.refusRaison && <>
                                    <p align="center" className="lead text-danger" style={{ fontSize: "14px" }}>Raison Refus Précédent: </p>
                                    <p align="center" className="lead text-danger" style={{ fontSize: "14px" }}><span className="font-weight-bold">{`${demande.refusRaison}`}</span></p>
                                </>}
                            </div>
                        </div>
                        
                    </div>
                    <div className="col-lg-6 h-100 border-left">
                        <RenderLigne>
                            <RenderLigneElem libelle="Matricule" textColor="danger" data={`${demande?.matricule ?? "/"}`} />
                        </RenderLigne>
                        <RenderLigne>
                            <RenderLigneElem libelle="Sexe" data={`${demande?.gender ?? "/"}`} />
                            <RenderLigneElem libelle="Date de Naissance" data={`${demande?.birthDate ? new Date(demande.birthDate).toLocaleDateString() : "/"}`} />
                        </RenderLigne>
                        <RenderLigne>
                            <RenderLigneElem libelle="Téléphonne" data={`${demande?.coord?.phone ?? "/"}`} />
                            <RenderLigneElem libelle="Gsm" data={`${demande?.coord?.gsm ?? "/"}`} />
                        </RenderLigne>
                        <RenderLigne>
                            <RenderLigneElem libelle="Adresse" data={demande?.coord?.adress ? `${demande?.coord?.adress.streetName} ${demande?.coord?.adress.streetNumber} ${demande?.coord?.adress?.streetNumberBte ? `Bte ${demande?.coord?.adress?.streetNumberBte}` : ""}` : "/"} />
                            <RenderLigneElem libelle="Ville" data={demande?.coord?.adress ? `${demande?.coord?.adress.city}` : "/"} />
                        </RenderLigne>
                        <RenderLigne>
                            <RenderLastLigneElem libelle="Code Postal" data={demande?.coord?.adress ? `${demande?.coord?.adress.postalCode}` : "/"} />
                            <RenderLastLigneElem libelle="Pays" data={demande?.coord?.adress ? `${demande?.coord?.adress.countryLibelle}` : "/"} />
                        </RenderLigne>
                    </div>
                </div>
            </div>
        </div>}

        <div className="row mb-2 align-items-center px-3">
            <div className="col"> 
                <p className="lead my-auto col">Recherche d'homonymes:</p>
            </div>
            <div className="col-auto"> 
                <Input placeholder="Nom" value={rNom} onChange={e => setRNom(e)} />
            </div>
            <div className="col-auto">
                <Input placeholder="Prénom" value={rPrenom} onChange={e => setRPrenom(e)} />
            </div>
            <div className="col-auto">
                <Input placeholder="Matricule" value={rMatricule} onChange={e => setRMatricule(e)} />
            </div>
            <div className="col-auto">
                <button className="btn btn-primary" onClick={search} disabled={isHomonymesLoading}>
                    Rechercher <SearchIcon />
                </button>
            </div>
        </div>
        {isHomonymesLoading && <Spinner />}
        <div className="row">
            <div className="col-xl-12 mb-4">
                <div className="card h-100">
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr className="text-dark">
                                    <th scope="col">Nom</th>
                                    <th scope="col">Prénom</th>
                                    <th scope="col">Date de Naissance</th>
                                    <th scope="col">Matricule</th>
                                    <th scope="col">Club</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {homonymes?.map(e => <tr key={e?.id} onClick={_ => history.push(`/membres/${e.id}`)}>
                                    <td>{e?.lastName ?? "/"}</td>
                                    <td>{e?.firstName ?? "/"}</td>
                                    <td>{e?.birthDate ? new Date(e.birthDate).toLocaleDateString() : "/"}</td>
                                    <td>{e?.matricule ?? "/"}</td>
                                    <td>{e?.groupMatricule ?? "/"}</td>
                                    <td>
                                        <button type="button" className="btn btn-warning text-dark" data-toggle="tooltip" title="Il s'agit ce de membre" onClick={event => handleClickTransfertMembre(event, e)}>
                                            Il s'agit de ce membre (transfert)
                                            <i className="fas fa-fw fa-people-arrows ml-1" />
                                        </button>
                                    </td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

const RenderLigne = ({ children }) => <div className="row mb-2"> {children}</div>;

const RenderLigneElem = ({ libelle, data, textColor }) => {
    return (<div className="col-6">
        <div className="px-4 mb-0 h-100">
            <p className="lead" style={{ fontSize: "14px" }}>{libelle}</p>
            <p className={`mb-0 text-${textColor ?? "dark"}`}>{data ?? " "}</p>
            <hr className="mt-1 px-4" />
        </div>
    </div>);
}

const RenderLastLigneElem = ({ libelle, data, textColor }) => {
    return (<div className="col-6">
        <div className="px-4 mb-0 h-100">
            <p className="lead" style={{ fontSize: "14px" }}>
                {libelle}
            </p>
            <p className={`mb-0 text-${textColor ?? "dark"}`}>
                {data ?? " "}
            </p>
        </div>
    </div>);
}