import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AssurancesApi } from '../api/AssurancesApi';
import FormatDate from '../lib/FormatDate';

export default _ => {
    const history = useHistory(); 

    const initBeginSearch = new Date().getFullYear() + "-01-01";
    const initEndSearch = new Date().getFullYear() + "-12-31";

    const [demandes, setDemandes] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const fetchAssurances = (beginSearch,endSearch) => {
        AssurancesApi
            .search(beginSearch, endSearch)
            .then(res => setDemandes(res))
            .catch(err => console.error(err))
            .finally(_ => setIsLoading(false));
    }

    useEffect(_ => {
        fetchAssurances(initBeginSearch, initEndSearch);
    }, [initBeginSearch,initEndSearch]);

    const handleSearch = (beginSearch, endSearch) => fetchAssurances(beginSearch, endSearch);
    const handleClickAssurance = id => history.push(`/assurances/${id}`);
    
    return (<>
        <div className="row mb-4">
            <div className="col-lg">
                <h3 className="h3">Assurances</h3>
            </div>
            <div className="col-lg-auto ">
                <RechercheAssurance
                    handleSearch={handleSearch}
                    initBeginSearch={initBeginSearch}
                    initEndSearch={initEndSearch}
                />
            </div>
        </div>
        {isLoading ?
            <LoadingSpinner /> :
            <ListAssurances
                demandes={demandes}
                handleLoadAssurance={handleClickAssurance}
            />}
    </>);
};

const LoadingSpinner = _ => {
    return (<div className="d-flex text-primary justify-content-center">
        <div className="spinner-border" role="status" style={{ width: "3rem", height: "3rem" }}>
            <span className="sr-only">Loading...</span>
        </div>
    </div>);
}

const RechercheAssurance = ({ initBeginSearch, initEndSearch, handleSearch }) => {
    const [beginSearch, setBeginSearch] = useState(initBeginSearch);
    const [endSearch, setEndSearch] = useState(initEndSearch);

    return (<div className="container">
        <form onSubmit={e => { e.preventDefault(); handleSearch(beginSearch, endSearch); }}>
            <div className="row">
                <div className="col-lg-auto my-auto">
                    <span>Assurances du: </span>
                </div>
                <div className="col-lg-auto">
                    <input
                        className="form-control"
                        type="date"
                        onChange={e => setBeginSearch(e.target.value)}
                        value={beginSearch}
                    />
                </div>
                <div className="col-lg-auto my-auto">
                    <span>au</span>
                </div>
                <div className="col-lg-auto">
                    <input
                        className="form-control"
                        type="date"
                        min={beginSearch}
                        onChange={e => setEndSearch(e.target.value)}
                        value={endSearch}
                    />
                </div>
                <div className="col-lg-auto mt-2 mt-lg-0 ">
                    <button type="submit" className="btn btn-primary" >
                        Rechercher
                        <i className="fa-solid fa-magnifying-glass ml-2"></i>
                    </button>
                </div>
            </div>
        </form>
    </div>);
}

const ListAssurances = ({ demandes, handleLoadAssurance }) => {

    const statusIcon = status => {
        switch (status) {
            case 1:
                return (<i className="fas fa-fw fa-clock text-secondary" data-toggle="tooltip" title="Demande en attente" />);
            case 2:
                return (<i className="fas fa-fw fa-check-circle text-success" data-toggle="tooltip" title="En Ordre" />);
            case 3:
                return (<i className="fas fa-fw fa-times-circle text-danger" data-toggle="tooltip" title="Demande Refusée" />);
                default: 
                return <p>Erreur</p>;
        }
    }

    const statusLine = status => {
        switch (status) {
            case 3:
                return "table-danger";
            default: 
                return <p>Erreur</p>;
        }
    }

    return (<div className="row">
        <div className="col">
            <div className="card">
                <div className="table">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Formulaire - Demande</th>
                                <th scope="col">Club</th>
                                <th scope="col">Etat</th>
                                <th scope="col">Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {demandes?.map(d => (<tr key={d.id} onClick={_ => handleLoadAssurance(d.id)} className={statusLine(d?.status)} >
                                <td>{d.numeroDemande}</td>
                                <td>{`${d.roiTitre} - ${d.roiTitreDemande}`}</td>
                                <td>{`${d.groupMatricule} - ${d.groupNom}`}</td>
                                <td>{statusIcon(d?.status)}</td>
                                <td>{FormatDate(d.date)}</td>
                            </tr>))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>);
}