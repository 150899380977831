import React, { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

import { Actions, Objects, Show } from 'services/AccessControl';

import { AssurancesApi } from 'api/AssurancesApi';
import FormatDate from 'lib/FormatDate';

import A140PDF from 'components/pdfs/Assurances/A140PDF';
import A143BisPDF from 'components/pdfs/Assurances/A143BisPDF';
import A143PDF from 'components/pdfs/Assurances/A143PDF';
import A144PDF from 'components/pdfs/Assurances/A144PDF';
import A147PDF from 'components/pdfs/Assurances/A147PDF';
import A201PDF from 'components/pdfs/Assurances/A201PDF';

import Ass140 from './Ass/Ass140';
import Ass143 from './Ass/Ass143';
import Ass143Bis from './Ass/Ass143Bis';
import Ass144 from './Ass/Ass144';
import Ass145 from './Ass/Ass145';
import Ass147 from './Ass/Ass147';
import Ass148 from './Ass/Ass148';
import Ass201 from './Ass/Ass201';

export default _ => { 
    const { id } = useParams();
    const history = useHistory();

    const [demande, setDemande] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const fetchAssurance = _ => {
        AssurancesApi
            .get(id)
            .then(res => setDemande(res))
            .finally(_ => setIsLoading(false));
    };

    useEffect(_ => {
        fetchAssurance();
        console.log("render");
    }); 
    const handleUpdate = demande => history.push(`/assurances/${demande?.id}/update`);

    const handleValidate = demande => {
        AssurancesApi
            .setStatusValid(demande?.id)
            .then(fetchAssurance);
    }

    const handleAttente = demande => {
        AssurancesApi
            .setStatusOnHold(demande?.id)
            .then(fetchAssurance);
    }

    const handleRefus = demande => {
        let note = window.prompt('Quel est la raison de ce refus ?', "/");

        if (note != null) {
            AssurancesApi
                .setStatusReject(demande?.id,note)
                .then(fetchAssurance);
        }
            
    };

    const status = demande => {
        switch (demande.status) {
            case 1:
                return <span className="h6 badge badge-warning m-1">En attente</span>;
            case 2:
                return <span className="h6 badge badge-success m-1">Validée</span>;
            case 3:
                return <span className="h6 badge badge-danger m-1">Refusée</span>;
            default:
                return <span className="h6 badge badge-info m-1">Erreur de status</span>;
        };
    };

    const renderAssInfo = _ => {
        switch (demande.code) {
            case 1400:
                return <Ass140 demande={demande} />;
            case 1430:
                return <Ass143 demande={demande} />;
            case 1431:
                return <Ass143Bis demande={demande} />;
            case 1440:
                return <Ass144 demande={demande} />;
            case 1450:
                return <Ass145 demande={demande} />;
            case 1470:
                return <Ass147 demande={demande} />;
            case 1480:
                return <Ass148 demande={demande} />;
            case 2010:
                return <Ass201 demande={demande} />;
            default:
                return null;
        }
    }

    const renderAssPDF = _ => {
        switch (demande.code) {
            case 1400:
                return <A140PDF demande={demande} />;
            case 1430:
                return <A143PDF demande={demande} />;
            case 1431:
                return <A143BisPDF demande={demande} />;
            case 1440:
                return <A144PDF demande={demande} />;
            case 1470:
                return <A147PDF demande={demande} />;
            case 2010:
                return <A201PDF demande={demande} />;
            default: 
                return null;
        }
    }

    if (isLoading)
        return <p>Chargement ...</p>;

    return (<>
        {demande && <div>
            <div className="container-fluid m-0 p-0">
                <div className="row mb-3 mb-4">
                    <div className="col-lg-6 ">
                        <div className="card h-100">
                            <div className="card-body">
                                <h3 className="card-title">Demande d'assurance</h3>
                                <p className="lead">{demande?.roiTitreDemande ?? "/"} ({demande?.roiTitre ?? "/"})</p>
                                <div className="row ml-1 mb-2">
                                    <Show object={Objects.Assurance} action={Actions.Update}>
                                        {(demande?.status === 1 && demande.code !== 1480 && demande.code !== 1450) && <div className="col-auto p-0 mr-2">
                                            <button type="button" className="btn btn-primary" onClick={_ => handleUpdate(demande)}>
                                                Modifier  <i className="fas fa-fw fa-pen"></i>
                                            </button>
                                        </div>}
                                    </Show>
                                    <div className="col-auto p-0">
                                        {!isLoading && renderAssPDF(demande)}
                                    </div>
                                </div>
                                <div className="row ml-1 mb-2">
                                    {(demande?.status === 1) && <div className="col-auto p-0">
                                        <Show object={Objects.Assurance} action={Actions.Valid}>
                                            <button type="button" className="btn btn-success mr-2 mb-2" onClick={_ => handleValidate(demande)}>
                                                Valider  <i className="fas fa-fw fa-check"></i>
                                            </button>

                                            <button type="button" className="btn btn-danger mr-2 mb-2" onClick={_ => handleRefus(demande)}>
                                                Refus  <i className="fas fa-fw fa-times"></i>
                                            </button>
                                        </Show>
                                    </div>}

                                    {(demande?.status !== 1) && <div className="col-auto p-0">
                                        <Show object={Objects.Assurance} action={Actions.Valid}>
                                            <button type="button" className="btn btn-warning mr-2 mb-2" onClick={_ => handleAttente(demande)}>
                                                Mettre en attente <i className="fas fa-fw fa-clock"></i>
                                            </button>
                                        </Show>
                                    </div>}

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="card">
                            <div className="card-body">
                                <h3 className="card-title">Informations générales</h3>
                                <p><b>Status: </b>{status(demande)}</p>
                                {demande.refusRaison && <p className="text-danger"><b>Raison du refus: </b>{demande.refusRaison}</p>}
                                <p><b>N° </b>{demande?.numeroDemande}</p>
                                <p><b>Date: </b>{demande?.date ? FormatDate(demande?.date) : "Date Indisponible"}</p>
                                <p><b>Groupe: </b><Link className="link-primary" to={`/clubs/${demande?.groupId}`}>{`${demande.groupNom} (${demande.groupMatricule})`}</Link></p>
                                <p><b>Initiateur: </b>{`${demande.initiateurNom} ${demande.initiateurPrenom}`}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="card">
                        <div className="card-body">
                            <h3 className="card-title">Informations spécifiques</h3>
                            {renderAssInfo()}
                        </div>
                    </div>
                </div>
            </div>
        </div>}
    </>);
}