import { useState, useEffect } from 'react';
import { Auth } from 'components/Form/Form';

const useWalk = (id) => {
    const [state, setState] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState();

    const controller = new AbortController();

    function CancelRequest()
    {
        controller.abort();
    }

    const options = (method, data) => {
        return {
            method: method,
            headers: {
                'signal': controller.signal,
                'Content-Type': "application/json",
                'Authorization': 'Bearer ' + Auth.token()
            },
            body: JSON.stringify(data)
        };
    }

    function GetWalk()
    {
        setIsLoading(true);
        setError();

        fetch(`api/marches/${id}`, options('GET'))
            .then(res => res.json())
            .then(res => setState(res))
            .catch (err => setError(err))
            .finally(_ => setIsLoading(false));

        return CancelRequest;
    }

    function UpdateWalk(data)
    {
        setIsLoading(true);
        setError();

        return fetch(`api/marches`, options('PATCH',data))
            .then(GetWalk)
            .catch(err => setError(err))
            .finally(_ => setIsLoading(false));
    }

    useEffect(GetWalk, [id]);

    return { walk:state, isLoading, reload: GetWalk, error, UpdateWalk, CancelRequest };
}

export default useWalk;