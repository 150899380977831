import { useState, useEffect } from "react";
import { Auth } from "components/Form/Form";

/// développée par Antoine dans le cadre du projet WattoEat Libre Usage sous licence MIT

export default (url) => {
    const [data, setData] = useState();
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const controller = new AbortController();

    function reload ()
    {
        setIsLoading(true);

        const options = {
            method: 'GET',
            headers: {
                'signal': controller.signal,
                'Content-Type': "application/json",
                'Authorization': 'Bearer ' + Auth.token()
            }
        };

        fetch(url, options)
            .then(res => res.json())
            .then(data => setData(data))
            .catch(err => {
                if (err.name === "AbortError") {
                    console.log("request Canceled: " + err );
                } else {
                    setError(err);
                }
            })
            .finally(_ => setIsLoading(false));
    }

    const cancel = () => controller.abort();

    function f () 
    {
        reload();

        return cancel;
    }

    useEffect(f, [url]);

    return { data, error, isLoading, reload, cancel };
}