import React from 'react';
import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';

export default ({ club }) => {

    console.log("PDF Props", club);

    const styles = StyleSheet.create({
        body: {
            paddingTop: 35,
            paddingBottom: 65,
            paddingHorizontal: 35
        },
        header: {
            fontSize: 12,
            marginBottom: 20,
            textAlign: 'center',
            color: 'grey',
        },
        title: {
            fontSize: 15,
            textAlign: 'center',
            fontFamily: 'Times-Roman'
        },
        subtitle: {
            fontSize: 18,
            margin: 12,
            fontFamily: 'Times-Roman'
        },
        row: { flexDirection: "row" },
        pageFooter: {
            position: 'absolute',
            fontSize: 8,
            bottom: 8,
            left: 10,
            right: 10,
            color: 'grey'
        },
        pageFooterCenter: { textAlign: 'center' },
        pageFooterLeft: { textAlign: 'left' },
        pageFooterRight: { textAlign: 'right' },
        table: {
            display: "table",
            width: "auto",
            borderStyle: "solid",
            borderColor: '#bfbfbf',
            borderWidth: 1,
            borderRightWidth: 0,
            borderBottomWidth: 0,
            padding: 0
        },
        tableRow: {
            margin: "auto",
            flexDirection: "row"
        },
        tableCol: {
            borderStyle: "solid",
            borderColor: '#bfbfbf',
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
            padding: 2
        },
        tableCol12: { width: "100%" },
        tableCol8: { width: "66%" },
        tableCol6: { width: "50%" },
        tableCol4: { width: "33%" },
        tableCol3: { width: "25%" },
        tableCollHeader: {
            fontSize: 12,
            fontWeight: 500
        },
        tableCell: { fontSize: 10 }
    });

    return (<Document>
        <Page style={styles.body}>
            {club ? <>
                <Text style={styles.header} fixed>FFBMP</Text>
                <Text style={styles.title}>Fiche Affilié</Text>

                <Text style={styles.subtitle}>Club de l'affilié</Text>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={[styles.tableCol, { width: "35%" }]}>
                            <Text style={styles.tableCell}>Matricule du club</Text>
                        </View>
                        <View style={[styles.tableCol, { width: "65%" }]}>
                            <Text style={styles.tableCell}>{club?.matricule ?? " "}</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={[styles.tableCol, { width: "35%" }]}>
                            <Text style={styles.tableCell}>Dénomination du club</Text>
                        </View>
                        <View style={[styles.tableCol, { width: "65%" }]}>
                            <Text style={styles.tableCell}>{club?.name ?? " "}</Text>
                        </View>
                    </View>
                </View>
                <Text style={styles.subtitle}>Signalétique de l'affilié</Text>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={[styles.tableCol, { width: "35%" }]}>
                            <Text style={styles.tableCell}>Matricule de l'affilié</Text>
                        </View>
                        <View style={[styles.tableCol, { width: "65%" }]}>
                            <Text style={styles.tableCell}></Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={[styles.tableCol, { width: "35%" }]}>
                            <Text style={styles.tableCell}>Nom</Text>
                        </View>
                        <View style={[styles.tableCol, { width: "65%" }]}>
                            <Text style={styles.tableCell}></Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={[styles.tableCol, { width: "35%" }]}>
                            <Text style={styles.tableCell}>Prénom</Text>
                        </View>
                        <View style={[styles.tableCol, { width: "65%" }]}>
                            <Text style={styles.tableCell}></Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={[styles.tableCol, { width: "35%" }]}>
                            <Text style={styles.tableCell}>Date de naissance</Text>
                        </View>
                        <View style={[styles.tableCol, { width: "65%" }]}>
                            <Text style={styles.tableCell}></Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={[styles.tableCol, { width: "35%" }]}>
                            <Text style={styles.tableCell}>Sexe</Text>
                        </View>
                        <View style={[styles.tableCol, { width: "65%" }]}>
                            <Text style={styles.tableCell}></Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={[styles.tableCol, { width: "35%" }]}>
                            <Text style={styles.tableCell}>Chef de Famille</Text>
                        </View>
                        <View style={[styles.tableCol, { width: "65%" }]}>
                            <Text style={styles.tableCell}>OUI - NON</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={[styles.tableCol, { width: "35%" }]}>
                            <Text style={styles.tableCell}>Téléphone</Text>
                        </View>
                        <View style={[styles.tableCol, { width: "65%" }]}>
                            <Text style={styles.tableCell}></Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={[styles.tableCol, { width: "35%" }]}>
                            <Text style={styles.tableCell}>Mobile</Text>
                        </View>
                        <View style={[styles.tableCol, { width: "65%" }]}>
                            <Text style={styles.tableCell}></Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={[styles.tableCol, { width: "35%" }]}>
                            <Text style={styles.tableCell}>Email</Text>
                        </View>
                        <View style={[styles.tableCol, { width: "65%" }]}>
                            <Text style={styles.tableCell}></Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={[styles.tableCol, { width: "35%" }]}>
                            <Text style={styles.tableCell}>Adresse</Text>
                        </View>
                        <View style={[styles.tableCol, { width: "65%" }]}>
                            <Text style={[styles.tableCell, { paddingVertical: 30 }]}></Text>
                        </View>
                    </View>
                </View>
                <Text style={[styles.pageFooter, styles.pageFooterCenter]} render={({ pageNumber, totalPages }) => (`${pageNumber} / ${totalPages}`)} fixed />
            </> : ""}
        </Page>
    </Document>);
}
