import React from 'react';
import PdfWorker, { View, Text, Image, Table2, Row, Cell, CellHeader } from '../PdfWorker';

export default ({ demande, titre, subtitle, roi, children }) => {

    // const statusStyle = demande => {
    //     switch (demande.status) {
    //         case 1:
    //             return <Text style={{ fontSize: 12, textAlign: 'left', fontFamily: 'Montserrat', fontWeight: 'bold', color: 'white', backgroundColor: '#f0ad4e' }}>En Attente</Text>; // En attente
    //         case 2:
    //             return <Text style={{ fontSize: 12, textAlign: 'left', fontFamily: 'Montserrat', fontWeight: 'bold', color: 'white', backgroundColor: '#5cb85c' }}>Validé</Text>; // XXX
    //         case 3:
    //             return <Text style={{ fontSize: 12, textAlign: 'left', fontFamily: 'Montserrat', fontWeight: 'bold', color: 'white', backgroundColor: '#d9534f' }}>Refus</Text>; //Refus
    //     };
    // };

    return (<PdfWorker>
        <View style={{ display: "table", width: "auto", flexDirection: "row" }}>
            <View style={{ margin: "auto", flexDirection: "row", width: "20%" }}>
                <Image style={{ width: "100%", height: "auto", padding: 10 }} src="img/FFBMPLogo.png" />
            </View>
            <View style={{ margin: "auto", width: "80%", marginRight: 10 }}>
                <Text style={{ fontSize: 12, marginBottom: 5, textAlign: 'center' }}>FEDERATION FRANCOPHONE BELGE DE MARCHES POPULAIRES</Text>
                <Text style={{ fontSize: 10, textAlign: 'center' }}>ASBL agréée par le Ministère de la Communauté Française, affiliée à la F.I.S.P. et à l'A.I.S.F.</Text>
                <Text style={{ fontSize: 10, textAlign: 'center' }}>200 CLUBS - 20.000 AFFILIES - 600 MARCHES - 500.000 MARCHEURS PAR AN</Text>
                <Text style={{ fontSize: 10, textAlign: 'right', color: '#bfbfbf', marginTop: 5}}>Site web: www.ffbmp.be</Text>
            </View>
        </View>

        <View>
            <Text style={{ fontSize: 14, marginBottom: 10, textAlign: 'center' }}>{titre ?? ""}</Text>
        </View>
        <View>
            <Text style={{ fontSize: 12, marginBottom: 10, textAlign: 'center' }}>{subtitle ?? ""}</Text>
        </View>
        {demande?.isRefusee && <View>
            <Text style={{ fontSize: 12, marginBottom: 10, textAlign: 'center', color:'red' }}>{`Cette demande d'assurance est refusée (motif :${demande?.refusRaison})`}</Text>
        </View>}
        {!demande?.isRefusee && !demande?.isValide && <View>
            <Text style={{ fontSize: 12, marginBottom: 10, textAlign: 'center', color: 'red' }}>Cette demande d'assurance n'a pas encore été vérifée.</Text>
        </View>}
        <View>
            <Text style={{ fontSize: 14, textAlign: 'center', borderWidth: 1, marginBottom: 20, padding: 3 }}>{roi ?? ""}</Text>
        </View>
        <Table2>
            <Row>
                <CellHeader style={{ width: "25%", padding: 3 }}>Matricule</CellHeader>
                <CellHeader style={{ width: "50%", padding: 3 }}>Nom du club</CellHeader>
                <CellHeader style={{ width: "25%", padding: 3 }}>Date de la demande</CellHeader>
            </Row>
            <Row>
                <Cell style={{ width: "25%", padding: 3 }}>{demande.groupMatricule}</Cell>
                <Cell style={{ width: "50%", padding: 3 }}>{demande.groupNom}</Cell>
                <Cell style={{ width: "25%", padding: 3 }}>{new Date(demande?.date).toLocaleDateString()}</Cell>
            </Row>
        </Table2>

        <View style={{ margin: 5 }} />

        {children}

        <Row>
            <View style={{ width: "50%", flexDirection: "row", marginTop: 20 }}>
                <View style={{ width: "50%" }}>
                    <Text style={{ fontSize: 10 }}>Responsable affiliations:</Text>
                </View>
                <View style={{ width: "50%" }}>
                    <Text style={{ fontSize: 10 }}>{`${demande?.responsableAffiliations?.lastName ?? ""} ${demande?.responsableAffiliations?.firstName ?? ""}`}</Text>
                    <Text style={{ fontSize: 10 }}>{`${demande?.responsableAffiliations?.coord?.adress?.streetName ?? ""} ${demande?.responsableAffiliations?.coord?.adress?.streetNumber ?? ""} ${demande?.responsableAffiliations?.coord?.adress?.streetNumberBte ?? ""}`}</Text>
                    <Text style={{ fontSize: 10 }}>{`${demande?.responsableAffiliations?.coord?.adress?.postalCode ?? ""} ${demande?.responsableAffiliations?.coord?.adress?.city ?? ""}`}</Text>
                    <Text style={{ fontSize: 10 }}>{`${demande?.responsableAffiliations?.coord?.phone ?? ""} ${demande?.responsableAffiliations?.coord?.gsm ?? ""}`}</Text>
                    <Text style={{ fontSize: 10 }}>{`${demande?.responsableAffiliations?.coord?.email ?? ""}`}</Text>
                </View>
            </View>
            <View style={{ width: "50%", flexDirection: "row", marginTop: 20 }}>
                <View style={{ width: "50%" }}>
                    <Text style={{ fontSize: 10 }}>Responsable assurances:</Text>
                </View>
                <View style={{ width: "50%" }}>
                    <Text style={{ fontSize: 10 }}>{`${demande?.responsableAssurances?.lastName ?? ""} ${demande?.responsableAssurances?.firstName ?? ""}`}</Text>
                    <Text style={{ fontSize: 10 }}>{`${demande?.responsableAssurances?.coord?.adress?.streetName ?? ""} ${demande?.responsableAssurances?.coord?.adress?.streetNumber ?? ""} ${demande?.responsableAssurances?.coord?.adress?.streetNumberBte ?? ""}`}</Text>
                    <Text style={{ fontSize: 10 }}>{`${demande?.responsableAssurances?.coord?.adress?.postalCode ?? ""} ${demande?.responsableAssurances?.coord?.adress?.city ?? ""}`}</Text>
                    <Text style={{ fontSize: 10 }}>{`${demande?.responsableAssurances?.coord?.phone ?? ""} ${demande?.responsableAssurances?.coord?.gsm ?? ""}`}</Text>
                    <Text style={{ fontSize: 10 }}>{`${demande?.responsableAssurances?.coord?.email ?? ""}`}</Text>
                </View>
            </View>
        </Row>
        <Row>
            <View style={{ width: "50%", flexDirection: "row", marginTop: 10 }}>
                <View style={{ width: "50%" }}>
                    <Text style={{ fontSize: 10 }}>Numéro de demande: </Text>
                </View>
                <View style={{ width: "50%" }}>
                    <Text style={{ fontSize: 10 }}>{demande?.numeroDemande}</Text>

                </View>
            </View>
        </Row>

    </PdfWorker>);
}