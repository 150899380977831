import React from 'react';
import { Switch, NavLink, Redirect } from 'react-router-dom';
import { Actions, Objects, Route } from 'services/AccessControl';
import useAcces from 'services/AccessControl/useAcces';
import useFetcher from 'services/useFetcher';
import Provinces from 'components/clubs/Provinces';

import Bubbles from './Bubbles';
import ListeAttenteMarches from './ListeAttenteMarches';
import ListeAttenteAssurances from './ListeAttenteAssurances';
import ListeAttenteCandidats from './ListeAttenteCandidats';

import { AssurancesApi } from 'api/AssurancesApi';
import { CandidatApi } from 'api/CandidatApi';
import { MarchesApi } from 'api/MarchesApi';

import Documents from '../documents/Documents';
import Organigramme from './Organigramme';

import Marches from '../Walk/Marches';
import Assurances from '../Assurances';
import Annuaire from '../Annuaire';

const IdCa = "dfc82482-8cb2-11de-a173-001372568c9a";
const path = "/ca"

export default _ => {
    const hasperm = useAcces();

    const { state: validCandidats, isLoading: isCandidatsLoading } = useFetcher(CandidatApi.getToValidate())
    const { state: validMarches, isLoading: isMarchesLoading } = useFetcher(MarchesApi.getAValider())
    const { state: validAssurances, isLoading: isAssLoading } = useFetcher(AssurancesApi.getToValidate())

    const BadgeTab = ({ array }) => {
        if (array?.length > 0)
            return <span className="badge badge-danger">{array?.length}</span>

        return null;
    }

    return (<div>
        <div className="row mb-4 justify-content-center">
            <div className="col-auto">
                <h1 className="display-4">Conseil d'Administration</h1>
            </div>
        </div>

        <Bubbles
            candidats={validCandidats}
            marches={validMarches}
            assurances={validAssurances}
        />
        
        <div className="card p-4">
            <div className="row">
                <div className="col-xl-auto">
                    <ul className="nav nav-pills flex-column">

                        <li className="nav-item">
                            <NavLink className="nav-link" to={path} exact>
                                <i className="fa-solid fa-map" /> Provinces
                            </NavLink>
                        </li>

                        {hasperm(Objects.Membre, Actions.Read, IdCa) && <li className="nav-item">
                            <NavLink className="nav-link" to={`${path}/annuaire`} exact>
                                <i className="fa-solid fa-address-book" /> Annuaire
                            </NavLink>
                        </li>}

                        {hasperm(Objects.Candidat, Actions.Valid, IdCa) && <li className="nav-item">
                            <NavLink className="nav-link" to={`${path}/validcandidats`} exact>
                                <i className="fa-solid fa-user-check" /> {`Candidat${(validCandidats?.length > 1 ? "s" : "")} en attente`} <BadgeTab array={validCandidats} />
                            </NavLink>
                        </li>}

                        {hasperm(Objects.Marche, Actions.Read, IdCa) && <li className="nav-item">
                            <NavLink className="nav-link" to={`${path}/marches`}>
                                <i className="fa-solid fa-calendar" /> Marches
                            </NavLink>
                        </li>}

                        {hasperm(Objects.Marche, Actions.Valid, IdCa) && <li className="nav-item">
                            <NavLink className="nav-link" to={`${path}/validmarches`}>
                                <i className="fa-solid fa-calendar-check" /> {`Marche${(validMarches?.length > 1 ? "s" : "")} en attente`} <BadgeTab array={validMarches} />
                            </NavLink>
                        </li>}

                        {hasperm(Objects.Assurance, Actions.Read, IdCa) && <li className="nav-item">
                            <NavLink className="nav-link" to={`${path}/assurances`}>
                                <i className="fa-solid fa-file-contract" /> Assurance
                            </NavLink>
                        </li>}

                        {hasperm(Objects.Assurance, Actions.Valid, IdCa) && <li className="nav-item">
                            <NavLink className="nav-link" to={`${path}/validassurances`}>
                                <i className="fa-solid fa-file-circle-check" /> {`Assurance${(validAssurances?.length > 1 ? "s" : "")} en attente`} <BadgeTab array={validAssurances} />
                            </NavLink>
                        </li>}

                        {hasperm(Objects.Organigramme, Actions.Read, IdCa) && <li className="nav-item">
                            <NavLink className="nav-link" to={`${path}/organigramme`}>
                                <i className="fa-solid fa-sitemap" /> Organigramme
                            </NavLink>
                        </li>}

                        {hasperm(Objects.Document, Actions.Read, IdCa) && <li className="nav-item">
                            <NavLink className="nav-link" to={`${path}/documents`}>
                                <i className="fa-solid fa-file" /> Documents
                            </NavLink>
                        </li>}
                    </ul>
                </div>
                <div className="col-auto">
                    <div className="vr" style={{"height":"100%"} }></div>
                </div>
                <div className="col-xl">
                    <Switch>

                        <Route exact path='/ca'
                            object={Objects.Club}
                            action={Actions.Read}
                            ressource={IdCa}>
                            <Provinces />
                        </Route>

                        <Route exact path='/ca/annuaire'
                            object={Objects.Membre}
                            action={Actions.Read}
                            ressource={IdCa}>
                            <Annuaire />
                        </Route>

                        <Route exact path='/ca/validcandidats'
                            object={Objects.Candidat}
                            action={Actions.Valid}
                            ressource={IdCa}>
                            <ListeAttenteCandidats
                                liste={validCandidats}
                                isLoading={isCandidatsLoading}
                            />
                        </Route>

                        <Route exact path='/ca/marches'
                            object={Objects.Marche}
                            action={Actions.Read}
                            ressource={IdCa}>
                            <Marches /> 
                        </Route>

                        <Route exact path='/ca/validmarches'
                            object={Objects.Marche}
                            action={Actions.Valid}
                            ressource={IdCa}>
                            <ListeAttenteMarches
                                liste={validMarches}
                                isLoading={isMarchesLoading}
                            />
                        </Route>

                        <Route exact path='/ca/assurances'
                            object={Objects.Assurance}
                            action={Actions.Read}
                            ressource={IdCa}>
                            <Assurances />
                        </Route>

                        <Route exact path='/ca/validassurances'
                            object={Objects.Assurance}
                            action={Actions.Valid}
                            ressource={IdCa}>
                            <ListeAttenteAssurances
                                ass={validAssurances}
                                isLoading={isAssLoading}
                            />
                        </Route>

                        <Route exact path='/ca/organigramme'
                            object={Objects.Organigramme}
                            action={Actions.Read}
                            ressource={IdCa}>
                            <Organigramme
                                id={IdCa}
                            />
                        </Route>

                        <Route exact path='/ca/documents'
                            object={Objects.Club}
                            action={Actions.Read}
                            ressource={IdCa}>
                            <Documents/>
                        </Route>

                        <Route path='/ca/*'>
                            <Redirect to="/404"/>
                        </Route>
                    </Switch>
                </div>
            </div>
        </div>
    </div>);
};

