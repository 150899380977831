import React from 'react';
import Input from 'components/Form/Input';
// import AdressForm from './AdressForm';

const ContactDetailsForm = ({ value: adresse, onChange }) => { // error
    const { website, email, phone, gsm } = adresse; // adress

    const HandleChange = (e) => {
        const { name, value } = e.target;
        onChange({
            target: {
                name: "coord",
                value: { ...adresse, [name]: value }
            }
        });
    }

    return (<div className="container-fluid">
        <h4 className="h4">Coordonnées</h4>

        <Input
            label="Site Web:"
            name="website"
            maxLength="100"
            placeholder="www.exemple.com"
            onChange={HandleChange}
            value={website}
        />
        <Input
            label="Email:"
            name="email"
            maxLength="300"
            placeholder="email@exemple.com"
            onChange={HandleChange}
            value={email}
        />
        <Input
            label="Téléphone:"
            name="phone"
            maxLength="50"
            onChange={HandleChange}
            value={phone}
        />
        <Input
            label="Gsm:"
            name="gsm"
            maxLength="50"
            value={gsm}
            onChange={HandleChange}
        />

        {/*<AdressForm*/}
        {/*    value={adress}*/}
        {/*    name={adress}*/}
        {/*    onChange={HandleChange}*/}
        {/*/>*/}

        {/* A ete décidé que la modification se ferait avec l'onglet de modification une fois le groupe crée */}

    </div>); 
};

export default ContactDetailsForm;