import React from 'react';

export default ({ children }) => {
    return (<div className="jumbotron">
        <div className="container">
            {children}
        </div>
    </div>);
};

export const Title = ({ children }) =><h1 className="display-4">{children}</h1>;