import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import Auth from 'services/createAuthProvider';

export default _ => {
    const history = useHistory();
    const LogOut = _ => {
        Auth.logout();
        history.push('/login');
    }

    useEffect(LogOut, [])

    return (<p>Redirection vers Login en cours</p>);
}