import React from 'react';
import PdfWorker, { View, Text, Image } from '../PdfWorker';
import DownloadPdf from '../DownloadPdf';
import PdfTableWalks from './components/PdfTableWalksV3';
import SignatureEtCachet from './components/SignatureEtCachet';
import ResponsableClub from './components/ResponsableClub';

export default ({ fileName, calendrierProjet }) => (<DownloadPdf document={MonDocument(calendrierProjet)} fileName={fileName} />);

const MonDocument = calendrierProjet => {
    const { marches, club, annee, responsableProvince, responsableClub, dateLimite } = calendrierProjet;

    return (<PdfWorker>
        <View style={{ width: "auto", flexDirection: "row", marginBottom: 5 }}>
            <View style={{ width: "20%" }}>
                <Image src="img/FFBMPLogoC.jpg" />
            </View>
            <View style={{ width: "50%" }}>
            </View>
            <View style={{ width: "30%" }}>
                <Text style={{ fontSize: 22, marginBottom: 5, textAlign: 'right' }}>FFBMP</Text>
                <Text style={{ fontSize: 18, textAlign: 'right' }}>Projet Calendrier</Text>
            </View>
        </View>

        <View style={{ width: "auto" }}>
            <Text style={{ fontSize: 10, marginBottom: 5 }}>Ce projet calendrier est à renvoyer EN DEUX EXEMPLAIRES dûment signés avant le {dateLimite} à votre responsable.</Text>
            <Text style={{ fontSize: 10, marginBottom: 5 }}>{`${responsableProvince?.lastName} ${responsableProvince?.firstName}, ${responsableProvince?.coord?.adress?.streetName} ${responsableProvince?.coord?.adress?.streetNumber} ${responsableProvince?.coord?.adress?.streetNumberBte ? `Bte ${responsableProvince?.coord?.adress?.streetNumberBte}` : ''} - ${responsableProvince?.coord?.adress?.postalCode} ${responsableProvince?.coord?.adress?.city} (${responsableProvince?.coord?.adress?.countryLibelle})`}</Text>
            <Text style={{ fontSize: 10 }}>{`${responsableProvince?.coord?.email}`}</Text>
        </View>

        <View style={{ borderWidth: 1, marginTop: 20, marginBottom: 20, padding: 15, backgroundColor: '#eeeeee' }}>
            <Text style={{ fontSize: 14, textAlign: 'center' }}>{`${club?.matricule} - ${club?.name}`}</Text>
            <Text style={{ fontSize: 12, textAlign: 'center' }}>{`Projet Calendrier ${annee}`}</Text>
        </View>

        <PdfTableWalks walks={marches }/>

        <ResponsableClub individu={responsableClub} club={club}/>

        <SignatureEtCachet/>

    </PdfWorker>);
}