import ErrorPage from "components/ErrorPage";
import React from "react"

export const ErrorPage403 =  _ => {
    return (<ErrorPage
        errorCode={"403"}
        msg1={"accès refusé"}
        msg2={"Il semblerait que vous n'ayez pas accès à cette page"}
    />);
}

export const ErrorPage404 =  _ => {
    return (<ErrorPage
        errorCode={"404"}
        msg1={"Page Introuvable"}
        msg2={"Il y a eu un bug dans la matrice..."}
    />);
}