import useAcces from './useAcces';

export default ({ object, action, ressource, children, fallback = null }) => {
    const hasAcces = useAcces();

    if (!hasAcces(object, action, ressource))
        return fallback;

    return children;
}
