import { Actions } from "services/AccessControl";
import { isAllowed } from "lib/permToolkit";

export default (subject, action, ressource) => {

    switch (action) {
        case Actions.Read:
            return isAllowed(subject, null, ressource);
        case Actions.Create:
            return false; // only ADMIN
        case Actions.Update:
            return false; // only ADMIN
        case Actions.Manage:
            return false; // only ADMIN
        default: 
            return false;
    };
}