import { Auth } from "components/Form/Form";

const url = '/api/v1/provinces';

export const ProvinceApi = {
    get: (id) => {
        return fetch(`${url}/${id}`, Auth.options('GET'))
            .then(res => res.json());
    },

    getCandidats: (id) => {
        return fetch(`${url}/${id}/candidats/valid`, Auth.options('GET'))
            .then(res => res.json());
    },

    getWalks: (id, startDate = new Date().toISOString(), endDate = new Date().getFullYear() + "-12-31") => {
        return fetch(`${url}/${id}/marches?startdate=${startDate}&enddate=${endDate}`, Auth.options('GET'))
            .then(res => res.json());
    },

    getWalksToValid: (id) => {
        return fetch(`${url}/${id}/marches/valid`, Auth.options('GET'))
            .then(res => res.json());
    },

    getInsurance: (id, startDate = new Date().getFullYear() + "-01-01", endDate = new Date().getFullYear() + "-12-31") => {
        return fetch(`${url}/${id}/assurances?startdate=${startDate}&enddate=${endDate}`, Auth.options('GET'))
            .then(res => res.json());
    },

    getInsuranceToValid: (id) => {
        return fetch(`${url}/${id}/assurances/valid`, Auth.options('GET'))
            .then(res => res.json());
    },

    getOrga: (id) => {
        return fetch(`${url}/${id}/organigramme`, Auth.options('GET'))
            .then(res => res.json());
    },
}