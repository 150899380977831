import React, { useContext, createContext, useState } from 'react'

const PreferencesContext = createContext();

export const usePreferences = _ => useContext(PreferencesContext);

export const PreferencesProvider = ({ children }) => {
    const [preferences, setPreferences] = useState({
        theme: "light",
        lang: "FR"
    });

    //todo load user Preferences
    //todo save on userPreferences change

    return (<PreferencesContext.Provider value={[preferences, setPreferences]}>
        {children}
    </PreferencesContext.Provider>);
}
