import React, { useEffect, useState } from 'react';
import { ParametresApi } from 'api/ParametresApi';
import FormatDate from 'lib/FormatDate';
import { ToEuro } from 'lib/FormatNum';
import Button from 'components/0components/Button';
import { PDFDownloadLink, Table2, Text, Row, Cell, CellHeader, View } from '../PdfWorker';
import AssuranceLayoutPDF from './AssuranceLayoutPDF';

export default ({ demande }) => {
    const [cout, setCout] = useState();
    const [iban, setIban] = useState();
    const [bic, setBic] = useState();

    useEffect(_ => {
        ParametresApi
            .get("MONTANT_A140")
            .then(res => setCout(parseFloat(res.value)))

        ParametresApi
            .get("ASSNUMCPTIBAN")
            .then(res => setIban(res.value))

        ParametresApi
            .get("ASSNUMCPTBIC")
            .then(res => setBic(res.value))
    }, [])

    if (!cout || !demande || !iban || !bic)
        return <p>chargement</p>;

    return (<PDFDownloadLink document={<MonDocument demande={demande} cost={cout} iban={iban} bic={bic} />} fileName={`DemandeAssurance${demande.numeroDemande}.pdf`}>
        {({ loading }) => (loading ? 'Génération du document...' : <Button icon="fa-download">Télécharger</Button>)}
    </PDFDownloadLink>);
}

const MonDocument = ({ demande, cost, iban, bic }) => {
    return (<AssuranceLayoutPDF demande={demande} titre="Dégâts Véhicules" subtitle="Demande de couverture d'assurance: Police n°2.650.097" roi="ROI ASS 140">

        <Table2>
            <Row>
                <CellHeader style={{ width: "12.5%" }}>Date</CellHeader>
                <CellHeader style={{ width: "10%" }}>Matricule</CellHeader>
                <CellHeader style={{ width: "25%" }}>Nom</CellHeader>
                <CellHeader style={{ width: "22.5%" }}>Prénom</CellHeader>
                <CellHeader style={{ width: "20%" }}>Marque</CellHeader>
                <CellHeader style={{ width: "10%" }}>Plaque</CellHeader>
            </Row>
            {demande?.vehicules?.map((e, i) => <Row key={i}>
                <Cell style={{ width: "12.5%", textAlign: "center" }}>{e.date ? FormatDate(e.date) : ""}</Cell>
                <Cell style={{ width: "10%", textAlign: "center" }}>{e.matriculeMarcheur ?? "/"}</Cell>
                <Cell style={{ width: "25%" }}>{e.nom ?? "/"}</Cell>
                <Cell style={{ width: "22.5%" }}>{e.prenom ?? "/"}</Cell>
                <Cell style={{ width: "20%" }}>{e.marque ?? "/"}</Cell>
                <Cell style={{ width: "10%", textAlign: "center" }}>{e.plaque ?? "/"}</Cell>
                </Row>)}
        </Table2>

        <View style={{ borderWidth: 1, padding: 3, marginTop:20 }}>
            <Text style={{ fontSize: 10 }}>{`Je verse la somme de ${ToEuro(demande?.vehicules?.length * cost)} soit ${demande?.vehicules?.length} x ${ToEuro(cost)}`}</Text>
            <Text style={{ fontSize: 10 }}>{`Sur le compte ${iban} - ${bic}`}</Text>
            <Text style={{ fontSize: 10 }}>{`"Assurances F.F.B.M.P. asbl - C.A."`}</Text>
        </View>

    </AssuranceLayoutPDF>);
}