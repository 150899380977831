import { Auth } from "components/Form/Form";

const url = "/api/candidats";

export const CandidatApi = {
    get: (id) => {
        return fetch(`${url}/${id}`, Auth.options('GET'))
            .then(res => res.json());
    },

    getToValidate: () => {
        return fetch(`${url}/attente`, Auth.options('GET'))
            .then(res => res.json());
    },

    create: (data) => {
        return fetch(`${url}`, Auth.options('POST', {
            firstName: data.firstName,
            lastName: data.lastName,
            birthDate: data.birthDate,
            gender: data.gender,
            familyChief: data.familyChief,
            coord: data.coord,
            groupId: data.groupId,
            anneeAffiliation: data.anneeAffiliation
        }));
    },

    createAndTransfert: (matricule, groupId, year) => {
        return fetch(`${url}/transfert`, Auth.options('POST', {
            matricule: matricule,
            groupId: groupId,
            anneeAffiliation: year
        }));
    },

    update: (data) => {
        return fetch(`${url}`, Auth.options('PATCH', {
            id:data.id,
            firstName: data.firstName,
            lastName: data.lastName,
            birthDate: data.birthDate,
            gender: data.gender,
            familyChief: data.familyChief,
            coord: data.coord,
            groupId: data.groupId,
            anneeAffiliation: data.anneeAffiliation
        }));
    }, 

    delete: (id) => {
        return fetch(`${url}/${id}`, Auth.options("DELETE"));
    },

    validate: (id) => {
        return fetch(`${url}/${id}/valide`, Auth.options('GET'))
    },

    refus: (id, noteRefus) => {
        return fetch(`${url}/refus`, Auth.options('POST', {
            id: id,
            note: noteRefus
        }))
    },

    transfert: (idCandidat, idmembre) => {
        return fetch(`${url}/${idCandidat}/transfert/${idmembre}`, Auth.options('GET'));
    }

}