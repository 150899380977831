import { Auth } from "../components/Form/Form";

const url = "/api/members";

export const MembreApi = {
    get: (id) => {
        return fetch(`${url}/${id}`, Auth.options("GET"))
            .then(res => res.json())
    },

    search: (matricule = "", nom = "", prenom = "", validOnly = false) => {
        return fetch(`${url}?matricule=${matricule}&nom=${nom}&prenom=${prenom}&validOnly=${validOnly}`, Auth.options("GET"))
            .then(res=>res.json())
    },

    PayerCotisation: (idMembre, idGroupe, annee) => {
        return fetch(`${url}/${idMembre}/pay?annee=${annee.toString()}&grpid=${idGroupe}`, Auth.options("GET"))
    },

    update: (data) => {
        return fetch(`${url}`, Auth.options("PATCH", data));
    },

    delete: (id) => {
        return fetch(`${url}/${id}`, Auth.options("DELETE"));
    },

    fusion: (idOldMembre, idNewMembre) => {
        return fetch(`${url}/fusion?id1=${idOldMembre}&id2=${idNewMembre}`, Auth.options("GET"));
    },

    getCotisations: (id) => {
        return fetch(`${url}/${id}/cotisations`, Auth.options("GET"))
            .then(res=>res.json());
    },

    deleteCotisation: (idMembre, idCot, annee) => {
        return fetch(`${url}/${idMembre}/cotisations/${idCot}?annee=${annee}`, Auth.options('DELETE'));
    },
}