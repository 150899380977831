import React from 'react';

const FormatAdress = (adress) => {
    if (adress) {
        const { streetName, streetNumber, streetNumberBte, postalCode, city, countryLibelle } = adress;
        return (<div>
            {streetName || streetNumber || streetNumberBte ? <p>{streetName || ""} {streetNumber || ""} {streetNumberBte ? ` Bte ${streetNumberBte}` : ""},</p> : <p></p>}
            {postalCode || city ? <p> {postalCode || ""} {city || ""}</p> : <p></p>}
            {countryLibelle ? <p> {countryLibelle || ""}</p> : ""}
        </div>);
    }
    else {
        return (<p></p>);
    }
}

/**
 *
 * @param {any} adr Objet adresse composé de { streetName, streetNumber, streetNumberBte, postalCode, city }
 * @returns string if adr exist null otherwise
 */
export const FormatAdressOneLine = adr => {
    if (adr) {
        let { streetName, streetNumber, streetNumberBte, postalCode, city } = adr;
        return (`${streetName} ${streetNumber}${streetNumberBte ? " bte " + streetNumberBte : ""}, ${postalCode} ${city}`);
    }
    else
        return null;
}

export default FormatAdress;
