import React, { createContext, useContext, useReducer } from 'react';
//import { CandidatApi } from '../api/CandidatApi';

//const IdCa = "dfc82482-8cb2-11de-a173-001372568c9a";

//const fetchCand = async() => await CandidatApi.getToValidate();

const initialState = {
    candidatesToValid: [],
    WalksToValid: [],
    InsurancesToValid: []
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'candidates/reload':
            return {
                ...state,
                theme: action.newTheme
            };
        default:
            return state;
    }
};

const CaContext = createContext();

export const CaConsumer = CaContext.Consumer;
export const CaConsumerHook = () => useContext(CaContext);

export const CaProvider = ({ children }) => {

    return (<CaContext.Provider value={useReducer(reducer, initialState)}>
        {children}
    </CaContext.Provider>);
}