import { Auth } from "components/Form/Form";

const url = 'api/params';

export const ParametresApi = {
    get: (paramId) => {
        return fetch(`${url}/${paramId}`, Auth.options('GET'))
            .then(res => res.json());
    },

    getV2: (paramId) => {
        return fetch(`api/params/V2/${paramId}`, Auth.options('GET'))
            .then(res => res.json());
    },

    updateV2: (paramId, value1, value2) => {
        return fetch(`api/params/V2/`, Auth.options('POST', {
            id: paramId,
            value1: value1,
            value2: value2
        }));
    },

    update: (paramId, value) => {
        return fetch(`${url}`, Auth.options('POST', {
            id: paramId,
            value: value
        }));
    },

    removeOldAffiliations: () => {
        return fetch(`${url}/oldaffiliations`, Auth.options('DELETE'));
    },
}