import React from 'react';
import { Spinner } from 'reactstrap';

import FormatDate from 'lib/FormatDate';

import { OrganigrammeApi } from 'api/OrganigrammeApi';

import CreateModal from 'components/Orga/CreateModal';
import UpdateModal from 'components/Orga/UpdateModal';
import { Actions, Objects, Show } from '../../../services/AccessControl';
import OrganigrammeCLubPDF from 'components/pdfs/Organigrammes/OrganigrammeCLubPDF';

export default ({id, organigramme, isLoading, reload }) => {

    const handleRemove = id => {
        OrganigrammeApi
            .delete(id)
            .then(reload)
    }

    if (isLoading)
        return <Spinner />;

    return (<>
        <div className="row">
            <div className="col">
                <h3 className="h3 mb-4">Organigramme</h3>
            </div>
            <div className="col-auto m-0">
                <Show object={Objects.Organigramme} action={Actions.Create}>
                    <CreateModal
                        id={id}
                        reload={reload}
                    />
                </Show>
            </div>
            <div className="col-auto m-0">
                <Show object={Objects.Organigramme} action={Actions.Read}>
                    <OrganigrammeCLubPDF id={id}/>
                </Show>
            </div>
        </div>


        <div className="card">
            <div className="table-responsive-xl">
                <table className="table table-hover mb-0">
                    <thead className="thead-light">
                        <tr>
                            <th scope="col" className="text-dark">Poste</th>
                            <th scope="col" className="text-dark">Nom</th>
                            <th scope="col" className="text-dark">Prénom</th>
                            <th scope="col" className="text-dark">Matricule</th>
                            <th scope="col" className="text-dark">Date Début</th>
                            <th scope="col" className="text-dark">Date Fin</th>
                            <th scope="col" className="text-dark">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {organigramme?.map((e, i) => <tr key={i}>
                            <td>{e?.post}</td>
                            <td>{e?.lastName}</td>
                            <td>{e?.firstName}</td>
                            <td>{e?.matricule}</td>
                            <td>{e?.startDate ? FormatDate(e.startDate) : "/"}</td>
                            <td>{e.endDate ? FormatDate(e.endDate) : "/"}</td>
                            <td>
                                <Show object={Objects.Organigramme} action={Actions.Update}>
                                    <UpdateModal
                                        id={e.id}
                                        reload={reload}
                                        initStartDate={e?.startDate}
                                        initEndDate={e?.endDate}
                                    />
                                </Show>
                                <Show object={Objects.Organigramme} action={Actions.Delete}>
                                    <button className="btn btn-danger mr-2" onClick={_ => handleRemove(e.id)}>
                                        <i className="fas fa-trash fa-sm" data-toggle="tooltip" title="Supprimer" />
                                    </button>
                                </Show>
                            </td>
                        </tr>)}
                    </tbody>
                </table>
            </div>
        </div>
    </>);
}
