export default {
    Assurance: "assurance",
    Candidat: "candidat",
    Cotisation:"cotisation",
    Club: "club",
    Membre: "membre",
    Marche: "marche", 
    Organigramme: "organigramme",
    Parametre: "parametre",
    Utilisateur: "utilisateur",
    ParametreSiteWeb:"parametreSiteWeb"
};