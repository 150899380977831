import React from 'react';
import Button from 'components/0components/Button';
import { ParametresApi } from 'api/ParametresApi';

export default _ => {

    const handleClick = _ => {
        if (!window.confirm("Attention : l'opération de cloture des anciennes affiliations est irreversible. Continuer ?"))
            return;

        ParametresApi.removeOldAffiliations();
    }

    return (<div className='m-3'>
        <label>Purger anciennes Affiliations</label>
        <Button onClick={handleClick} color="danger" icon='fa-trash'>Purger</Button>
    </div>);
}