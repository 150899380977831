import React from 'react';
import FormatDate from 'lib/FormatDate';
import Button from  'components/0components/Button';
import { Document, Image, Page, PDFDownloadLink, StyleSheet, Text, View } from '@react-pdf/renderer';
import JsBarcode from 'jsbarcode';

export default ({ affilies, callbak, club }) => {
    return (<div onClick={callbak}><PDFDownloadLink document={<MonDocument affilies={affilies} club={club} />} fileName={`ExportMembres.pdf`}>
        {({ loading }) => (loading ? 'Génération du document...' : <Button icon="fa-file-pdf">Télécharger le fichier</Button>)}
    </PDFDownloadLink>
    </div >);
}

const Affilies = (affilies) => {

    const styles = StyleSheet.create({
        element: {
            flex: 1,
            padding: 10
        }
    });

    const aff = affilies || [];

    const out = [];

    for (var i = 0; i < aff.length; i = i + 3) {
        out.push(<View style={{ display: "flex", flexDirection: "row" }} wrap={false}>
            <View style={styles.element}>
                <Barcodes affilie={affilies[i]} />
            </View>
            <View style={styles.element}>
                {(i + 1 < aff.length ? <Barcodes affilie={affilies[i + 1]} /> : <Text></Text>)}
            </View>
            <View style={styles.element}>
                {(i + 2 < aff.length ? <Barcodes affilie={affilies[i + 2]} /> : <Text></Text>)}
            </View>
        </View>);
    }

    return <View>{out}</View>;
}

const MonDocument = ({ affilies, club }) => {

    const styles = StyleSheet.create({
        pageNumber: {
            position: 'absolute',
            fontSize: 12,
            bottom: 15,
            left: 0,
            right: 0,
            textAlign: 'center',
            color: 'grey'
        },
    });

    return (<Document>
        <Page style={{ marginTop: 30, marginBottom: 30 }} wrap>
            <View style={{ borderWidth: 1, margin: 10, padding: 3 }}>
                <Text style={{ fontSize: 14, textAlign: 'center' }}>Liste des Affiliés - {club.matricule} - {club.name}</Text>
            </View>

            {Affilies(affilies)}

            <Text style={[styles.pageNumber, { fontSize: 8 }]} render={({ pageNumber, totalPages }) => (`Page: ${pageNumber} / ${totalPages} | Le: ${FormatDate(new Date())}`)} fixed />

        </Page>
    </Document>);
}

const Barcodes = ({ affilie }) => {
    let canvas = document.createElement('canvas');
    JsBarcode(canvas, `FFB/${affilie?.matricule}`);

    const styles = StyleSheet.create({
        card: {
            margin: "auto"
        },
        img: {
            height: "15mm",
            width: "45mm",
        }
    });

    return (<View style={styles.card}>
        <Image style={styles.img} src={canvas.toDataURL()} />
        <Text style={{ fontSize: 8 }}>{`${affilie?.firstName} ${affilie?.lastName}`}</Text>
    </View>);
}


