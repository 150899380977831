import { Actions } from "services/AccessControl";
import { isAllowed } from "lib/permToolkit";

export default (subject, action, ressource) => {

    switch (action) {
        case Actions.Create:
            return isAllowed(subject, "GestionAssurances", ressource) || isAllowed(subject, "CreationAssurances", ressource) || isAllowed(subject, "ValiderAssurances", ressource);
        case Actions.Read:
            return isAllowed(subject, "GestionAssurances", ressource) || isAllowed(subject, "LectureAssurances", ressource) || isAllowed(subject, "ValiderAssurances", ressource);
        case Actions.Update:
            return isAllowed(subject, "GestionAssurances", ressource) || isAllowed(subject, "ModificationAssurances", ressource) || isAllowed(subject, "ValiderAssurances", ressource);
        case Actions.Valid:
            return isAllowed(subject, "ValiderAssurances", ressource);
        default: 
            return false;
    };
}