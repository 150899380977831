import { Actions } from "services/AccessControl";
import { isAllowed } from "lib/permToolkit";

export default (subject, action, ressource) => {

    switch (action) {
        case Actions.Read:
            return isAllowed(subject, "LectureDocuments", ressource);
        default: 
            return false;
    };
}