import { Auth } from "../components/Form/Form";

const url = "/api/v2/members";

export const MembreApiV2 = {
    affiliations: (idMembre) => {
        return fetch(`${url}/${idMembre}/affiliations`, Auth.options("GET"));
    },
    deleteAffiliaiton: (membreId, affiliationId, annee) => {
        return fetch(`${url}/${membreId}/affiliations/${affiliationId}?annee=${annee}`, Auth.options('DELETE'))
    },
    deleteAffiliaitonGenerale: (membreId, affiliationId) => {
        return fetch(`/api/v3/members/${membreId}/memberships/${affiliationId}`, Auth.options('DELETE'));
    }
}