import { Actions } from "services/AccessControl";
import { isAllowed } from "lib/permToolkit";
import Groups from "../Groups";

export default (subject, action, ressource) => {

    switch (action) {
        case Actions.Create:
            return isAllowed(subject, "GestionMarches", ressource) || isAllowed(subject, "CreationMarches", ressource) || isAllowed(subject, "ValiderMarches", ressource);
        case Actions.Read:
            return isAllowed(subject, null, ressource); //Tous le monde � droit de lire les marches
        case Actions.Update:
            return isAllowed(subject, "GestionMarches", Groups.CA) || isAllowed(subject, "ModificationMarches", ressource) || isAllowed(subject, "ValiderMarches", ressource);          
        case Actions.Valid:
            return isAllowed(subject, "ValiderMarches", ressource);
        case Actions.Delete: 
            return isAllowed(subject, "GestionMarches", Groups.CA) || isAllowed(subject, "SuppressionMarches", ressource);
        default: 
            return false;
    };
}