import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import createTokenProvider from 'services/createTokenProvider';
import { yearsListBetween } from 'lib/utils';

export default ({ groupid }) => {
    const [isVisible, setIsVisible] = useState(false);

    return (<div onClick={_ => setIsVisible(true)}>
        <button className="btn btn-primary" >
            Calendrier <i className="fas fa-fw fa-file-excel ml-1" />
        </button>
        {isVisible && <MyModal isVisible={isVisible} setIsVisible={setIsVisible} groupid={groupid} />}
    </div>);
}

const MyModal = ({ isVisible, setIsVisible, groupid }) => {
    const annees = yearsListBetween(1979, new Date().getFullYear()+2);

    const [annee, setAnnee] = useState(new Date().getFullYear());

    if (!groupid)
        return <p>Erreur aucun groupe sélectionné</p>

    return (<Modal isOpen={isVisible} toggle={_ => setIsVisible(!isVisible)}>
        <ModalHeader >XLSX - Calendrier</ModalHeader>
        <ModalBody>

            <select className="custom-select m-2 mr-sm-2" value={annee} onChange={e => setAnnee(e.target.value)}>
                {annees.map((e,i) => <option key={i}>{e}</option>)}
            </select>

            <button className="btn btn-primary" onClick={_ => { window.open(`/api/marches/export/xlsx?jwt=${createTokenProvider().getToken()}&annee=${annee}&groupid=${groupid}`) } } >Télécharger</button>
        </ModalBody>
    </Modal>);
}