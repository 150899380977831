import { useState, useEffect } from 'react';
import { Auth } from "components/Form/Form";
//DEPRECATED
export default (url) => {
    const [data, setData] = useState();
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const controller = new AbortController();

    const options = (method, data) => {
        return {
            method: method,
            headers: {
                'signal': controller.signal,
                'Content-Type': "application/json",
                'Authorization': 'Bearer ' + Auth.token()
            },
            body: JSON.stringify(data)
        };
    }

    const refetch = _ => {
        const f = async () => {
            setIsLoading(true);
            await fetch(url, 'GET');
        }
        f();
        return (_ => controller.abort());
    }

    useEffect(refetch, [url])

    const fetch = async (url, method, data, callback) => {
        if (url) {
            setIsLoading(true);
            return window.fetch(url, options(method, data))
                .then(async(res) => {
                    const contentType = res.headers.get("content-type");

                    if (res.status >= 200 && res.status <= 299) {
                        if (contentType && contentType.indexOf("application/json") !== -1)
                            return res.json();
                    }
                    else if (res.status >= 100 && res.status <= 199)
                        return "";
                    else {
                        if (contentType && contentType.indexOf("application/problem+json") !== -1)
                            throw await res.json();
                        else
                            throw await res;
                    }
                })
                .then(res => setData(res))
                .then(callback)
                .catch(err => setError(err))
                .finally(setIsLoading(false));
        }
    }

    return { fetch, refetch, isLoading, data, error };
}

export const fetch = async (url, method, data, callback) => {
    const controller = new AbortController();

    const options = (method, data) => {
        return {
            method: method,
            headers: {
                'signal': controller.signal,
                'Content-Type': "application/json",
                'Authorization': 'Bearer ' + Auth.token()
            },
            body: JSON.stringify(data)
        };
    }

    if (url) {
        return window.fetch(url, options(method, data))
            .then(async (res) => {
                const contentType = res.headers.get("content-type");

                if (res.status >= 200 && res.status <= 299) {
                    if (contentType && contentType.indexOf("application/json") !== -1)
                        return res.json();
                }
                else if (res.status >= 100 && res.status <= 199)
                    return "";
                else {
                    if (contentType && contentType.indexOf("application/problem+json") !== -1)
                        throw await res.json();
                    else
                        throw await res;
                }
            })
    }
}