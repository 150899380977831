import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import SelectAffilie from './SelectAffilie';
import SelectPoste from './SelectPoste';

import { Label } from 'components/Form/Form';

export default ({ title, groupId, submit, isVisible, setIsVisible }) => {
    const [affId, setAffId] = useState();
    const [posteId, setPosteId] = useState();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    return (<Modal isOpen={isVisible} toggle={_ => setIsVisible(false)}>
        <ModalHeader toggle={_ => setIsVisible(false)}>{title}</ModalHeader>
        <ModalBody>
            <form onSubmit={e => submit(e, affId, posteId, startDate, endDate)} className="p-2">
                <SelectAffilie
                    value={affId}
                    onChange={val => setAffId(val)}
                />

                <SelectPoste
                    groupId={groupId}
                    label="posteid"
                    type="text"
                    value={posteId}
                    onChange={val => setPosteId(val)}
                    required
                />

                <div>
                    <Label required label="Date de Prise de Fonctions" />
                    <input
                        className="form-control"
                        type="date"
                        onChange={e => setStartDate(e.target.value)}
                        value={startDate ?? ""}
                        required
                    />
                </div>

                <div>
                    <Label required label="Date de Fin" />
                    <input
                        className="form-control"
                        type="date"
                        onChange={e => setEndDate(e.target.value)}
                        value={endDate ?? ""}
                    />
                </div>

                <button className="btn btn-primary mt-3" type="submit">Valider</button>
            </form>
        </ModalBody>
    </Modal>);
}