import { Actions } from "services/AccessControl";
import { isAllowed } from "lib/permToolkit";
import Groups from "../Groups";

export default (subject, action, ressource) => {

    switch (action) {
        case Actions.Read:
            return isAllowed(subject, "GestionCotisations", ressource) || isAllowed(subject, "LectureCotisations", ressource);//désigne la lecture du credit d'un club
        case Actions.Update://maj de la cotisation d'un groupe
            return isAllowed(subject, "GestionCotisations", Groups.CA) || isAllowed(subject, "ModificationCotisations", Groups.CA);
        case Actions.Pay:
            return isAllowed(subject, "GestionCotisations", ressource) || isAllowed(subject, "PaiementCotisations", ressource);
        case Actions.Manage:
            return false; // uniquement Admin
        default:
            return false;
    };
}