import React from 'react';

import DownloadPdf from '../DownloadPdf';
import PdfWorker, { View, Text, Image } from '../PdfWorker';

import PdfTableWalks from './components/PdfTableWalks';

export default ({ fileName, data }) => (<DownloadPdf document={MonDocument(data)} fileName={fileName} />);

const MonDocument = data => {
    const { marches, annee } = data;

    return (<PdfWorker>

        <View style={{ width: "auto", flexDirection: "row", marginBottom: 5 }}>
            <View style={{ width: "20%" }}>
                <Image src="img/FFBMPLogoC.jpg" />
            </View>
            <View style={{ width: "50%" }}>
            </View>
            <View style={{ width: "30%" }}>
                <Text style={{ fontSize: 22, marginBottom: 5, textAlign: 'right' }}>FFBMP</Text>
                <Text style={{ fontSize: 18, textAlign: 'right' }}>Calendrier</Text>
            </View>
        </View>

        <View style={{ borderWidth: 1, marginTop: 20, marginBottom: 20, padding: 15, backgroundColor: '#eeeeee' }}>
            <Text style={{ fontSize: 14, textAlign: 'center' }}>{`Calendrier ${annee}`}</Text>
        </View>

        <PdfTableWalks walks={marches} />

    </PdfWorker>);
}