import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import createTokenProvider from 'services/createTokenProvider';
import { FormSelect } from 'components/Form/Form';

export default _ => {
    const [isVisible, setIsVisible] = useState(false);

    return (<div className="d-flex justify-content-between align-items-center" onClick={_ => setIsVisible(true)}>
        <p className="my-auto"><span className="badge badge-success mr-3">XLSX</span>Export FWB</p>
        <button className="btn btn-primary ml-3" >
            <i className="fas fa-sm fa-arrow-down" data-toggle="tooltip" title="Télécharger" />
        </button>
        {isVisible && <MyModal isVisible={isVisible} setIsVisible={setIsVisible} />}
    </div>);
}

const MyModal = ({ isVisible, setIsVisible }) => {
    const [selectedAnnee, setSelectedAnnee] = useState(new Date().getFullYear());

    const yearsListBetween = (debut, fin) => {
        let tmp = []
        for (let i = fin; i >= debut; i--)
            tmp.push(i);
        return tmp;
    }

    const annees = yearsListBetween(1979, new Date().getFullYear());

    return (<Modal isOpen={isVisible} toggle={_ => setIsVisible(!isVisible)}>
        <ModalHeader >XLSX - Export FWB</ModalHeader>

        <FormSelect label="Année" required value={selectedAnnee} handleChange={value => setSelectedAnnee(value)}>
            {annees.map(e => <option key={e}>{e}</option>)}
        </FormSelect>

        <ModalBody>
            <button className="btn btn-primary" onClick={_ => { window.open(`/api/stats/fwb/xlsx?jwt=${createTokenProvider().getToken()}&annee=${selectedAnnee}`) } }>Télécharger</button>
        </ModalBody>
    </Modal>);
}
