import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { MembreApi } from 'api/MembreApi';

import Button from 'components/0components/Button';
import Input from 'components/0components/Input';
import { LoadingButton } from 'components/0components/Spinner';
import Table, { TableElem } from 'components/0components/Table';

export default _ => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);

    const [rNom, setRNom] = useState("");
    const [rPrenom, setRPrenom] = useState("");
    const [rMatricule, setRMatricule] = useState("");
    const [results, setResults] = useState([]);

    const search = _ => {
        setIsLoading(true);
        MembreApi.search(rMatricule, rNom, rPrenom)
            .then(res => setResults(res))
            .finally(_ => setIsLoading(false))
    }

    return (<>
        <div className="row mb-4">
            <div className="col-md">
                <h3 className="h3">Annuaire</h3>
            </div>
            <div className="col-md-2 my-auto">
                    <Input placeholder="Nom" value={rNom} onChange={e => setRNom(e)} />
            </div>
            <div className="col-md-2 my-auto">
                <Input placeholder="Prénom" value={rPrenom} onChange={e => setRPrenom(e)} />
            </div>
            <div className="col-md-2 my-auto">
                <Input placeholder="Matricule" value={rMatricule} onChange={e => setRMatricule(e)} />
            </div>
            <div className="col-md-auto my-auto m-2">
                {isLoading ? <LoadingButton>Chargement</LoadingButton> : <Button onClick={search} icon="fa-search">Rechercher</Button>}
            </div>
        </div>
        <Table titles={["Nom", "Prénom", "Matricule", "Club"]} clickable>
            {results && results.map((e, i) => <tr key={i} onClick={_ => history.push(`/membres/${e.id}`)}>
                <TableElem>{e?.lastName}</TableElem>
                <TableElem>{e?.firstName}</TableElem>
                <TableElem>{e?.matricule}</TableElem>
                <TableElem>{(e?.groupMatricule || e?.groupName) ? `(${e?.groupMatricule}) ${e?.groupName}` : ""}</TableElem>
            </tr>)}
        </Table>
    </>);
}