export default _ => {
    let storageId = "REACT_TOKEN_AUTH";

    const isValid = jwtToken => {
        if (jwtToken.length === 0)
            return false;

        const jwtPayload = JSON.parse(atob(jwtToken.split('.')[1])); //r�cup�re le payload au sein du token 
        return jwtPayload && Date.now() < (jwtPayload.exp * 1000)// * 1000 pour les Milisecondes
    }

    const getToken = _ => {
        let token = localStorage.getItem(storageId);
        if (token && isValid(token))
            return token;

        return null;
    };

    const isLoggedIn = _ => getToken() != null;

    const setToken = newtoken => {
        if (newtoken)
            localStorage.setItem(storageId, newtoken);
        else 
            localStorage.removeItem(storageId);
    };

    return {
        getToken,
        isLoggedIn,
        setToken,
    };

};
