import React, { useState } from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { LoadingButton } from '../0components/Spinner';
import Button from '../0components/Button';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import FicheInscriptionClubLayout from './FicheInscriptionClubLayout';

export default ({ club }) => {
    const [isVisible, setIsVisible] = useState(false);

    return (<div onClick={_ => setIsVisible(true)}>
        <Button>Fiche d'inscription <i className="fas fa-sm fa-file-pdf" /></Button>
        {isVisible && <MyModal
            club={club}
            isVisible={isVisible}
            setIsVisible={val => setIsVisible(val)} />}
    </div>);
}

const MyModal = ({ club, isVisible, setIsVisible }) => {
    if (!club)
        return null;

    return (<Modal isOpen={isVisible} toggle={_ => setIsVisible(false)}>
        <ModalHeader toggle={_ => setIsVisible(false)}>PDF - Fiche d'inscription</ModalHeader>
        <ModalBody>
            <PDFDownloadLink document={<FicheInscriptionClubLayout club={club} />} fileName={`${club?.matricule ?? ""} Fiche d'inscription.pdf`}>
                {({ loading }) => (loading ? <LoadingButton>Génération</LoadingButton> : <Button icon="fa-file-pdf" onClick={_ => setIsVisible(false)}>Télécharger</Button>)}
            </PDFDownloadLink>
        </ModalBody>
    </Modal>);
}