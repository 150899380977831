import React from 'react';
import { useLocation } from 'react-router-dom';
import { Form140 } from './Ass140';
import { Form143 } from './Ass143';
import { Form143Bis } from './Ass143Bis';
import { Form144 } from './Ass144';
import { Form147 } from './Ass147';
import { Form201 } from './Ass201';

export default _ => {
    const location = useLocation();
    const { code = null, grpid = null } = location.state || {};

    if (!grpid) {
        console.error("Création Demande Ass", "code: " + code, "grpid: " + grpid);
        return <ErrorBanner text="Erreur: GRPID null"/>
    }

    switch (code) {
        case "1400":
            return <Form140 gid={grpid}/>;
        case "1430":
            return <Form143 gid={grpid}/>;
        case "1431":
            return <Form143Bis gid={grpid}/>;
        case "1440":
            return <Form144 gid={grpid}/>;
        case "1470":
            return <Form147 gid={grpid}/>;
        case "2010":
            return <Form201 gid={grpid} />;
        default:
            return <ErrorBanner text="Erreur: code null" />;
    }
}

const ErrorBanner = ({text}) => {
    return (<div className="alert alert-danger" role="alert">{text}</div>);
}