import React from "react";
/// Librairie de gestion des erreurs développée par Antoine dans le cadre du projet WattoEat Libre Usage sous licence MIT

class ErrorBoundary extends React.Component {
    state = { hasError: false, error: null }

    static getDerivedStateFromError(error) {
        return {
            hasError: true,
            error,
        }
    }

    componentDidCatch(error, info) {
        console.log(error, info);
    }

    render() {
        if (this.state.hasError) {
            return this.props.fallback ?? <div className="alert alert-danger" role="alert">Une erreur s'est produite</div>;
        }
        return this.props.children;
    }
}

export default ErrorBoundary;