import React from 'react';
import { useHistory } from 'react-router-dom';
import FormatDate from 'lib/FormatDate';
import { Actions, Objects, Show } from 'services/AccessControl';
import CalendrierPdfProvince from 'components/pdfs/Calendrier/CalendrierPdfProvince';
import CalendrierPdfOnlyProvince from 'components/pdfs/Calendrier/CalendrierPdfOnlyProvince';
import CalendrierExportXLSXModal from './CalendrierExportXLSXModal';
import ProjetCalendrierPdfProvince from '../../pdfs/Calendrier/ProjetCalendrierPdfProvince';
import ProjetCalendrierPdfOnlyProvince from '../../pdfs/Calendrier/ProjetCalendrierPdfOnlyProvince';

export default ({ id, marches, isLoading }) => {
    const history = useHistory();

    const handleClickMarche = marche => history.push("/marches/" + marche.id);

    const handleClickCreateMarche = _ => history.push('/marches/nouvelle', { groupId: id });

    return (<>
        <div className="row mb-3">
            <div className="col">
                <h3 className="h3 mb-3">Marches Organisées</h3>
            </div>
            <div className="col-auto">
                <div className="row">
                    <div className="col-auto">
                    </div>
                    <Show object={Objects.Marche} action={Actions.Create}>
                        <div className="col-auto">
                            <button type="button" className="btn btn-primary" onClick={handleClickCreateMarche}>Nouvelle Marche <i className="fas fa-fw fa-plus"></i></button>
                        </div>
                    </Show>
                    <div className="col-auto">
                        <CalendrierPdfProvince groupid={id} />
                    </div>
                    <div className="col-auto">
                        <CalendrierPdfOnlyProvince groupid={id} />
                    </div>
                    <div className="col-auto">
                        <ProjetCalendrierPdfProvince id={id} />
                    </div>
                    <div className="col-auto">
                        <ProjetCalendrierPdfOnlyProvince id={id} />
                    </div>
                    <div className="col-auto">
                        <CalendrierExportXLSXModal groupid={id} />
                    </div>
                </div>
            </div>
        </div>
        <div className="card">
            <div className="table-responsive-xl">
                <table className="table table-hover">
                    <thead className="thead-light">
                        <tr>
                            <th scope="col" className="text-dark">Date</th>
                            <th scope="col" className="text-dark">Marche</th>
                            <th scope="col" className="text-dark">Club</th>
                        </tr>
                    </thead>
                    <tbody>
                        {marches?.map(m => <tr key={m.id} onClick={_ => handleClickMarche(m)}>
                            <th>
                                <span className={`mb-3 ${new Date(m?.date) > new Date() ? (m?.validee ? "text-primary" : "text-warning") : "text-secondary"}`} style={{ display: "block" }}>
                                    <i className="fas fa-fw fa-clock mr-1" />
                                    <span>{m.date ? FormatDate(m.date) : "Indisponible"}</span>
                                </span>
                            </th>
                            <td>{m?.titreMarche ?? ""}</td>
                            <td>{`(${m?.groupMatricule}) ${m.groupLibelle}`}</td>
                        </tr>)}
                        {isLoading && <tr><td>Chargement...</td><td>/</td><td>/</td></tr>}
                    </tbody>
                </table>
            </div>
        </div>
    </>);
}