import { useState } from "react"

export default initialState => {
    const [fields, setFields] = useState(initialState);

    ///Need name prop to input
    const handleInputChange = event => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setFields({ ...fields, [name]: value });
    }

    return {fields, handleInputChange, setFields}
}