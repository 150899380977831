import React from 'react';
import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';

export default ({ club, data, actif, annee }) => {

    const styles = StyleSheet.create({
        body: {
            paddingTop: 35,
            paddingBottom: 65,
            paddingHorizontal: 35
        },
        row: { flexDirection: "row" },
        pageFooter: {
            position: 'absolute',
            fontSize: 8,
            bottom: 8,
            left: 10,
            right: 10,
            color: 'grey'
        },
        pageFooterCenter: { textAlign: 'center' },
        pageFooterLeft: { textAlign: 'left' },
        pageFooterRight: { textAlign: 'right' },
        table: {
            display: "table",
            width: "auto",
            borderStyle: "solid",
            borderColor: '#bfbfbf',
            borderWidth: 1,
            borderRightWidth: 0,
            borderBottomWidth: 0
        },
        tableRow: {
            margin: "auto",
            padding: 0,
            flexDirection: "row"
        },
        tableCol: {
            borderStyle: "solid",
            borderColor: '#bfbfbf',
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
        },
        tableCollHeader: {
            fontSize: 12,
            fontWeight: 500
        },
        tableCell: { fontSize: 10, margin:2 }
    });

    return (<Document>
        <Page style={styles.body} orientation="landscape">
            <View style={styles.row}>
                <View style={{ width: '50%' }}>
                    <View style={styles.row}>
                        <View style={{ width: '20%' }}>
                            <Text style={styles.tableCell}>Club:</Text>
                        </View>
                        <View style={{ width: '100%' }}>
                            <Text style={styles.tableCell}>{club?.name ?? ""}</Text>
                        </View>
                    </View>
                    <View style={styles.row}>
                        <View style={{ width: '20%' }}>
                            <Text style={styles.tableCell}>Matricule:</Text>
                        </View>
                        <View style={{ width: '100%' }}>
                            <Text style={styles.tableCell}>{club?.matricule ?? ""}</Text>
                        </View>
                    </View>
                    <View style={styles.row}>
                        <View style={{ width: '20%' }}>
                            <Text style={styles.tableCell}>Année:</Text>
                        </View>
                        <View style={{ width: '100%' }}>
                            <Text style={styles.tableCell}>{annee}</Text>
                        </View>
                    </View>
                    <View style={styles.row}>
                        <View style={{ width: '20%' }}>
                            <Text style={styles.tableCell}>Nb d'affiliés:</Text>
                        </View>
                        <View style={{ width: '100%' }}>
                            <Text style={styles.tableCell}>{data?.length}</Text>
                        </View>
                    </View>
                </View>
                <View style={{ width: '50%', right: 1 }}></View>
            </View>
            <View style={{ borderBottomColor: 'black', borderBottomWidth: 1, marginTop: 5, marginBottom: 10 }} />
            <View style={styles.table}>
                <View style={[styles.tableRow, { backgroundColor: "lightgrey" }]}>
                    <View style={[styles.tableCol, styles.tableCollHeader, { width: "8%" }]}>
                        <Text style={styles.tableCell}>Matricule</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "15%" }]}>
                        <Text style={styles.tableCell}>Nom</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "15%" }]}>
                        <Text style={styles.tableCell}>Prénom</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "32%" }]}>
                        <Text style={styles.tableCell}>Coordonnées</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "15%" }]}>
                        <Text style={styles.tableCell}>Date de naissance</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "5%" }]}>
                        <Text style={styles.tableCell}>Sexe</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "5%" }]}>
                        <Text style={styles.tableCell}>Chef</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "5%" }]}>
                        <Text style={styles.tableCell}>Cot.</Text>
                    </View>
                </View>
                {data.map((e,i) => <View key={i} style={styles.tableRow}>
                    <View style={[styles.tableCol, { width: "8%" }]}>
                        <Text style={styles.tableCell}>{e?.matricule}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "15%" }]}>
                        <Text style={styles.tableCell}>{e?.lastName}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "15%" }]}>
                        <Text style={styles.tableCell}>{e?.firstName}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "32%" }]}>
                        <Text style={{ fontSize: 8 }}>{`${e.coord?.adress?.streetName} ${e.coord?.adress?.streetNumber} ${e.coord?.adress?.streetNumberBte ? `Bte ${e.coord?.adress?.streetNumberBte}` : ""} - ${e.coord?.adress?.postalCode} ${e.coord?.adress?.city}`}</Text>
                        <Text style={{ fontSize: 8 }}>{`Tel: ${e.coord?.phone}`}</Text>
                        <Text style={{ fontSize: 8 }}>{`Mobile: ${e.coord?.gsm}`}</Text>
                        <Text style={{ fontSize: 8 }}>{`Email: ${e.coord?.email}`}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "15%" }]}>
                        <Text style={styles.tableCell}>{new Date(e?.birthDate).toLocaleDateString()}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "5%" }]}>
                        <Text style={styles.tableCell}>{e?.gender}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "5%" }]}>
                        <Text style={styles.tableCell}>{e?.familyChief ? "OUI" : "NON"}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "5%" }]}>
                        <Text style={styles.tableCell}>{e?.isCotisationEnOrdre ? "OUI" : "NON"}</Text>
                    </View>
                </View>)}
            </View>
            <Text style={[styles.pageFooter, styles.pageFooterLeft]} fixed>{`${club?.matricule ?? ""} - ${actif ? "Affiliations en ordre de cotisation" : "Affiliations"} - Année ${annee}`}</Text>
            <Text style={[styles.pageFooter, styles.pageFooterCenter]} render={({ pageNumber, totalPages }) => (`${pageNumber} / ${totalPages}`)} fixed />
            <Text style={[styles.pageFooter, styles.pageFooterRight]} fixed >Date: {new Date().toLocaleDateString()}</Text>
        </Page>
    </Document>);
}