import { Auth } from "../components/Form/Form";

const url = '/api/clubs';

export const ClubApi = {
    get: (ClubId) => {
        return fetch(`${url}/${ClubId}`, Auth.options('GET'))
            .then(res => res.json());
    },

    getCandidats: (id) => {
        return fetch(`${url}/${id}/candidats`, Auth.options('GET'))
            .then(res => res.json())
            .catch(err => console.error(err));
    },

    getMembres: (clubId, annee = new Date().getFullYear(), actifOnly = false) => {
        return fetch(`${url}/${clubId}/members?year=${annee.toString()}&actifOnly=${actifOnly}`, Auth.options('GET'))
            .then(res => res.json())
            .catch(err => console.error(err));
    },

    getAssurances: (clubId, startDate = `${new Date().getFullYear().toString()}-01-01`, endDate = `${new Date().getFullYear().toString()}-12-31`) => {
        return fetch(`${url}/${clubId}/assurances?start=${startDate}&end=${endDate}`, Auth.options('GET'))
            .then(res => res.json());
    },

    all: () => {
        return fetch(`${url}`, Auth.options('GET'))
            .then(res => res.json());
    },

    create: (club) => {
        return fetch(`${url}`, Auth.options('POST', club));
    },

    update: (data) => {
        return fetch(`${url}`, Auth.options('PATCH', data));
    },

    updateCredit: (id, credit) => {
        return fetch(`${url}/credit`, Auth.options('PATCH', { "idGroup": id, "provision": credit }));
    },
}