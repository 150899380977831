import React from 'react';
import { Page, Text, Image, View, Document, StyleSheet } from '@react-pdf/renderer';
import JsBarcode from 'jsbarcode';

export default ({ member, annee }) => {
    //canvas = document.createElement('canvas');
    //QRCode.toCanvas(canvas, your_data);
    //const qr = canvas.toDataURL();
    let canvas = document.createElement('canvas');
    JsBarcode(canvas, `FFB/${member?.identification}`);
    const barcode = canvas.toDataURL();

    const styles = StyleSheet.create({
        doc: {
            width: "100%",
            height: "100%",
        },
        card: {padding: "4mm",},
        groupe: {fontSize:6,},
        info: {fontSize: 8,},
        nom: {fontSize: 10,},
        img: {
            height: "15mm",
            width: "45mm",
            marginTop: "3mm",
            marginBottom: "3mm",
        }
    });

    return (<Document style={styles.doc}>
        <Page size="A4" style={{
            paddingTop: "235mm",
            paddingBottom: "10mm",
            paddingLeft: "105mm",
            paddingRight: "20mm",
            width: "100%",
            height: "100%",
        }}>
            <View style={styles.card}>
                <Text style={styles.nom}>{`${member.lastName} ${member.firstName}`}</Text>
                <Text style={styles.groupe}>{`${member?.groupIdentification} - ${member?.groupName} `}</Text>
                <Text style={styles.info}>{new Date(member?.birthDate).toLocaleDateString()}</Text>
                <Text style={styles.info}>{member?.adressStreet || member?.adressNum || member?.adressBte ? `${member?.adressStreet ?? ""}, ${member?.adressNum ?? ""} ${member?.adressBte ?? ""}` : " "}</Text>
                <Text style={styles.info}>{member?.adressPostalCode || member?.adressCity ? `${member?.adressPostalCode ?? ""} - ${member?.adressCity ?? ""}` : " "}</Text>
                <Image style={styles.img} src={barcode} />
                <Text style={styles.info}>{`CARTE DE MEMBRE ${annee}`}</Text>
            </View>
        </Page>
    </Document>);
};