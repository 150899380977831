import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import { FormatDateInput } from 'lib/FormatDate';

import { Label } from 'components/Form/Form';

export default ({ title, initStartDate, initEndDate, submit, isVisible, setIsVisible }) => {
    const [startDate, setStartDate] = useState(initStartDate);
    const [endDate, setEndDate] = useState(initEndDate);

    return (<Modal isOpen={isVisible} toggle={_ => setIsVisible(false)}>
        <ModalHeader toggle={_ => setIsVisible(false)}>{title}</ModalHeader>
        <ModalBody>
            <form onSubmit={e => submit(e, startDate, endDate)} className="p-2">

                <div>
                    <Label required label="Date de Prise de Fonctions" />
                    <input
                        className="form-control"
                        type="date"
                        onChange={e => setStartDate(e.target.value)}
                        value={startDate ? FormatDateInput(startDate) : ""}
                        required
                    />
                </div>

                <div>
                    <Label required label="Date de Fin"/>
                    <input
                        className="form-control"
                        type="date"
                        onChange={e => setEndDate(e.target.value)}
                        value={endDate ? FormatDateInput(endDate) : ""}
                    />
                </div>

                <button className="btn btn-primary mt-3" type="submit">Valider</button>
            </form>
        </ModalBody>
    </Modal>);
}