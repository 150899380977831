import React, { useState } from 'react';
import { useParams } from "react-router-dom";

import Spinner from 'components/0components/Spinner';
import { Required } from '../Form/Form';

export default ({ credit, isLoading, handleUpdateCredit  }) => {
    const { id } = useParams();

    const [newCredit, setNewCredit] = useState(credit);

    const contactSubmit = event => {
        event.preventDefault();
        handleUpdateCredit(id, newCredit);
    }

    if (isLoading)
        return <Spinner/>

    return (<>
        <h3 className="h3">Modification Cotisation Club</h3>
        {credit !== undefined && <form onSubmit={contactSubmit}>
            <div className="row m-2">
                <div className="col-lg-4">
                    <label>Provision<Required /></label>
                    <input
                        className="form-control"
                        type="number"
                        min="0"
                        max="2000"
                        inputMode="decimal"
                        step=".01"
                        placeholder="Provision"
                        pattern="[0-9]+([\.][0-9]+)?"
                        onChange={e => setNewCredit(parseFloat(e.target.value))}
                        value={newCredit}
                    />
                </div>
            </div>
            <button type="submit" className="btn btn-primary m-3">Confirmer</button>
        </form>}
    </>);
}