import React from 'react';
import { useParams } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import ErrorBoundary from 'lib/Antoine/ErrorBoundary';
import useFetch from 'lib/Antoine/useFetch';
import FormatDate from 'lib/FormatDate';
import { MembreApiV2 } from '../../api/MembreApiV2';

export default _ => {
	const { id } = useParams();
	//const history = useHistory();

	const { data: affiliations,reload, isLoading } = useFetch(`/api/v2/members/${id}/affiliations`);

	const handleSuppr = (cid, annee) => {
		if (window.confirm("�tes-vous s�r de vouloir supprimer cette affiliation ?")) {
			MembreApiV2
				.deleteAffiliaiton(id, cid, annee)
				.then(_ => reload());
		}
	}

	const handleDeleteAffiliation = (affId) => {
		if (window.confirm("�tes-vous s�r de vouloir supprimer cette affiliation ?")) {
			MembreApiV2
				.deleteAffiliaitonGenerale(id, affId)
				.then(_ => reload());
		}
	}

	if (isLoading)
		return <Spinner />; 

	return (<div>        
		<h1 className="display-4 mb-4" >Listing des affiliations: </h1>
		<ErrorBoundary fallback={<div className="alert alert-danger" role="alert">Erreur dans le chargement des Affiliations</div>}>
			<ul className="list-group">
				{affiliations?.map((e, i) => (<li className="list-group-item p-4" key={i}>
					<div className="row mb-2">
						<div className="col m-auto">
							<h4 className="h4">
								{`${e.groupMatricule}-${e.groupName} (${FormatDate(e.begin)} - ${(e.end ? FormatDate(e.end) : "...")})`}
							</h4>
						</div>
						<div className="col-auto">
							<button className="btn btn-danger m-auto" onClick={_ => handleDeleteAffiliation(e.id)} data-toggle="tooltip" title="Modifier">
							    <i className="fas fa-trash fa-sm" />
							</button>
						</div>
					</div>
				   
					<ul className="list-group">
						{e.cotisations?.map((annee, i) => (<li className="list-group-item" key={i}>
							<div className="row">
								<div className="col m-auto">
									{annee}
								</div>
								<div className="col-auto m-auto">
									<button className="btn btn-danger" onClick={_ => handleSuppr(e.id, annee)} data-toggle="tooltip" title="Supprimer">
										<i className="fas fa-trash fa-sm" />
									</button>
								</div>
							</div>
						</li>))}   
					</ul>
				</li>))}
			</ul>
		</ErrorBoundary>
	</div>);
}