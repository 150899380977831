import React, { useState } from 'react';
import { FormSelect } from 'components/Form/Form';
import Button from 'components/0components/Button';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import createTokenProvider from 'services/createTokenProvider';

export default _ => {
    const [isVisible, setIsVisible] = useState(false);

    return (<div className="d-flex justify-content-between align-items-center" onClick={_ => setIsVisible(true)}>
        <p className="my-auto"><span className="badge badge-success mr-3">XLSX</span>Responsables par province</p>
        <button className="btn btn-primary ml-3" >
            <i className="fas fa-sm fa-arrow-down" data-toggle="tooltip" title="Télécharger" />
        </button>
        {isVisible && <MyModal isVisible={isVisible} setIsVisible={setIsVisible} />}
    </div>);
}

const MyModal = ({ isVisible, setIsVisible }) => {
    const [province, setProvince] = useState("");

    let url_fichier = `/api/v1/orga/all/xlsx?grpid=${province}&jwt=${createTokenProvider().getToken()}`; 

    const provinces = [
        {
            "id": "dfc8be92-8cb2-11de-a173-001372568c9a",
            "nom": "C. P. BRUXELLES - BRABANT WALLON"
        },
        {
            "id": "dfc8c0fe-8cb2-11de-a173-001372568c9a",
            "nom": "C. P. HAINAUT"
        },
        {
            "id": "dfc8c284-8cb2-11de-a173-001372568c9a",
            "nom": "C. P. LIEGE"
        },
        {
            "id": "dfc8c3f6-8cb2-11de-a173-001372568c9a",
            "nom": "C. P. LUXEMBOURG"
        },
        {
            "id": "dfc8c554-8cb2-11de-a173-001372568c9a",
            "nom": "C. P. NAMUR"
        }
    ];

    const handleClick = _ => window.open(url_fichier, '_blank');

    return (<Modal isOpen={isVisible} toggle={_ => setIsVisible(!isVisible)}>
        <ModalHeader >Responsables dans la province</ModalHeader>
        <ModalBody>
            <FormSelect label="Province" required value={province} handleChange={val => setProvince(val)}>
                <option value="">Sélectionner une province</option>
                {provinces && provinces.map(e => <option value={e.id} key={e.id}>{e.nom}</option>)}
            </FormSelect>
            {province && <Button className="mt-3" onClick={handleClick}>Télécharger</Button>}
        </ModalBody>
    </Modal>);
}