import { Auth } from "components/Form/Form";

const endpointApi = 'api/users';

export const UtilisateurApi = {
    all: () => fetch(`${endpointApi}`, Auth.options('GET')).then(res => res.json()),
    me: () => fetch(`${endpointApi}/me`, Auth.options('GET')).then(res => res.json()),
    get: (id) => fetch(`${endpointApi}/${id}`, Auth.options('GET')).then(res => res.json()),
    create: (data) => fetch(`${endpointApi}`, Auth.options('POST', data)),
    update: (data) => fetch(`${endpointApi}`, Auth.options('PATCH', data)),
    delete: (id) => fetch(`${endpointApi}/${id}`, Auth.options('DELETE')),
}