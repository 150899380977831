import React from 'react';

const SubmitButtonLoading = ({ isLoading }) => {
    if (isLoading) {
        return (<button className="btn btn-primary" type="button" disabled>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" /><span className="sr-only">Chargement...</span>
        </button >);
    } else {
        return (<button type="submit" className="btn btn-primary">Confirmer <i className="fas fa-sm fa-check" /></button>);
    }
}

export default SubmitButtonLoading;