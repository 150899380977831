import React, { useState, useEffect } from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink as NL,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import { useUserData } from "services/UserContext";
import { useHistory } from 'react-router-dom';
import useAcces from 'services/AccessControl/useAcces';
import { Actions, Objects } from 'services/AccessControl';
import useNotifications from 'hooks/useNotifications';
import ErrorBoundary from '../../lib/Antoine/ErrorBoundary';

export default _ => {
    const [user] = useUserData();
    const history = useHistory();
    const hasPerm = useAcces();

    const [collapsed, setCollapsed] = useState(true);

    const toggleNavbar = () => setCollapsed(!collapsed);

    const [ca, setCa] = useState([]);
    const [cps, setCps] = useState([]);
    const [clubs, setClubs] = useState([]);

    const IdCa = "dfc82482-8cb2-11de-a173-001372568c9a";

    const provinces = [
        "dfc8be92-8cb2-11de-a173-001372568c9a",
        "dfc8c0fe-8cb2-11de-a173-001372568c9a",
        "dfc8c284-8cb2-11de-a173-001372568c9a",
        "dfc8c3f6-8cb2-11de-a173-001372568c9a",
        "dfc8c554-8cb2-11de-a173-001372568c9a"
    ];

    function InitLoad()
    {
        setCa(user?.groupes?.filter(e => e.id === IdCa));
        setCps(user?.groupes?.filter(e => provinces.includes(e.id)));
        setClubs(user?.groupes?.filter(e => e.id !== IdCa && !provinces.includes(e.id)));
    }

    useEffect(InitLoad, [])

    return (<div className="sticky-top">
        <Navbar color="primary" dark expand="md" container="fluid">
            <NavbarBrand onClick={_ => history.push('/')}>
                <img
                    alt="logo"
                    src="/img/FFBMPLogo.png"
                    style={{
                        height: 40,
                        width: 40
                    }}
                    className="mr-2"
                />
                FFBMP <sup>Portail</sup>
            </NavbarBrand>
            <NavbarToggler onClick={toggleNavbar} className="me-2" />
            <Collapse isOpen={!collapsed} navbar>
                <Nav navbar className="ml-auto">
                    <NavItem>
                        <NL className="text-light" onClick={_ => history.push("/")}>
                            <i className="fa-solid fa-home"></i> Accueil
                        </NL>
                    </NavItem>
                    {(ca.length + cps.length + clubs.length) > 1 && 
                    <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret className="text-light">
                            <i className="fa-solid fa-users-rectangle"></i> Mes groupes
                        </DropdownToggle>
                        <DropdownMenu right className="p-2">
                            {ca.length > 0 && <>
                                <DropdownItem header>
                                    Conseil d'Administration
                                </DropdownItem>
                                <DropdownItem onClick={_ => history.push(`/ca`)}>
                                        <p className="text-secondary m-0">Conseil d'Administration (CA)</p>
                                </DropdownItem>
                                <DropdownItem divider />
                            </>}
                            {cps.length > 0 && <>
                                <DropdownItem header>
                                    Comité Provincial
                                </DropdownItem>
                                {cps.map(e => <DropdownItem key={e.id} onClick={_ => history.push(`/provinces/${e.id}`)} >
                                    <p className="text-secondary m-0">{e.name}</p>
                                </DropdownItem>)}
                                < DropdownItem divider />
                            </>}
                            {clubs.length > 0 && <>
                                <DropdownItem header>
                                   Club(s)
                                </DropdownItem>
                                    {clubs.map(e=> <DropdownItem key={e.id} onClick={_ => history.push(`/clubs/${e.id}`)}>
                                        <p className="text-secondary m-0">{e.name}</p>
                                </DropdownItem>)}
                            </>}
                        </DropdownMenu>
                        </UncontrolledDropdown>}

                    {hasPerm("AdminOnly", "this TEXT Is A Place Holder", "oops") &&
                        <ErrorBoundary fallback={null}>
                            <Notifications />
                        </ErrorBoundary>}
                    
                    <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret className="text-light font-weight-bold">
                            {user?.name ?? "Utilisateur"}
                        </DropdownToggle>
                        <DropdownMenu right>
                            <DropdownItem header>
                                <p className="m-0">Menu Utilisateur</p>
                            </DropdownItem>
                            {
                                hasPerm(Objects.Utilisateur, Actions.Manage) &&
                                <DropdownItem onClick={_ => history.push("/admin/users")}>
                                    <p className="text-secondary m-0"><i className="fa-solid fa-users-gear"></i> Utilisateurs</p>
                                </DropdownItem>
                            }
                            {
                                hasPerm(Objects.Parametre, Actions.Manage) &&
                                <DropdownItem onClick={_ => history.push("/parametres")}>
                                    <p className="text-secondary m-0"><i className="fa-solid fa-gear"></i> Paramètres</p>
                                </DropdownItem>
                            }
                            {
                                hasPerm(Objects.ParametreSiteWeb, Actions.Manage) &&
                                <DropdownItem onClick={_ => history.push("/parametres/siteWeb")}>
                                    <p className="text-secondary m-0"><i className="fa-solid fa-globe"></i> Configuration Site Web</p>
                                </DropdownItem>
                            }
                            <DropdownItem divider />
                            <DropdownItem onClick={_ => history.push(`/logout`)}>
                                <p className="text-danger m-0"><i className="fa-solid fa-arrow-right-from-bracket" /> Déconnecter</p>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>

                </Nav>
            </Collapse>
        </Navbar>
    </div>);
}

const Notifications = _ => {
    const { notifications } = useNotifications();
    const history = useHistory();

    return (<NavItem>
        <NL className="text-light" onClick={_ => history.push("/Notifications")}>
            <i className="fa-solid fa-bell" />
            {notifications?.filter(e => !e.is_read)?.length > 0 &&
                <span className="badge badge-danger">
                    {notifications?.filter(e => !e.is_read)?.length}
                </span>}
        </NL>
    </NavItem>);
}