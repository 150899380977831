import React, { useEffect, useState } from 'react';
import { Auth, FormSelect } from '../../Form/Form';
import Spinner from '../../0components/Spinner';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Button from '../../0components/Button';
import { yearsListBetween } from 'lib/utils';
import FormatDate from 'lib/FormatDate';
import { ParametresApi } from 'api/ParametresApi';
import CalendrierProjetProvinceLayout from './CalendrierProjetProvinceLayout';
import { MarchesApi } from '../../../api/MarchesApi';

export default ({ id }) => {
    const [isVisible, setIsVisible] = useState(false);

    return (<div onClick={_ => setIsVisible(true)}>
        <button className="btn btn-primary" >
            'Projet' Cal. Prov. <i className="fas fa-fw fa-file-pdf ml-1" />
        </button>
        {isVisible && <MyModal isVisible={isVisible} setIsVisible={setIsVisible} id={id} />}
    </div>);
}

const MyModal = ({ isVisible, setIsVisible, id }) => {
    const curYear = new Date().getFullYear()

    const begin = curYear;
    const end = curYear + 2;

    const [annee, setAnnee] = useState(curYear + 1); //année de recherche
    const [respCalProv, setRespCalProv] = useState();
    const [respCalClub, setRespCalClub] = useState();
    const [paramDateLimite, setParamDateLimit] = useState();
    const [club, setClub] = useState();
    const [marches, setMarches] = useState();

    useEffect(_ => {
        setClub();
        fetch(`api/clubs/${id}`, Auth.requestOptions("GET"))
            .then(res => res.json())
            .then(res => setClub(res));
    }, [id]);

    useEffect(_ => {
        setMarches();

        MarchesApi
            .getFromProvinceV4(id, annee)
            .then(res => setMarches(res));

    }, [id, annee]);

    useEffect(_ => {
        setRespCalProv()
        fetch(`api/v1/orga/${id}/prov2/Calendrier`, Auth.requestOptions("GET"))
            .then(res => res.json())
            .then(res => setRespCalProv(res));
    }, [id]);

    useEffect(_ => {
        setRespCalClub()
        fetch(`api/v1/orga/${id}/Calendrier`, Auth.requestOptions("GET"))
            .then(res => res.json())
            .then(res => setRespCalClub(res));
    }, [id]);

    useEffect(_ => {
        setParamDateLimit();
        ParametresApi.get("CAL_PROJ_DATELIMIT")
            .then(res => setParamDateLimit(FormatDate(res.value)));
    }, []);

    const canPdfBeGenerated = paramDateLimite && club && marches && respCalProv && respCalClub;

    return (<Modal isOpen={isVisible} toggle={_ => setIsVisible(false)}>
        <ModalHeader toggle={_ => setIsVisible(false)}>Génération Calendrier</ModalHeader>
        <ModalBody>
            <FormSelect label="Année" required value={annee} handleChange={val => setAnnee(val)}>
                {yearsListBetween(begin, end).map((e, i) => <option value={e} key={i}>{e}</option>)}
            </FormSelect>
            {canPdfBeGenerated &&
                <CalendrierProjetProvinceLayout
                    calendrierProjet={{
                        marches: marches,
                        club: club,
                        annee: annee,
                        responsableProvince: respCalProv,
                        responsableClub: respCalClub,
                        dateLimite: paramDateLimite
                    }}
                    fileName={`Projet_Calendrier_${club?.matricule}_${annee}`} />}
            {!canPdfBeGenerated && <>
                <p>Chargement des données...</p>
                <Spinner />
            </>}
        </ModalBody>
    </Modal>);
}
