import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { useHistory, useParams } from 'react-router-dom';
import { PDFDownloadLink } from '@react-pdf/renderer';

import FormatDate from 'lib/FormatDate';

import { MembreApi } from 'api/MembreApi';

import Spinner from 'components/0components/Spinner';
import FicheMembrePdf from "components/pdfs/FicheMembrePdf";
import CarteMembre from "components/pdfs/CarteMembre";
import { Actions, Objects, Show } from 'services/AccessControl';
import Groups from 'services/AccessControl/Groups';
import useFetch from 'lib/Antoine/useFetch';
import ErrorBoundary from 'lib/Antoine/ErrorBoundary';

export default _ => {
    const history = useHistory();
    const { id } = useParams();

    const { data: member, isLoading: isMemberLoading, refetch } = useFetch(`/api/v3/members/${id}`)

    const { data: memberAnneeplus1, isLoading: isMemberAnneeplus1Loading } = useFetch(`/api/v3/members/${id}?cotisations=${new Date().getFullYear()+1}`)

    const [isShowFusionModal, setIsShowFusionModal] = useState(false);

    const handleModifyMember = _ => history.push(`/membres/update/${id}`);//todo vérifier le path

    const handleDeleteMember = (e, member) => {
        e.stopPropagation();
        MembreApi.delete(id)
            .finally(_ => history.goBack());
    }

    const toggleFusionModal = _ => setIsShowFusionModal(!isShowFusionModal);

    const gender = gender => {
        switch (gender) {
            case 'M':
                return "Homme";
            case 'F':
                return "Femme";
            case 'A':
                return "Autre";
            default:
                return '';
        }
    }

    if (isMemberLoading)
        return <Spinner />;

    if (!member)
        return null;

    return (<>

        <div className="row mb-4">
            <div className="col">
                <h1 className="display-4">{member.firstName} {member.lastName}<span className="lead">{member.identification ?? ""}</span></h1>
            </div>
            <div className="col-auto my-auto">
                <div>
                    <Show object={Objects.Membre} action={Actions.Read} ressource={member?.group}>
                        <LinkToMemberPdf member={member} />
                    </Show>
                    <Show object={Objects.Membre} action={Actions.Read} ressource={member?.group}>
                        <ErrorBoundary fallback={<button type = "button" className = "btn btn-danger ml-2" >Erreur</button>}>
                            <PDFDownloadLink document={
                                <CarteMembre
                                    member={member}
                                    annee={new Date().getFullYear()} />}
                                fileName={`CARTE.${member?.firstName}.${member?.lastName}.${new Date().toISOString().split('T')[0]}.pdf`}>
                                <button type="button" className="btn btn-primary ml-2">
                                    Carte <i className="fas fa-sm fa-id-card" data-toggle="tooltip" title="Télécharger" />
                                </button>
                            </PDFDownloadLink>

                            {memberAnneeplus1 && !isMemberAnneeplus1Loading && new Date().getMonth() === 11 && <PDFDownloadLink document={
                                <CarteMembre
                                    member={memberAnneeplus1}
                                    annee={new Date().getFullYear() + 1} />}
                                fileName={`CARTE.${memberAnneeplus1?.firstName}.${memberAnneeplus1?.lastName}.${new Date().getFullYear() + 1}.pdf`}>
                                <button type="button" className="btn btn-primary ml-2">
                                    Carte {new Date().getFullYear() + 1} <i className="fas fa-sm fa-id-card" data-toggle="tooltip" title="Télécharger" />
                                </button>
                            </PDFDownloadLink>}

                        </ErrorBoundary>
                    </Show>
                    <Show object={Objects.Membre} action={Actions.Fusion} ressource={member?.group}>
                        <button type="button" className="btn btn-warning ml-2 text-dark" onClick={_ => toggleFusionModal()}>
                            Fusion <i className="fas fa-sm fa-user" data-toggle="tooltip" title="Fusion" />
                        </button>
                    </Show>
                    <Show object={Objects.Membre} action={Actions.Delete} ressource={member?.group}>
                        <button type="button" className="btn btn-danger ml-2" onClick={e => handleDeleteMember(e, member)}>
                            Clôturer l'affiliation au club <i className="fas fa-sm fa-times" data-toggle="tooltip" title="Clôturer l'affiliation au club" />
                        </button>
                    </Show>
                </div>
                <ErrorBoundary>
                    <Show object={Objects.Membre} action={Actions.Fusion} ressource={member?.group}>
                        <ModalFusion
                            id={id}
                            isVisible={isShowFusionModal}
                            refetch={refetch}
                            toggleFusionModal={toggleFusionModal}
                        />
                    </Show>
                </ErrorBoundary>
            </div>
        </div>
        <div className="row">
            <div className="col-lg-8">
                <div className="card">
                    <div className="card-header">
                        <div className="container-fluid p-0">
                            <div className="row">
                                <div className="col-auto my-auto">Informations</div>
                                <div className="col"/>
                                <div className="col-auto">
                                    <Show object={Objects.Membre} action={Actions.Update} ressource={member?.group}>
                                        <button type="button" className="btn btn-primary" onClick={handleModifyMember}>
                                            Modifier <i className="fas fa-sm fa-pen" data-toggle="tooltip" title="Modifier" />
                                        </button>
                                    </Show>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <p><b>Date de naissance:</b> {FormatDate(member?.birthDate)}</p>
                        <p><b>Sexe:</b> {gender(member?.gender)}</p>
                        <p><b>Chef de Famille ? </b>{member?.isFamilyChief ? "Oui" : "Non"}</p>
                        <div>
                            <b>Adresse: </b>
                            <div className="p-3">
                                <p>{member?.adressStreet} {member?.adressNum} {member?.adressBte ? `Bte ${member?.adressBte}` : ""},</p>
                                <p>{member?.adressPostalCode} {member?.adressCity},</p>
                                <p>{member?.adressCountry}</p>
                            </div>
                        </div>
                        {member?.phone ? <p><b>Téléphone: </b>{member?.phone}</p> : ""}
                        {member?.gsm ? <p><b>GSM: </b>{member?.gsm}</p> : ""}
                        {member?.email ? <p><b>Email: </b>{member?.email}</p> : ""}
                        {/*{member?.stateCotisation ? <p><b>Affilie ? </b>{member?.stateCotisation === 1 || member?.stateCotisation === 3 ? "oui" : "non"}</p> : ""}*/}
                        {member?.noteRefus?.length > 0 ? <p className="text-danger"><b>Note Refus: </b>{member?.noteRefus}</p> : ""}
                    </div>
                </div>
            </div>
            <div className="col-lg-4">
                <ErrorBoundary>
                    <Affiliations id={id} />
                </ErrorBoundary>
            </div>
        </div>
    </>);
}

const Affiliations = ({ id }) => {
    const history = useHistory();

    const { data: affiliations, isLoading, error } = useFetch(`/api/V3/members/${id}/memberships`);

    const handleModifyCotisations = _ => history.push(`/membres/${id}/affiliations`);

    if (isLoading)
        return <Spinner />;

    if (error)
        return <div className="alert alert-danger" role="alert">Une erreur s'est produite lors du chargement des données</div>

    return (<div className="card">
        <div className="card-header">
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-auto my-auto">Historique des Affiliations</div>
                    <div className="col"></div>
                    <div className="col-auto">
                        <Show object={Objects.Membre} action={Actions.Update} ressource={Groups.CA}>
                            <button type="button" className="btn btn-primary" onClick={handleModifyCotisations}>
                                Modifier <i className="fas fa-sm fa-pen" data-toggle="tooltip" title="Modifier" />
                            </button>
                        </Show>
                    </div>
                </div>
            </div>
        </div> 
        <div className="card-body">
            {affiliations.map(c => <p key={c.id}>
                {`${(c?.start ? new Date(c.start).getFullYear() : '/')} à ${(c?.end ? new Date(c.end).getFullYear() : 'Aujourd\'hui')} | ${c.groupIdentification} - ${c.groupName}`}
            </p>)}
        </div>
    </div>);
}

const ModalFusion = ({ id, isVisible, refetch, toggleFusionModal }) => {
    const [search, setSearch] = useState(""); //fusion modal

    const { data: searchData, isLoading: isSearchDataLoading, cancel } = useFetch(`/api/members?matricule=${search}`);

    useEffect(_ => {
        cancel();
    }, [search, cancel]);

    const handleFusion = m => {
        let id2 = m.id

        if (id === id2) {
            window.alert("Vous ne pouvez pas transférer un membre dans lui même");
            return;
        }

        if (!window.confirm(`Attention Il n'est pas possible de revenir en arrière et le membre :${m.lastName} ${m.firstName} - ${m.matricule ?? ""} va être supprimé`))
            return;

        if (!id && id.length === 0)
            return;

        if (id2 && id2.length === 0)
            return;

        MembreApi.fusion(id, id2)
            .then(_ => setSearch(""))
            .then(_ => toggleFusionModal())
            .finally(_ => refetch());
    }

    return (<Modal isOpen={isVisible} toggle={_ => toggleFusionModal()}>
        <ModalHeader toggle={_ => toggleFusionModal()}>FUSION</ModalHeader>
        <ModalBody>
            <div className="row">
                <div className="col-md-6 mb-3">
                    <label className="form-label">Recherche de Membre pour la fusion</label>
                    <input className="form-control" placeholder="Matricule" onChange={e => setSearch(e.target.value)} />
                    {isSearchDataLoading ? <p>Chargement ...</p> : ""}
                    <ul className="list-group">
                        {searchData?.filter(e => e.id !== id).map(e => <li className="list-group-item d-flex justify-content-between align-items-center" key={e.id}>
                            {`${e.lastName} ${e.firstName} - ${e.matricule ?? ""}`}
                            <div>
                                <button type="button" className="btn btn-warning ml-2" onClick={_ => handleFusion(e)}><i className="fas fa-sm fa-user" data-toggle="tooltip" title="Fusion" /></button>
                            </div>
                        </li>)}
                    </ul>
                </div>
            </div>
        </ModalBody>
    </Modal>)
}

const LinkToMemberPdf = ({member}) => {
    const { id, firstName, lastName } = member;

    const { data: affiliations, isLoading, error } = useFetch(`/api/V3/members/${id}/memberships`);

    const pdfFileName = `${firstName}.${lastName}.${new Date().toISOString().split('T')[0]}.pdf`;

    if (isLoading)
        return null;

    if (error) {
        return (<button type="button" className="btn btn-danger ml-2">
            Fiche - Erreur<i className="fas fa-sm fa-file" data-toggle="tooltip" title="Error" />
        </button>);
    }

    return (<PDFDownloadLink document={<FicheMembrePdf member={member} affiliations={affiliations} />} fileName={pdfFileName}>
        <button type="button" className="btn btn-primary ml-2">
            Fiche <i className="fas fa-sm fa-file" data-toggle="tooltip" title="Télécharger" />
        </button>
    </PDFDownloadLink>);
}