import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { AccesApi } from 'api/AccesApi';
import SelectGroup from './SelectGroup';

export default ({ isVisibleModal, setIsVisibleModal, reload }) => {
    const { id } = useParams();

    const [fields, setFields] = useState({
        roleId: null,
        groupId: null,
        dateDebut: null,
        dateFin: null
    })

    const isDataValid = (fields) => {
        if (!fields?.roleId) {
            window.alert("Le rôle ne peut pas être vide");
            return false;
        }
        return true;
    }

    const handleAdd = e => {
        e.preventDefault();

        if (!isDataValid(fields))
            return;

        AccesApi
            .create(id, fields.roleId, fields.groupId, fields.dateDebut, fields.dateFin)
            .then(_ => setIsVisibleModal(false))
            .then(_ => reload())
            .catch(err => console.error(err));
    }

    return (<Modal isOpen={isVisibleModal} toggle={_ => setIsVisibleModal(false)}>
        <ModalHeader toggle={_ => setIsVisibleModal(false)}>Permission</ModalHeader>
        <ModalBody>
            <form onSubmit={handleAdd}>
                <SelectRole
                    onChange={val => setFields({ ...fields, roleId: val })}
                    value={fields.roleId || ""} 
                    required
                />

                <SelectGroup
                    onChange={val => setFields({ ...fields, groupId: val })}
                    value={fields.groupId || ""}
                />

                <label>Date de début</label>
                <input
                    className="form-control mr-sm-2"
                    type="date"
                    required
                    onChange={e => setFields({ ...fields, dateDebut: e.target.value  })}
                    value={fields.dateDebut?.split('T')[0]}
                />

                <label>Date de fin</label>
                <input
                    className="form-control mr-sm-2"
                    type="date"
                    onChange={e => setFields({ ...fields, dateFin: e.target.value })}
                    value={fields.dateFin?.split('T')[0]}
                />
                <button className="btn btn-primary mt-2" type="submit">Valider</button>
            </form>
        </ModalBody>
    </Modal>);
}

const SelectRole = ({ value, onChange }) => {
    const [data,setData] = useState();

    useEffect(() => {
        AccesApi
            .allRoles()
            .then(res => setData(res));
    }, []);

    if (!data)
        return <p>Chargement ...</p>;

    return (<select className="custom-select mb-2" onChange={e => onChange(e.target.value)} value={value || ""} required>
        <option value="">Sélectionner un role</option>
        {data?.map((elem, i) => <option key={i} value={elem.id}>{`${elem.libelle}`}</option>)}
    </select>);
}