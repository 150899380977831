import { Objects } from ".";
import AssuranceObjectPolicy from "./ObjectsPolicy/AssuranceObjectPolicy";
import CandidatObjectPolicy from "./ObjectsPolicy/CandidatObjectPolicy";
import ClubObjectPolicy from "./ObjectsPolicy/ClubObjectPolicy";
import CotisationObjectPolicy from "./ObjectsPolicy/CotisationObjectPolicy";
import MarcheObjectPolicy from "./ObjectsPolicy/MarcheObjectPolicy";
import MembreObjectPolicy from "./ObjectsPolicy/MembreObjectPolicy";
import OrganigrammeObjectPolicy from "./ObjectsPolicy/OrganigrammeObjectPolicy";
import DocumentObjectPolicy from "./ObjectsPolicy/DocumentObjectPolicy";
import ParametreObjectPolicy from "./ObjectsPolicy/ParametreObjectPolicy";
import UtilisateurObjectPolicy from "./ObjectsPolicy/UtilisateurObjectPolicy";
import ParametreSiteWebObjectPolicy from "./ObjectsPolicy/ParametreSiteWebObjectPolicy";

export default (subject, object, action, ressource) => {
    if (subject?.isAdmin)
        return true;

    if (!object && !action)
        return true;

    switch (object) {
        case Objects.Assurance:
            return AssuranceObjectPolicy(subject, action, ressource);
        case Objects.Candidat:
            return CandidatObjectPolicy(subject, action, ressource);
        case Objects.Cotisation:
            return CotisationObjectPolicy(subject, action, ressource);
        case Objects.Club:
            return ClubObjectPolicy(subject, action, ressource);
        case Objects.Membre:
            return MembreObjectPolicy(subject, action, ressource);
        case Objects.Marche:
            return MarcheObjectPolicy(subject, action, ressource);
        case Objects.Organigramme:
            return OrganigrammeObjectPolicy(subject, action, ressource);
        case Objects.Parametre:
            return ParametreObjectPolicy(subject, action, ressource);
        case Objects.ParametreSiteWeb:
            return ParametreSiteWebObjectPolicy(subject, action, ressource);
        case Objects.Utilisateur:
            return UtilisateurObjectPolicy(subject, action, ressource);
        case Objects.Document:
            return DocumentObjectPolicy(subject, action, ressource);
        default:
            return false;
    }
}