import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";

import { ClubApi } from 'api/ClubApi';

import { Required, SubmitButtonLoading } from 'components/Form/Form';
import { FormCoordAvecSiteWeb } from 'components/Form/FormCoordonnees';

export default _ => {
    const { id } = useParams();
    const history = useHistory();

    const [fields, setFields] = useState({});
    const [submitLoading, setSubmitLoading] = useState(false);

    useEffect(_ => {
        ClubApi
            .get(id)
            .then(res => setFields(res));
    }, [id]);

    const contactSubmit = e => {
        e.preventDefault();
        setSubmitLoading(true);

        ClubApi
            .update(fields)
            .then(_ => history.goBack())
            .finally(_ => setSubmitLoading(false))
    }

    return (<form onSubmit={e => contactSubmit(e)} className="m-2">
        <h1 className="display-4 mb-4">Modification de club</h1>
        <div className="row">
            <div className="col-lg-4">
                <label>Matricule<Required /></label>
                <input
                    className="form-control"
                    type="text"
                    minLength="3"
                    maxLength="100"
                    placeholder="Matricule du groupe"
                    onChange={e => setFields({ ...fields, "matricule": e.target.value })}
                    value={fields?.matricule || ""}
                    required
                />
            </div>
            <div className="col">
                <label>Nom du groupe<Required /></label>
                <input
                    className="form-control"
                    type="text"
                    minLength="3"
                    maxLength="100"
                    placeholder="Nom du groupe"
                    onChange={e => setFields({ ...fields, "name": e.target.value })}
                    value={fields?.name || ""}
                    required
                />
            </div>
        </div>
        <div className="row">
            <div className="col">
                <label>Compte Financier</label>
                <input
                    className="form-control"
                    type="text"
                    placeholder="Compte financier"
                    onChange={e => setFields({ ...fields, "cptFin": e.target.value })}
                    value={fields?.cptFin || ""}
                />
            </div>
        </div>
        <div className="row">
            <div className="col">
                <label>N° de compte (IBAN)</label>
                <input
                    className="form-control"
                    type="text"
                    pattern="[a-zA-Z]{2}[0-9, ]+"
                    placeholder="BEXX XXXX XXXX XXXX"
                    onChange={e => setFields({ ...fields, "bankNumber": e.target.value })}
                    value={fields?.bankNumber || ""}
                />
            </div>
            <div className="col-4">
                <label>Code BIC</label>
                <input
                    className="form-control"
                    type="text"
                    placeholder="code BIC de la banque"
                    onChange={e => setFields({ ...fields, "bankBicNumber": e.target.value })}
                    value={fields?.bankBicNumber || ""}
                />
            </div>
        </div>
        <div className="row">
            <div className="col">
                <label>Intitulé Compte Financier</label>
                <input
                    className="form-control"
                    type="text"
                    placeholder="Intitulé Compte Financier"
                    onChange={e => setFields({ ...fields, "cptFinLibelle": e.target.value })}
                    value={fields?.cptFinLibelle || ""}
                />
            </div>
            <div className="col">
                <label>N° d'entreprise</label>
                <input
                    className="form-control"
                    type="text"
                    placeholder="N° d'entreprise"
                    onChange={e => setFields({ ...fields, "numEnterprise": e.target.value })}
                    value={fields?.numEnterprise || ""}
                />
            </div>
        </div>
        <div className="row m-2">
            <div className="col">
                <div className="form-check">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        onChange={e => setFields({ ...fields, "isffbmp": e.target.checked })}
                        checked={fields?.isffbmp || ""}
                    />
                    <label>Groupe FFBMP</label>
                </div>
            </div>
        </div>
        <FormCoordAvecSiteWeb fields={fields?.coord ?? {}} setFields={newVal => setFields({ ...fields, "coord": newVal })} />
        <SubmitButtonLoading isLoading={submitLoading}/>
    </form>);
}
