import { Document, Font, Image, Page, PDFDownloadLink, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import MontserratBold from './Montserrat/Montserrat-Bold.ttf';
import MontserratLight from './Montserrat/Montserrat-Light.ttf';
import MontserratRegular from './Montserrat/Montserrat-Regular.ttf';
import MontserratThin from './Montserrat/Montserrat-Thin.ttf';

export default ({ children }) => {

    Font.register({ family: 'Montserrat', format: "truetype", src: MontserratThin, fontWeight: 'thin' });
    Font.register({ family: 'Montserrat', format: "truetype", src: MontserratLight, fontWeight: 'light' });
    Font.register({ family: 'Montserrat', format: "truetype", src: MontserratRegular, fontStyle: 'normal', fontWeight: 'normal' });
    Font.register({ family: 'Montserrat', format: "truetype", src: MontserratBold, fontWeight: 'bold' });

    return (<Document>
        <Page style={styles.body}>
            {children}
            <Text style={[styles.pageNumber, { fontSize: 8 }]} render={({ pageNumber, totalPages }) => (
                `Page: ${pageNumber} / ${totalPages} | Le: ${new Date().toLocaleDateString()}`
            )} fixed />
        </Page>
    </Document>);
};

export const styleTable = StyleSheet.create({
    table: {
        display: "table",
        width: "auto",
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    row: {
        // margin: "auto",
        flexDirection: "row",
        padding: 0,
        margin: 0,
    },
    cell: {
        margin: "auto",
        height: '100%',
        padding: 3,
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    cellTextHeader: {
        marginVertical: 2,
        fontSize: 10,
        textAlign: 'center',
        fontWeight: 'bold'
    },
    cellText: {
        marginVertical: 2,
        fontSize: 8,
        textAlign: 'left',
    }
});

export const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    title: {
        fontSize: 20,
        textAlign: 'center',
        fontFamily: 'Times-Roman'
    },
    author: {
        fontSize: 12,
        textAlign: 'center',
        marginBottom: 40,
    },
    subtitle: {
        fontSize: 20,
        marginTop: 0,
        marginBottom: 5,
        textAlign: 'left',
        fontFamily: 'Montserrat'
    },
    text: {
        margin: 12,
        fontSize: 14,
        textAlign: 'justify',
        fontFamily: 'Times-Roman'
    },
    image: {
        marginVertical: 15,
        marginHorizontal: 100,
    },
    header: {
        fontSize: 12,
        marginBottom: 20,
        textAlign: 'center',
        color: 'grey',
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 15,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    table2: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderColor: '#aa0000',
        borderWidth: 1,
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row",
        padding: 0
    },
    tableColHeader: {
        width: "25%",
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderBottomColor: '#000',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCol12: {
        width: "100%",
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCol8: {
        width: "66%",
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCol6: {
        width: "50%",
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCol4: {
        width: "33%",
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCol3: {
        width: "25%",
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCol2: {
        width: "16%",
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCol: {
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    col2: { display: "flex", flexDirection: "row", width: "12.5%" },
    col3: { display: "flex", flexDirection: "row", width: "25%" },
    col4: { display: "flex", flexDirection: "row", width: "33.33%" },
    col6: { display: "flex", flexDirection: "row", width: "50%" },
    col9: { display: "flex", flexDirection: "row", width: "66.66%" },
    col12: { display: "flex", flexDirection: "row", width: "100%" },
    tableCellHeader: {
        margin: 5,
        fontSize: 12,
        fontFamily: 'Montserrat',
        weight: 400
    },
    tableCell: {
        margin: 5,
        fontSize: 10,
        fontFamily: 'Montserrat',
        fontWeight: 'thin'
    },
    cell: {
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderWidth: 1,
    },
    img: {
        width: "100%",
        height: "auto"
    }
});

export const Table = ({ data }) => {
    return (<View style={styles.table}>
        {data?.map((e, i) => <View style={styles.tableRow} key={i}>
            <View style={styles.tableCol4}>
                <Text style={styles.tableCellHeader}>{e.libelle ?? ""}</Text>
            </View>
            <View style={styles.tableCol8}>
                <Text style={styles.tableCell}>{e.data ?? ""}</Text>
            </View>
        </View>)}
    </View>);
}

export const Title = ({ children }) => <Text style={styles.title}>{children}</Text>;
export const SubTitle = ({ children }) => <Text style={styles.subtitle}>{children}</Text>;
export { View, Text, Image, PDFDownloadLink, StyleSheet };

export const Table2 = ({ children }) => <View style={styleTable.table}>{children}</View>;
export const Row = ({ children }) => <View style={styleTable.row} wrap={false}>{children}</View>;
export const Cell = ({ style, children }) => <View style={[style, styleTable.cell]}><Text style={styleTable.cellText}>{children}</Text></View>;
export const CellHeader = ({ style, children }) => <View style={[style, styleTable.cell]}><Text style={styleTable.cellTextHeader}>{children}</Text></View>;
