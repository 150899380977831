import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import FormatDate from 'lib/FormatDate';

export default ({ assurances, isLoading, handleSearch, handleClickAssurance, initBeginSearch, initEndSearch, handleNouvAss }) => {

    return (<>
        <div className="row mb-4">
            <div className="col m-auto">
                <h3 className="h3">Demandes d'assurance</h3>
            </div>
            <div className="col-auto m-auto">
                <RechercheAssurance
                    handleSearch={handleSearch}
                    initBeginSearch={initBeginSearch}
                    initEndSearch={initEndSearch}
                />
            </div>
            <div className="col-auto m-auto">
                <ModalTMP handleNouvAss={handleNouvAss} />
            </div>
        </div>
        {isLoading ? <LoadingSpinner /> : <ListAssurances demandes={assurances} handleClickAssurance={handleClickAssurance} />}
    </>);
}

const LoadingSpinner = _ => {
    return (<div className="d-flex text-primary justify-content-center">
        <div className="spinner-border" role="status" style={{ width: "3rem", height: "3rem" }}>
            <span className="sr-only">Loading...</span>
        </div>
    </div>);
}

const RechercheAssurance = ({ initBeginSearch, initEndSearch, handleSearch }) => {
    const [beginSearch, setBeginSearch] = useState(initBeginSearch);
    const [endSearch, setEndSearch] = useState(initEndSearch);

    const handleSubmit = e => {
        e.preventDefault();
        handleSearch(beginSearch, endSearch);
    }

    return (<form onSubmit={handleSubmit}>
        <div className="row">
            <div className="col-auto my-auto">
                <span>Assurances du: </span>
            </div>
            <div className="col-auto">
                <input
                    className="form-control"
                    type="date"
                    onChange={e => setBeginSearch(e.target.value)}
                    value={beginSearch}
                />
            </div>
            <div className="col-auto my-auto p-1">
                <span>au</span>
            </div>
            <div className="col-auto">
                <input
                    className="form-control"
                    type="date"
                    min={beginSearch}
                    onChange={e => setEndSearch(e.target.value)}
                    value={endSearch}
                />
            </div>
            <div className="col-auto mr-0">
                <button type="submit" className="btn btn-primary" >
                    Rechercher
                    <i className="fa-solid fa-magnifying-glass ml-2"></i>
                </button>
            </div>
        </div>
    </form>);
}

const ListAssurances = ({ demandes, handleClickAssurance }) => {

    const statusIcon = status => {
        switch (status) {
            case 1:
                return (<i className="fas fa-fw fa-clock text-secondary" data-toggle="tooltip" title="Demande en attente" />);
            case 2:
                return (<i className="fas fa-fw fa-check-circle text-success" data-toggle="tooltip" title="En Ordre" />);
            case 3:
                return (<i className="fas fa-fw fa-times-circle text-danger" data-toggle="tooltip" title="Demande Refusée" />);
            default:
                    return <p>ERREUR!!</p>
        }
    }

    const statusLine = status => {
        switch (status) {
            case 3:
                return "table-danger";
            default:
                return "";
        }
    }

    return (<div className="row">
        <div className="col">
            <div className="card">
                <div className="table-responsive-xl">
                    <table className="table table-hover">
                        <thead className="thead-light">
                            <tr>
                                <th scope="col" className="text-dark">N°</th>
                                <th scope="col" className="text-dark">Type</th>
                                <th scope="col" className="text-dark">Etat</th>
                                <th scope="col" className="text-dark">Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {demandes?.map(d => (<tr key={d.id} onClick={_ => handleClickAssurance(d.id)} className={statusLine(d?.status)} >
                                <th>{d.numeroDemande}</th>
                                <td>{`${d.roiTitre} - ${d.roiTitreDemande}`}</td>
                                <td>{statusIcon(d?.status)}</td>
                                <td>{FormatDate(d.date)}</td>

                            </tr>))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>);
}

const ModalTMP = ({ handleNouvAss }) => {
    const [isNewModalVisible, setIsNewModalVisible] = useState(false);

    return (<>

        <button type="button" className="btn btn-primary" onClick={_ => setIsNewModalVisible(true)}>
            Nouvelle demande <i className="fas fa-fw fa-plus"></i>
        </button>

        <Modal isOpen={isNewModalVisible} toggle={_ => setIsNewModalVisible(false)}>
            <ModalHeader toggle={_ => setIsNewModalVisible(false)}>Sélectionner le type de demande d'assurance</ModalHeader>
            <ModalBody>
                <div className="p-2 m-auto">
                    <button className="btn btn-primary" onClick={_ => handleNouvAss('1400')}>
                        {"ROI ASS 140 > Dégâts Vehicules"}
                    </button>
                </div>
                
                <div className="p-2 m-auto">
                    <button className="btn btn-primary" onClick={_ => handleNouvAss('1430')}>
                        {"ROI ASS 143 > Extension Assurances marcheurs"}
                    </button>
                </div>

                <div className="p-2 m-auto">
                    < button className="btn btn-primary" onClick={_ => handleNouvAss('1431')}>
                        {"ROI ASS 143Bis > Annexe au ROI ASS 143"}
                    </button>
                </div>

                <div className="p-2 m-auto">
                    < button className="btn btn-primary" onClick={_ => handleNouvAss('1440')}>
                        {"ROI ASS 144 > Incendie 'Bâtiment Privé' "}
                    </button>
                </div>
                <div className="p-2 m-auto">
                    <button className="btn btn-primary" onClick={_ => handleNouvAss('1470')}>
                        {"ROI ASS 147 > Dénicheur de parcours"}
                    </button>
                </div>
                <div className="p-2 m-auto">
                    <button className="btn btn-primary" onClick={_ => handleNouvAss('2010')}>
                        {"ROI ASS 201 > Incapacité temporaire"}
                    </button>
                </div>
            </ModalBody>
        </Modal>
    </>);
}