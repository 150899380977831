import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { PDFDownloadLink } from '@react-pdf/renderer';

import { ClubApi } from 'api/ClubApi';

import { FormSelect } from 'components/Form/Form';
import { LoadingButton } from 'components/0components/Spinner';
import Button from 'components/0components/Button';
import AffiliationsClubLayout from './AffiliationsClubLayout';
import { yearsListBetween } from '../../lib/utils';
import useFetcher from '../../services/useFetcher';
import createTokenProvider from '../../services/createTokenProvider';

export default ({ id }) => {
    const [isVisible, setIsVisible] = useState(false);

    return (<div onClick={_ => setIsVisible(true)} data-toggle="tooltip" title="Exporter les Membres">
        <Button>
            <i className="fa-solid fa-file-export" />
        </Button>

        {isVisible && <MyModal
            grpId={id}
            isVisible={isVisible}
            setIsVisible={val => setIsVisible(val)} />}
    </div>);
}

const MyModal = ({ grpId, isVisible, setIsVisible }) => {
    const annees = yearsListBetween(1979, new Date().getFullYear() + 1);

    const { state: club } = useFetcher(ClubApi.get(grpId));

    const [isLoading, setIsLoading] = useState(false);//dataloading 
    const [actif, setActif] = useState(true);// membres actifs
    const [data, setData] = useState(); //données des membres
    const [annee, setAnnee] = useState(new Date().getFullYear()); //année de recherche

    const fetchData = (grpId, annee, actif) => {
        setIsLoading(true);
        ClubApi
            .getMembres(grpId, annee, actif)
            .then(res => setData(res))
            .finally(_ => setIsLoading(false))
    }

    useEffect(_ => setData(), [annee]) 

    const handleClickLoadData = _ => {
        fetchData(grpId, annee, actif)
    }

    return (<Modal isOpen={isVisible} toggle={_ =>setIsVisible(!isVisible)}>
        <ModalHeader toggle={_=>setIsVisible(!isVisible)}>PDF - Affiliés</ModalHeader>
        <ModalBody>
            <FormSelect label="Année" required value={annee} handleChange={setAnnee}>
                {annees.map(e => <option value={e} key={e}>{e}</option>)}
            </FormSelect>
            <p>Afficher uniquement les membres actifs:  <input type="checkbox" checked={actif} onChange={e => setActif(e.target.checked)} /></p>

            {!data && !isLoading && <Button onClick={handleClickLoadData} icon="fa-file-pdf">Charger les données</Button>}

            {isLoading && <LoadingButton>Chargement</LoadingButton>}

            {!isLoading && club && data && (<PDFDownloadLink document={<AffiliationsClubLayout club={club} data={data} actif={actif} annee={annee}></AffiliationsClubLayout>} fileName={`${club?.matricule ?? ""} ${actif ? "Affiliations en ordre de cotisation" : "Affiliations"} ${annee}.pdf`}>
                {({ loading }) => (loading ? 'Génération du document PDF ...' : <Button icon="fa-file-pdf" onClick={_ => {setIsVisible(false); } }>Télécharger PDF</Button>)}
            </PDFDownloadLink>)}

            <Button className="mt-3" icon="fa-file-excel" onClick={_ => { window.open(`/api/clubs/${grpId}/members/xlsx?year=${annee}&actifOnly=${actif}&jwt=${createTokenProvider().getToken()}`, '_blank'); setIsVisible(false); }}>Télécharger Excel</Button>

        </ModalBody>
    </Modal>);
}