import React from 'react';
import { useHistory } from 'react-router-dom';
import Table, { TableElem, TableElemDate } from 'components/0components/Table';
import Spinner from 'components/0components/Spinner';

export default ({ass, isLoading}) => {
    const history = useHistory();

    const handleClickAssurance = (assurance) => history.push(`/assurances/${assurance.id}`);

    if (isLoading)
        return (<Spinner />);

    return (<div className="Row">
        <h3 className="h3 mb-4">{`Assurance${(ass?.length > 1 ? "s" : "")} en attente`}</h3>
        <Table titles={["#", "Type", "Groupe", "Initiateur", "Date"]} clickable>
            {ass?.map((e, i) => (<tr key={i} onClick={_=>handleClickAssurance(e)}>
                <TableElem>{e.numeroDemande}</TableElem>
                <TableElem>{e.roiTitre}</TableElem>
                <TableElem>{`${e.groupMatricule}-${e.groupNom}`}</TableElem>
                <TableElem>{`${e.initiateurNom} ${e.initiateurPrenom}`}</TableElem>
                <TableElemDate>{e.date}</TableElemDate>
            </tr>))}
        </Table>
    </div>);
}