import React from 'react';
import { SubmitButtonLoading } from 'components/Form/Form';

export default (login, setLogin, password, setPassword, submit, submitLoading, error, setError) => {

    const Alert = ({ message, setMessage }) => {
        if (!message)
            return <></>;

        return (<div className="alert alert-danger alert-dismissible fade show m-3" role="alert">
            {message?.status && <h4 className="alert-heading">{`${message?.status ?? "Error"} : ${message?.title ?? ""}`}</h4>}
            {!message?.status ? <p>{message}</p> :
                <>
                    {message?.detail && <p>{message.detail}</p>}
                    {message?.errors && <p>{Object.keys(message.errors).map(key => <p className="mb-0"> {`${key}: ${message.errors[key]}`}</p>)}</p>}
                </>}
            <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={_ => setMessage("")}>
                <span aria-hidden="true">&times;</span>
            </button>
        </div>);
    }

    return (<div className="bg-gradient-primary mh100" style={{ "minHeight": "100vh" }}>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-10 col-lg-12 col-md-9">
                    <Alert message={error} setMessage={setError} />
                    <div className="card o-hidden border-0 shadow-lg my-5">
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-lg-6 d-none d-lg-block bg-login-image"></div>
                                <div className="col-lg-6">
                                    <div className="p-5">
                                        <div className="text-center">
                                            <h1 className="h4 text-gray-900 mb-4">Bonjour!</h1>
                                        </div>
                                        <form className="user" onSubmit={e => submit(e)}>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control form-control-user"
                                                    id="exampleInputEmail"
                                                    aria-describedby="emailHelp"
                                                    placeholder="Login"
                                                    name="login"
                                                    value={login}
                                                    onChange={e => setLogin(e.target.value)}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="password"
                                                    className="form-control form-control-user"
                                                    id="exampleInputPassword"
                                                    placeholder="Password"
                                                    name="password"
                                                    value={password}
                                                    onChange={e => setPassword(e.target.value)}
                                                    required
                                                />
                                            </div>
                                            <SubmitButtonLoading submitLoading={submitLoading} />
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>);
}