import React from 'react';

export default ({message, dismiss, color}) => {
    if(message)
        return (<div className={`alert alert-${color ?? "danger"} ${dismiss && "alert-dismissible"} fade show m-3`} role="alert">
            <h4 className="alert-heading">{`${message?.title}`}</h4>
            {message?.detail && <p>{message.detail}</p>}
            <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={dismiss}>
                <span aria-hidden="true">&times;</span>
            </button>
        </div>);
    else
        return <></>;
}