import { Auth } from "components/Form/Form";

const url = '/api/access';

export const AccesApi = {
    allRoles: () => {
        return fetch(`${url}`, Auth.options('GET'))
            .then(res => res.json());
    },
    get: (userId) => {
        return fetch(`${url}/${userId}`, Auth.options('GET'))
            .then(res => res.json())
    },
    create: (userId, roleId, groupId, startDate, endDate) => {
        return fetch(`${url}`, Auth.options('POST', {
            'userId': userId,
            'roleId': roleId,
            'groupId': groupId,
            'startDate': startDate,
            'endDate': endDate,
        }))
    },
    delete: (id) => {
        return fetch(`${url}/${id}`, Auth.options('DELETE'))
    },
}