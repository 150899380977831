import { Auth } from "components/Form/Form";

const url = '/api/v2/clubs';

export const ClubApiV2 = {
    activerClub: (clubId) => fetch(`${url}/${clubId}/activate`, Auth.options('PATCH')),
    desactiverClub: (clubId) => fetch(`${url}/${clubId}/disactivate`, Auth.options('PATCH')),
    creerClub: (data) => {
        return fetch(url, Auth.options('POST', data))
            .then(res => {
                if (res.status === 400)
                    throw res;
            });
    }
};