import { Auth } from "components/Form/Form";

const url = '/api/v2/provinces';

export const ProvinceApiV2 = {
    /// id : identifiant de la province
    /// validOnly : seulement récupérer les clubs valides
    getClubs: (id, validOnly) => {
        return fetch(`${url}/${id}/clubs?validOnly=${validOnly}`, Auth.options('GET'))
            .then(res => res.json());
    }
}