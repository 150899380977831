import React, { useState } from 'react';

import { OrganigrammeApi } from 'api/OrganigrammeApi';

import CreateOrgaModal from 'components/Orga/CreateOrgaModal';
import { AddIcon } from 'components/Icons';

export default ({ id, reload }) => {
    const [isVisible, setIsVisible] = useState(false);

    const handleClickNewButton = _ => setIsVisible(true);

    const submit = (e, affId, posteId, startDate, endDate)  => {
        e.preventDefault();

        OrganigrammeApi
            .create(id, affId, posteId, startDate, endDate)
            .then(reload)
            .then(_ => setIsVisible(false))
            .catch(err => console.error(err));
    };

    return (<>
        <button onClick={_ => handleClickNewButton()} className="btn btn-primary mb-3">Ajouter <AddIcon/> </button>
        {isVisible && <CreateOrgaModal
            title="Nouveau"
            groupId={id}
            submit={submit}
            isVisible={isVisible}
            setIsVisible={setIsVisible}
        />}
    </>);
}