import React from 'react';

const Errors = ({ errors }) => {

    if (!errors)
        return null;

    if (Array.isArray(errors)) {
        return (<ul>
            {errors.map(err => (<li key={err} className="text-danger"><small>{err}</small></li>))}
        </ul>);
    }
    else {
        return <small className="text-danger">{errors}</small>;
    }

}

export default Errors;