import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { UtilisateurApi } from '../api/UtilisateursApi';
import { HierarchieClubsApi } from '../api/HierarchieClubsApi';

const UserContext = React.createContext();

export const useUserData = _ => useContext(UserContext);

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory();

    const fetchUser = () => {
        return UtilisateurApi.me()
            .then(res => setUser(res))
            .catch(_ => history.push('/login'));
    }

    const fetchHierarchieClubs = _ => {
        return HierarchieClubsApi
            .all()
            .then(res => { window.clubtree = res; })//je sais utiliser window pour des variables globales c'est pas bien mais je n'avais que ça 
    }

    function InitalLoading()
    {
        Promise
        .all([
            fetchUser(), 
            fetchHierarchieClubs()
        ])
        .finally(_ => setIsLoading(false));
    }

    useEffect(InitalLoading, []);

    if (isLoading)
        return <p>Chargement du profil utilisateur</p>;

    return (<UserContext.Provider value={[user,setUser]}>
        { children }
    </UserContext.Provider>);
}
