import { useState, useEffect } from 'react';
import { Auth } from 'components/Form/Form';

export default _ => {
    const [state, setState] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState();

    const controller = new AbortController();

    const options = (method, data) => {
        return {
            method: method,
            headers: {
                'signal': controller.signal,
                'Content-Type': "application/json",
                'Authorization': 'Bearer ' + Auth.token()
            },
            body: JSON.stringify(data)
        };
    }

    function load () {
        setIsLoading(true);
        setError();
        fetch(`api/notifications`, options('GET'))
            .then(res => res.json())
            .then(res => setState(res))
            .catch(err => setError(err))
            .finally(_ => setIsLoading(false));

        return(_ => controller.abort())
    }

    function markAsRead(id) {
        return fetch(`api/notifications/${id}/read`, options('PATCH'))
            .then(load)
            .catch(err => console.error(err));
    }

    function deleteNotification(id) {
        return fetch(`api/notifications/${id}`, options('DELETE'))
            .then(load)
            .catch(err => console.error(err));
    }

    function AutoReload() {
        const interval = setInterval(load, 10000);
        return _ => clearInterval(interval);
    }

    useEffect(AutoReload, []);
    useEffect(load, []);

    return { notifications:state, isLoading, reload: load, error, markAsRead, deleteNotification };
}