import React from 'react';

const SelecteurAnneeCotisation = ({ reaffiliationYear, setReaffiliationYear }) => {

    const curYear = new Date().getFullYear();
    const nextYear = curYear + 1;

    return (<>
        <span className="ml-3">Année de cotisation:</span>
        <div className="btn-group col-md-auto pr-0">
            <button type="button" className={`btn ${reaffiliationYear === curYear ? "btn-primary" : "btn-secondary"}`} onClick={_ => setReaffiliationYear(curYear)}>{curYear}</button>
            <button type="button" className={`btn ${reaffiliationYear === nextYear ? "btn-primary" : "btn-secondary"}`} onClick={_ => setReaffiliationYear(nextYear)}>{nextYear}</button>
        </div>
    </>);
}

export default SelecteurAnneeCotisation;