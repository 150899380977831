import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import ErrorHandler from 'lib/ErrorHandler';
import { FormatDateInput } from 'lib/FormatDate';
import Auth from 'services/createAuthProvider';

export default ({ title, submit, submitLoading, children }) => {
    return (<div>
        <Row><Title>{title}</Title></Row>
        <form onSubmit={e=> submit(e)}>
            {children}
            <SubmitButtonLoading isLoading={submitLoading} />
        </form>
    </div>);
}

export { ErrorHandler, Auth };
export const Required = _ => <b className="text-danger"> *</b>;
export const Label = ({ label, required }) => label ? <label>{ label }{ required ? <Required /> : "" }</label> : "";
export const Title = ({ children }) => <h1 className="m-3">{ children }</h1>;
export const SubTitle = ({ children }) => <h3 className="m-3">{ children }</h3>;
export const Row = ({ children }) => <div className="row m-2">{ children }</div>;

export const FormInput = ({ required, label, type, value, handleChange, ...rest }) => {
    return (<div className="m-2">
        {label ? <Label required={required || false} label={label} /> : ""}
        <input
            className="form-control mr-sm-2"
            type={type ?? "text"}
            onChange={e => handleChange(e.target.value)}
            value={value ?? ""}
            required={required || false}
            {...rest}
        />
    </div>);
}

export const FormInput2 = ({ required, label, value, onChange, ...rest }) => {
    return (<>
        {label && <Label required={required || false} label={label} />}
        <input className="form-control mr-sm-2" {...rest} value={value ?? ""} onChange={e => { e.preventDefault(); onChange(e.target.value); }}/>
    </>);
}

export const FormInputDate = ({ required, label, value, handleChange, ...rest }) => {
    return (<div className="m-2">
        {label ? <Label required={required || false} label={label} /> : ""}
        <input
            className="form-control mr-sm-2"
            type="date"
            onChange={e => handleChange(e.target.value)}
            value={value ? FormatDateInput(value) : ""}
            required={required || false}
            {...rest}
        />
    </div>);
}

export const FormInputDate2 = ({ required, label, value, onChange, ...rest }) => {
    return (<div className="m-2">
        {label ? <Label required={required ?? false} label={label} /> : ""}
        <input className="form-control" type="date" onChange={e => onChange(e.target.value)} value={value ? value.split('T')[0] : ""} {...rest} required={required ?? false} />
    </div>);
}

export const FormSelect2 = ({label, required, data, onChange, value, ...rest}) => {
    return (<div className="m-2">
        {label ? <Label required={required ?? false} label={label} /> : ""}
        <select className="custom-select" onChange={e => onChange(e.target.value)} value={value || ""} required={required ?? false} {...rest}>
            <option value="">Sélectionner une option</option>
            {data.map(elem => <option key={elem.id} value={elem.id}>{`${elem.libelle}`}</option>)}
        </select>
    </div>);
}

export const FormSelect = ({ required, label, value, handleChange, disabled, children }) => {
    return (<div className="m-2">
        <Label required={required || false} label={label} />
        <select
            className="custom-select mr-sm-2"
            onChange={e => handleChange(e.target.value)}
            value={value ?? ""}
            required={required || false}
            disabled={disabled || false}
        >
            {children}
        </select>
    </div>);
}

export const SubmitButtonLoading = ({ isLoading }) => {
    if (isLoading) {
        return (<button className="btn btn-primary m-3" type="button" disabled>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" /><span className="sr-only">Chargement...</span>
        </button >);
    } else {
        return (<button type="submit" className="btn btn-primary m-3">Confirmer <i className="fas fa-sm fa-check" /></button>);
    }
}

export const HandleAffichage = (val, type) => {
    switch (type){
        case "date":
            return new Date(val).toLocaleDateString();
        default:
            return val;
    }
}

export const Table = ({ format, data }) => {
    return (<div className="table-responsive-xl">
        <table className="table table-striped">
            <thead className="thead-light">
                <tr>
                    {format?.map(f => <th scope="col" key={f.name}>{f.name}</th>)}
                </tr>
            </thead>
            <tbody>
                {data && data?.map((v, i) => (<tr key={v.id}>
                    {format?.map(f => <td key={f.id}>
                        {HandleAffichage(v[f.id], f.type)}
                    </td>)}
                </tr>))}
            </tbody>
        </table>
    </div>);
}

export const TableEdit = ({ format, data, setData }) => {
    const [isModal, setIsModal] = useState(false);

    const removeItem = (e, index) => {
        e.stopPropagation();
        e.preventDefault();
        let tmp = data;
        tmp.splice(index, 1);
        setData(tmp);
    }

    const handleAdd = val => {
        let tmp = data ? [...data] : [];
        tmp.push(val);
        setData(tmp);
    }

    return (<div>
        <ModalCreate
            title="Nouveau"
            isVisible={isModal}
            setIsVisible={val => setIsModal(val)}
            handleAdd={newVal => handleAdd(newVal)}
            format={format}
        />

        <div className="card" >
            <div className="table-responsive-xl">
                <table className="table table-striped">
                    <thead className="thead-light">
                        <tr>
                            {format?.map((f, i) => <th scope="col" key={i}>{f.name}</th>)}
                            <th scope="col" onClick={_ => setIsModal(true)}>
                                <button type="button" className="btn btn-primary"><i className="fas fa-xs fa-plus mt-2 mb-2 ml-1 mr-1" title="Ajouter" /></button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data && data?.map((v,i) => (
                            <tr key={i}>
                                {format?.map((f,i) => <td key={i}>{HandleAffichage(v[f.id], f.type)}</td>)}
                                <td onClick={e => removeItem(e, i)}>
                                    <button type="button" className="btn btn-danger">
                                        <i className="fas fa-xs fa-trash mt-2 mb-2 ml-1 mr-1" title="Supprimer" />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    </div>);
}

export const TableSelect = ({ format, data, setData, options }) => {
    const [isModal, setIsModal] = useState(false);

    const removeItem = (e, index) => {
        e.stopPropagation();
        e.preventDefault();
        let tmp = data;
        tmp.splice(index, 1);
        setData(tmp);
    }

    const handleAdd = val => {
        let tmp = data ?? [];
        tmp.push(val);
        setData(tmp);
    }

    return (<>
        <ModalCreateOne
            title="Nouveau"
            isVisible={isModal}
            setIsVisible={val => setIsModal(val)}
            handleAdd={newVal => handleAdd(options.find(e => e.id === newVal).data)}
            type="select"
            options={options}
        />
        <div className="table-responsive-xl">
            <table className="table table-striped">
                <thead className="thead-primary">
                    <tr>
                        {format?.map(f => <th scope="col" key={f.name}>{f.name}</th>)}
                        <th scope="col" onClick={_ => setIsModal(true)}>
                            <button type="button" className="btn btn-primary">
                                <i className="fas fa-xs fa-plus mt-2 mb-2 ml-1 mr-1" title="Ajouter" />
                            </button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data?.map((v, i) => (
                        <tr key={v.id}>
                            {format?.map(f => <td key={f.id}>{HandleAffichage(v[f.id], f.type)}</td>)}
                            <td onClick={e => removeItem(e, i)}>
                                <button type="button" className="btn btn-danger">
                                    <i className="fas fa-xs fa-trash mt-2 mb-2 ml-1 mr-1" title="Supprimer" />
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    </>);
}

export const MyModal = ({title, isVisible, setIsVisible, children }) => {
    return (<Modal isOpen={isVisible} toggle={_ => setIsVisible(false)}>
        {title && <ModalHeader toggle={_ => setIsVisible(false)}>{title}</ModalHeader>}
        <ModalBody>
            {children}
        </ModalBody>
    </Modal>);
};

export const ModalCreate = ({ handleAdd, title, format, isVisible, setIsVisible }) => {
    const [data, setData] = useState({});

    const Update = (key, val) => {
        let tmp = data
        tmp[key] = val;
        setData(tmp);
    }

    const inputText = (key, placeholder, maxlength) => {
        return (<input
            key={key}
            className="form-control mr-sm-2 mb-2"
            type="text"
            placeholder={placeholder ?? ""}
            maxLength={maxlength ?? 200}
            onChange={e => Update(key, e.target.value ?? "")}
            value={data[key]}
            required
        />);
    }

    const inputDate = key => {
        return (<input
            key={key}
            className="form-control mr-sm-2 mb-2"
            type="date"
            onChange={e => Update(key, e.target.value)}
            value={data[key]}
            required
        />);
    }

    const inputSelect = (key,options) => {
        return (<div key={key} className="m-2">
            <select className="custom-select mr-sm-2" onChange={e => Update(key, e.target.value)} value={data[key] ?? ""} required>
                {options?.map(elem => <option key={elem.id} value={elem.id}>{elem.libelle}</option>)}
            </select>
        </div>);
    }

    const renderInput = ({ id, type, name, options, maxlength }) => {
        switch (type) {
            case "date":
                return inputDate(id);
            case "select":
                return inputSelect(id, options);
            default:
                return inputText(id, name, maxlength);
        }
    }

    return (<Modal isOpen={isVisible} toggle={_ => setIsVisible(false)}>
        <ModalHeader toggle={_ => setIsVisible(false)}>{title}</ModalHeader>
        <ModalBody>
            {format.map(e => renderInput(e))}
            <button className="btn btn-primary float-right mt-2" onClick={_ => { handleAdd(data); setIsVisible(false); setData({}); }}>Ajouter</button>
        </ModalBody>
    </Modal>);
};

export const ModalCreateOne = ({ title, handleAdd, type, placeholder, options, isVisible, setIsVisible }) => {
    const [data, setData] = useState("");

    const renderInput = (type, placeholder) => {
        switch (type) {
            case "date":
                return (<input className="form-control mr-sm-2 mb-2" type="date" onChange={e => setData(e.target.value)} value={data} required />);
            case "select":
                return (<div className="m-2">
                    <select className="custom-select mr-sm-2" onChange={e => setData(e.target.value)} value={data} required>
                        <option value="">Sélectionner une option</option>
                        {options?.map(elem => <option key={elem.id} value={elem.id}>{elem.libelle}</option>)}
                    </select>
                </div>);
            default:
                return (<input className="form-control mr-sm-2 mb-2" type="text" placeholder={placeholder ?? ""} onChange={e => setData(e.target.value)} value={data} required />);
        }
    }

    return (<Modal isOpen={isVisible} toggle={_ => setIsVisible(false)}>
        <ModalHeader toggle={_ => setIsVisible(false)}>{title}</ModalHeader>
        <ModalBody>
            {renderInput(type)}
            <button className="btn btn-primary float-right mt-2" onClick={_ => { handleAdd(data); setIsVisible(false); setData({}); }}>Ajouter</button>
        </ModalBody>
    </Modal>);
};

export const DynamicList = ({ title, data, setData, type }) => {

    const [isModal, setIsModal] = useState(false);

    const handleRemove = (e, index) => {
        e.stopPropagation();
        e.preventDefault();
        let tmp = data;
        tmp.splice(index, 1);
        setData(tmp);
    }

    const handleAdd = val => {
        let tmp = data ?? [];
        tmp.push(val);
        setData(tmp);
    }

    return (<>
        <ModalCreateOne
            title="Nouveau"
            type={type}
            isVisible={isModal}
            setIsVisible={val => setIsModal(val)}
            handleAdd={newVal => handleAdd(newVal)}
        />
        <div className="card" >
            <div className="card-header bg-light text-primary">
                {title}
                <button onClick={e => { e.stopPropagation(); e.preventDefault(); setIsModal(true); }} className="btn btn-primary ml-auto">+</button>
            </div>
            <ul className="list-group list-group-flush">
                {data?.length > 0 ? data.map((elem, i) => <li key={i} className="list-group-item d-flex justify-content-between align-items-center">{HandleAffichage(elem, type)} <div className="justify-content-end d-flex"><button onClick={e => handleRemove(e, i)} className="btn btn-danger mr-auto">-</button></div></li>) : <li className="list-group-item">Vide</li>}
            </ul>
        </div>
    </>);
}

export const Elem = (title, elem) => <p><b>{title}: </b>{elem ?? "/"}</p>;
export const ElemDate = (title, elem) => <p><b>{title}: </b>{elem ? new Date(elem).toLocaleDateString() : "/"}</p>
export const ElemList = (title, elem) => <div><b>{title}: </b><ul>{elem?.map((e,i) => <li key={i}>{e}</li>) ?? ""}</ul></div>;