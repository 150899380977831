import React from 'react';
import { useHistory } from 'react-router-dom';
import './CardHover.css';
import useAcces from 'services/AccessControl/useAcces';

export default _ => {
    const hasPerm = useAcces();

    return (<>
        <h3 className="h3 mb-4">Provinces</h3>
        <div className="row mb-4">
            <Card link="/provinces/dfc8be92-8cb2-11de-a173-001372568c9a" img="img/BBW.svg" caption= "Bruxelles - Brabant Wallon" alt="Carte belgique avec les provinces du brabant wallon et bruxelles mis en avant"/>
            <Card link="/provinces/dfc8c0fe-8cb2-11de-a173-001372568c9a" img="img/HT.svg" caption="Hainaut" alt="Carte belgique avec la province du hainaut mis en avant" />
            <Card link="/provinces/dfc8c284-8cb2-11de-a173-001372568c9a" img="img/LG.svg" caption="Liège" alt="Carte belgique avec la province de liège mis en avant" />
            <Card link="/provinces/dfc8c3f6-8cb2-11de-a173-001372568c9a" img="img/LUX.svg" caption="Luxembourg" alt="Carte belgique avec la province du luxembourg mis en avant"/>
            <Card link="/provinces/dfc8c554-8cb2-11de-a173-001372568c9a" img="img/NA.svg" caption="Namur" alt="Carte belgique avec la province de namur mis en avant" />
        </div>
        
        {hasPerm("AdminOnly", "this TEXT Is A Place Holder", "oops") &&
            <div>
                <h3 className="h3 mb-4">Comités</h3>
                <div className="row">
                    <Card2 link="/clubs/674394b0-59c0-102d-93fa-002219533737/organigramme" img="img/users.svg" caption="Commission Féminine" alt="" />
                    <Card2 link="/clubs/88d3823e-5a1a-102d-93fa-002219533737/organigramme" img="img/users.svg" caption="Commission Règlements" alt="" />
                    <Card2 link="/clubs/9f7e1a9e-5a1a-102d-93fa-002219533737/organigramme" img="img/users.svg" caption="Conseil d'Appel" alt="" />
                    <Card2 link="/clubs/b8f2d186-5a1a-102d-93fa-002219533737/organigramme" img="img/users.svg" caption="Conseil de Discipline" alt="" />
                    <Card2 link="/clubs/dba42f14-59bf-102d-93fa-002219533737/organigramme" img="img/users.svg" caption="Bureau" alt="" />
                </div>

                <h3 className="h3 mb-4">PERMA</h3>
                <div className="row">
                    <Card link="/provinces/792ec0d4-7036-1030-9a9f-001d096850d6/organigramme" img="img/BBW.svg" caption="PERMA - Bruxelles - Brabant Wallon" alt="Carte belgique avec les provinces du brabant wallon et bruxelles mis en avant" />
                    <Card link="/provinces/81f88bb4-7036-1030-9a9f-001d096850d6/organigramme" img="img/HT.svg" caption="PERMA - Hainaut" alt="Carte belgique avec la province du hainaut mis en avant" />
                    <Card link="/provinces/8d030796-7036-1030-9a9f-001d096850d6/organigramme" img="img/LG.svg" caption="PERMA - Liège" alt="Carte belgique avec la province de liège mis en avant" />
                    <Card link="/provinces/98e3418e-7036-1030-9a9f-001d096850d6/organigramme" img="img/LUX.svg" caption="PERMA - Luxembourg" alt="Carte belgique avec la province du luxembourg mis en avant" />
                    <Card link="/provinces/a23cbb2a-7036-1030-9a9f-001d096850d6/organigramme" img="img/NA.svg" caption="PERMA - Namur" alt="Carte belgique avec la province de namur mis en avant" />
                </div>
            </div>}
    </>);
}

const Card = ({ link, img, caption, alt }) => {
    const history = useHistory();
    return (<div className="col-lg mb-2">
        <div className="card cardhover" onClick={_ => history.push(link)} >
            <img className="card-img-top" src={img} alt={alt} />
            <div className="card-footer bg-primary text-light">{caption}</div>
        </div>
    </div>);
}

const Card2 = ({ link, img, caption, alt }) => {
    const history = useHistory();
    return (<div className="col-lg mb-2">
        <div className="card cardhover" onClick={_ => history.push(link)} >
            <img className="card-img-top p-5" src={img} alt={alt} />
            <div className="card-footer bg-primary text-light">{caption}</div>
        </div>
    </div>);
}