import React from 'react';
import { Cell, CellHeader, Table2, Row } from '../../PdfWorker';
import { FormatDateShort } from 'lib/FormatDate';

const td = tab => tab?.map(({ distance }) => distance + '\n');
const th = tab => tab?.map(({ heureDepart }) => heureDepart + '\n');
const tl = tab => tab?.map(({ code }) => code);
/// Fonction spéciale pour les tableaux de Marches V2
export default ({ walks }) => {
    return (<Table2>
        <Row>
            <CellHeader style={{ width: "8%" }}>Date</CellHeader>
            <CellHeader style={{ width: "25%" }}>{"Club Organisateur \n Dénomination de la marche"} </CellHeader>
            <CellHeader style={{ width: "15%", textAlign: "left" }}>{"Distance \n Km"}</CellHeader>
            <CellHeader style={{ width: "10%" }}>Heures</CellHeader>
            <CellHeader style={{ width: "24%" }}>Lieu de départ</CellHeader>
            <CellHeader style={{ width: "10%" }}>{"Légende \n IGN"}</CellHeader>
            <CellHeader style={{ width: "8%" }}>{"N° Club"}</CellHeader>
        </Row>
        {walks && Array.isArray(walks) && walks?.map((e, i) => <Row key={i}>
            <Cell style={{ width: "8%", textAlign: "center" }}>{e?.debutMarchePermanente ? `${FormatDateShort(e?.debutMarchePermanente)}\n au\n ${FormatDateShort(e?.finMarchePermanente) ?? " / "}` : `${FormatDateShort(new Date(e.date))}`}</Cell>
            <Cell style={{ width: "25%", textAlign: "center" }}>{`${e?.groupNom} \n ${e?.titreMarche} \n ${e?.description ?? ""}`}</Cell>
            <Cell style={{ width: "15%" }}>{td(e.distances)}</Cell>
            <Cell style={{ width: "10%" }}>{th(e.distances)}</Cell>
            <Cell style={{ width: "24%" }}>{`${e.lieuDepart} \n ${e.adress?.streetName} ${e.adress?.streetNumber} ${e.adress?.streetNumberBte} \n ${e.adress?.postalCode} ${e.adress?.city}`}</Cell>
            <Cell style={{ width: "10%", textAlign: "center" }}>{tl(e.legende) + '\n' + e.carteIgn}</Cell>
            <Cell style={{ width: "8%", textAlign: "center" }}>{e.groupMatricule}</Cell>
        </Row>)}
    </Table2>);
}