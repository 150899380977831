import React from 'react';

export default ({ color, title, icon, text, className, ...rest }) => {
    return (<div className={`card border-left-${color} shadow h-100 py-2 ${className ?? ""}`} {...rest}>
        <div className="card-body">
            <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                    <div className={`text-xs font-weight-bold text-${color} text-uppercase mb-1`}>{title}</div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">{text}</div>
                </div>
                <div className="col-auto">
                    <i className={`fas ${icon} fa-2x text-gray-300`} style={{ "--fa-animation-iteration-count": "3", "--fa-animation-timing": "ease-in-out" }} />
                </div>
            </div>
        </div>
    </div>);
};