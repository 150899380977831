import { Actions } from "services/AccessControl";
import { isAllowed } from "lib/permToolkit";

export default (subject, action, ressource) => {

    switch (action) {
        case Actions.Create: // Create
            return isAllowed(subject, "GestionOrganigrammes", ressource) || isAllowed(subject, "CreationOrganigrammes", ressource) ;
        case Actions.Read: // Read
            return isAllowed(subject, null, ressource) || isAllowed(subject, "LectureOrganigrammes", ressource);
        case Actions.Update: // Update
            return isAllowed(subject, "GestionOrganigrammes", ressource) || isAllowed(subject, "ModificationOrganigrammes", ressource);
        case Actions.Delete: // Delete
            return isAllowed(subject, "GestionOrganigrammes", ressource) || isAllowed(subject, "SuppressionOrganigrammes", ressource);
        default: 
            return false;
    };
}