import React, { useEffect, useState } from 'react';
import { ClubApi } from '../../api/ClubApi';

export default ({ value, onChange, ...rest }) => {
    const [groups, setGroups] = useState([]);

    useEffect(_ => {
        ClubApi
            .all()
            .then(res => setGroups(res))
    }, []);

    return (<select className="custom-select" onChange={e => onChange(e.target.value)} value={value || ""} {...rest} >
        <option value="">Sélectionner un Groupe</option>
        {groups.map((c, i) => <option key={i} value={c.id}>{`${c.matricule} - ${c.name}`}</option>)}
    </select>);
}