import React from 'react';
import { Switch } from "react-router-dom";
import './sb-admin-2.min.css'

import { Actions, Objects, Route } from 'services/AccessControl';

import {ErrorPage403, ErrorPage404} from 'pages/ErrorPages';
import ReleaseNotes from 'pages/ReleaseNotes';
import Notifications from 'pages/Notifications';

import Home from 'components/Home';
import Parametres from 'components/params/Parametres';
import Users from 'components/Admin/Users';
import UserCreate from 'components/Admin/UserCreate';
import UserModify from 'components/Admin/UserModify';
import Marches from 'components/Walk/Marches';
import Walk from 'components/Walk/Walk';
import CreateWalk from 'components/Walk/CreateWalk';
import ModifyWalk from 'components/Walk/ModifyWalk';
import UserPerm from 'components/Admin/UserPerm';
import Club from 'components/clubs/Club';
import Assurance from 'components/clubs/Assurance';
import Province from 'components/Province';
import CreateClub from 'components/clubs/CreateClub';
import Member from 'components/member/Member';
import ModifyMember from 'components/member/ModifyMember';

import { UpdateForm140 } from 'components/clubs/Ass/Ass140';
import {UpdateForm143 } from 'components/clubs/Ass/Ass143';
import { UpdateForm143Bis } from 'components/clubs/Ass/Ass143Bis';
import { UpdateForm144 } from 'components/clubs/Ass/Ass144';
import { UpdateForm147 } from 'components/clubs/Ass/Ass147';
import { UpdateForm201 } from 'components/clubs/Ass/Ass201';

import Annuaire from 'components/Annuaire';
import Candidats from 'components/Candidats/Candidats';
import Assurances from 'components/Assurances';
import MemberCotisation from 'components/member/MemberCotisation';
import ModifyClub from 'components/clubs/ModifyClub';

import CA from 'components/CA';
import { CaProvider } from './bll/caStore';
import CreateDemandeAssurance from './components/clubs/Ass/CreateDemandeAssurance';
import UpdateDemandeAssurance from './components/clubs/Ass/UpdateDemandeAssurance';
import MemberAffiliations from './components/member/MemberAffiliations';

import ErrorBoundary from 'lib/Antoine/ErrorBoundary';
import ParametresSiteWeb from './pages/Parametres/ParametresSiteWeb';

export default _ => {

    return (<Switch>
        <Route exact path='/' >
            <Home />
        </Route>

        <Route path='/candidats'> {/*Géré dans le composant*/}
            <Candidats />
        </Route>

        <Route path='/ca'> {/*Géré dans le composant*/}
            <CaProvider>
                <CA />
            </CaProvider>
        </Route>

        <Route path='/provinces/:id'> {/*Géré dans le composant*/}
            <Province/>
        </Route>

        <Route exact path='/clubs/create' object={Objects.Club} action={Actions.Create}>
            <CreateClub />
        </Route>

        <Route path='/clubs/:id/modifier' object={Objects.Club} action={Actions.Update} >
            <ModifyClub />
        </Route>

        <Route path='/clubs/:id' object={Objects.Club} action={Actions.Read}>
            <Club />
        </Route>

        <Route path='/membres/update/:id' object={Objects.Membre} action={Actions.Update}>
            <ModifyMember/>
        </Route>

        <Route path='/membres/:id/cotisations' object={Objects.Cotisation} action={Actions.Read}>
            <MemberCotisation/> 
        </Route>

        <Route path='/membres/:id/affiliations' object={Objects.Cotisation} action={Actions.Manage}>
            <MemberAffiliations />
        </Route>
        
        <Route path='/membres/:id' object={Objects.Membre} action={Actions.Read}>
            <Member/>
        </Route>

        <Route exact path='/membres' object={Objects.Membre} action={Actions.Read} ressource="dfc82482-8cb2-11de-a173-001372568c9a">
            <Annuaire/>
        </Route>

        <Route exact path='/assurances/update/1400/:assid' object={Objects.Assurance} action={Actions.Update}>
            <UpdateForm140 />
        </Route> 

        <Route exact path='/assurances/update/1430/:assid' object={Objects.Assurance} action={Actions.Update}>
            <UpdateForm143/>
        </Route>

        <Route exact path='/assurances/update/1431/:assid' object={Objects.Assurance} action={Actions.Update}>
            <UpdateForm143Bis/>
        </Route>

        <Route exact path='/assurances/update/1440/:assid' object={Objects.Assurance} action={Actions.Update}>
            <UpdateForm144/>
        </Route>

        <Route exact path='/assurances/update/1470/:assid' object={Objects.Assurance} action={Actions.Update}>
            <UpdateForm147/>
        </Route>

        <Route exact path='/assurances/update/2010/:assid' object={Objects.Assurance} action={Actions.Update}>
            <UpdateForm201/>
        </Route>

        <Route exact path='/assurances' object={Objects.Assurance} action={Actions.Read} ressource="dfc82482-8cb2-11de-a173-001372568c9a" >
            <Assurances/>
        </Route>

        <Route exact path='/assurances/new' object={Objects.Assurance} action={Actions.Create} >
            <CreateDemandeAssurance />
        </Route>

        <Route path='/assurances/:id/update' object={Objects.Assurance} action={Actions.Update} >
            <UpdateDemandeAssurance />
        </Route>

        <Route path='/assurances/:id' object={Objects.Assurance} action={Actions.Read} >
            <Assurance/>
        </Route>

        <Route exact path='/marches' object={Objects.Marche} action={Actions.Read} ressource="dfc82482-8cb2-11de-a173-001372568c9a">
            <Marches/>
        </Route>

        <Route exact path='/marches/nouvelle' object={Objects.Marche} action={Actions.Create} >
            <CreateWalk/>
        </Route >

        <Route path='/marches/modifier/:id' object={Objects.Marche} action={Actions.Update} >
            <ErrorBoundary>
                <ModifyWalk />
            </ErrorBoundary>
        </Route >

        <Route path='/marches/:id' object={Objects.Marche} action={Actions.Read} >
            <Walk/>
        </Route >

        <Route exact path='/admin/users' object={Objects.Utilisateur} action={Actions.Manage}>
            <Users />
        </Route>

        <Route exact path='/admin/users/create' object={Objects.Utilisateur} action={Actions.Manage}>
            <UserCreate/>
        </Route>

        <Route path='/admin/users/update/:id' object={Objects.Utilisateur} action={Actions.Manage}>
            <UserModify/>
        </Route>

        <Route path='/admin/users/permissions/:id' object={Objects.Utilisateur} action={Actions.Manage}>
            <UserPerm/>
        </Route>
        
        <Route exact path='/parametres' object={Objects.Parametre} action={Actions.Manage}>
            <Parametres/>
        </Route>

        <Route exact path='/parametres/siteWeb' object={Objects.ParametreSiteWeb} action={Actions.Manage}>
            <ParametresSiteWeb />
        </Route>

        <Route exact path='/releasenotes'>
            <ReleaseNotes/>
        </Route>

        <Route exact path='/notifications'>
            <Notifications/>
        </Route>

        <Route path='/404'>
            <ErrorPage404/>
        </Route>

        <Route path='/403'>
            <ErrorPage403/>
        </Route>

        <Route path='*'>
            <ErrorPage404/>
        </Route>

    </Switch>);
}