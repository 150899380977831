import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Spinner from 'components/0components/Spinner';
import { Button } from 'components/0components/Form';
import { OrganigrammeApi } from 'api/OrganigrammeApi';
import { ClubApi } from 'api/ClubApi';
import { MergePostesOrganigrammes } from './Utils';
import LayoutInfo from './LayoutInfo';
import LayoutCACPDemandeMAJ from './LayoutCACPDemandeMAJ';

export default ({ id }) => {
    const [isVisible, setIsVisible] = useState(false); // Concerne la visibilité du modal
    const [isLoading, setIsLoading] = useState(false);

    const [orga, setOrga] = useState(null);
    const orgaCompact = orga ? MergePostesOrganigrammes(orga) : null;
    const [club, setClub] = useState(null);
    const [orgaSec, setOrgaSec] = useState(null);

    function f () 
    {
        if (isVisible)
            loadData();
    }

    useEffect(f, [isVisible]);

    const loadData = _ => {
        setIsLoading(true);

        Promise.all([
            OrganigrammeApi
                .get(id)
                .then(res => setOrga(res)),
            ClubApi
                .get(id)
                .then(res => setClub(res)),
            OrganigrammeApi
                .getSecretary(id)
                .then(res => setOrgaSec(res))
        ]).finally(_ => setIsLoading(false));
    }

    const toggleVisible = _ => {
        setIsVisible(!isVisible);
    }

    return (<>
        <BoutonExport onClick={toggleVisible} />

        <Modal isOpen={isVisible} toggle={toggleVisible}>
            <ModalHeader toggle={toggleVisible}>PDF - Organigramme</ModalHeader>
            <ModalBody>
                {isLoading && <Loader />}
                {!isLoading && <LayoutCACPDemandeMAJ orga={orga} club={club} secretaire={orgaSec} />}
                {!isLoading && <LayoutInfo orga={orgaCompact} club={club} />}
            </ModalBody>
        </Modal>
    </>);
}

const Loader = _ => <> Chargement des données...<Spinner /> </>;

const BoutonExport = ({ ...rest }) => <Button {...rest}> <i className="fa-solid fa-file-export" data-toggle="tooltip" title="Exporter" /></Button>;
