import React from 'react';

export default ({ titles, children, clickable }) => {
    return (<div className="card">
        <div className="table-responsive-xl">
            <table className={`table ${clickable ? "table-hover" : ""} mb-0`}>
                <thead className="thead-light">
                    <tr className="text-dark">
                        {titles.map(e => <th scope="col" key={e}>{e}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {children}
                </tbody>
            </table>
        </div>
    </div>);
}

export const TableElem = ({ placeholder, children }) => <td>{children ?? (placeholder ?? "")}</td>;
export const TableElemDate = ({ children }) => <TableElem>{children ? new Date(children).toLocaleDateString() : <></> }</TableElem>;
