import React, { useEffect, useState } from 'react';
import { FormSelect } from 'components/Form/Form';
import Button from 'components/0components/Button';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import createTokenProvider from 'services/createTokenProvider';
import { ClubApi } from 'api/ClubApi';

export default _ => {
    const [isVisible, setIsVisible] = useState(false);

    const titre = "Historique des affiliations par groupe"

    return (<div className="d-flex justify-content-between align-items-center" onClick={_ => setIsVisible(true)}>
        <p className="my-auto"><span className="badge badge-success mr-3">XLSX/CSV</span>{titre}</p>
        <button className="btn btn-primary ml-3" >
            <i className="fas fa-sm fa-arrow-down" data-toggle="tooltip" title="Télécharger" />
        </button>
        {isVisible && <MyModal isVisible={isVisible} setIsVisible={setIsVisible} />}
    </div>);
}


const MyModal = ({ isVisible, setIsVisible }) => {

    useEffect(_ => {
        ClubApi
            .all()
            .then(res => setGroupes(res));
    }, [])

    const [groupes, setGroupes] = useState([]);
    const [grpid, setGrpid] = useState("");
    const [format, setFormat] = useState("xlsx");

    return (<Modal isOpen={isVisible} toggle={_ => setIsVisible(!isVisible)}>
        <ModalHeader >Affilies Par Groupe</ModalHeader>
        <ModalBody>
            <FormSelect label="Groupe" required value={grpid} handleChange={val => setGrpid(val)}>
                <option value="" key="">Sélectionner un club</option>
                {groupes && groupes.map(e => <option value={e.id} key={e.id}>{`${e.matricule} - ${e.name}`}</option>)}
            </FormSelect>
            <FormSelect label="Format" required value={format} handleChange={val => setFormat(val)}>
                <option value="xlsx">Excel</option>
                <option value="CSV">CSV</option>
            </FormSelect>
            <Button className="mt-3" onClick={_ => window.open(`/api/stats/historique/affilies/${grpid}?jwt=${createTokenProvider().getToken()}&format=${format}`, '_blank')}>Télécharger</Button>
        </ModalBody>
    </Modal>);
}