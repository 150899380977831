import React from 'react';

import StatistiquesClubMembres from 'components/pdfs/StatistiquesClubMembres';
import StatistiquesGlobal from 'components/pdfs/StatistiquesGlobal';
import StatistiquesProvince from 'components/pdfs/StatistiquesProvince';
import CalendrierPdf from 'components/pdfs/Calendrier/CalendrierPdf';

import Affiliations from './Affiliations';
import OrganigrammeCAPdf from './OrganigrammePdf';
import AffiliesParGroupe from './AffiliationsParGroupe';
import CartesImprimeur from 'components/pdfs/CartesImprimeur';
import CalendrierCAXLSX from './XLSX/CalendrierCAXLSX';
import FWBXLSX from './FWBXLSX';
import ResponsablesXLSX from './XLSX/ResponsablesXLSX';
import ResponsablesParProvinceXLSX from './XLSX/ResponsablesParProvinceXLSX';


export default _ => {
    return (<>
        <h3 className="h3 mb-4">Documents</h3>
        <div className="row">
            <div className="col-lg-auto">
                <ul className="list-group w-auto">
                    <li className="list-group-item">
                        <StatistiquesGlobal />
                    </li>

                    <li className="list-group-item">
                        <StatistiquesClubMembres />
                    </li>

                    <li className="list-group-item">
                        <StatistiquesProvince />
                    </li>

                    <li className="list-group-item">
                        <Affiliations />
                    </li>

                    <li className="list-group-item ">
                        <OrganigrammeCAPdf />
                    </li>

                    <li className="list-group-item ">
                        <ResponsablesXLSX/>
                    </li>

                    <li className="list-group-item ">
                        <ResponsablesParProvinceXLSX/>
                    </li>

                    <li className="list-group-item">
                        <AffiliesParGroupe />
                    </li>
                
                    <li className="list-group-item">
                        <CalendrierPdf />
                    </li>
                
                    <li className="list-group-item">
                        <CartesImprimeur />
                    </li>

                    <li className="list-group-item">
                        <CalendrierCAXLSX />
                    </li>
                    
                    <li className="list-group-item">
                        <FWBXLSX/>
                    </li>
                </ul>
            </div>
        </div>
    </>);
}