import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";

import useForm from 'services/useForm';
import { FormatDateInput } from 'lib/FormatDate';

import { SubmitButtonLoading, Required } from 'components/Form/Form';
import FormCoordonnees from 'components/Form/FormCoordonnees';
import Spinner from 'components/0components/Spinner';
import { CandidatApi } from 'api/CandidatApi';

export default _ => {
    const history = useHistory();
    const { candidatId } = useParams();

    const [isLoading, setIsLoading] = useState(true);
    const { fields, setFields } = useForm({
        "Id" : candidatId
    });

    function FetchCandidat()
    {
        CandidatApi
            .get(candidatId)
            .then(res => setFields(res))
            .catch(err=>console.error(err))
            .finally(_=>setIsLoading(false));
    }

    useEffect(FetchCandidat, [candidatId])

    const yearAffiliation = _ => {
        let r = [new Date().getFullYear()];
        if (new Date().getMonth() === 11) //si on est en décembre
            r.push(new Date().getFullYear() + 1); //proposer l'année suivante
        return r;
    }

    const handleSubmit = e => {
        e.preventDefault();
        CandidatApi
            .update(fields)
            .then(_ => history.goBack());
    }

    return (<form onSubmit={handleSubmit}>
        <h1 className="display-4 mb-4">Modifier candidat</h1>
        {isLoading && <Spinner />}
        {fields && <>
            <div className="row">
                <div className="col-lg-4">
                    <label>Année d'affiliation<Required /></label>
                    <select
                        className="custom-select mr-sm-2"
                        onChange={e => setFields({ ...fields, "anneeAffiliation": parseInt(e.target.value) })}
                        value={fields?.anneeAffiliation}
                        disabled={yearAffiliation().length <= 1 && yearAffiliation().includes(fields?.anneeAffiliation)}
                        required
                    >
                        {!yearAffiliation().includes(fields?.anneeAffiliation) && <option value="">{fields?.anneeAffiliation}</option>}
                        {yearAffiliation().map((annee, i) => <option key={i} value={annee}>{annee}</option>)}
                    </select>
                </div>
                <div className="col-lg-4">
                    <label>Matricule (En cas de demande de Transfert)</label>
                    <input
                        className="form-control"
                        type="text"
                        minLength="0"
                        maxLength="6"
                        placeholder="Matricule du membre a transférer"
                        onChange={e => setFields({ ...fields, "matricule": e.target.value })}
                        value={fields?.matricule || ""}
                    />
                </div>
            </div>
            {(!fields?.matricule || fields.matricule === "") && <>
                <div className="row">
                    <div className="col">
                        <label>Nom <Required /></label>
                        <input
                            className="form-control"
                            type="text"
                            minLength="1"
                            maxLength="60"
                            placeholder="Nom de l'affilié"
                            onChange={e => setFields({ ...fields, "lastName": e.target.value })}
                            value={fields?.lastName || ""}
                            required
                        />
                    </div>
                    <div className="col">
                        <label>Prénom <Required /></label>
                        <input
                            className="form-control"
                            type="text"
                            maxLength="60"
                            placeholder="Prénom de l'affilié"
                            onChange={e => setFields({ ...fields, "firstName": e.target.value })}
                            value={fields?.firstName || ""}
                            required
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-2">
                        <label>Date de Naissance <Required /></label>
                        <input
                            className="form-control mr-sm-2"
                            type="date"
                            max={new Date().toISOString().split('T')[0]}
                            onChange={e => setFields({ ...fields, "birthDate": e.target.value })}
                            value={FormatDateInput(fields?.birthDate)}
                            required
                        />
                    </div>
                    <div className="col-lg-auto">
                        <label className="mr-1">Sexe<Required /></label>
                        <br />
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="radioGender"
                                value="M"
                                onChange={_ => setFields({ ...fields, "gender": "M" })}
                                checked={fields?.gender === "M"}
                                required
                            />
                            <label className="form-check-label">M</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="radioGender"
                                value="F"
                                onChange={_ => setFields({ ...fields, "gender": "F" })}
                                checked={fields?.gender === "F"}
                                required
                            />
                            <label className="form-check-label">F</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="radioGender"
                                value="A"
                                onChange={_ => setFields({ ...fields, "gender": "A" })}
                                checked={fields?.gender === "A"}
                                required
                            />
                            <label className="form-check-label">Autre</label>
                        </div>
                    </div>
                    <div className="col-lg-auto">
                        <label className="mr-1">Chef de Famille</label>
                        <br />
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="radioFamily"
                                value="1"
                                onChange={e => setFields({ ...fields, "familyChief": true })}
                                checked={fields?.familyChief === true}
                            />
                            <label className="form-check-label">Oui</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="radioFamily"
                                value="0"
                                onChange={e => setFields({ ...fields, "familyChief": false })}
                                checked={fields?.familyChief === false}
                            />
                            <label className="form-check-label">Non</label>
                        </div>
                    </div>
                </div>
                <FormCoordonnees fields={fields?.coord ?? {}} setFields={newVal => setFields({ ...fields, "coord": newVal })} />
            </>}
            <SubmitButtonLoading isLoading={isLoading} /> 
        </>}
    </form>);
}