export const removeFromArray = (index, array) => {
    let tmp = [...array];
    tmp.splice(index, 1);
    return tmp;
}

export const generateNewPassword = (length) => {
    return new Array(length).fill().map(() => String.fromCharCode(Math.random() * 26 + 97)).join("");
}

export const searchCompare = (value, search) => value?.toLowerCase().includes(search?.toLowerCase());

export const addDays = (date, days) =>  {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

export const yearsListBetween = (debut, fin) => {
    let tmp = []
    for (let i = fin; i >= debut; i--)
        tmp.push(i);
    return tmp;
}