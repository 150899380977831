import React, { useState } from 'react';
import Button from '../0components/Button';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Input } from '../0components/Form';
import createTokenProvider from '../../services/createTokenProvider';

export default _ => {
    const [isVisible, setIsVisible] = useState(false);

    return (<div className="d-flex justify-content-between align-items-center" onClick={_ => setIsVisible(true)}>
        <p className="my-auto"><span className="badge badge-success mr-3">XLSX</span>Affiliations</p>
        <button className="btn btn-primary ml-3" >
            <i className="fas fa-sm fa-arrow-down" data-toggle="tooltip" title="Télécharger" />
        </button>
        {isVisible && <MyModal isVisible={isVisible} setIsVisible={setIsVisible}/>}
    </div>);
}

const MyModal = ({ isVisible, setIsVisible }) => {
    const [beginDate, setBeginDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    return (<Modal isOpen={isVisible} toggle={_ => setIsVisible(!isVisible)}>
        <ModalHeader >Affiliations</ModalHeader>
        <ModalBody>
            du :
            <Input
                type="date"
                value={beginDate.toISOString().split('T')[0].toString()}
                onChange={e => setBeginDate(new Date(e.target.value))} />
            au
            <Input
                type="date"
                value={endDate.toISOString().split('T')[0].toString()}
                onChange={e => setEndDate(new Date(e.target.value))} />
            <Button className="mt-3" onClick={_ => window.open(`/api/stats/affiliations/xlsx?begin=${beginDate?.toISOString()}&end=${endDate?.toISOString()}&jwt=${createTokenProvider().getToken()}`, '_blank')}>Télécharger</Button>
        </ModalBody>
    </Modal>);}