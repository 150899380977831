import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Button } from 'components/0components/Form';
import Layout from './Layout';

export default ({ MembresActifs, club }) => {
    const [isVisible, setIsVisible] = useState(false); // Concerne la visibilité du modal
    const [membresSelect, setMembresSelect] = useState([]);
    const [GenData, setGenData] = useState(null);

    const toggleVisible = _ => setIsVisible(!isVisible);

    const SelectMembre = (m) => {
        let data = [...membresSelect] //copie le tableau

        if (data.includes(m))
            data = data.filter(e => e !== m);
        else
            data.push(m);

        setMembresSelect(data);
        console.log(data);
    };

    const isSelected = (membre, tableau) => {
        if (!tableau || tableau.length === 0)
            return false;
        else
            return tableau.map(elem => elem.id).includes(membre.id);
    }

    const clickGen = _ => setGenData(membresSelect);

    return (<>
        <Button onClick={_ => setIsVisible(true)}> <i className="fa-solid fa-barcode" data-toggle="tooltip" title="Générer" /></Button>

        <Modal isOpen={isVisible} toggle={toggleVisible}>
            <ModalHeader toggle={toggleVisible}>Liste des Membres (format Code Bar)</ModalHeader>
            <ModalBody>
                <h2 className="h2">Sélectionner les membres à exporter</h2>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Nom</th>
                            <th scope="col">Prenom</th>
                            <th scope="col">Sélectionné</th>
                        </tr>
                    </thead>
                    <tbody>
                        {MembresActifs?.sort((a,b) => a.lastName - b.lastName).map((e, i) => <tr key={i}>
                            <th scope="row">{e?.matricule}</th>
                            <td>{e.lastName}</td>
                            <td>{e.firstName}</td>
                            <td><input className="form-check-input" type="checkbox" checked={isSelected(e, membresSelect)} id="flexCheckDefault" onClick={_ => SelectMembre(e)} /></td>
                        </tr>)}
                    </tbody>
                </table>
                
                {!GenData && <Button onClick={clickGen}>Générer</Button>}
                {GenData && <Layout affilies={GenData} callbak={_ => setGenData(null)} club={club} />}
            </ModalBody>
        </Modal>
    </>);



}

// const Loader = _ => <> Chargement des données...<Spinner /> </>;