import React from 'react';
import { View, Text } from '../../PdfWorker';

export default _ => {
    return (<View wrap={false} style={{ width: "auto" }} >
        <Text style={{ fontSize: 10, marginBottom: 5 }}>Pour accord,</Text>
        <View style={{ width: "auto", flexDirection: "row", marginBottom: 40 }}>
            <View style={{ width: "33%" }}>
                <Text style={{ fontSize: 10 }}>Le Président,</Text>
            </View>
            <View style={{ width: "33%" }}>
                <Text style={{ fontSize: 10 }}>Le Secrétaire</Text>
            </View>
            <View style={{ width: "33%" }}>
                <Text style={{ fontSize: 10 }}>Le Trésorier,</Text>
            </View>
        </View>

        <Text style={{ fontSize: 10 }}>Cachet</Text>
    </View>);
}