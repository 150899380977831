import React from 'react';
import { useHistory } from 'react-router-dom';
import formatDate from 'lib/FormatDate';
import Spinner from 'components/0components/Spinner';

export default ({ liste: marches, isLoading }) => {
    const history = useHistory();

    const handleClickMarche = marche => history.push(`/marches/${marche.id}`);

    if (isLoading)
        return <Spinner />;

    return (<div className="Row">
        <h3 className="h3 mb-4">{`Marche${(marches?.length > 1 ? "s" : "")} en attente`}</h3>
        <div className="card">
            <div className="table-responsive">
                <table className="table table-hover">
                    <thead className="thead-light">
                        <tr>
                            <th scope="col" className="text-dark">Titre</th>
                            <th scope="col" className="text-dark">Type</th>
                            <th scope="col" className="text-dark">Date</th>
                            <th scope="col" className="text-dark">Groupe</th>
                            <th scope="col" className="text-dark">Départ</th>
                        </tr>
                    </thead>
                    <tbody>
                        {marches?.map(w => (<tr key={w.id} onClick={_ => handleClickMarche(w)}>
                            <td>{w.titreMarche}</td>
                            <td>{w.typeMarcheLibelle}</td>
                            <td>{formatDate(w.date)}</td>
                            <td>{w.groupLibelle}</td>
                            <td>{w.lieuDepart}</td>
                        </tr>))}
                    </tbody>
                </table>
            </div>
        </div>
    </div>);
}