import React from 'react';
import { useHistory } from 'react-router-dom';
import useNotifications from 'hooks/useNotifications';
import { FormatDateEtHeure } from 'lib/FormatDate';
import { DeleteIcon } from 'components/Icons';

const Notifications = _ => {

    const { notifications, markAsRead, deleteNotification } = useNotifications();
    const history = useHistory();

    const HandleClick = e => {
        markAsRead(e.id)
            .then(_ => history.push(e.redirect_link))
    }
    const HandleDelete = (e, z) => {
        z.preventDefault();
        z.stopPropagation();
        deleteNotification(e.id);
    }

    return (<>
        <h1 className="h1 mb-4">Notifications</h1>

        {notifications?.map(e =>
            <NotificationCard
                notification={e}
                key={e.id}
                HandleClick={HandleClick}
                HandleDelete={HandleDelete}
            />
        )}
    </>);
}

const NotificationCard = ({ notification, HandleClick, HandleDelete }) => {
    const { title, description, created_at, is_read } = notification;

    const descriptionLines = description?.split('\n');

    return (<div className={`card mb-2 ${is_read ? "text-secondary" : "text-primary"}`} onClick={_ => HandleClick(notification)}>
        <div className="card-body">
            <h5 className="card-title">
                <b>{title}</b> <span className="lead">{FormatDateEtHeure(created_at)}</span>
            </h5>
            {descriptionLines?.map(str => <p key={str}>{str}</p>)}
            <div
                className="btn btn-danger"
                onClick={z => HandleDelete(notification, z)}>
                <DeleteIcon />
            </div>
        </div>
    </div>);
}

export default Notifications;