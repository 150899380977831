import React from 'react';
import { FormatAdressOneLine } from 'lib/FormatAdress';
import FormatDate from 'lib/FormatDate';
import Button from '../../0components/Button';
import { PDFDownloadLink, Text, Row, View, Table2, CellHeader, Cell } from '../PdfWorker';
import AssuranceLayoutPDF from './AssuranceLayoutPDF';

export default ({ demande }) => {
    return (<PDFDownloadLink document={<MonDocument demande={demande} />} fileName={`DemandeAssurance${demande.numeroDemande}.pdf`}>
        {({ loading }) => (loading ? 'Génération du document...' : <Button icon="fa-download">Télécharger</Button>)}
    </PDFDownloadLink>);
}

const myDate = d => d ? FormatDate(d) : "...../...../.....";

const MonDocument = ({ demande }) => {
    return (<AssuranceLayoutPDF demande={demande} titre="Incapacité temporaire" subtitle="Demande de couverture d'assurance: Police n° 45.031.655" roi="ROI ASS 201">
        <Table2>
            <Row>
                <CellHeader style={{ width: "15%" }}>Matricule</CellHeader>
                <CellHeader style={{ width: "20%" }}>Nom</CellHeader>
                <CellHeader style={{ width: "20%" }}>Prénom</CellHeader>
                <CellHeader style={{ width: "30%" }}>Adresse</CellHeader>
                <CellHeader style={{ width: "15%" }}>Naissance</CellHeader>
            </Row>
            {demande?.affilies?.map((e, i) => <Row key={i}>
                <Cell style={{ width: "15%" }}>{e?.matricule ?? " "}</Cell>
                <Cell style={{ width: "20%" }}>{e?.lastName ?? " "}</Cell>
                <Cell style={{ width: "20%" }}>{e?.firstName ?? " "}</Cell>
                <Cell style={{ width: "30%" }}>{FormatAdressOneLine(e?.coord?.adress)}</Cell>
                <Cell style={{ width: "15%" }}>{myDate(e?.birthDate)}</Cell>
            </Row>)}
        </Table2>

        <View>
            <Text style={{ fontSize: 8, marginBottom: 0, borderWidth: 1, marginTop: 20, padding: 3 }}>{demande?.message}</Text>
        </View>
    </AssuranceLayoutPDF>);
}