import React, { useState, useEffect } from 'react';

import { OrganigrammeApi } from 'api/OrganigrammeApi';

import { Label } from 'components/Form/Form';

export default ({groupId, value, onChange }) => {
    const [data, setData] = useState([]);

    useEffect(_ => {
        OrganigrammeApi
            .typeFunctions(groupId)
            .then(res => setData(res));
    }, [groupId])

    return (<div className="mb-2">
        <Label required label="Poste"/>
        <select className="custom-select" onChange={e => onChange(e.target.value)} value={value || ""} required>
            <option value="">Sélectionner une option</option>
            {data?.map(elem => <option key={elem.id} value={elem.id}>
                {`${elem.libelle}`}
            </option>)}
        </select>
    </div>);
}