import React from 'react';
import { LoadingButton } from 'components/0components/Spinner';

export default ({ titre, value, setValue, SubmitHandler, error, isLoading }) => {

    return (<div className='m-3'>
        <form onSubmit={SubmitHandler}>
            <label htmlFor="val">{titre}</label>
            <div className="form-row">
                <div className="col-md-6 mb-3">
                    <input
                        id="val"
                        className={!error ? "form-control mb-1" : "form-control mb-1 is-invalid"}
                        type="number"
                        step=".01"
                        placeholder=""
                        required
                        onChange={e => setValue(e.target.value)}
                        value={value}
                    />
                    {error && <div className="invalid-feedback">{error}</div>}
                </div>
                <div className="col-md-6 mb-3">
                    {isLoading ?
                        <LoadingButton>Sauvegarder</LoadingButton> :
                        <button className="btn btn-primary" type="submit">Sauvegarder</button>}
                </div>
            </div>
        </form>
    </div>);
}