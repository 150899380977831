export const MergePostesOrganigrammes = orga => {
    let data = [];

    orga = JSON.parse(JSON.stringify(orga)); // cette fonction permets de faire une deep copy de l'objet orga et �vite les erreurs li�es � la modification de r�f�rences au sein des objets.

    try {

        orga.forEach(membre => {
            let index = data.findIndex(e => e.memberId === membre.memberId);
            if (index === -1)//si il n'existe pas encore 
                data.push(membre);// on l'ajoute
            else // on ajoute le poste � la personne
            {
                data[index].post = data[index].post + ', ' + membre.post;
            }
        })
    }
    catch (error) {
        console.log(error);
    }

    return data;
}