import React, { useState, useEffect} from 'react';
import { Link, useHistory } from "react-router-dom";
import Spinner from 'components/0components/Spinner';
import { UtilisateurApi } from 'api/UtilisateursApi';
import { searchCompare } from 'lib/utils';

export default _ => {
    const history = useHistory();

    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState("");
    
    const fetchUsers = _ => {
        UtilisateurApi
            .all()
            .then(res => setUsers(res));
    }

    const InitialLoad = _ => {
        fetchUsers();
    }

    const handleSupprUser = ({ login, name }, index) => {
        if (window.confirm(`L'utilisateur (${login}) ${name} va être supprimé. Continuer ?`)) {
            UtilisateurApi
                .delete(login)
                .then(fetchUsers);
        }
    }

    const handleModifyUser = (e, id) => {
        e.stopPropagation();
        history.push(`/admin/users/update/${id}`);
    }

    const handleModifyPermUser = (e, id) => {
        e.stopPropagation();
        history.push(`/admin/users/permissions/${id}`);
    }

    const filterUsers = u => {
        return searchCompare(u.name, search) ||
            searchCompare(u.login, search) ||
            searchCompare(u.group, search);
    }

    useEffect(InitialLoad, []);

    return (<>
        <div className="row mb-4">
            <div className="col-lg">
                <h1 className="display-4">Utilisateurs</h1>
            </div>
            <div className="col-lg-4 mt-auto">
                <input
                    className="form-control"
                    type="search"
                    placeholder="Rechercher un utilisateur"
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                />
            </div>
            <div className="col-lg-auto mt-auto">
                <Link to="/admin/users/create">
                    <button type="button" className="btn btn-primary">
                        Nouveau <i className="fas fa-fw fa-plus ml-2" />
                    </button>
                </Link>
            </div>
        </div>

        {!users ? <Spinner /> :
        <div className="row">
            <div className ="col">
                <div className="card">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Nom</th>
                                    <th scope="col">Login</th>
                                    <th scope="col">Commentaire</th>
                                    <th scope="col">Actif</th>
                                    <th scope="col">Actions</th>   
                                </tr>
                            </thead>
                            <tbody>
                                    {users.filter(filterUsers).map((user, index) => (<tr className={`${!user?.actif && "table-active text-dark "}`} key={index}>
                                        <td className="align-middle">{user.name}{user.isAdmin && <i className="fa-solid fa-crown text-warning ml-2" data-toggle="tooltip" title="Administrateur" />}</td>
                                    <td className="align-middle">{user.login}</td>
                                    <td className="align-middle">{user.comment}</td>
                                        <td className="align-middle">
                                            <i className={`fas fa-fw  ${user.actif ? " fa-check-circle text-success" : "fa-times-circle text-danger"}`}
                                                data-toggle="tooltip"
                                                title={user.actif ? "Oui" : "Non"} />
                                    </td>
                                        <td className="align-middle">
                                        {!user?.isAdmin && <>
                                            <button
                                                className="btn btn-warning mr-2"
                                                onClick={e => handleModifyPermUser(e, user.login)}
                                                data-toggle="tooltip"
                                                title="Gestion des Permissions">
                                                <i className="fas fa-lock fa-sm" />
                                            </button>
                                            <button
                                                className="btn btn-primary mr-2"
                                                onClick={e => handleModifyUser(e, user.login)}
                                                data-toggle="tooltip"
                                                title="Modifier">
                                                <i className="fas fa-pen fa-sm" />
                                            </button>
                                            <button className="btn btn-danger" onClick={_ => handleSupprUser(user, index)} data-toggle="tooltip" title="Supprimer">
                                                <i className="fas fa-trash fa-sm"/>
                                            </button>
                                        </>}
                                    </td>
                                </tr>))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>}
    </>);
}

