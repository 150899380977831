import React, { useState, useEffect } from 'react';
import { ParametresApi } from '../../api/ParametresApi';
import ModifyParametres from '../../components/params/ModifyParametres';

const ParametresSiteWeb = _ => {

    return (<>
        <h1 className="display-4">Paramètres site Web</h1>
        <div className="row">
            <div className="col">
                <ParametreCoutSiteWeb
                    titre="Prix Bulletin (BE) (€)"
                    parametre="PriceNewsBe"
                />
                <ParametreCoutSiteWeb
                    titre="Prix Bulletin (Eu) (€)"
                    parametre="PriceNewsEu"
                />
                <ParametreCoutSiteWeb
                    titre="Prix Calendrier (BE) (€)"
                    parametre="PriceCalendarBe"
                />
                <ParametreCoutSiteWeb
                    titre="Prix Calendrier (Eu) (€)"
                    parametre="PriceCalendarEu"
                />
            </div>
        </div>
    </>);
}

const ParametreCoutSiteWeb = ({titre, parametre}) => {
    const [value, setValue] = useState('');

    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(true);

    function FetchingParam() {
        setIsLoading(true);
        ParametresApi
            .getV2(parametre)
            .then(res => setValue(res.value1))
            .catch(err => setError('Erreur récupération de la donnée impossible'))
            .finally(_ => setIsLoading(false))
    }

    useEffect(FetchingParam, []);

    const submitHandler = e => {
        e.preventDefault();
        setIsLoading(true);

        ParametresApi
            .updateV2(parametre, value)
            .then(res => { if (!res.ok) throw res; })
            .catch(err => setError('Erreur lors de la modification'))
            .finally(_ => setIsLoading(false))
    }

    return (<div className='m-3'>
        <ModifyParametres
            titre={titre}
            value={value}
            setValue={setValue}
            SubmitHandler={submitHandler}
            error={error}
            isLoading={isLoading}
        />
    </div>);
}

export default ParametresSiteWeb;