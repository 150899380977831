import React, { useState, useEffect } from 'react';
import { PaysApi } from 'api/PaysApi';
import { FormInput2 } from 'components/Form/Form';
import Spinner from 'components/0components/Spinner';

export default ({ fields, setFields }) => <BasicCoordForm fields={fields} setFields={setFields} />;

export const FormCoordAvecSiteWeb = ({ fields, setFields }) => {
    return (<>
        <BasicCoordForm fields={fields} setFields={setFields} />
        <div className="row">
            <div className="col-md">
                <FormInput2
                    label="Site Web"
                    placeholder="Site Web"
                    maxLength="100"
                    value={fields["website"]}
                    onChange={val => setFields({ ...fields, "website": val })} />
            </div>
        </div>
    </>);
}

export const FormAdresse = ({ fields, setFields }) => {
    const [countrys, setCountrys] = useState();

    useEffect(_ => {
        PaysApi
            .all()
            .then(res => setCountrys(res))
    }, []);

    return (<>
        <div className="row">
            <div className="col-lg-8">
                <FormInput2
                    label="Rue"
                    placeholder="Rue"
                    maxLength="100"
                    value={fields["streetName"]}
                    onChange={val => setFields({ ...fields, "streetName": val })} />
            </div>
            <div className="col-lg-2">
                <FormInput2
                    label="N°"
                    placeholder="N°"
                    maxLength="60"
                    value={fields["streetNumber"]}
                    onChange={val => setFields({ ...fields, "streetNumber": val })} />
            </div>
            <div className="col-lg-2">
                <FormInput2
                    label="Boîte"
                    placeholder="Boîte"
                    maxLength="10"
                    value={fields["streetNumberBte"]}
                    onChange={val => setFields({ ...fields, "streetNumberBte": val })} />
            </div>
        </div> 
        <div className="row">
            {!countrys && <Spinner />}
            {countrys && <div className="col-lg-3">
                <label>Pays</label>
                <select className="custom-select"
                    onChange={e => setFields({ ...fields, "countryCode": e.target.value })}
                    value={fields["countryCode"] || "BE"}
                >
                    <option value="">Sélectionner un Pays</option>
                    {countrys.map(c => <option key={c.id} value={c.id}>{c.libelle}</option>)}
                </select>
            </div>}
            <div className="col-lg-2">
                <FormInput2
                    label="Code Postal"
                    placeholder="Code Postal"
                    maxLength="10"
                    value={fields["postalCode"]}
                    onChange={val => setFields({ ...fields, "postalCode": val })} />
            </div>
            <div className="col">
                <FormInput2
                    label="Localité"
                    maxLength="60"
                    placeholder="Localité"
                    value={fields["city"]}
                    onChange={val => setFields({ ...fields, "city": val })} />
            </div>
        </div>
    </>);
};

const BasicCoordForm = ({ fields, setFields }) => {
    return (<div>
        <FormAdresse fields={fields?.adress ?? {}} setFields={val => setFields({ ...fields, "adress": val })} />
        <div className="row ">
            <div className="col-lg-4">
                <FormInput2
                    label="Email"
                    type="text"
                    maxLength="300"
                    placeholder="email@exemple.com"
                    onChange={val => setFields({ ...fields, "email": val })}
                    value={fields?.email}
                />
            </div>
            <div className="col-lg-4">
                <FormInput2
                    label="Téléphone"
                    maxLength="50"
                    placeholder="Numéro Fixe"
                    value={fields["phone"]}
                    onChange={val => setFields({ ...fields, "phone": val })} />
            </div>
            <div className="col-lg-4">
                <FormInput2
                    label="GSM"
                    maxLength="50"
                    placeholder="Numéro de GSM"
                    value={fields["gsm"]}
                    onChange={val => setFields({ ...fields, "gsm": val })} />
            </div>
        </div>
    </div>);
}