import { Auth } from "components/Form/Form";

const url = 'api/marches';

const myFetch = (uri, method = 'GET', data) => { return fetch(uri, Auth.options(method, data)); }

export const MarchesApi = {

    get: (id) => {
        return myFetch(`${url}/${id}`)
            .then(res => res.json())
    },

    search: (start, end) => {
        return myFetch(`${url}?startDate=${start}&endDate=${end}`)
            .then(res => {
                if (res.ok)
                    return res.json();
                else
                    throw res;
            })
    },

    byGroup: (id) => {
        return myFetch(`${url}/bygroup/${id}`)
            .then(res => res.json())
    },

    byGroupToExport: (id) => {
        return myFetch(`${url}/toexport/bygroup/${id}`)
            .then(res => res.json())
    },

    getFromProvinceToExport: (id) => {
        return myFetch(`${url}/byProvinceToExport/${id}`)
            .then(res => res.json())
    },

    getFromProvinceToExportBis: (id, annee) => {
        const start = `${annee}-01-01`;
        const end = `${annee}-12-31`;

        return myFetch(`${url}/export/prov2?provinceid=${id}&startDate=${start}&endDate=${end}`)
            .then(res => res.json())
    },

    getFromProvinceV3: (id, annee) => {
        const start = `${annee}-01-01`;
        const end = `${annee}-12-31`;

        return myFetch(`${url}/export/prov3?provinceid=${id}&startDate=${start}&endDate=${end}`)
            .then(res => res.json())
    },

    getFromProvinceV4: (id, annee) => {
        const start = `${annee}-01-01`;
        const end = `${annee}-12-31`;

        return myFetch(`${url}/export/prov4?provinceid=${id}&startDate=${start}&endDate=${end}`)
            .then(res => res.json())
    },

    getAValider: () => {
        return myFetch(`${url}/tovalidate`)
            .then(res => res.json())
    },

    /// Valide la marche
    validate: (id) => { 
        return myFetch(`${url}/${id}/validate`)
    },

    //invalide la marche
    invalidate: (id) => {
        return myFetch(`${url}/${id}/invalidate`)
    },

    create: (data) => {
        return myFetch(`${url}`, 'POST',data)
    },

    duplicate: (oldId, newDate) => {
        return myFetch(`${url}/createfrom?id=${oldId}&date=${newDate}`)
    },

    duplicate2: (oldId, newDate, dateend) => {
        return myFetch(`${url}/createfrom2?id=${oldId}&date=${newDate}&enddate=${dateend}`)
    },
    
    update: (data) => {
        return myFetch(`${url}`, 'PATCH', data)
    },

    delete: (id) => {
        return myFetch(`${url}/${id}`, 'DELETE')
    },

    legendes: {

        getAll: () => {
            return myFetch(`${url}/legendes`)
                .then(res => res.json());
        },

        create: (code, libelle) => {
            return myFetch(`${url}/legendes`, 'POST', { code: code, libelle: libelle });
        },

        update: (id, code, libelle) => {
            return myFetch(`${url}/legendes`, 'PATCH', { id: id, code: code, libelle: libelle })
        },

        delete: (id) => {
            return myFetch(`${url}/legendes/${id}`, 'DELETE')
        }
    },

    types: {
        getAll: () => {
            return myFetch(`${url}/types`).then(res => res.json())
        }
    }
}