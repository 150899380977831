import React from 'react';

export default ({ children, ...rest }) => <form {...rest}>{children}</form>;

export const FormGroup = ({ label, tooltip, children}) => {
    return (<div className="form-group">
        {label && <label>{label}</label>}
        {children}
        {tooltip && <small className="form-text text-muted">{tooltip}</small>}
    </div>);
}

export const Input = ({ ...rest }) => <input className="form-control" {...rest} />;

export const Inputcheck = ({ label, ...rest }) => <div className="form-check"><input type="checkbox" className="form-check-input" {...rest} /> <label className="form-check-label">{label}</label></div>;

export const Button = ({ children, ...rest }) => <button type="submit" className="btn btn-primary mb-2" {...rest}>{children}</button>;

export const Select = ({ children, ...rest }) => <select className="form-control" {...rest}>{children}</select>;