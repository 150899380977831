import React from "react";
import { Elem, ElemDate} from '../../Form/Form';

export default ({ demande }) => {
    return (<div>
        {ElemDate("Date Organisation", demande.dateOrganisation)}
        {Elem("Adresse de Départ", demande.adresseDeDepart)}
    </div>);
}

//export const Form145 = _ => {
//    const titre = "Création Demande Assurance Roi 145";
//    const apiSubmitUrl = "api/assurances/145"; 

//    const history = useHistory();
//    const [errorMessage, setErrorMessage] = useState("");
//    const [submitLoading, setSubmitLoading] = useState(false);

//    const { id } = useParams();
//    const [fields, setFields] = useState({ "groupId": id });

//    const submit = e => {
//        e.preventDefault();
//        setSubmitLoading(true);
//        fetch(apiSubmitUrl, Auth.postRequestOptions(JSON.stringify(fields)))
//            .then(res => { if (!res.ok) throw res })
//            .then(_ => history.goBack())
//            .catch(err => setErrorMessage(ErrorHandler(err)))
//            .finally(_ => setSubmitLoading(false));
//    }

//    return (MyForm(titre, errorMessage, submit, submitLoading, fields, setFields));
//};

//export const UpdateForm145 = _ => {
//    const titre = "Modification Demande Assurance Roi 145";
//    const apiSubmitUrl = "api/assurances/145";

//    const history = useHistory();
//    const [errorMessage, setErrorMessage] = useState("");
//    const [submitLoading, setSubmitLoading] = useState(false);

//    const { assid } = useParams();
//    const [fields, setFields] = useState({});
//    const apiFetchUrl = `api/assurances/base/${assid}`;

//    const submit = e => {
//        e.preventDefault();
//        setSubmitLoading(true);
//        fetch(apiSubmitUrl, Auth.options('PATCH',fields))
//            .then(res => { if (!res.ok) throw res })
//            .then(_ => history.goBack())
//            .catch(err => setErrorMessage(ErrorHandler(err)))
//            .finally(_ => setSubmitLoading(false));
//    }

//    useEffect(_ => {
//        fetch(apiFetchUrl, Auth.getRequestOptions())
//            .then((res) => {
//                if (!res.ok)
//                    throw res
//                else
//                    return res
//            })
//            .then(res => res.json())
//            .then(json => setFields(json))
//            .catch(err => setErrorMessage(ErrorHandler(err)))
//    }, []);

//    return (MyForm(titre, errorMessage, submit, submitLoading, fields, setFields));
//};

//const MyForm = (titre, errorMessage, submit, submitLoading, fields, setFields) => {
//    return (<Form title={titre} errorMessage={errorMessage} submit={submit} submitLoading={submitLoading}>
//        <Row>
//            <div className="col-md-3 mb-2">
//                <FormInputDate
//                    label="Date Organisation"
//                    value={fields["dateOrganisation"]}
//                    handleChange={val => setFields({ ...fields, "dateOrganisation": val })} />
//                <FormInput label="Adresse Départ"
//                    value={fields["adresseDeDepart"]}
//                    handleChange={val => setFields({ ...fields, "adresseDeDepart": val })} />
//            </div>
//        </Row>
//    </Form>);
//};