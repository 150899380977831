import React from 'react'; 
import Errors from './Errors';
import Required from './Required';

export default (props) => {
    const { type, label, error, required, ...rest } = props;
    // type doit �tre un "text" / "number" / "email" / "password"

    return (<div className="form-group">
        <label>{label}{required && <Required />}</label>
        <input
            className={(`form-control ${error ? "is-invalid" : ""}`)}
            type={type || 'text'}
            required={required}
            {...rest}
        />
        <Errors errors={error} />
    </div>);
}
