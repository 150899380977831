
export const Check = demande => {
    if (!demande) {
        window.alert("Check Error : Pas de données");//todo mettre dans un banner
        return false;
    }

    if (!demande.affilies || demande.affilies.length === 0) {
        let res = window.confirm("Attention, aucun affilié n'a été renseigné dans cette demande, Voulez-vous quand même l'envoyer ?");//todo mettre dans un banner
        if (!res)
            return false;
    }

    return true;
}