import React from "react"
import { Link } from "react-router-dom";

export default ({errorCode, msg1, msg2}) => {
    return (<div className="text-center">
        <div className="error mx-auto" data-text={errorCode}>{errorCode}</div>
        <p className="lead text-gray-800 mb-5">{msg1}</p>
        <p className="text-gray-500 mb-0">{msg2}</p>
        <Link to="/">&larr; Retourner à la page d'accueil</Link>
    </div>);
}