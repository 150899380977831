import React from 'react';
import { useHistory } from "react-router-dom";
import { AssurancesApi } from 'api/AssurancesApi';
import FormatDate, { FormatDateInput } from 'lib/FormatDate';
import useForm from 'services/useForm';
import { Input } from '../../0components/Form';
import { Title } from '../../0components/Jumbotron';
import { DynamicList, TableEdit, Elem, ElemList } from '../../Form/Form';
import { FormatDateShortAndYear } from 'lib/FormatDate';

export default ({ demande }) => {
    //const DateEntre = (libelle, debut, fin) => (debut || fin) && Elem(libelle, `du ${FormatDate(debut)} au ${FormatDate(fin)}`);
    const DateEntre = (libelle, debut, fin) => (debut || fin) && Elem(libelle, `du ${FormatDate(debut)} ${(fin ? `au ${FormatDate(fin)}` : "")} `);

    return (<div>
        {DateEntre("Fléchage (-42)", demande.debutFlechageMoins42, demande.finFlechageMoins42)}
        {DateEntre("Fléchage (+42)", demande.debutFlechagePlus42, demande.finFlechagePlus42)}

        {(demande.debutVoyage || demande.finVoyage || demande.destination) && Elem("Voyage", `du ${FormatDate(demande.debutVoyage)} au ${FormatDate(demande.finVoyage)}: ${demande.destination || "destination non spécifiée"}`)}
        {/*{(demande.debutVisite || demande.finVisite || demande.lieuVisite) && Elem("Visite", `du ${FormatDate2(demande.debutVisite)} au ${FormatDate2(demande.finVisite)}: ${demande.lieuVisite || "lieu de visite non spécifié"}`)}*/}

        {demande.localisation && Elem("Localisation", demande.localisation)}
        {demande.dateSouper && Elem("Date Souper", FormatDateShortAndYear(demande.dateSouper))}
        {demande.dateAG && Elem("Date AG", FormatDateShortAndYear(demande.dateAG))}

        {demande.datesReunions?.length > 0 && ElemList("Dates Réunions", demande?.datesReunions?.map(e => FormatDate(e)))}
        {demande?.entrainements?.length > 0 && ElemList("Marches de détente", demande?.entrainements?.map(e => `${FormatDateShortAndYear(e?.date)} - ${e.localisation}` ))}
        {demande?.datesReconnaissance?.length > 0 && ElemList("Dates Reconnaissance", demande?.datesReconnaissance?.map(e => FormatDate(e)))}
        {demande?.datesPrologue?.length > 0 && ElemList("Dates Prologue", demande?.datesPrologue?.map(e => FormatDate(e)))}
    </div>);
}

export const Form143 = ({ gid: id }) => {
    const history = useHistory();

    const submit = fields => AssurancesApi.create(fields, '143').then(_ => history.goBack());

    return (<MyForm initData={{ "groupId": id }} onSubmit={submit}/>);
};

export const UpdateForm143 = ({ demande }) => {
    const history = useHistory();

    const submit = fields => AssurancesApi.update(fields, '143').then(_ => history.goBack());

    return (<MyForm initData={demande} onSubmit={submit} />);
};

const MyForm = ({ initData, onSubmit }) => {
    const { fields, setFields } = useForm(initData);

    const handleInputDateChange = e => { setFields({ ...fields, [e.target.name]: e.target.value === "" ? null : e.target.value }); };

    const handleSubmit = e => {
        e.preventDefault();
        onSubmit(fields)
    }

    return (<form onSubmit={handleSubmit}>
        <Title>ROI ASS 143 : Extension Assurances marcheurs</Title>
        <p>Extensions assurances marcheurs. Demande de couverture d'assurance: Police n° 45.031.557</p>
        <p>Le club demande l'extension de la couverture assurance de la police n° 45.031.557 pour les membres en ordre de cotisation et repris aux listes nominatives pour : </p>

        <div className="form-row">
            <div className="form-group col-md-auto">
                <p>1. La marche, fléchage, défléchage (moins de 42km): max 7 jours du</p>
            </div>
            <div className="form-group col-md-auto">
                <input
                    className="form-control"
                    name="debutFlechageMoins42"
                    type="date"
                    onChange={handleInputDateChange}
                    value={FormatDateInput(fields?.debutFlechageMoins42)}
                />
            </div>
            <div className="form-group col-md-auto">
                <p>au</p>
            </div>
            <div className="form-group col-md-auto">
                <input
                    type="date"
                    name="finFlechageMoins42"
                    className="form-control"
                    value={FormatDateInput(fields?.finFlechageMoins42)}
                    onChange={handleInputDateChange} />
            </div>
        </div>        

        <div className="form-row">
            <div className="form-group col-md-auto">
                <p>2. La marche, fléchage, défléchage (plus de 42km): max 14 jours du</p>
            </div>
            <div className="form-group col-md-auto">
                <input type="date" name="debutFlechagePlus42" className="form-control" value={FormatDateInput(fields?.debutFlechagePlus42)} onChange={handleInputDateChange} />
            </div>
            <div className="form-group col-md-auto">
                <p>au</p>
            </div>
            <div className="form-group col-md-auto">
                <input type="date" name="finFlechagePlus42" className="form-control" value={FormatDateInput(fields?.finFlechagePlus42)} onChange={handleInputDateChange} />
            </div>
        </div>

        <div className="form-row">
            <div className="form-group col-md-auto">
                <p>3. La reconnaissance du parcours le:</p>
                <DynamicList
                            title="Dates Reconnaissance"
                            type="date"
                            data={fields["datesReconnaissance"]}
                            setData={newVal => setFields({ ...fields, "datesReconnaissance": newVal })} />
            </div>
        </div>

        <div className="form-row">
            <div className="form-group col-md-auto">
                <p>4. L'AG du club le</p>
            </div>
            <div className="form-group col-md-auto">
                <input type="date" name="dateAG" className="form-control" value={FormatDateInput(fields?.dateAG)} onChange={handleInputDateChange} />
            </div>
        </div>

        <div className="form-row">
            <div className="form-group col-md-auto">
                <p>5. Le souper du club le</p>
            </div>
            <div className="form-group col-md-auto">
                <input type="date" name="dateSouper" className="form-control" value={FormatDateInput(fields?.dateSouper)} onChange={handleInputDateChange} />
            </div>
        </div>

        <div className="form-row">
            <div className="form-group col-md-auto">
                <p>6. Le prologue organisé le</p>
                <DynamicList
                    title="Dates Prologue"
                    type="date"
                    data={fields["datesPrologue"]}
                    setData={newVal => setFields({ ...fields, "datesPrologue": newVal })} />
            </div>
        </div>

        <div className="form-row">
            <div className="form-group col-md-auto">
                <p>7. Marche de détente les</p>
                <TableEdit
                    data={fields?.entrainements ?? []}
                    setData={e => setFields({ ...fields, "entrainements": e })}
                    format={[
                        { name: "Date", id: "date", type: "date" },
                        { name: "Localisation", id: "localisation", maxlength: "30" }
                    ]}
                />
            </div>
        </div>

        <div className="form-row">
            <div className="form-group col-md-auto">
                <p>8. Le voyage en autocar avec participation à une marche du</p>
            </div>
            <div className="form-group col-md-auto">
                <input type="date" name="debutVoyage" className="form-control" value={FormatDateInput(fields?.debutVoyage)} onChange={handleInputDateChange} />
            </div>
            <div className="form-group col-md-auto">
                <p>au</p>
            </div>
            <div className="form-group col-md-auto">
                <input type="date" name="finVoyage" className="form-control" value={FormatDateInput(fields?.finVoyage)} onChange={handleInputDateChange} />
            </div>
            <div className="form-group col-md-auto">
                <p>destination:</p>
            </div>
            <div className="form-group col-md-auto">
                <input type="text" name="destination" className="form-control" value={fields?.destination || ""} onChange={handleInputDateChange} />
            </div>
        </div>

        <div className="form-row">
            <div className="form-group col-md-auto">
                <p>8. Les réunions du club organisées les:</p>
                <DynamicList
                    title="Dates Réunions"
                    type="date"
                    data={fields["datesReunions"]}
                    setData={newVal => setFields({ ...fields, "datesReunions": newVal })} />
            </div>
        </div>

        <button type="submit" className="btn btn-primary">Confirmer</button>
    </form>);
};