import React from 'react'
import Bubble from 'components/0components/Bubble'

export default ({ title, array, icon }) => {
    return (<Bubble
        title={title}
        text={array?.length}
        color={array && (array.length > 0 ? "warning" : "info")}
        icon={`${icon} ${(array?.length > 0 && "fa-bounce")}`}
    />)
}