
import React from 'react';

import { generateNewPassword } from 'lib/utils';
import useForm from 'services/useForm';

export default ({ title, handleSubmit, initData }) => {
    const { fields, setFields } = useForm(initData ?? {actif: true});

    return (<>
        <h1 className="display-4 mb-4">{title || "Modification d'un utilisateur"}</h1>
        <form onSubmit={e => handleSubmit(e, fields)}>
            <div className="row mb-4">
                <div className="col-lg-8">
                    <div className="input-group mb-2">
                        <input
                            className="form-control"
                            type="text"
                            placeholder="login"
                            onChange={e => setFields({ ...fields, "login": e.target.value })}
                            value={fields?.login || ""}
                            required
                        />
                    </div>
                    <div className="input-group mb-2">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="mot de passe"
                            onChange={e => setFields({ ...fields, "password": e.target.value })}
                            value={fields?.password || ""}
                        />
                        <div className="input-group-append">
                            <button className="btn btn-primary" type="button" onClick={_ => setFields({ ...fields, "password": generateNewPassword(12) })}>
                                Générer <i className="fas fa-fw fa-sync-alt" />
                            </button>
                        </div>
                    </div>
                    <div className="input-group mb-2">
                        <input
                            className="form-control"
                            type="text"
                            placeholder="nom"
                            onChange={e => setFields({ ...fields, "name": e.target.value })}
                            value={fields?.name || ""}
                            required
                        />
                    </div>
                    {/*<div className="input-group mb-2">*/}
                    {/*    <SelectGroup*/}
                    {/*        onChange={val => setFields({ ...fields, "group": val })}*/}
                    {/*        value={fields?.group || ""}*/}
                    {/*    />*/}
                    {/*</div>*/}
                    {/*<div className="input-group mb-2">*/}
                    {/*    <select*/}
                    {/*        className="custom-select"*/}
                    {/*        onChange={e => setFields({ ...fields, "accessLevel": parseInt(e.target.value) })}*/}
                    {/*        value={fields?.accessLevel || 0}>*/}

                    {/*        <option value={0}>Club</option>*/}
                    {/*        <option value={1}>Province</option>*/}
                    {/*        <option value={2}>CA</option>*/}
                    {/*        <option value={3}>Admin</option>*/}

                    {/*    </select>*/}
                    {/*</div>*/}
                    <div className="input-group mb-2">
                        <textarea 
                            className="form-control"
                            id="userCommentTextArea"
                            rows="3"
                            maxLength="200"
                            placeholder="commentaire"
                            onChange={e => setFields({ ...fields, "comment": e.target.value })}
                            value={fields?.comment || ""}
                        />
                    </div>
                    <div className="input-group">
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                onChange={e => setFields({ ...fields, "actif": e.target.checked })}
                                checked={fields?.actif || false}
                            />
                            <label className="form-check-label">
                                Actif
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <button type="submit" className="btn btn-primary">
                Confirmer<i className="fas fa-sm fa-check ml-1" />
            </button>
        </form>
    </>);
}