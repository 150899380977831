export default date => {
   return new Date(date).toLocaleString("fr-FR", { year: 'numeric', month: 'numeric', day: 'numeric' });
}

export const FormatDateEtHeure = date => {
    let date2 = date + "Z";
    return new Date(date2).toLocaleString("fr-FR", { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' });
};

export const FormatDateInput = date => {
    if (date) {
        let d = new Date(date);
        if (!isNaN(d)) {
            return FormatISO(d).split('T')[0];
        }
    }
    return "";
}

const FormatISO = date => new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();

export const FormatDateShort = date => {// SAM. 10/08
    if (date) {
        let d = new Date(date)
        if (!isNaN(d)) {
            return d.toLocaleDateString("fr-FR", { weekday: 'short', month: 'numeric', day: 'numeric' });
        }
    }
    return date;
}

export const FormatDateShortAndYear = date => {
    if (date) {
        let d = new Date(date)
        if (!isNaN(d)) {
            return d.toLocaleDateString("fr-FR", { weekday: 'short', month: 'numeric', day: 'numeric', year:'numeric' });
        }
    }
    return date;
}

