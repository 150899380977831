import React from 'react';
import { useHistory } from 'react-router-dom';
import FormatDate from 'lib/FormatDate';
import Club from 'bll/Club/Club';
import { Actions, Objects, Show } from 'services/AccessControl';
import Spinner from 'components/0components/Spinner';
import FicheInscriptionClubPdf from "components/pdfs/FicheInscriptionClubPdf";

export default ({ club, candidats, isLoading, handleDeleteCandidat, handleUpdateCandidat }) => {
    const history = useHistory();

    const clickNouvMembre = _ => history.push({
        pathname: '/candidats/create',
        state: { grpid: club.id }
    });

    return (<>
        <div className="row mb-4">
            <div className="col">
                <h3 className="h3" > Candidat(s)</h3>
            </div>
            <div className="col-md-auto ml-2 p-0">
                <Show object={Objects.Candidat} action={Actions.Create}>
                    <button type="button" className="btn btn-primary" disabled={!Club.isCreditSuffisant} onClick={clickNouvMembre}>
                        <span className="text-light">Nouveau Membre <i className="fas fa-fw fa-plus" /></span>
                    </button>
                </Show>
            </div>
            <div className="col-md-auto p-0 ml-2 m-0">
                <FicheInscriptionClubPdf club={club} />
            </div>
        </div>
        {isLoading && <Spinner />}
        {!isLoading && <Candidats
            candidats={candidats}
            handleUpdateCandidat={handleUpdateCandidat}
            handleDeleteCandidat={handleDeleteCandidat} />}
    </>);
}

const Candidats = ({ candidats, handleUpdateCandidat, handleDeleteCandidat}) =>  {
    return(<div className="card">
        <div className="table-responsive">
            <table className="table mb-0">
                <thead className="thead-light">
                    <tr>
                        <th scope="col" className="text-dark">Nom</th>
                        <th scope="col" className="text-dark">Prénom</th>
                        <th scope="col" className="text-dark">Date de Naissance</th>
                        <th scope="col" className="text-dark">Annee d'inscription</th>
                        <th scope="col" className="text-dark">Statut</th>
                        <th scope="col" className="text-dark">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {candidats?.map(m => (<tr key={m.id} className={m?.isRefus ? "table-danger" : ""}>
                        <td className="align-middle">{m.lastName ?? "Pas Disponnible"}</td>
                        <td className="align-middle">{m.firstName}</td>
                        <td className="align-middle">{m?.birthDate ? FormatDate(m.birthDate) : "Pas Disponible"}</td>
                        <td className="align-middle">{m.anneeAffiliation ?? "Pas Disponnible"}</td>
                        <td className="align-middle">{m.isRefus ? (m.refusRaison ?? '/') : <span>En Attente <i className="fas fa-fw fa-clock text-secondary" data-toggle="tooltip" title="Demande en attente" /></span>}</td>
                        <td className="align-middle">
                            {m?.isRefus && <>
                                <Show object={Objects.Candidat} action={Actions.Update}>
                                    <button className="btn btn-primary mr-2" onClick={_ => handleUpdateCandidat(m)} data-toggle="tooltip" title="Modifier">
                                        <i className="fas fa-pen fa-sm" />
                                    </button>
                                </Show>
                                <Show object={Objects.Candidat} action={Actions.Delete}>
                                    <button className="btn btn-danger" onClick={_ => handleDeleteCandidat(m)} data-toggle="tooltip" title="Supprimer">
                                        <i className="fas fa-trash fa-sm" />
                                    </button>
                                </Show>
                            </>}
                        </td>
                    </tr>))}
                </tbody>
            </table>
        </div>
    </div>);
}