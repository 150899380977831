import { Objects } from ".";

export default (ressource) => {
    switch (ressource) { //identifiant des groupes PERMA (GRPMatricule like '%000P') et les commissions
        case "792ec0d4-7036-1030-9a9f-001d096850d6":
            return false;
        case "81f88bb4-7036-1030-9a9f-001d096850d6":
            return false;
        case "8d030796-7036-1030-9a9f-001d096850d6":
            return false;
        case "98e3418e-7036-1030-9a9f-001d096850d6":
            return false;
        case "a23cbb2a-7036-1030-9a9f-001d096850d6":
            return false;
        case "674394b0-59c0-102d-93fa-002219533737":
            return false;
        case "88d3823e-5a1a-102d-93fa-002219533737":
            return false;
        case "9f7e1a9e-5a1a-102d-93fa-002219533737":
            return false;
        case "b8f2d186-5a1a-102d-93fa-002219533737":
            return false;
        case "dba42f14-59bf-102d-93fa-002219533737":
            return false;
        default:
            return true;
    }
}