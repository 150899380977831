import React from 'react';
import FormatDate from 'lib/FormatDate';
import Button from 'components/0components/Button';
import { PDFDownloadLink, Text, Table2, Row, CellHeader, Cell} from '../PdfWorker';
import AssuranceLayoutPDF from './AssuranceLayoutPDF';

export default ({ demande }) => {
    return (<PDFDownloadLink document={<MonDocument demande={demande} />} fileName={`DemandeAssurance${demande.numeroDemande}.pdf`}>
        {({ loading }) => (loading ? 'Génération du document...' : <Button icon="fa-download">Télécharger</Button>)}
    </PDFDownloadLink>);
}

const mydate = d => d ? FormatDate(d) : "...../...../.....";

const MonDocument = ({ demande }) => {
    return (<AssuranceLayoutPDF demande={demande} titre="Annexe au ROI ASS 143" subtitle="Demande de couverture d'assurance: Police n°45.031.557" roi="ROI ASS 143 bis">

        <Text style={{ fontSize: 10, marginBottom: 10 }}>Liste des marcheurs d'autres clubs :</Text>

        {demande?.debutAide && demande?.finAide && <Text style={{ fontSize: 10, margin: 8 }}>qui aident lors de la marche du {mydate(demande?.debutAide)} au {mydate(demande?.finAide)} inclus</Text> }
        {demande?.debutCar && demande?.finCar && <Text style={{ fontSize: 10, margin: 8 }}>qui complètent le car du {mydate(demande?.debutCar)} au {mydate(demande?.finCar)} inclus</Text>}
        {demande?.debutPrologue && demande?.finPrologue && <Text style={{ fontSize: 10, margin: 8 }}>qui participent au prologue du {mydate(demande?.debutPrologue)} au {mydate(demande?.finPrologue)} inclus</Text>}        
        {demande?.dateSouper && <Text style={{ fontSize: 10, margin: 8 }}>qui participent au diner ou souper du club du {mydate(demande?.dateSouper)}</Text>}        
        {demande?.dateMarcheDetente && <Text style={{ fontSize: 10, margin: 8 }}>qui participent à la marche de détente du {mydate(demande?.dateMarcheDetente)}</Text>}        

        <Table2>
            <Row>
                <CellHeader style={{ width: "15%" }}>Matricule</CellHeader>
                <CellHeader style={{ width: "20%" }}>Nom</CellHeader>
                <CellHeader style={{ width: "20%" }}>Prénom</CellHeader>
                <CellHeader style={{ width: "15%" }}>Club</CellHeader>
                <CellHeader style={{ width: "30%" }}>Nom du club</CellHeader>
            </Row>
            {demande?.affilies?.map((e, i) => <Row key={i}>
                <Cell style={{ width: "15%" }}>{e?.matricule ?? "-"}</Cell>
                <Cell style={{ width: "20%" }}>{e?.lastName ?? "-"}</Cell>
                <Cell style={{ width: "20%" }}>{e?.firstName ?? "-"}</Cell>
                <Cell style={{ width: "15%" }}>{e?.groupMatricule ?? "-"}</Cell>
                <Cell style={{ width: "30%" }}>{e?.groupName ?? "-"}</Cell>
            </Row>)}
        </Table2>

    </AssuranceLayoutPDF>);
}