import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useHistory } from "react-router-dom";

import useForm from "services/useForm";

import { removeFromArray } from "lib/utils";
import FormatDate, { FormatDateInput } from "lib/FormatDate";

import Button from "components/0components/Button";
import Spinner from "components/0components/Spinner";
import { MembreApi } from "api/MembreApi";
import { SearchIcon } from "../../Icons";
import { AssurancesApi } from "api/AssurancesApi";

export default ({ demande }) => {
    return (<table className="table mt-3">
        <thead>
            <tr>
                <th scope="col">Date</th>
                <th scope="col">Marque</th>
                <th scope="col">Plaque</th>
                <th scope="col">Matricule</th>
                <th scope="col">Nom</th>
                <th scope="col">Prenom</th>
            </tr>
        </thead>
        <tbody>
            {demande?.vehicules?.map((e, i) => (<tr key={i}>
                <td>{new Date(e?.date).toLocaleDateString()}</td>
                <td>{e.marque}</td>
                <td>{e.plaque}</td>
                <td>{e.matriculeMarcheur}</td>
                <td>{e.nom}</td>
                <td>{e.prenom}</td>
            </tr>))}
        </tbody>
    </table>);
}

export const Form140 = ({gid: id}) => {
    const history = useHistory();

    const validation = fields => {
        let vehicules = fields?.vehicules;

        if (!Array.isArray(vehicules) || vehicules.length === 0) {
            window.alert("Il doit y avoir au moins un véhicule");
            return false;
        }

        for (const vehicule of fields?.vehicules) {
            if (!validationVehicule(vehicule))
                return false;
        };

        return true;
    };

    const handleSubmit = fields => {
        if (validation) {
            AssurancesApi
                .create(fields, '140')
                .then(_ => history.goBack())
        }
    }

    return (<Form
        titre="Création Demande ROI ASS 140 : Dégâts Vehicules"
        initData={{ "groupId": id, "vehicules": [] }}
        handleSubmit={handleSubmit}
    />);
};

export const UpdateForm140 = ({ demande }) => {
    const history = useHistory();

    const validation = fields => {
        let vehicules = fields?.vehicules;

        if (!Array.isArray(vehicules) || vehicules.length === 0) {
            window.alert("Il doit y avoir au moins un véhicule");
            return false;
        }

        for (const vehicule of fields?.vehicules){
            if (!validationVehicule(vehicule))
                return false;
        };

        return true;
    };

    const handleSubmit = fields => {
        if (validation) {
            AssurancesApi
                .update(fields, '140')
                .then(_ => history.goBack());
        }
    }

    return (<Form
        titre="Modification Demande ROI ASS 140 : Dégâts Vehicules"
        initData={demande}
        handleSubmit={handleSubmit}
    />);
};

const Form = ({ titre, initData, handleSubmit }) => {
    const { fields, setFields } = useForm(initData);

    const clickSubmit = e => {
        e.preventDefault();
        handleSubmit(fields);
    }

    return (<>
        <h1 className="display-4 mb-4">{titre}</h1>
        {!fields && <Spinner />}
        {fields && <>
            <MyTable data={fields.vehicules} updateData={data => setFields({ ...fields, "vehicules": data })}></MyTable>
            <Button onClick={clickSubmit}>Confirmer <i className="fas fa-sm fa-check" /></Button>
        </>}
    </>);
}

const MyTable = ({ data, updateData, ...rest }) => {
    const [ isAddModalVisible, setIsModalVisible ] = useState(false);

    const addVehicule = (e, v) => {
        e.preventDefault();
        if (validationVehicule(v)) {
            setIsModalVisible(false);
            let tmp = [...data];
            tmp.push(v);
            updateData(tmp);
        } else {
            alert("Erreur dans les informations du véhicule");
        }
    }

    const handleSupprVehicule = (e, i) => {
        e.preventDefault();
        updateData(removeFromArray(i, data));
    };

    return (<div {...rest}>
        <MyModal isVisible={isAddModalVisible} setIsVisible={setIsModalVisible} handleAddVehicule={(e,v) => addVehicule(e,v)} />
        <table className="table table-striped">
            <thead className="thead-dark">
                <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Marque</th>
                    <th scope="col">Plaque</th>
                    <th scope="col">Matricule</th>
                    <th scope="col">Nom</th>
                    <th scope="col">Prenom</th>
                    <th scope="col"><Button onClick={_ => setIsModalVisible(true)}><i className="fas fa-sm fa-plus"/></Button></th>
                </tr>
            </thead>
            <tbody>
                {data?.map((e, i) => (<tr key={i}>
                    <td>{FormatDate(e?.date)}</td>
                    <td>{e.marque}</td>
                    <td>{e.plaque}</td>
                    <td>{e.matriculeMarcheur}</td>
                    <td>{e.nom}</td>
                    <td>{e.prenom}</td>
                    <td><Button onClick={e => handleSupprVehicule(e, i)}><i className="fas fa-sm fa-minus"/></Button></td>
                </tr>))}
            </tbody>
        </table>
    </div>);
}

const validString = (s, min, max) => {
    if (typeof s !== 'string') return false; // la variable n'est pas un string
    if (min !== undefined && s.length < min) return false; // la variable est inférieure à min
    if (max !== undefined && s.length > max) return false; // la variable est suppérieure à max

    return true;
}

const validationVehicule = v => {
    let isValid = true;

    //date
    let date = Date.parse(v?.date);

    if (isNaN(date)) {
        window.alert("La date est invalide");
        isValid = false;
    } else {
        if (new Date(date).getTime() < new Date().getTime()) {
            window.alert("La date doit être suppérieur à la date du jour");
            isValid = false;
        }
    }

    // marque
    if (!validString(v?.marque, 1, 100)) {
        window.alert("La marque du véhicule est invalide");
        isValid = false;
    }

    // plaque
    if (!validString(v?.plaque, 1, 10)) {
        window.alert("La plaque du véhicule est invalide");
        isValid = false;
    }

    // matriculeMarcheur
    if (!validString(v?.matriculeMarcheur, 1, 8)) {
        window.alert("Le matricule est invalide");
        isValid = false;
    }

    //nom 
    if (!validString(v?.nom, 1)) {
        window.alert("Le nom est invalide");
        isValid = false;
    }

    return isValid;
}

const MyModal = ({ isVisible, setIsVisible, handleAddVehicule }) => {
    const [membres, setMembres] = useState();
   
    const [vehicule, setVehicule] = useState({
        date: "",
        marque: "",
        plaque: "",
        matriculeMarcheur: "",
        nom: "",
        prenom: ""
    });

    const addMarcheur = (e, m) => {
        e.preventDefault();
        setVehicule({ ...vehicule, "matriculeMarcheur": m.matricule, "nom": m.lastName, "prenom": m.firstName });
        setMembres();
    }

    const fetchMembres = _ => {
        MembreApi
            .search(vehicule?.matriculeMarcheur)
            .then(res => setMembres(res));
    }

    return (<Modal isOpen={isVisible} toggle={_ => setIsVisible(false)}>
        <ModalHeader toggle={_ => setIsVisible(false)}>Ajouter un véhicule</ModalHeader>
        <ModalBody>
            <form onSubmit={e => handleAddVehicule(e, vehicule)}>
                <label>Date</label>
                <input
                    className="form-control mr-sm-2 mb-2"
                    type="date"
                    onChange={e => setVehicule({ ...vehicule, date: e.target.value })}
                    min={FormatDateInput(new Date().setDate(new Date().getDate() +1))}
                    value={FormatDateInput(vehicule?.date)}
                    required
                />
                <label>Marque</label>
                <input
                    className="form-control mr-sm-2 mb-2"
                    type="text"
                    maxLength="100"
                    onChange={e => setVehicule({ ...vehicule, "marque": e.target.value })}
                    value={vehicule?.marque}
                    required
                />
                <label>Plaque</label>
                <input
                    className="form-control mr-sm-2 mb-2"
                    type="text"
                    maxLength="10"
                    onChange={e => setVehicule({ ...vehicule, "plaque": e.target.value })}
                    value={vehicule?.plaque}
                    required
                />

                <label>Recherche par matricule</label>
                <div className="input-group mb-2">
                    <input
                        className="form-control"
                        type="text"
                        onChange={e => setVehicule({ ...vehicule, "matriculeMarcheur": e.target.value })}
                        value={vehicule?.matriculeMarcheur}
                        placeholder="ex: 123456"
                        required
                    />
                    <div className="input-group-append">
                        <button className="btn btn-primary" type="button" onClick={_ => fetchMembres()}><SearchIcon /></button>
                    </div>
                </div>

                {membres && <div className="list-group">
                    {membres.map(m => <div key={m.id} className="list-group-item list-group-item-action list-group-item-light" onClick={e => addMarcheur(e, m)} >
                        <div className="row">
                            <div className="col">
                                {m.matricule} - {m.firstName} {m.lastName}
                            </div>
                        </div>
                    </div>)}
                </div>}

                <label>Nom:</label>
                <p>{vehicule?.nom}</p>

                <label>Prenom:</label>
                <p>{vehicule?.prenom}</p>

                <button type="submit" className="btn btn-primary">Ajouter <i className="fas fa-sm fa-plus" /></button>
            </form>
        </ModalBody>
    </Modal>);
}