import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "components/0components/Button";
import Form, { Elem, ElemList, MyModal, TableEdit } from 'components/Form/Form';
import FormatDate, { FormatDateInput } from "lib/FormatDate";
import { AssurancesApi } from "api/AssurancesApi";
import { MembreApi } from "api/MembreApi";
import A143BisPDFBarCode from "components/pdfs/Assurances/A143BisPDFBarCode";
import Label from "components/Common/Label";
import { removeFromArray } from 'lib/utils';
import { Check } from 'bll/AssurancesBL/A143BisBL';
import { FormatDateShortAndYear } from 'lib/FormatDate';

export default ({ demande }) => {
    const DateEntre = (libelle, debut, fin) => (debut || fin) && Elem(libelle, `du ${FormatDate(debut)} ${(fin ? `au ${FormatDate(fin)}` : "")} `);

    return (<div>
        {DateEntre("Aide", demande.debutAide, demande.finAide)}
        {DateEntre("Car", demande.debutCar, demande.finCar)}
        {(demande.debutCar || demande.finCar) && <A143BisPDFBarCode demande={demande} />}

        {DateEntre("Prologue", demande.debutPrologue, demande.finPrologue)}
        {demande?.dateSouper && Elem("Date Souper", FormatDateShortAndYear(demande.dateSouper))}
        {demande?.dateMarcheDetente && Elem("Date Marche de détente", FormatDateShortAndYear(demande.dateMarcheDetente))} 

        {ElemList("Affiliés", demande?.affilies?.map(elem => `${elem.firstName} ${elem.lastName} - ${elem.matricule}`))}
    </div>);
}

export const Form143Bis = ({ gid: id }) => {
    const history = useHistory();

    const handleSubmit = fields => AssurancesApi.create(fields, '143Bis').then(_ => history.goBack())

    return (<MyForm
        titre="Création Demande ROI ASS 143Bis : Annexe au ROI ASS 143"
        initData={{
            "groupId": id,
            "affilies": []
        }}
        onSubmit={handleSubmit}
    />);
};
    
export const UpdateForm143Bis = ({ demande }) => {
    const history = useHistory();

    const handleSubmit = fields => AssurancesApi.update(fields, '143Bis').then(_ => history.goBack())

    return (<MyForm
        titre="Modification Demande ROI ASS 143Bis : Annexe au ROI ASS 143"
        initData={demande}
        onSubmit={handleSubmit}
    />);
};

const MyForm = ({ titre, initData, onSubmit }) => {
    const [fields, setFields] = useState(initData);

    const handleSubmit = e => {
        e.preventDefault();

        if (Check(fields))
            onSubmit(fields);
    }

    const handleInputDateChange = e => { setFields({ ...fields, [e.target.name]: e.target.value === "" ? null : e.target.value }); };

    const handleChangeAffilies = data => {
        setFields({ ...fields, "affilies": data });
    };

    return (<div className="card">
        <div className="card-body">
            <Form title={titre} submit={handleSubmit}>
                <div className="m-4">
                    <div className="row mb-2">
                        <div className="col-2 my-auto">
                            <Label>Aide:</Label>
                        </div>
                        <div className="col-auto">
                            <input
                                className="form-control"
                                name="debutAide"
                                type="date"
                                onChange={handleInputDateChange}
                                value={FormatDateInput(fields?.debutAide)}
                            />
                        </div>
                        <div className="col-auto my-auto p-1">
                            <span>au</span>
                        </div>
                        <div className="col-auto">
                            <input
                                className="form-control"
                                name="finAide"
                                type="date"
                                min={FormatDateInput(fields?.debutAide)}
                                onChange={handleInputDateChange}
                                value={FormatDateInput(fields?.finAide)}
                            />
                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="col-2 my-auto">
                            <span>Car: </span>
                        </div>
                        <div className="col-auto">
                            <input
                                className="form-control"
                                name="debutCar"
                                type="date"
                                onChange={handleInputDateChange}
                                value={FormatDateInput(fields?.debutCar)}
                            />
                        </div>
                        <div className="col-auto my-auto p-1">
                            <span>au</span>
                        </div>
                        <div className="col-auto">
                            <input
                                className="form-control"
                                name="finCar"
                                type="date"
                                min={FormatDateInput(fields?.debutCar)}
                                onChange={handleInputDateChange}
                                value={FormatDateInput(fields?.finCar)}
                            />
                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="col-2 my-auto">
                            <span>Prologue: </span>
                        </div>
                        <div className="col-auto">
                            <input
                                className="form-control"
                                name="debutPrologue"
                                type="date"
                                onChange={handleInputDateChange}
                                value={FormatDateInput(fields?.debutPrologue)}
                            />
                        </div>
                        <div className="col-auto my-auto p-1">
                            <span>au</span>
                        </div>
                        <div className="col-auto">
                            <input
                                className="form-control"
                                name="finPrologue"
                                type="date"
                                min={FormatDateInput(fields?.debutPrologue)}
                                onChange={handleInputDateChange}
                                value={FormatDateInput(fields?.finPrologue)}
                            />
                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="col-2 my-auto">
                            <span>Date Souper: </span>
                        </div>
                        <div className="col-auto">
                            <input
                                className="form-control"
                                name="dateSouper"
                                type="date"
                                onChange={handleInputDateChange}
                                value={FormatDateInput(fields?.dateSouper)}
                            />
                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="col-2 my-auto">
                            <span>Date Marche de détente: </span>
                        </div>
                        <div className="col-auto">
                            <input
                                className="form-control"
                                name="dateMarcheDetente"
                                type="date"
                                onChange={handleInputDateChange}
                                value={FormatDateInput(fields?.dateMarcheDetente)}
                            />
                        </div>
                    </div>
                </div>
                <div className="card">
                    
                    <div className="card-body m-0">
                        <h3 className="card-title m-2">Affiliés</h3>
                        <TableAffilies
                            affilies={fields.affilies}
                            handleChange={handleChangeAffilies}
                        />
                    </div>
                </div>
                <br/>

            </Form>
        </div>
    </div>);
};

const TableAffilies = ({ affilies, handleChange }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const addAffilie = a => {
        let tmp = [...affilies];
        tmp.push(a);
        handleChange(tmp);
        setIsModalVisible(false);
    };

    const handleRemoveAffilie = (e, i) => {
        handleChange(removeFromArray(i, affilies));
        e.preventDefault();
    };

    if (!affilies)
        return null;

    return (<>
        <MyModal title="Recherche par matricule" isVisible={isModalVisible} setIsVisible={setIsModalVisible}>
            <FormAddUser addAffilie={addAffilie} />
        </MyModal>
        <div className="table-responsive-xl">
            <table className="table table-striped">
                <thead className="thead-primary">
                    <tr>
                        <th scope="col">Matricule</th>
                        <th scope="col">Nom</th>
                        <th scope="col">Prenom</th>
                        <th scope="col">Club</th>
                        <th scope="col">Nom du Club</th>
                        <th scope="col"><Button onClick={_ => setIsModalVisible(true)}>+</Button></th>
                    </tr>
                </thead>
                <tbody>
                    {affilies?.map((a, i) => <tr key={i}>
                        <td>{a?.matricule}</td>
                        <td>{a?.lastName}</td>
                        <td>{a?.firstName}</td>
                        <td>{a?.groupMatricule}</td>
                        <td>{a?.groupName}</td>
                        <td>
                            <button
                                className="btn btn-danger"
                                onClick={e => handleRemoveAffilie(e, i)}
                                data-toggle="tooltip"
                                title="Supprimer">
                                <i className="fas fa-trash fa-sm" />
                            </button>
                        </td>
                    </tr>)}
                </tbody>
            </table>
        </div>
    </>);
}

const FormAddUser = ({ addAffilie }) => {
    const [search, setSearch] = useState("");
    const [membres, setMembres] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {//todo abort controller
        let unsubscribed = false;
        setIsLoading(true);

        MembreApi
            .search(search)
            .then(res => {
                if (!unsubscribed)
                    setMembres(res);
            })
            .finally(_ => setIsLoading(false));

        return (_ => unsubscribed = true);
    }, [search]);

    const handleClickAddAffilie = (event, affilie) => {
        event.preventDefault();
        addAffilie(affilie);
    }

    return (<>
        <Label isLoading={isLoading}> Recherche par matricule</Label>
        <div className="input-group mb-2">
            <input
                className="form-control"
                type="text"
                onChange={e => setSearch(e.target.value)}
                value={search}
                placeholder="ex: 123456"
                required
            />
        </div>
        <div className="list-group">
            {membres.map((affilie,index) => (
                <p key={index} className="list-group-item list-group-item-action list-group-item-light mb-0" onClick={e => handleClickAddAffilie(e, affilie)} >
                    <b>{affilie.matricule}</b> {` - ${affilie.firstName} ${affilie.lastName} - `} <i>{affilie.groupMatricule}</i>
                </p>))}
        </div>
    </>);
}