import React from "react";
import { useHistory } from "react-router-dom";
import { AssurancesApi } from "api/AssurancesApi";
import { FormatDateInput } from "lib/FormatDate";
import useForm from "services/useForm";
import Form, { FormInputDate, SubTitle, Table, TableEdit, ElemDate, Label } from '../../Form/Form';

export default ({demande}) => {
    return (<div>
        {ElemDate("Date Organisation Debut", demande.dateOrganisationDebut)}
        {ElemDate("Date Organisation Fin", demande.dateOrganisationFin)}
        {ElemDate("Date Coverture Debut", demande.dateCouvertureDebut)}
        {ElemDate("Date Couverture Fin", demande.dateCouvertureFin)}
        <p><b>Adresse de départ: </b>{demande.adresseDeDepart}</p>
        <SubTitle>Adresses de Contrôle</SubTitle>
        <Table
            data={demande?.adresseControles}
            format={[
                { id: "local", name: "Local" },
                { id: "nomProprietaire", name: "Nom Propriétaire" },
                { id: "prenomProprietaire", name: "Prénom Propriétaire" },
                { id: "adresseProprietaire", name: "Adresse Propriétaire" }
            ]}
        />
    </div>);
}

export const Form144 = ({ gid: id }) => {
    const history = useHistory();

    const submit = fields => AssurancesApi.create(fields, '144').then(_ => history.goBack());

    return (<MyForm
        titre="Création Demande ROI ASS 144 : Incendie 'Bâtiment Privé'"
        initData={{ "groupId": id }}
        onSubmit={submit}
    />);
};

export const UpdateForm144 = ({ demande }) => {
    const history = useHistory();

    const submit = fields => AssurancesApi.update(fields, '144').then(_ => history.goBack());

    return (<MyForm
        titre="Modification Demande ROI ASS 144 : Incendie 'Bâtiment Privé'"
        initData={demande}
        onSubmit={submit}
        />);
};

const MyForm = ({ titre, initData, onSubmit }) => {
    const { fields, setFields } = useForm(initData);

    const handleSubmit = e => {
        e.preventDefault();
        onSubmit(fields);
    }

    return (
        <Form title={titre} submit={handleSubmit}>
            <div className="row">
                <div className="col-md-6">
                    <SubTitle>Organisation</SubTitle>
                    <div className="row">
                        <div className="col-md-6">
                            <FormInputDate
                                label="Début"
                                value={FormatDateInput(fields["dateOrganisationDebut"])}
                                handleChange={val => setFields({ ...fields, "dateOrganisationDebut": val })}
                            />
                        </div>
                        <div className="col-md-6">
                            <FormInputDate
                                label="Fin"
                                value={FormatDateInput(fields["dateOrganisationFin"])}
                                handleChange={val => setFields({ ...fields, "dateOrganisationFin": val })}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <SubTitle>Couverture</SubTitle>
                    <div className="row">
                        <div className="col-md-6">
                            <FormInputDate
                                label="Début"
                                value={FormatDateInput(fields["dateCouvertureDebut"])}
                                handleChange={val => setFields({ ...fields, "dateCouvertureDebut": val })}
                            />
                        </div>
                        <div className="col-md-6">
                            <FormInputDate
                                label="Fin"
                                value={FormatDateInput(fields["dateCouvertureFin"])}
                                handleChange={val => setFields({ ...fields, "dateCouvertureFin": val })}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 mb-1">
                    <div className="m-2">
                        <Label label="Adresse De Départ" />
                        <textarea
                            className="form-control mr-sm-2"
                            id="adresseDeDepart"
                            rows="3"
                            value={fields["adresseDeDepart"] ?? ""}
                            onChange={e => setFields({ ...fields, "adresseDeDepart": e.target.value })}
                        >
                        </textarea>
                    </div>
                </div>
            </div>
            <TableEdit
                data={fields?.adresseControles}
                setData={e => setFields({ ...fields, "adresseControles": e })}
                format={[
                    { id: "local", name: "Local"},
                    { id: "nomProprietaire", name: "Nom Propriétaire" },
                    { id: "prenomProprietaire", name: "Prénom Propriétaire" },
                    { id: "adresseProprietaire", name: "Adresse Propriétaire" }
                ]} />
        </Form>);
};