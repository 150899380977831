import React, { useState } from 'react';
import { FormSelect } from '../Form/Form';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import createTokenProvider from '../../services/createTokenProvider';

export default _ => {
    const [isVisible, setIsVisible] = useState(false);

    return (<div className="d-flex justify-content-between align-items-center" onClick={_ => setIsVisible(true)}>
        <p className="my-auto"><span className="badge badge-success mr-3">XLSX</span>Export Imprimeur (cartes)</p>
        <button className="btn btn-primary ml-3" >
            <i className="fas fa-sm fa-arrow-down" data-toggle="tooltip" title="Télécharger" />
        </button>
        {isVisible && <MyModal isVisible={isVisible} setIsVisible={setIsVisible} />}
    </div>);
}

const MyModal = ({ isVisible, setIsVisible }) => {
    const [province, setProvince] = useState("");
    const [annee, setAnnee] = useState(new Date().getFullYear());

    return (<Modal isOpen={isVisible} toggle={_ => setIsVisible(!isVisible)}>
        <ModalHeader >XLSX - Cartes Imprimeur Par Province</ModalHeader>
        <ModalBody>
            <FormSelect label="Province" required value={province} handleChange={val => setProvince(val)}>
                <option value="">Sélectionner une province</option>
                <option value="dfc8be92-8cb2-11de-a173-001372568c9a">BRUXELLES - BRABANT WALLON</option>
                <option value="dfc8c0fe-8cb2-11de-a173-001372568c9a">HAINAUT</option>
                <option value="dfc8c284-8cb2-11de-a173-001372568c9a">LIEGE</option>
                <option value="dfc8c3f6-8cb2-11de-a173-001372568c9a">LUXEMBOURG</option>
                <option value="dfc8c554-8cb2-11de-a173-001372568c9a">NAMUR</option>
            </FormSelect>
            {new Date().getMonth() === 11 && <FormSelect value={annee} handleChange={val => setAnnee(val)}>
                <option>{new Date().getFullYear()}</option>
                <option>{new Date().getFullYear() + 1}</option>
            </FormSelect>}
            {(province !== "") && <button className="btn btn-primary" onClick={_ => { window.open(`/api/members/province/${province}/cartes?annee=${annee}&jwt=${createTokenProvider().getToken()}`) }} >Télécharger</button>}
        </ModalBody>
    </Modal>);
}
