import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Spinner } from 'reactstrap';

import FormatDate from 'lib/FormatDate';
import { AccesApi } from 'api/AccesApi';
import { UtilisateurApi } from 'api/UtilisateursApi';

import ModalPerm from './ModalPerm';

export default _ => {
    const { id } = useParams();

    const [permissions, setPermissions] = useState([]);
    const [user, setUser] = useState({});

    const [isVisibleModal, setIsVisibleModal] = useState(false);

    const handleDelete = accesid => {
        if (window.confirm(`Confirmez-vous la suppression de cette permission?`)) {
            AccesApi
                .delete(accesid)
                .then(_ => fetchPermissions());
        }
    }

    function fetchPermissions()
    {
        AccesApi
            .get(id)
            .then(res => setPermissions(res));
    }

    function fetchUser()
    {
        UtilisateurApi
            .get(id)
            .then(res => setUser(res));
    }

    const clickAddPermission = e => {
        e.stopPropagation();
        setIsVisibleModal(true);
    }
    
    useEffect(fetchPermissions, []);
    useEffect(fetchUser, []);

    if (!user)
        return <Spinner />;

    return (<>
        <ModalPerm setIsVisibleModal={setIsVisibleModal} isVisibleModal={isVisibleModal} reload={fetchPermissions} />
        <h1 className="display-4">
            {user?.name}
            <span className="lead">
                ({user?.login}) {user.actif ? <span className="badge badge-success">Actif</span> : <span className="badge badge-secondary">Pas Actif</span>}
            </span>
        </h1>
        
        <div className="row mb-3">
            {/*<div className="col-auto">*/}
            {/*    Status: {isLoading ? <div className="spinner-border text-primary" role="status" style={{ height: "1rem", width: "1rem" }} ><span className="sr-only">Chargement...</span></div> : <span><i className="fas fa-check-circle fa-sm text-success" data-toggle="tooltip" title="à jour" style={{ height: "1rem", width: "1rem" }} /> Sauvegardé</span>}*/}
            {/*</div>*/}
            <div className="col" />
            <div className="col-auto">
                <button className="btn btn-primary" onClick={clickAddPermission}>
                    Ajouter Permission <i className="fas fa-plus fa-sm" data-toggle="tooltip" title="Supprimer" />
                </button>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <div className="card">
                    <div className="table-responsive">
                        <table className="table ">
                            <thead>
                                <tr>
                                    <th scope="col">Role</th>
                                    <th scope="col">Groupe</th>
                                    <th scope="col">Date Début</th>
                                    <th scope="col">Date Fin</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {permissions?.map((p, i)  => (<tr key={i}>
                                    <td>{p.role2}</td>
                                    <td>{p.group}</td>
                                    <td>{p.startDate ? FormatDate(p.startDate) : ""}</td>
                                    <td>{p.endDate ? FormatDate(p.endDate) : ""}</td>
                                    <td>
                                        <button className="btn btn-danger" data-toggle="tooltip" title="Supprimer" onClick={e => { e.stopPropagation(); handleDelete(p.id); }}>
                                            <i className="fas fa-trash fa-sm" />
                                        </button>
                                    </td>
                                </tr>))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    </>);
};