import React, { useState } from 'react';
import { useHistory } from "react-router-dom";

import Button from 'components/0components/Button';

import BtnModify from "./BtnModify";
import ModalInfo from "./ModalInfo";

export default ({ club }) => {
    const history = useHistory();
    const [showModal, setShowModal] = useState(false);

    if (!club)
        return <></>;

    const toggleModal = _ => setShowModal(!showModal);
    const handleModify = _ => history.push({ pathname: `/clubs/${club?.id}/modifier/` });

    return (<>
        <Button onClick={toggleModal} ><i className="fas fa-fw fa-info" /></Button>
        <ModalInfo club={club} isVisible={showModal} toggle={toggleModal}>
            <BtnModify clickModify={handleModify}/>
        </ModalInfo>
    </>);
}