import React, { useState } from 'react';
import { useHistory } from "react-router-dom";

import useForm from 'services/useForm';

import Club from 'bll/Club/Club';

import { CandidatApi } from 'api/CandidatApi';

import { SubmitButtonLoading, Required } from '../Form/Form';
import FormCoordonnees from '../Form/FormCoordonnees';
import { useLocation } from 'react-router-dom';

export default _ => {
    const history = useHistory();
    const location = useLocation();

    const { grpid } = location.state || {};

    const [isLoading, setIsLoading] = useState(false);

    const { fields, setFields, handleInputChange } = useForm(
        {
            "anneeAffiliation": (Club.isPeriodeReaffiliation ? new Date().getFullYear() + 1 : new Date().getFullYear()),
            'groupId': grpid,
            "familyChief": false
        });

    const handleSubmit = e => {
        e.preventDefault();
        setIsLoading(true);

        let data = fields; 
        data.anneeAffiliation = Number.parseInt(data.anneeAffiliation);

        if (fields.matricule) {
            CandidatApi
                .createAndTransfert(data.matricule, data.groupId, data.anneeAffiliation)
                .then(_ => history.goBack())
                .catch(err => console.log(err))
                .finally(_ => setIsLoading(false))
        }
        else {
            CandidatApi.create(data)
                .then(_ => history.goBack())
                .catch(err => console.log(err))
                .finally(_ => setIsLoading(false))
        }

        
    }

    return (<FormulaireCandidat
        fields={fields}
        setFields={setFields}
        submit={handleSubmit}
        isLoading={isLoading}
        handleInputChange={handleInputChange}
    />);
}

const FormulaireCandidat = ({ fields, setFields, submit, isLoading, handleInputChange }) => {

    const yearAffiliation = _ => {
        let r = [new Date().getFullYear()];
        if (Club.isPeriodeReaffiliation) //si on est en décembre
            r.push(new Date().getFullYear() + 1); //proposer l'année suivante
        return r;
    }

    return (<form onSubmit={submit}>
        <h1 className="display-4 mb-4">Nouveau Membre</h1>
        <div className="row">
            <div className="col-lg-4">
                <label>Année d'affiliation<Required /></label>
                <select
                    className="custom-select mr-sm-2"
                    name="anneeAffiliation"
                    onChange={handleInputChange}
                    value={fields?.anneeAffiliation}
                    disabled={yearAffiliation().length <= 1}
                    required
                >
                    {yearAffiliation().map((annee, i) => <option key={i} value={annee}>{annee.toString()}</option>)}
                </select>
            </div>
            <div className="col-lg-4">
                <label>Matricule (En cas de demande de Transfert)</label>
                <input
                    className="form-control"
                    name="matricule"
                    type="text"
                    minLength="0"
                    maxLength="6"
                    placeholder="Matricule du membre a transférer"
                    onChange={handleInputChange}
                    value={fields?.matricule ?? ""}
                />
            </div>
        </div>
        {(!fields?.matricule || fields.matricule === "") && <>
            <div className="row">
                <div className="col">
                    <label>Nom<Required /></label>
                    <input
                        className="form-control"
                        type="text"
                        minLength="1"
                        maxLength="60"
                        placeholder="Nom de l'affilié"
                        required
                        name="lastName"
                        onChange={handleInputChange}
                        value={fields?.lastName}
                    />
                </div>
                <div className="col">
                    <label>Prénom<Required /></label>
                    <input
                        className="form-control"
                        type="text"
                        maxLength="60"
                        placeholder="Prénom de l'affilié"
                        required
                        name="firstName"
                        onChange={handleInputChange}
                        value={fields?.firstName}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-lg-2">
                    <label>Date de Naissance <Required /></label>
                    <input
                        className="form-control mr-sm-2"
                        type="date"
                        max={new Date().toISOString().split('T')[0]}
                        required
                        name="birthDate"
                        onChange={handleInputChange}
                        value={fields?.birthDate}
                    />
                </div>
                <div className="col-lg-auto">
                    <label className="mr-1">Sexe<Required /></label>
                    <br />
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="radioGender"
                            value="M"
                            onChange={_ => setFields({ ...fields, "gender": "M" })}
                            checked={fields?.gender === "M"}
                            required
                        />
                        <label className="form-check-label">M</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="radioGender"
                            value="F"
                            onChange={_ => setFields({ ...fields, "gender": "F" })}
                            checked={fields?.gender === "F"}
                            required
                        />
                        <label className="form-check-label">F</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="radioGender"
                            value="A"
                            onChange={_ => setFields({ ...fields, "gender": "A" })}
                            checked={fields?.gender === "A"}
                            required
                        />
                        <label className="form-check-label">Autre</label>
                    </div>
                </div>
                <div className="col-lg-auto">
                    <label className="mr-1">Chef de Famille</label>
                    <br />
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="radioFamily"
                            value="1"
                            onChange={e => setFields({ ...fields, "familyChief": true })}
                            checked={fields?.familyChief === true}
                        />
                        <label className="form-check-label">Oui</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="radioFamily"
                            value="0"
                            onChange={e => setFields({ ...fields, "familyChief": false })}
                            checked={fields?.familyChief === false}
                        />
                        <label className="form-check-label">Non</label>
                    </div>
                </div>
            </div>
            <FormCoordonnees fields={fields?.coord ?? {}} setFields={newVal => setFields({ ...fields, "coord": newVal })} />
        </>}
        <SubmitButtonLoading isLoading={isLoading} />
    </form>);
}