import { Auth } from "components/Form/Form";

const url = "/api/v1/assurances";

export const AssurancesApi = {
    get: (id) => {
        return fetch(`${url}/${id}`, Auth.options('GET'))
            .then(res => res.json())
    },

    getToValidate: () => {
        return fetch(`${url}/tovalidate`, Auth.options('GET'))
            .then(res => res.json())
    },

    search: (startDate, endDate) => {
        return fetch(`${url}?startDate=${startDate}&endDate=${endDate}`, Auth.options('GET'))
            .then(res => res.json())
    },

    setStatusValid: (id) => {
        return fetch(`${url}/${id}/setValid`, Auth.options('PATCH'))
    },

    setStatusOnHold: (id) => {
        return fetch(`${url}/${id}/setOnHold`, Auth.options('PATCH'))
    },

    setStatusReject: (id, note) => {
        return fetch(`${url}/refus`, Auth.options('PATCH', {
            'id': id,
            'note': note
        }))
    },

    create: (data, code) => {
        if (code === 1470 && (data?.affilies?.length > 6 || data?.affilies?.length < 1)) {
            alert("Le nombre de membres doit être compris entre 1 et 6");
            return;
        }

        return fetch(`api/assurances/${code}`, Auth.options('POST', data))
    },

    update: (data, code) => {
        if (code === 1470 && (data?.affilies?.length > 6 || data?.affilies?.length < 1)) {
            alert("Le nombre de membres doit être compris entre 1 et 6");
            return;
        }

        return fetch(`api/assurances/${code}`, Auth.options('PATCH',data))
    },
}