import React, { useEffect, useState } from 'react';
import Spinner from '../0components/Spinner';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import OrganigrammeLayout from './OrganigrammeLayout';
import { Button } from '../0components/Form';
import { OrganigrammeApi } from '../../api/OrganigrammeApi';
import { ClubApi } from '../../api/ClubApi';

export default ({ id }) => {
    const [isVisible, setIsVisible] = useState(false);

    return (<div onClick={_ => setIsVisible(true)}>
        <Button><i className="fa-solid fa-file-export" data-toggle="tooltip" title="Exporter l'organigramme" /></Button>
        {isVisible && <MyModal
            grpId={id}
            isVisible={isVisible}
            setIsVisible={val => setIsVisible(val)} />}
    </div>);
}

const MyModal = ({ grpId, isVisible, setIsVisible }) => {
    const [orga, setOrga] = useState();
    const [orgaSec, setOrgaSec] = useState();
    const [club, setClub] = useState();

    const [isLoading, setIsLoading] = useState(true);

    useEffect(_ => {
        Promise.all([
            OrganigrammeApi
                .get(grpId)
                .then(res => setOrga(res)),
            OrganigrammeApi
                .getSecretary(grpId)
                .then(res => setOrgaSec(res)),
            ClubApi
                .get(grpId)
                .then(res => setClub(res))
        ]).finally(_ => setIsLoading(false))
    }, [grpId])


    return (<Modal isOpen={isVisible} toggle={_ => setIsVisible(false)}>
        <ModalHeader toggle={_ => setIsVisible(false)}>PDF - Organigramme du club</ModalHeader>
        <ModalBody>
            {(isLoading) && <> Chargement des données...<Spinner /> </>}
            {!isLoading && <OrganigrammeLayout club={club} orga={orga} secretaire={orgaSec} />}
        </ModalBody>
    </Modal>);
}
