import React from 'react';
import DownloadPdf from '../DownloadPdf';
import PdfWorker, { View, Text, Image } from '../PdfWorker';
import PdfTableWalksV2 from './components/PdfTableWalksV2';

export default ({ fileName, data }) => (<DownloadPdf document={MonDocument(data)} fileName={fileName} />);

const MonDocument = data => {
    const { marches, province, annee, responsableProvince } = data;

    return (<PdfWorker>
        <View style={{ width: "auto", flexDirection: "row", marginBottom: 5 }}>
            <View style={{ width: "20%" }}>
                <Image src="img/FFBMPLogoC.jpg" />
            </View>
            <View style={{ width: "50%" }}>
            </View>
            <View style={{ width: "30%" }}>
                <Text style={{ fontSize: 22, marginBottom: 5, textAlign: 'right' }}>FFBMP</Text>
                <Text style={{ fontSize: 18, textAlign: 'right' }}>Calendrier</Text>
            </View>
        </View>

        <View style={{ width: "auto" }}>
            <Text style={{ fontSize: 10, marginBottom: 5 }}>Responsable provincial</Text>
            <Text style={{ fontSize: 10, marginBottom: 5 }}>{`${responsableProvince?.lastName} ${responsableProvince?.firstName}, ${responsableProvince?.coord?.adress?.streetName} ${responsableProvince?.coord?.adress?.streetNumber} ${responsableProvince?.coord?.adress?.streetNumberBte ? `Bte ${responsableProvince?.coord?.adress?.streetNumberBte}` : ''} - ${responsableProvince?.coord?.adress?.postalCode} ${responsableProvince?.coord?.adress?.city} (${responsableProvince?.coord?.adress?.countryLibelle})`}</Text>
            <Text style={{ fontSize: 10 }}>{`${responsableProvince?.coord?.email}`}</Text>
        </View>

        <View style={{ borderWidth: 1, marginTop: 20, marginBottom: 20, padding: 15, backgroundColor: '#eeeeee' }}>
            <Text style={{ fontSize: 14, textAlign: 'center' }}>{`Calendrier Province ${annee}`}</Text>
            <Text style={{ fontSize: 12, textAlign: 'center' }}>{`${province?.matricule} - ${province?.name}`}</Text>
        </View>

        <PdfTableWalksV2 walks={marches} />

    </PdfWorker>);
}