import React from 'react'
import AuthService from './createAuthProvider'
import { Route, useHistory } from 'react-router-dom'

export default ({ children, ...rest }) => {
    const isLoggedIn = AuthService.isLoggedIn();
    const history = useHistory();

    if (!isLoggedIn) {
        history.push("/login");
        return (<p>Redirection vers l'écran de connexion</p>);
    }
       
    return (<Route {...rest} >
        {children}
    </Route>);
}