import React from 'react';
import createTokenProvider from '../../../services/createTokenProvider';

export default _ => {
    let titre = "Tous Responsables de la FFBMP";
    let url_fichier = `/api/v1/orga/all/xlsx?jwt=${createTokenProvider().getToken()}`; 

    const handleClick = _ => window.open(url_fichier, '_blank');

    return (<div className="d-flex justify-content-between align-items-center">
        <p className="my-auto"><span className="badge badge-success mr-3">XLSX</span>{titre}</p>
        <button className="btn btn-primary ml-3" onClick={handleClick} >
            <i className="fas fa-sm fa-arrow-down" data-toggle="tooltip" title="Télécharger"  />
        </button>
    </div>);
}