import { useEffect, useState } from "react"
//DEPRECATED
export default (func) => {
    const [state, setState] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState();

    const load = _ => {
        setIsLoading(true);
        setError();
        func
            .then(res => setState(res))
            .catch(err => setError(err))
            .finally(_ => setIsLoading(false));
    }

    useEffect(load, []);

    return { state, isLoading, error, reload:load };
}