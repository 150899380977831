import React from 'react';

const BarreRechercheMembres = ({ search, setSearch }) => {
    return (
        <input
            className="form-control"
            type="search"
            placeholder="Rechercher un membre"
            value={search}
            onChange={e => setSearch(e.target.value)}
        />
    );
}
 
export default BarreRechercheMembres;