import React from 'react';
import Input from './Input';
import CheckInput from './CheckInput';

export default (props) => {
    const { type } = props;

    if (type === "checkbox")
        return <CheckInput {...props} />
    else
        return <Input {...props } />
}