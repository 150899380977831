import React, { useEffect, useState } from 'react';
import { ParametresApi } from '../../../api/ParametresApi';
import FormatDate from '../../../lib/FormatDate';
import { ToEuro } from '../../../lib/FormatNum';
import Button from '../../0components/Button';
import { PDFDownloadLink, Text, Row, View, Table2, CellHeader, Cell } from '../PdfWorker';
import AssuranceLayoutPDF from './AssuranceLayoutPDF';

export default ({ demande }) => {
    const [cout, setCout] = useState();
    const [iban, setIban] = useState();
    const [bic, setBic] = useState();

    useEffect(_ => {
        ParametresApi
            .get("MONTANT_A144")
            .then(res => setCout(parseFloat(res.value)))

        ParametresApi
            .get("ASSNUMCPTIBAN")
            .then(res => setIban(res.value))

        ParametresApi
            .get("ASSNUMCPTBIC")
            .then(res => setBic(res.value))
    }, [])

    if (!cout || !demande || !iban || !bic)
        return <p>chargement</p>;
    
    if (!cout || !demande)
        return <p>chargement</p>;

    return (<PDFDownloadLink document={<MonDocument demande={demande} cout={cout} iban={iban} bic={bic} />} fileName={`DemandeAssurance${demande.numeroDemande}.pdf`}>
        {({ loading }) => (loading ? 'Génération du document...' : <Button icon="fa-download">Télécharger</Button>)}
    </PDFDownloadLink>);
}

const MonDocument = ({ demande, cout, iban, bic }) => {
    const mydate = d => d ? FormatDate(d) : "...../...../.....";

    return (<AssuranceLayoutPDF demande={demande} titre="Incendie «Bâtiment privé»" subtitle="Demande de couverture d'assurance: Police n°38.140.811" roi="ROI ASS 144">

        <Row>
            <View style={{ width: "50%", flexDirection: "row" }}>
                <View style={{ width: "50%" }}>
                    <Text style={{ fontSize: 10 }}>Adresse de la salle de départ:</Text>
                </View>
                <View style={{ width: "50%", paddingLeft: 5 }}>
                    <Text style={{ fontSize: 10 }}>{demande?.adresseDeDepart ?? ""}</Text>
                </View>
            </View>
        </Row>

        <View style={{margin:10}}/>

        <Table2>
            <Row>
                <CellHeader style={{ width: "100%" }}>Nom et adresse complète des propriétaires de tous les contrôles</CellHeader>
            </Row>
            <Row>
                <CellHeader style={{ width: "20%" }}>Local</CellHeader>
                <CellHeader style={{ width: "20%" }}>Nom</CellHeader>
                <CellHeader style={{ width: "20%" }}>Prénom</CellHeader>
                <CellHeader style={{ width: "40%" }}>Adresse</CellHeader>
            </Row>
            {demande?.adresseControles?.map((e, i) => <Row key={i}>
                <Cell style={{ width: "20%" }}>{e?.local ?? " "}</Cell>
                <Cell style={{ width: "20%" }}>{e?.nomProprietaire ?? " "}</Cell>
                <Cell style={{ width: "20%" }}>{e?.prenomProprietaire ?? " "}</Cell>
                <Cell style={{ width: "40%" }}>{e?.adresseProprietaire ?? " "}</Cell>
            </Row>)}
        </Table2>

        <Text style={{ fontSize: 10, marginBottom: 10, marginTop: 10 }}>Marche organisée du {mydate(demande?.dateOrganisationDebut)} au {mydate(demande?.dateOrganisationFin)}</Text>
        <Text style={{ fontSize: 10, marginBottom: 10 }}>Durée de la demande de couverture du {mydate(demande?.dateCouvertureDebut)} au {mydate(demande?.dateCouvertureFin)}</Text>

        <View style={{ borderWidth: 1, padding: 3, marginTop: 20 }}>
            <Text style={{ fontSize: 10 }}>{`Je verse la somme de ${ToEuro(cout)}`}</Text>
            <Text style={{ fontSize: 10 }}>{`Sur le compte ${iban} - ${bic}`}</Text>
            <Text style={{ fontSize: 10 }}>{`"Assurances F.F.B.M.P. asbl - C.A."`}</Text>
        </View>

    </AssuranceLayoutPDF>);
}