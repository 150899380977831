import React, { useState } from 'react';
import { useHistory, useLocation} from "react-router-dom";
import { Spinner } from 'reactstrap';

import { MarchesApi } from 'api/MarchesApi';

import WalkForm from './WalkForm';

export default _ => {
    const { state } = useLocation();
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);

    const submit = (e, fields) => {
        e.preventDefault();
        setIsLoading(true);
        if (fields?.distances?.length === 0) {
            alert("Les distances ne peuvent pas être vide");
            return;
        }

        MarchesApi
            .create(fields)
            .then(_ => history.goBack())
            .finally(_ => setIsLoading(false));
    }

    if (isLoading)
        return <Spinner />;

    return (<WalkForm
        title="Créer une marche"
        data={{ "groupId": state?.groupId, "adress": { "countryCode": 'BE' }, "legende":[] }}
        submit={submit}
    />);
}