import React from 'react';

export default ({ required, label, value, onChange, ...rest }) => {
    return (<div className="m-2">
        {label ? <Label required={required || false} label={label} /> : ""}
        <input className="form-control mr-sm-2" {...rest} value={value ?? ""} onChange={e => { e.preventDefault(); onChange(e.target.value); }} />
    </div>);
}

export const Required = _ => <b className="text-danger"> *</b>;
export const Label = ({ label, required }) => label ? <label>{label}{required ? <Required /> : ""}</label> : "";