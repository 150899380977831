import React, { useState, useEffect } from 'react';
import { useHistory, useParams, NavLink, Switch, Redirect } from 'react-router-dom';
import Spinner from '../0components/Spinner';

import { ProvinceApi } from '../../api/ProvinceApi';
import Bubble from './Bubble';

import Assurances from './Assurances';
import Organigramme from './Organigramme'; 

import useAcces from '../../services/AccessControl/useAcces';
import useAcces2 from '../../services/AccessControl/useAcces2';
import { Actions, Objects, Show, Route } from '../../services/AccessControl';
import ListeAttenteCandidats from './ListeAttenteCandidats';
import ListeAttenteMarches from './ListeAttenteMarches';
import ListeAttenteAssurances from './ListeAttenteAssurances';
import Marches from './Marches';
import ModalInfo from './ModalInfo';
import { ProvinceApiV2 } from '../../api/ProvinceApiV2';

export default _ => {//443
    const { id } = useParams();//id de la province
    const hasPerm = useAcces();
    const history = useHistory();
    const hasPerm2 = useAcces2();

    const path = `/provinces/${id}`;
    const initBeginSearch = new Date().getFullYear() + "-01-01";
    const initEndSearch = new Date().getFullYear() + "-12-31";

    const [province, setProvince] = useState();
    const [isProvinceLoading, setIsProvinceLoading] = useState(true);
    
    const [marches, setMarches] = useState();
    const [isMarchesLoading, setIsMarchesLoading] = useState(true);

    const [orga, setOrga] = useState();
    const [isOrgaLoading, setIsOrgaLoading] = useState(true);

    const [ass, setAss] = useState();
    const [isAssLoading, setIsAssLoading] = useState(true);

    const [assValid, setAssValid] = useState();
    const [isAssValidLoading, setIsAssValidLoading] = useState(true);

    const [mv, setmv] = useState();//marches validation
    const [ismvLoading, setIsmvLoading] = useState(true);

    const [cv, setcv] = useState(); //candidat validation
    const [iscvLoading, setIscvLoading] = useState(true);

    const [clubs, setClubs] = useState([]);

    const fetchProvince = id => {
        ProvinceApi
            .get(id)
            .then(res => setProvince(res))
            .finally(_ => setIsProvinceLoading(false));
    };

    const fetchClubs = id => {
        ProvinceApiV2
            .getClubs(id, true)
            .then(res => setClubs(res));
    };

    const fetchCV = _ => {
        ProvinceApi
            .getCandidats(id)
            .then(res => setcv(res))
            .finally(_ => setIscvLoading(false));
    };

    const fetchAssurances = (id, startDate, endDate) => {
        ProvinceApi
            .getInsurance(id, startDate, endDate)
            .then(res => setAss(res))
            .finally(_ => setIsAssLoading(false));
    };

    const fetchAV = _ => {
        ProvinceApi
            .getInsuranceToValid(id)
            .then(res => setAssValid(res))
            .finally(_ => setIsAssValidLoading(false));
    };

    const fetchOrga = id => {
        ProvinceApi
            .getOrga(id)
            .then(res => setOrga(res))
            .finally(_ => setIsOrgaLoading(false));
    };

    const fetchMarches = (id, startDate, endDate) => {
        ProvinceApi
            .getWalks(id, startDate, endDate)
            .then(res => setMarches(res))
            .finally(_ => setIsMarchesLoading(false));
    };

    const fetchMV = _ => {
        ProvinceApi
            .getWalksToValid(id)
            .then(res => setmv(res))
            .finally(_ => setIsmvLoading(false));
    };

    function InitFetching()
    {
        fetchProvince(id, initBeginSearch, initEndSearch);
        fetchClubs(id);
        fetchMarches(id, new Date().toISOString(), initEndSearch);
        fetchOrga(id);
        fetchAssurances(id);
        fetchAV();
        fetchMV();
        fetchCV();
    }

    useEffect(InitFetching, [id]);

    const handleCreateNewClub = _ => {
        history.push('/clubs/create', {
            idgrp: id,
            grptype: '8baa825c-cf9a-11de-90a6-001372568c9a'
        })
    };

    const handleClickAssurance = id => history.push(`/assurances/${id}`);

    const handleNouvAss = code => history.push(`/assurances/new`, { code: code, grpid: id });

    if (!hasPerm(Objects.Club, Actions.Read, id))
    {
        history.push('/403');
        return;
    }

    if (isProvinceLoading )
        return <Spinner />;

    return (<>
        <div className="row mb-4 justify-content-center">
            <div className="col-auto">
                <h1 className="display-4">{province?.name}</h1>
            </div>
            <div className="col-auto ml-3 m-auto">
                <ModalInfo club={province} />
            </div>
            <div className="col" />
        </div>
        <div className="row mb-4">           
            <Show object={Objects.Club} action={Actions.Read} ressource={id}>
                <div className="col-md">
                    <Bubble
                        title="Clubs Actifs"
                        color="primary"
                        data={province?.clubs?.length}
                        dataLoading={isProvinceLoading}
                        icon='fa-user-group'
                    />
                </div>
            </Show>

            <Show object={Objects.Candidat} action={Actions.Valid} ressource={id}>
                <div className="col-md">
                    <Bubble
                        title="Candidats en attente"
                        color={(cv?.length > 0 ? "warning" : "info")}
                        data={cv?.length ?? 0}
                        dataLoading={iscvLoading}
                        icon='fa-user-check'
                    />
                </div>
            </Show>

            <Show object={Objects.Marche} action={Actions.Valid} ressource={id}>
                <div className="col-md">
                    <Bubble
                        title="Marches a valider"
                        color={(mv?.length > 0 ? "warning" : "info")}
                        data={mv?.length ?? 0}
                        dataLoading={ismvLoading}
                        icon='fa-calendar-check'
                    />
                </div>
            </Show>

            <Show object={Objects.Marche} action={Actions.Read} ressource={id}>
                <div className="col-md">
                    <Bubble
                        title="Marches à Venir"
                        color="primary"
                        data={marches?.length}
                        dataLoading={isMarchesLoading}
                        icon='fa-calendar'
                    />
                </div>
            </Show>

            <Show object={Objects.Assurance} action={Actions.Valid} ressource={id}>
                <div className="col-md">
                    <Bubble
                        title="Assurances en attente"
                        color={(assValid?.length > 0 ? "warning" : "info")}
                        data={assValid?.length ?? 0}
                        dataLoading={isAssValidLoading}
                        icon='fa-users'
                    />
                </div>
            </Show>

        </div>
        <div className="card p-4">
            <div className="row">
                <div className="col-xl-auto">
                    <ul className="nav nav-pills flex-column">

                        {hasPerm2(id) && <li className="nav-item">
                            <NavLink className="nav-link" to={path} exact>
                                <i className="fa-solid fa-user-group" /> Clubs
                            </NavLink>
                        </li>}

                        {hasPerm(Objects.Candidat, Actions.Valid, id) && hasPerm2(id) && <li className="nav-item">
                            <NavLink className="nav-link" to={path + '/validcandidats'} exact>
                                <i className="fa-solid fa-user-check" /> Candidats à valider {cv?.length > 0 && <span className="badge badge-danger">{cv?.length}</span>}
                            </NavLink>
                        </li>}

                        {hasPerm(Objects.Marche, Actions.Valid, id) && hasPerm2(id) && <li className="nav-item">
                            <NavLink className="nav-link" to={path + '/validmarches'}>
                                <i className="fa-solid fa-calendar-check" /> Marches à valider {mv?.length > 0 && <span className="badge badge-danger">{mv?.length}</span>}
                            </NavLink>
                        </li>}

                        {hasPerm(Objects.Marche, Actions.Read, id) && hasPerm2(id) && <li className="nav-item">
                            <NavLink className="nav-link" to={path + '/marches'}>
                                <i className="fa-solid fa-calendar" /> Marches
                            </NavLink>
                        </li>}

                        {hasPerm(Objects.Assurance, Actions.Valid, id) && hasPerm2(id) && <li className="nav-item">
                            <NavLink className="nav-link" to={path + '/validassurances'}>
                                <i className="fa-solid fa-file-circle-check" /> Assurances à valider {assValid?.length > 0 && <span className="badge badge-danger">{assValid?.length}</span>}
                            </NavLink>
                        </li>}

                        {hasPerm(Objects.Assurance, Actions.Read, id) && hasPerm2(id) && <li className="nav-item">
                            <NavLink className="nav-link" to={path + '/assurances'}>
                                <i className="fa-solid fa-file" /> Assurances
                            </NavLink>
                        </li>}

                        {hasPerm(Objects.Organigramme, Actions.Read, id) && <li className="nav-item">
                            <NavLink className="nav-link" to={path + '/organigramme'}>
                                <i className="fa-solid fa-sitemap" /> Organigramme
                            </NavLink>
                        </li>}
                    </ul>
                </div>
                <div className="col-auto">
                    <div className="vr" style={{ "height": "100%" }}/>
                </div>
                <div className="col-xl">
                    <Switch>
                        <Route exact path="/provinces/:id" object={Objects.Club} action={Actions.Read} ressource={id}>
                            <ListeClubs
                                id={id}
                                clubs={clubs}
                                handleCreateNewClub={handleCreateNewClub}
                            />
                        </Route>

                        <Route exact path="/provinces/:id/validcandidats" object={Objects.Candidat} action={Actions.Valid} ressource={id}>
                            <ListeAttenteCandidats
                                liste={cv}
                                isLoading={iscvLoading}
                            />
                        </Route>

                        <Route exact path="/provinces/:id/validmarches" object={Objects.Marche} action={Actions.Valid} ressource={id}>
                            <ListeAttenteMarches
                                liste={mv}
                                isLoading={ismvLoading}
                            />
                        </Route>

                        <Route exact path="/provinces/:id/validassurances" object={Objects.Assurance} action={Actions.Valid} ressource={id}>
                            <ListeAttenteAssurances
                                liste={assValid}
                                isLoading={isAssValidLoading}
                            />
                        </Route>

                        <Route path="/provinces/:id/marches" object={Objects.Marche} action={Actions.Read} ressource={id}>
                            <Marches
                                id={id}
                                isLoading={isMarchesLoading }
                                marches={marches}
                            />
                        </Route>

                        <Route path="/provinces/:id/assurances" object={Objects.Assurance} action={Actions.Read} ressource={id}>
                            <Assurances
                                assurances={ass}
                                isLoading={isAssLoading}
                                handleClickAssurance={handleClickAssurance}
                                handleSearch={(beginSearch, endSearch) => { setIsAssLoading(true); fetchAssurances(id, beginSearch, endSearch); }}
                                initBeginSearch={initBeginSearch}
                                initEndSearch={initEndSearch}
                                handleNouvAss={handleNouvAss}
                            />
                        </Route>

                        <Route path="/provinces/:id/organigramme" object={Objects.Organigramme} action={Actions.Read} ressource={id}>
                            <Organigramme
                                id={id}
                                organigramme={orga}
                                isLoading={isOrgaLoading}
                                reload={_ => fetchOrga(id)}
                            />
                        </Route>

                        <Route path="*">
                            <Redirect to="/404"/>
                        </Route>

                    </Switch>
                </div>
            </div>
        </div>
    </>);
}

const ListeClubs = ({ handleCreateNewClub }) => {
    const history = useHistory(); 
    const { id } = useParams();//id de la province

    const [clubs, setClubs] = useState([]);
    const [isClubsLoading, setIsClubsLoading] = useState(true);
    const [selectionnerClubsActifs, setSelectionnerClubsActifs] = useState(true);

    useEffect(_ => {
        setIsClubsLoading(true);
        ProvinceApiV2
            .getClubs(id, selectionnerClubsActifs)
            .then(res => setClubs(res))
            .finally(_ => setIsClubsLoading(false));
    }, [id, selectionnerClubsActifs]);

    const handleClickClub = id => history.push(`/clubs/${id}`);

    return (<>
        <div className="row mb-3">
            <div className="col">
                <h3 className="h3">Clubs</h3>
            </div>
            <div className="col-auto m-auto">
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" checked={selectionnerClubsActifs} id="flexCheckChecked" onChange={e => setSelectionnerClubsActifs(e.target.checked)}/>
                    <label className="form-check-label">
                        Uniquement clubs actifs
                    </label>
                </div>
            </div>
            <div className="col-auto">
                <div onClick={handleCreateNewClub}>
                    <Show object={Objects.Club} action={Actions.Create}>
                        <button
                            type="button"
                            data-toggle="tooltip"
                            title="Créer un nouveau club"
                            className="btn btn-primary m-1"
                        >
                            Nouveau Club
                            <i className="fas fa-fw fa-plus ml-1" />
                        </button>
                    </Show>
                </div>
            </div>
        </div>
        <div className="card">
            {isClubsLoading ?
                <Spinner />
                :  
                <table className="table table-hover" >
                    <thead className="thead-light">
                        <tr >
                            <th scope="col" className="text-dark">Matricule</th>
                            <th scope="col" className="text-dark" >Nom du club</th>
                        </tr>
                    </thead>
                    <tbody>
                        {clubs?.map(club => (<tr key={club.id} onClick={_ => handleClickClub(club.id)} className={club.isActive ? "" : "table-danger"} >
                            <th scope="row" className="align-middle">{club.matricule}{club.isActive ? "" : " -Inactif-"}</th>
                            <td className="align-middle">{club.name}</td>
                        </tr>))}
                    </tbody>
                </table>
            }
        </div>
    </>);
}